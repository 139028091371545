import { createContext, useReducer } from 'react'
import { layoutReducer } from './layoutReducer'

export const layoutContext = createContext()

const initialState = {
	isStaticNoteMutation: false,
}

export const LayoutContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(layoutReducer, initialState)

	return <layoutContext.Provider value={{ state, dispatch }}>{children}</layoutContext.Provider>
}
