import { Dialog, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { forwardRef } from 'react'

const ContentDialog = forwardRef(({ overlayDialogRef, handleClose, open, children }, ref) => {
	const useStyles = makeStyles({
		newPosOfDialog: {
			position: 'absolute',
			top: '5%',
			boxShadow: 'none',
			backgroundColor: 'transparent',
			background: 'transparent',
			width: '380px',
			overflow: 'hidden',
			minHeight: '40px',
		},
	})
	const classes = useStyles()

	return (
		<div>
			<Dialog
				open={open}
				container={overlayDialogRef.current}
				onClose={handleClose}
				sx={{ position: 'absolute' }}
				classes={{
					paper: classes.newPosOfDialog,
				}}
				slotProps={{
					backdrop: {
						style: {
							position: 'inherit',
							backdropFilter: 'blur(5px)',
							backgroundColor: 'rgba(243, 243, 246, 0.6)',
						},
					},
				}}
			>
				{children}
			</Dialog>
		</div>
	)
})

export default ContentDialog
