import styled from 'styled-components'
import { getNodeIcon, vizTitleLengthFormat } from '@lynit/shared/src/utils/utils'
import {
	Connection,
	ElementCardContainer,
	ElementName,
	ElementNameLable,
	IconContainer,
	PremiseLine,
	TooltipDescription,
	TooltipLabel,
	TooltipWrapper,
} from './styles'
import missingStructureTagIcon from '@lynit/shared/src/images/missingStructureTag.svg'
import missingStructureTagConnectionIcon from '@lynit/shared/src/images/missing-structuretag-icon.svg'
import { makeStyles } from '@mui/styles'
import HtmlTooltip from '@lynit/shared/src/ui/ToolTip/HtmlTooltip'
import { useContext, useEffect, useRef } from 'react'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { useDeleteConnectionMutation, useGetRelationshipsQuery } from '@lynit/shared/src/hooks'
import { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import deleteIcon from '@lynit/shared/src/images/deleteIcon.svg'
import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'
import getConnections from '@lynit/shared/src/utils/getConnections'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { setActiveConnections, setElementToDelete, setNewConnId } from '@lynit/shared/src/state/actions'
import { sharedContext } from '@lynit/shared/src/state'

const MissingStructureTagDriver = ({
	text,
	handleClick,
	connectionModalType,
	referenceElement,
	id,
	connectionsArr,
	recommendedDriver,
	handleClose = () => {},
	sourceElement
}) => {
	const client = useApolloClient()
	const { createLog } = useContext(systemStateContext)
	const getShortName = name => {
		return vizTitleLengthFormat(name, 11)
	}
	const modalRef = useRef()
	const graphData = useContext(graphDataStateContext)
	const { data: relationships } = useGetRelationshipsQuery({ fetchPolicy: 'cache-only' , component:"MissingStructureTagDriver"})
	const [performDeleteConnectionMutation] = useDeleteConnectionMutation()
	const [currentElement, setCurrentElement] = useState()
	
	const {
		state: {
			deleteNode,
			cacheConnData: { newConnId },
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	const useStyles = makeStyles(() => ({
		tooltip: {
			marginLeft: 0,
			background: 'transparent',
		},
		tooltipSm: {
			margin: '0 auto',
			background: 'transparent',
			width: '100%',
			padding: 0,
			marginTop: '6px !important',
			margin: '6px 0',
		},
	}))

	useEffect(() => {
		if (modalRef) {
			if (!recommendedDriver) {
				// let recommendedDriver = {
				// 	id: connectionsArr?.all.find(connection => connection.destNode.id === id)?.id,
				// }
				// handleClick(
				// 	modalRef.current,
				// 	(connectionModalType = {
				// 		...connectionModalType,
				// 		recommendedDriver,
				// 	}),
				// )
			} else {
				handleClick(
					modalRef.current,
					(connectionModalType = {
						...connectionModalType,
						recommendedDriver,
					}),
				)
				createLog(
					'Relationship Clicked',
					`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${connectionModalType?.type}"}`,
					'StoryViz',
					null,
				)
				// createLog(
				// 	'Connection Creation Attempted',
				// 	{"workflowStep":1,"connectionType":"Static", "connectionModalType":connectionModalType.type
				// 	},
				// 	'StoryViz',
				// 	'Connection Creation',
				// )
			}
		}
	}, [modalRef])

	const removeConnection = async connToDelete => {
		let elementToDelete

		elementToDelete = relationships?.relations?.find(e => e?.id === connToDelete?.id)
		await performDeleteConnectionMutation({
			id: connToDelete?.__typename !== 'Note' ? connToDelete?.id : connToDelete?.id,
			client,
			elementToDelete,
			isCreateCacheConnection: true,
		})
		handleClose()
	}
	const classes = useStyles()
	return (
		<HtmlTooltip
			title={
				<img
					src={deleteIcon}
					onClick={event => {
						event.stopPropagation()
						if (!JSON.stringify(id)?.includes('temp')) {
							sharedDispatch(
								setElementToDelete({
									isActive: true,
									type: 'Relationship',
									id,
								}),
							)
							createLog(
								'Connection Deletion Attempted',
								`{"workflowStep":${1},"connectionType":"Static"}`,
								'StoryViz',
								'Connection Deletion',
							)
						}
					}}
				/>
			}
			placement="left"
		>
			<ConnectionContainer
				id={`connectionCard`}
				onMouseEnter={() =>
					createLog(
						'Relationship Hover',
						`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${connectionModalType?.type}"}`,
						'Missing Structure Tag Driver',
						null,
					)
				}
			>
				<ElementCardContainer>
					<div>
						<HtmlTooltip
							title={
								<TooltipWrapper>
									<TooltipLabel>Missing relationship type</TooltipLabel>
									<TooltipDescription>
										Click <img src={missingStructureTagIcon} width={'14px'} height={'14px'} /> to
										select the relationship type{' '}
									</TooltipDescription>
								</TooltipWrapper>
							}
							placement={'bottom'}
							classes={{
								tooltip: classes.tooltipSm,
							}}
							ref={modalRef}
							onClick={e => {
								if (!recommendedDriver) {
									let recommendedDriver = {
										id: connectionsArr?.all
											? connectionsArr?.all.find(connection => connection.destNode.id === id)?.id
											: connectionsArr.find(connection => connection.destNode.id === id)?.id,
									}
									handleClick(
										e.currentTarget,
										(connectionModalType = {
											...connectionModalType,
											recommendedDriver,
										}),
									)
								} else {
									handleClick(
										e.currentTarget,
										(connectionModalType = {
											...connectionModalType,
											recommendedDriver,
										}),
									)
								}

								createLog(
									'Relationship Clicked',
									`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${connectionModalType?.type}"}`,
									'Missing Structure Tag Driver',
									null,
								)
								// createLog(
								// 	'Connection Creation Attempted',
								// 	{"workflowStep":1,"connectionType":"Static", "connectionModalType":connectionModalType.type
								// 	},
								// 	'Missing Structure Tag Driver',
								// 	'Connection Creation',
								// )
							}}
						>
							<ConnectionCardContainer>
								<ElementName>
									<IconContainer isSelected={true} color={referenceElement?.__typename}>
										<img src={getNodeIcon(referenceElement?.__typename)} />
									</IconContainer>{' '}
									<HtmlTooltip
										title={
											<TooltipWrapper>
												<TooltipDescription>Open {referenceElement?.name}</TooltipDescription>
											</TooltipWrapper>
										}
										placement={'bottom'}
										classes={{
											tooltip: classes.tooltipSm,
										}}
									>
										<ElementNameLable
											onClick={e => {
												e.stopPropagation()
												sharedDispatch(
													setActiveConnections({
														isActive: true,
														elementId: referenceElement.id,
														elementType: referenceElement.__typename,
														docType:"Driver", 
														appView:"Outlining"
													}),
												)
											}}
										>
											{getShortName(referenceElement?.name)}
										</ElementNameLable>
									</HtmlTooltip>
								</ElementName>
								<Connection>
									<PremiseLine>
										<img src={missingStructureTagConnectionIcon} height={'36px'} />
									</PremiseLine>
								</Connection>
								<ElementName>
									<IconContainer isSelected={true} color={connectionModalType?.type}>
										<img src={getNodeIcon(connectionModalType?.type)} />
									</IconContainer>{' '}
									<HtmlTooltip
										title={
											<TooltipWrapper>
												<TooltipDescription>Open {sourceElement?.name || ""}</TooltipDescription>
											</TooltipWrapper>
										}
										placement={'bottom'}
										classes={{
											tooltip: classes.tooltipSm,
										}}
									>
										<ElementNameLable
											onClick={e => {
												e.stopPropagation()
												sharedDispatch(
													setActiveConnections({
														isActive: true,
														elementId: sourceElement.id,
														elementType: sourceElement.__typename,
														docType:"Driver", 
														appView:"Outlining"
													}),
												)
											}}
										>
											{getShortName(text)}
										</ElementNameLable>
									</HtmlTooltip>
								</ElementName>
							</ConnectionCardContainer>
						</HtmlTooltip>
					</div>
				</ElementCardContainer>
			</ConnectionContainer>
		</HtmlTooltip>
	)
}

export default MissingStructureTagDriver

const ConnectionContainer = styled.div`
	border-radius: 4px;
	cursor: pointer;
`

const ConnectionCardContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
