import { useApolloClient } from '@apollo/client'
import React, { createContext, useReducer } from 'react'

import { useDeleteConnectionMutation } from '../hooks'
import { deleteCacheConnections, updateCacheOrderBeat } from '../utils/apollo'
import { sharedReducer } from './sharedReducer'
import { toastHandler } from '../utils/backendHandler'

export const beatsDataStateContext = createContext()
export const beatsDataDispatchContext = createContext()

const initialbeatsData = {
	currentElement: '',
	serverError: false,
	noElementError: false,
	connectionData: {},
	currentStoryId: null,
	deepModeElement: '',
	reorderedPosition: null,
	fieldError:false,
}

export const setBeatsData = payload => {
	return {
		type: 'SET_BEATS_DATA',
		payload,
	}
}

export const beatsDataReducer = (state, action) => {
	switch (action.type) {
		case 'SET_BEATS_DATA':
			return action.payload
		default:
			return state
	}
}

export const BeatsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(sharedReducer, initialbeatsData)
	const [performDeleteConnectionMutation] = useDeleteConnectionMutation()
	const client = useApolloClient()

	const removeBeatConnection = async (beatConnection, referenceElement) => {
	
		try {
			if (!beatConnection?.id?.includes('temp')) {
				const mutationData = []
				referenceElement.beatConnections.forEach(connection => {
					if (+connection?.order > +beatConnection?.order) {
						mutationData.push({
							id: connection?.id,
							order: String(+connection?.order - 1),
						})
					}
				})
				
				await performDeleteConnectionMutation({
					id: beatConnection?.id,
					client,
					elementToDelete:beatConnection,
					mutationData
				})
			}
		} catch (error) {
			console.error(error)
			toastHandler('error', 'Error while removing from chapter. Try again.')
		}
	}

	return (
		<beatsDataStateContext.Provider value={{ ...state, removeBeatConnection }}>
			<beatsDataDispatchContext.Provider value={dispatch}>
				{children}
			</beatsDataDispatchContext.Provider>
		</beatsDataStateContext.Provider>
	)
}

export default BeatsProvider
