import { useContext, useEffect, useState } from 'react'
import { useLazyGetRelationshipsQuery } from '../../hooks'
import getConnections from '../../utils/getConnections'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { DriverListWrapper, RecentRelationshipHeader } from '../DriverList/styles'
import NewConnectionCard from '@lynit/layout/src/ui/ListElement/NewConnectionCard'
import { systemStateContext } from '../../state/systemProvider'
import CreateConnectionModal from '@lynit/shared/src/ui/CreateConnectionModal'
import MissingStructureTagDriver from '@lynit/layout/src/ui/ListElement/MissingStructureTagDriver'
import MissingPremiseDriver from '@lynit/layout/src/ui/ListElement/MissingPremiseDriver'
import { analyticsDataStateContext } from '../../state/analyticsDataProvider'

const AllRelationshipList = ({premiseRelationshipList}) => {
	const { createLog } = useContext(systemStateContext)
	const graphData = useContext(graphDataStateContext)
	const [openedConnectionCard, setOpenedConnectionCard] = useState(false)
	
	const [connectionsArr, setConnectionsArr] = useState([])
	const [connectionModalType, setConnectionModalType] = useState(false)
	const [connectionModal, setConnectionModal] = useState(false)
	const [currentElementConnection, setCurrentElementConnection] = useState(false)


	const {missingPremiseRelationships} = useContext(analyticsDataStateContext)



	const handleMissingDriverClick = (event, connectionModalType = { type: 'Opposing' }) => {

		setConnectionModalType(connectionModalType)
		setConnectionModal(event)
		setCurrentElementConnection( connectionModalType.referenceElement )
		
	}

	return (
		<>
			<RecentRelationshipHeader>
				<DriverListWrapper>
				{missingPremiseRelationships?.map((missingConn, index) => {
							return (
							<MissingPremiseDriver
								key={missingConn.sourceNode.id + missingConn.destNode.id}
								id={missingConn.sourceNode.id}
								text={missingConn.sourceNode.name}
								handleClick={handleMissingDriverClick}
								connectionModalType={{
									type: missingConn.sourceNode.__typename,
									recommendedDriverId: missingConn.sourceNode.id,
									isRecommendedDriver: true,
									referenceElement:missingConn.destNode
								}}
								referenceElement={missingConn.destNode}
							/>
						

							)
						} )}
					{premiseRelationshipList.length > 0 ? (
						premiseRelationshipList?.map((element, index) => {
							return element.structureTag ? (
								<NewConnectionCard
									key={element?.id}
									isDefaultOpened={element?.source}
									id={element?.id}
									name={element?.name}
									type={element?.__typename}
									structureTag={element?.structureTag}
									beatsDriven={element?.beatsDriven}
									sourceNode={element?.sourceNode}
									description={element?.description}
									firstBeat={element?.firstBeat}
									disableHoverListener={true}
									createLog={createLog}
									handleClose={() => {
										setOpenedConnectionCard(false)
									}}
									setCurrentElement={() => {}}
									sharedBeats={element?.sharedBeats}
									referenceElement={graphData?.nodes[element?.sourceNode?.id]}
									destNode={graphData?.nodes[element?.destNode.id]}
									isNewStructureTag={element?.source}
									tabValue={0}
									setOpenedConnectionCard={setOpenedConnectionCard}
									openedConnectionCard={openedConnectionCard}
									isAddingExistingBeatVisible={false}
									parentLocation="Search"
								/>
							) : (
								<MissingStructureTagDriver
									key={`structureTag-${element.id}`}
									id={element.id}
									type={element.__typename}
									text={element.name}
									handleClick={(event, connectionModalType = { type: 'Opposing' }) => {
										setConnectionModal(event)
										setConnectionModalType(connectionModalType)
										setCurrentElementConnection(element?.sourceNode)
									}}
									handleClose={() => {
										// setCurrentOpenedDriver(null)
										setOpenedConnectionCard(false)
									}}
									connectionsArr={connectionsArr}
									connectionModalType={{
										type: element.__typename,
										recommendedDriverId: element.id,
									}}
									// recommendedDriver={node}
									referenceElement={graphData?.nodes[element.sourceNode.id]}
									sourceElement={graphData?.nodes[element.destNode.id]}
									// setCurrentDriverType={setCurrentDriverType}
								/>
							)
						})
					) : (
						<></>
					)}
				</DriverListWrapper>
			</RecentRelationshipHeader>
			{connectionModal && connectionModalType && (
				<CreateConnectionModal
					mainElementId={currentElementConnection?.id}
					mainElementType={currentElementConnection?.__typename}
					isOpenStructureTagModal={connectionModal}
					setStructureTagConnnectionModal={setConnectionModal}
					setConnectionModalType={setConnectionModalType}
					connectionModalType={connectionModalType}
					handleClose={({ id }) => {
						setConnectionModal(false)
					}}
					setIsNewStructureTag={() => {}}
				/>
			)}
		</>
	)
}
export default AllRelationshipList
