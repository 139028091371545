import styled from 'styled-components'
import { colorStyles, nodeColors } from '../../utils/commonStyles'

const getColor = type => {
	switch (type) {
		case 'Chapter':
			return nodeColors.Chapter
		case 'Character':
			return nodeColors.Character
		case 'Arc':
			return nodeColors.Arc
		case 'Event':
			return nodeColors.Event
		case 'Theme':
			return nodeColors.Theme
		default:
			return 'white'
	}
}

const ElementContainer = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border-radius: 3px;
	padding: 0 0 0 0.3rem;
	max-height: 32px;
	cursor: pointer;
	p {
		font-weight: normal;
		font-size: 14px;
	}
`
const DriverCount = styled.div`
	margin: 1rem 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	span {
		font-family: Source Sans Pro;
		font-size: 12px;
		font-weight: 600;
		line-height: 15.08px;
		text-align: left;
	}
`

const IconContainer = styled.div`
	display: flex;
	width: 18px;
	height: 18px;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	border: 1px solid ${props => nodeColors[props.type]};
	border-radius: 50%;
	object-fit: contain;
	margin-right: 0.4rem;
	background: ${props => nodeColors[props.type]};
	img {
		height: 15px;
		width: 15px;
	}
	p {
		font-size: 14px;
	}
`

const InputContainer = styled.div`
	height: 18px;
	padding-left: 5px;
	margin: 10px 0;
	flex-direction: row;
	position: sticky;
	display: flex;
	span {
		color: #232323;
		font-family: Source Sans Pro;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	input {
		width: 70%;
		border: none;
		background: transparent;
	}
	img {
		width: 16px;
		opacity: 0.6;
		padding: 0.3rem;
	}
`
const Heading = styled.div`
	height: 43.875px;
	border-radius: 5px 5px 0px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #d3f4ff;
	padding-left: 5px;
`
const DriverContainer = styled.div`
	//background-color: #f1fbff;
	padding-top: 10px;
`
const Line = styled.div`
	display: ${({ length }) => (length > 0 ? 'flex' : 'none')};
	height: 12px;
	width: 12px;
	border-radius: 50%;
	background-color: #b5b5b5;
	position: relative;
	z-index: 1;
	left: 10px;
	top: 10px;
`

const DriverInnerWrapper = styled.div`
	position: relative;
	margin: 10px 0;
	height: 100%;
	background-color: #fff;
	padding-left: 27px;
	display: flex;
	align-items: flex-start;
	.driver-arrow {
		position: relative;
		left: -12px;
		width: 260px;
		height: 32px;
	}

	// ::before {
	// 	position: absolute;
	// 	content: '';
	// 	width: 18px;
	// 	top: 15px;
	// 	border-top: 1px solid #000;
	// 	left: 15px;
	// 	z-index: 1;
	// }
`
const Information = styled.div`
	border-radius: 4px;
	background: #fff3ce;
	display: flex;
	align-items: flex-start;
	height: 20px;
	padding-top: 4px;
	p {
		font-weight: 600;
	}
	span {
		color: #232323;
		font-family: Source Sans Pro;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	img {
		padding: 0 5px;
	}
`
const ConnectionIconContainer = styled.div`
	position: absolute;
	top: 7px;
	left: 200px;
	img {
		height: 18px;
		width: 18px;
	}
	@media (min-width: 601px) and (max-width: 1024px) {
		left: 11px;
		top: 32px;
		img {
			height: 16px;
			width: 16px;
		}
	}
`
const CountIcon = styled.div`
	border-radius: 50%;
	background-color: #f5f5f5;
	color: #888585;
	font-family: Nunito Sans;
	font-size: 9px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	height: 12px;
	width: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		margin: 0 !important;
	}
`
const BeatCountSection = styled.div`
	position: relative;
	right: 240px;
	display: flex;
	align-items: center;
	min-width: 45px;
	height: 30px;
	justify-content: space-between;
	img {
		opacity: 0.5;
		height: 18px;
		width: 29px;
	}
`
const UpdateDriverButton = styled.div`
	display: flex;
	justify-content: center;
	margin: 10px 0;
	button {
    border-radius: 7px;
    border: none;
    background: #ffc300;
    box-shadow: 2px 5px 5px 0px rgb(0 0 0 / 10%);
    height: 32px;
    width: 119px;
		cursor: pointer;
}
`
const DriverWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex: 1;
	padding: 0 10px 0 0;
`

export {
	ElementContainer,
	IconContainer,
	InputContainer,
	Heading,
	DriverContainer,
	Information,
	DriverInnerWrapper,
	Line,
	ConnectionIconContainer,
	CountIcon,
	BeatCountSection,
	UpdateDriverButton,
	DriverWrapper,
	DriverCount,
}
