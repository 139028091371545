export const colorStyles = {
	darkGrey: '#8E8E8E',
	grey: '#D8D8D8',
	lightGrey: '#E4E4E4',
	black: '#000000',
	white: '#FFFFFF',
	borderGrey: '#DDDDDD',
	onyxGrey: '#3C3C3C',
	fontBlack: '#22222',
	fontGrey: '#4A4A4A',
	backgroundGrey: '#5C5C5C',
	lynitYellow: '#FFC300',
	lynitDeep: '#FFFBEC',
}

// Loading circle styles
export const progressCircle = {
	display: 'block',
	margin: 'auto',
	marginBottom: 40,
	marginTop: 40,
}
// 455ede
// TODO: pick more pleasant colors

export const nodeColors = {
	Chapter: '#000000',
	Character: '#3185FC',
	Theme: '#FF7C81',
	Note: '#c51162',
	Arc: '#DD429B',
	Event: '#41EAD4',
	Beat: '#78C091',
	Setting: "#0db602"
}


export const designationColor = {
	Premise:"#FFC300",
	Secondary:"#AF984E",
	Minor:"#C5C5C5"
}