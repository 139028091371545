import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import { colorStyles } from '../../utils/commonStyles'

const Spinner = styled(CircularProgress)`
	position: absolute;
	color: ${colorStyles.lynitYellow};
	z-index: 999;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const LoadingSpinner = () => <Spinner />
export default LoadingSpinner
