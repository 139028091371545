import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Quill from '../Quill/quill-lynit' //quill'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import { useApolloClient } from '@apollo/client'
import { CSS } from '@dnd-kit/utilities'
import { Divider, Menu, MenuItem, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ThreeDot from '../../images/ThreeDot.svg'
import AddDriverIcon from '../../images/AddDriver.svg'
import PlusIcon from '../../images/PlusIcon.svg'
import OutsideClickHandler from '../../utils/OutsideClickHandler'

import {
	useAddDriversToBeatsMutation,
	useCreateConnectionMutation,
	useDeleteConnectionMutation,
	useGetRelationshipsQuery,
	useMoveBeatMutation,
	useUpdateNodeMutation,
} from '../../hooks'

import { toastHandler } from '../../utils/backendHandler'
import {
	Beat,
	QuillContainer,
	MenuButton,
	DriversContainer,
	AddDriver,
	PlusSign,
	ElementIconContainer,
	NodeIconContainer,
	AdditionalItemContainer,
	MissingDriverWrapper,
	MilestoneBeatLabel,
	MilestoneBeatWrapper,
	TemplateText,
} from './styles'

import useQuillAutoSave from '../AutoSave/useQuillAutoSave'
import DriverModal from '../DriverModal'
import ShadowBeat from './ShadowBeat'
import ToolTip from '../ToolTip'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { userStateContext } from '../../state/userProvider'
import { systemStateContext } from '../../state/systemProvider'
import { beatsDataStateContext } from '../../state/beatsProvider'
import { useDndContext } from '@dnd-kit/core'
import TimelineContent from '@mui/lab/TimelineContent'
import { sharedContext } from '../../state'
import {
	createCacheConnection,
	deleteCacheConnections,
	updateCacheField,
	updateCacheOrderBeat,
} from '../../utils/apollo'
import { createNodeId, nodeTypeForId } from '../../utils/utils'
import missingDriverIcon from './../../images/missingDriverIcon.svg'

const Delta = Quill.import('delta')

const useStyles = makeStyles(() => ({
	tooltip: {
		backgroundColor: '#0D5D56',
		borderRadius: '4px',
		color: '#FFFFFF',
		position: 'relative',
		//top: '25px',
	},
	root: {
		fontSize: '12px',
		lineHeight: '10px',
		fontFamily: 'Source Sans Pro',
		color: '#000000',
		padding: '4px 6px',
	},
	list: {
		padding: 0,
	},
}))

const BeatCard = React.memo(
	({
		beatId,
		setCurrentElement,
		referenceElement,
		beatConnection,
		isOverlay,
		isNewBeat,
		setNewBeat,
		shouldFocus,
		cursor,
		setIsDragDisabled,
		driversList,
		driverIds,
		beatDrivers,
		beat,
		isDragging,
		location,
	}) => {
		const client = useApolloClient()
		const classes = useStyles()
		const quillContentRef = useRef()
		const beatRef = useRef()
		const saveInterval = useRef()
		let change = new Delta()
		const graphData = useContext(graphDataStateContext)
		const beatsRelatedData = useContext(beatsDataStateContext)
		const systemRelatedData = useContext(systemStateContext)
		const user = useContext(userStateContext)
		const templateText = useRef()
		const [renderTemplateText, setRenderTemplateText] = useState()

		const [isCreateDriver, setIsCreateDriver] = useState(false)
		const [anchorEl, setAnchorEl] = useState(false)
		const [isEditMode, setIsEditMode] = useState(isNewBeat)
		const [isHovered, setIsHovered] = useState(false)
		const [isCollapsed, setIsCollapsed] = useState(true)
		const [isButtonClickedInsideBeat, setIsButtonClickedInsideBeat] = useState(false)
		const [mouseX, setMouseX] = useState()
		const [mouseY, setMouseY] = useState()
		const [loadedQuill, setLoadedQuill] = useState(false)
		const [quillPlaceholder, setQuillPlaceholder] = useState('')
		const [moveBeat, { loading: response }] = useMoveBeatMutation()
		const [performCreateConnectionMutation] = useCreateConnectionMutation({ ignoreResults: true })
		const { dispatch: dispatchAction } = useContext(sharedContext)
		const [isTemplateTextDisabled,setIsTemplateTextDisabled] = useState(true)

		const { data: relationships } = useGetRelationshipsQuery({fetchPolicy: 'cache-only', component:"BeatCard"})
		const [updateBeat] = useUpdateNodeMutation('Beat', { ignoreResults: true })
		const objectArray = Object.values(graphData.allNodes.nodes).filter(node =>
			['Character', 'Arc', 'Event', 'Theme', 'Chapter', 'Setting'].includes(node.__typename),
		)
		const [performDeleteConnectionMutation] = useDeleteConnectionMutation({ ignoreResults: true })

		const [isVisibleExtraMenu, setIsVisibleExtraMenu] = useState(true)
		objectArray.sort((a, b) => {
			if (a.driverConnections.length < b.driverConnections.length) {
				return 1
			}
			if (a.driverConnections.length > b.driverConnections.length) {
				return -1
			}
			return 0
		})
		const [driverList, setDriverList] = useState(objectArray)
		//const beatConnection = beat?.beatConnection?.[0]

		const handleTemplateTextChange = debounce(e => {
			updateBeat({
				variables: {
					id: beatId,
					input: {
						id: beatId,
						updatedAt: new Date().toISOString(),
						templateText: e.target.value,
					},
				},
			})
				.then(() => {
					clearTimeout(saveInterval.current)
					client.cache.modify({
						id: client.cache.identify({
							id: beatId,
							__typename: beat?.__typename,
						}),
						fields: {
							templateText() {
								return e.target.value
							},
						},
					})
				})
				.catch(error => {
					toastHandler('error', 'There was an error updating a beat, try again.')
					systemRelatedData?.createLog(
						`ToastHandler Error Message`,
						`{"errorMessage":"${error.message}"}`,
						'BeatCard',
						'Beat Updation',
					)
					setIsEditMode(false)
					console.error(error)
				})
			handleClose()
		}, 1000)
		const update = async (beatId, description, text) => {
			updateBeat({
				variables: {
					id: beatId,
					input: {
						id: beatId,
						description,
						updatedAt: new Date().toISOString(),
					},
				},
			})
				.then(() => {
					change = new Delta()
					clearTimeout(saveInterval.current)
				})
				.catch(error => {
					toastHandler('error', 'There was an error updating a beat, try again.')
					systemRelatedData?.createLog(
						`ToastHandler Error Message`,
						`{"errorMessage":"${error.message}"}`,
						'BeatCard',
						'Beat Updation',
					)
					setIsEditMode(false)
					console.error(error)
				})
		}

		const { quill, quillRef } = useQuillAutoSave(
			{
				modules: {
					toolbar: false,
				},
				readOnly: true,
				//formats: ["background"],//['color'],
			},
			update,
			beatId,
			'description',
			beat?.description ,
		)

		useEffect(() => {
			beatRef.current = beat
		}, [beat])

		useEffect(() => {
			quillContentRef.current = quill
		}, [quill])

		useEffect(() => {
			if (!isCollapsed) {
				setIsCollapsed(true)
			}
		}, [])

		useEffect(() => {
			if (!isCreateDriver) {
				setIsDragDisabled(false)
			}
		}, [isCreateDriver])

	

		// useEffect(() => {
		// 	if (isNewBeat) {
		// 		if (tempBeatContent && tempBeatContent.isFocused) {
		// 			setIsEditMode(true)
		// 		} else {
		// 			setIsEditMode(true)
		// 			driverConnections?.length === 1 && setNewBeat(null)
		// 		}
		// 	}
		// }, [isNewBeat, tempBeatContent])

		// useEffect(() => {
		// 	if (shouldFocus) {
		// 		setIsEditMode(true)
		// 	}
		// }, [])

		useEffect(() => {
			if (isDragging) {
				setIsHovered(false)
				setIsEditMode(false)
				setAnchorEl(null)
				setIsCreateDriver(null)
				if (
					beat?.description !== JSON.stringify(quill?.getContents()) &&
					JSON.stringify(quill?.getContents())
				) {
					client.cache.modify({
						id: client.cache.identify({
							id: beatId,
							__typename: beat?.__typename,
						}),
						fields: {
							description() {
								return JSON.stringify(quill?.getContents())
							},
						},
					})
				}
			}
		}, [isDragging])

		useEffect(() => {
			return () => {
				if (
					beatRef.current &&
					quillContentRef.current &&
					beatRef.current.description !== JSON.stringify(quillContentRef.current.getContents())
				) {
					client.cache.modify({
						id: client.cache.identify({
							id: beatId,
							__typename: 'Beat',
						}),
						fields: {
							description() {
								return JSON.stringify(quillContentRef.current?.getContents())
							},
						},
					})
				}
			}
		}, [])

		useEffect(() => {
			if (beat?.description && !loadedQuill) {
				try {
					setQuillPlaceholder(
						JSON.parse(beat?.description)
							.ops.reduce((text, op) => {
								return text + op.insert
							}, '')
							.slice(0, -1),
					)	
				} catch {
					setQuillPlaceholder(beat.description)
				}
			}
		}, [quill, beat])

		useEffect(() => {
			if (isEditMode) {
				setLoadedQuill(true)
				quill?.enable()
				quill?.focus()
				quill?.setSelection(quill.getLength(), 0)
				setIsDragDisabled(true)
			} else {
				//quill?.disable()
				setIsDragDisabled(false)
				if (beat?.id && JSON.stringify(quill?.getContents()) !== beat.description && quill) {
					const updateData = {}
					updateData['description'] = JSON.stringify(quill?.getContents())
					updateData.updatedAt = new Date().toISOString()
					updateCacheField(client, { id: beat.id, __typename: 'Beat' }, updateData, true)
				}
				if (isNewBeat) {
					setNewBeat(null)
				}
			}
		}, [isEditMode, quill])

		const getTrimmedNodeName = node => {
			if (node?.name) {
				return node?.name?.length > 26 ? `${node?.name?.substring(0, 26)} ...` : node?.name
			}
			return ''
		}

		const handleClose = () => {
			setAnchorEl(null)
			setIsHovered(false)
		}

		const menuClickHandler = event => {
			isEditMode && setIsEditMode(true)
			setIsButtonClickedInsideBeat(true)
			systemRelatedData?.createLog('Options Menu Clicked', { location }, 'BeatCard', null)
			setAnchorEl(event.currentTarget)
		}

		const handleAddDriver = event => {
			setIsButtonClickedInsideBeat(true)
			//isEditMode && setIsEditMode(true)
			systemRelatedData?.createLog('Add Driver Clicked', { location }, 'BeatCard', 'Options Menu')
			setIsHovered(false)
			setIsEditMode(false)
			setAnchorEl(null)
			setIsCreateDriver(event.currentTarget)
			setIsDragDisabled(true)
			setMouseX(event.clientX)
			setMouseY(event.clientY)
			setIsHovered(false)
		}

		const handleEditBeat = () => {
			if (!isCreateDriver) {
				handleClose()
				setTimeout(() => {
					setIsEditMode(true)
				})
			}
		}

		const removeBeatDebounce = useCallback(
			debounce(() => {
				beatsRelatedData?.removeBeatConnection(beatConnection, referenceElement)
			}, 400),
			[beatConnection, referenceElement],
		)

		const handleRemoveBeat = () => {
			setIsButtonClickedInsideBeat(true)
			handleClose()
			systemRelatedData?.createLog('Remove Beat Clicked', { location }, 'BeatCard', 'Options Menu')
			// used debounce to avoid double clicks
			removeBeatDebounce()
		}

		const deleteBeatDebounce = useCallback(
			debounce(() => {
				setCurrentElement({
					name: beat?.title,
					id: beat?.id,
					type: beat?.__typename,
					description: beat?.description?.trim(),
				})
			}, 400),
			[beat],
		)

		const handleDeleteBeat = () => {
			setIsButtonClickedInsideBeat(true)
			handleClose()
			systemRelatedData?.createLog('Delete Beat Clicked', { location }, 'BeatCard', 'Options Menu')
			// used debounce to avoid double clicks
			deleteBeatDebounce()
		}

		const removeConnection = async connToDelete => {
			let elementToDelete

			elementToDelete = relationships?.relations?.find(e => e?.id === connToDelete?.id)
			await performDeleteConnectionMutation({
				id: connToDelete?.id,
				client,
				elementToDelete,
				isCreateCacheConnection: true,
			})
			handleClose()
		}
		const createConnection = async () => {
			const tempId = createNodeId('Relationship')
			const premiseID = graphData?.allNodes?.nodes?.find(node => node?.id.startsWith('prm'))?.id

			const newConnection = {
				id: tempId,
				description: '',
				arcStage: null,
				relName: 'RELATED',
				order: String(graphData?.nodesByType['Premise']?.[0]?.premiseConnections?.length || 0) ,
				connectionType: 'Premise',
				structureTag: null,
				arc: null,
				sourceNode: {
					id: beat.id,
					__typename: 'Beat',
				},
				destNode: {
					id: premiseID,
					__typename: 'Premise',
				},
				__typename: 'Relationship',
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				name: null,
				beatsDriven: '0',
				sharedBeats: [],
				firstBeat: '',
			}
			performCreateConnectionMutation({
				client,
				newConnection,
				user,
			})
			handleClose()
		}

		return (
			<Beat
				data-testid="beat"
				isMenuOpen={Boolean(anchorEl)}
				milestoneBeat={beat?.premiseConnections?.length || 0}
				isEdit={isEditMode}
				cursor={isEditMode ? 'text' : cursor}
				onDoubleClick={() => {
					systemRelatedData?.createLog(
						'Beat Double Clicked',
						`{"action":"Enter Edit Mode"}`,
						'BeatCard',
						null,
					)
					handleEditBeat()
				}}
				onClick={() => {
					setIsCollapsed(false)
				}}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<OutsideClickHandler
					onOutsideClick={() => {
						setIsEditMode(false)
					}}
					position="unset"
				>
					<Menu
						id="options-menu"
						anchorEl={anchorEl}
						transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						open={Boolean(anchorEl)}
						onClose={handleClose}
						classes={{ list: classes.list }}
					>
						<div>
							<Divider />
							<MenuItem
								classes={{ root: classes.root }}
								onClick={() => {
									systemRelatedData?.createLog(
										'Edit Beat Clicked',
										`{"action":"Enter Edit Mode"}`,
										'BeatCard',
										'Options Menu',
									)
									handleEditBeat()
								}}
							>
								Edit Beat
							</MenuItem>
						</div>
						<div>
							<Divider />
							<MenuItem classes={{ root: classes.root }} onClick={handleDeleteBeat}>
								Delete Beat
							</MenuItem>
						</div>
						{beat?.premiseConnections?.length > 0  && (
							<div>
								<Divider />
								<MenuItem
									classes={{ root: classes.root }}
									onClick={() => {
										handleClose()
										setRenderTemplateText(true)
										setIsTemplateTextDisabled(false)
										setTimeout(() => {
											templateText.current.focus()
										}, 500)
									}}
								>
									{beat?.templateText ? `Edit Guidance Text` : `Add Guidance Text`}
								</MenuItem>
							</div>
						)}
						<div>
							<Divider />
							<MenuItem
								classes={{ root: classes.root }}
								onClick={async () => {
									if (beat?.premiseConnections?.length) {
										await removeConnection(beat?.premiseConnections[0])
									} else {
										await createConnection()
									}
								}}
							>
								{beat?.premiseConnections?.length
									? `Demote from Milestone Beat`
									: `Promote to Milestone Beat`}
							</MenuItem>
						</div>
						{referenceElement?.id && nodeTypeForId(referenceElement?.id) === 'Chapter' && (
							<div>
								<Divider />
								<MenuItem
									classes={{ root: classes.root }}
									onClick={handleRemoveBeat}
									aria-controls="connection-modal"
									aria-haspopup="true"
								>
									Remove from Chapter
								</MenuItem>
							</div>
						)}
						<MenuItem
								classes={{ root: classes.root }}
								onClick={handleAddDriver}
							>
								
								Specify Drivers
							</MenuItem>
					</Menu>
					<div className="quill-container">
						{loadedQuill ? (
							<QuillContainer
								translate="no"
								ref={quillRef}
								id={beatId}
								data-fieldname={'description'}
								isCollapsed={!isEditMode && isCollapsed}
								milestoneBeat={beat?.premiseConnections?.length > 0}
							/>
						) : (
							<QuillContainer
								milestoneBeat={beat?.premiseConnections?.length > 0}
							>
								<div className="ql-editor">
								{quillPlaceholder}
									{/* {beat?.premiseConnections?.length && !quillPlaceholder.trim()
										? beat?.description
										: quillPlaceholder}{' '} */}
								</div>
							</QuillContainer>
						)}

						<MenuButton
							aria-controls="options-menu"
							aria-haspopup="true"
							onClick={menuClickHandler}
							id="menu-button"
						>
							<img src={ThreeDot} alt="Three dot icon" />
						</MenuButton>
					</div>

					{/* {beatDrivers?.length > 0 ? ( */}
						{(<DriversContainer>
							<MilestoneBeatWrapper visible={beat?.premiseConnections?.length}>
								<MilestoneBeatLabel position={beat?.templateText || renderTemplateText}>
									Milestone Beat
								</MilestoneBeatLabel>
								{(!isTemplateTextDisabled) ? (
									<TemplateText
									>
										<textarea
											disabled ={isTemplateTextDisabled}
											type="text"
											defaultValue={beat?.templateText}
											onChange={handleTemplateTextChange}
											placeholder="Describe the structural purpose of this Milestone."
											ref={templateText}
											onBlur={()=>{
												setIsTemplateTextDisabled(true)
											}}
										/>
									</TemplateText>
								)
								:
								<TemplateText>
									{beat?.templateText}
								</TemplateText> 
								
								
								}
							</MilestoneBeatWrapper>
							{beatDrivers?.length > 0 ? (
								<div
									style={{
										display: 'flex',
										justifyContent: 'end'
									}}
								>
									<div
										id="driver-container"
										className="drivers-container"
										onDoubleClick={e => e.stopPropagation()}
									>
										{beatDrivers?.slice(0, 3).map((node, index) => {
											const name = getTrimmedNodeName(node)
											return (
												<ToolTip key={index} title={name} placement="top" arrow>
													<ElementIconContainer
														onClick={event => handleAddDriver(event, node)}
														id={`${node?.id}-${beatId}`}
													>
														<NodeIconContainer type={node?.__typename} isDriver={true}>
															<span>
																{node?.name
																	?.match(/\b(\w)/g)
																	?.join('')
																	?.slice(0, 2)
																	?.toUpperCase()}
															</span>
														</NodeIconContainer>
													</ElementIconContainer>
												</ToolTip>
											)
										})}
										{beatDrivers?.length > 3 && (
											<AdditionalItemContainer onClick={handleAddDriver}>
												<span>+{beatDrivers.length - 3}</span>
											</AdditionalItemContainer>
										)}
									</div>
									<Tooltip
										title={'Specify Drivers'}
										classes={{ tooltip: classes.tooltip }}
										placement="bottom"
										arrow
									>
										<PlusSign
											isCreateDriver={isCreateDriver}
											aria-controls="connection-modal"
											aria-haspopup="true"
											onClick={handleAddDriver}
										>
											<img src={PlusIcon} alt="plus icon" />
										</PlusSign>
									</Tooltip>
								</div>
							) : (
								<MissingDriverWrapper onClick={handleAddDriver}>
									<img src={missingDriverIcon} alt="missing-driver" />
									{
										<p
											style={{
												visibility: isHovered ? 'visible' : 'hidden',
											}}
										>
											Missing Driver
										</p>
									}
								</MissingDriverWrapper>
							)}
						</DriversContainer>)
						
						}
					{/* ) : (
						<MissingDriverWrapper onClick={handleAddDriver}>
							<img src={missingDriverIcon} alt="missing-driver" />
							{
								<p
									style={{
										visibility: isHovered ? 'visible' : 'hidden',
									}}
								>
									Missing Driver
								</p>
							}
						</MissingDriverWrapper>
					)} */}
					{false ? (
						<AddDriver isHovered={isHovered} isEditMode={isEditMode}>
							<div className="add-driver" onClick={handleAddDriver}>
								<img src={AddDriverIcon} alt="add new driver" />
								<p>Add driver</p>
							</div>
						</AddDriver>
					) : (
						<></>
					)}
					{isCreateDriver && (
						<DriverModal
							isRightSidePanel={true}
							isArc={true}
							driverIds={driverIds}
							isActive={isCreateDriver}
							//currentId={originNode?.id}
							objectList={driversList}
							setConnectionModal={setIsCreateDriver}
							//connectionArc={originNode}
							sourceId={beat?.id}
							createLog={systemRelatedData?.createLog}
							position="bottom"
							referenceElement={referenceElement}
							beatDrivers={beatDrivers}
							mouseX={mouseX}
							mouseY={mouseY}
							beat={beat}
							setCurrentElement={setCurrentElement}
						/>
					)}
				</OutsideClickHandler>
			</Beat>
		)
	},
)

const Draggable = props => {
	const {
		beatId,
		cursor,
		setIsSetModifiers = () => {},
		setIsDragDisabled = () => {},
		isDragging,
		setNodeRef,
		attributes,
		listeners,
		transition,
		transform,
		chapterId,
	} = props

	const graphData = useContext(graphDataStateContext)

	const [beat, setBeat] = useState({})
	const [driversList, setDriversList] = useState([])
	const [driverIds, setDriverIds] = useState([])
	const [beatDrivers, setBeatDrivers] = useState([])
	//const [originNode, setOriginNode] = useState()
	const [isDraggingShadowBeat, setIsDraggingShadowBeat] = useState(false)
	//const {active:dndContextActive} = useDndContext()

	const isShadow = Boolean(false)
	// useEffect(() => {
	// 	if (isDraggingShadowBeat) {
	// 		setIsSetModifiers(true)
	// 	} else {
	// 		setIsSetModifiers(false)
	// 	}
	// }, [isDraggingShadowBeat])

	useEffect(() => {
		const changed = !isEqual(graphData?.nodes?.[beatId], beat)
		if (changed && graphData?.nodes?.[beatId]) {
			setBeat(graphData.nodes[beatId])
		}
	}, [graphData?.allNodes?.nodes])
	useEffect(() => {
		const temp = graphData.allNodes?.nodes
			?.filter(
				node =>
					['Arc', 'Character', 'Event', 'Theme', 'Chapter', 'Setting'].includes(node.__typename) &&
					!beat.driverConnections?.find(conn => conn.destNode.id === node.id),
			)
			.sort((a, b) => a.__typename.localeCompare(b.__typename))

		const changed = !isEqual(temp, driversList)
		if (changed) {
			setDriversList(temp)
		}
	}, [graphData.allNodes])

	useEffect(() => {
		if (beat.id) {
			const allDriverIds = beat?.driverConnections?.map(d_conn => {
				return !d_conn?.sourceNode?.id?.startsWith('bea') ? d_conn?.sourceNode : d_conn?.destNode
			})
			const updatedDriverIds = allDriverIds || []
			const changed = !isEqual(driverIds, updatedDriverIds)
			if (changed) {
				setDriverIds(updatedDriverIds)
			}
		}
	}, [beat])

	useEffect(() => {
		const driverNodes = graphData.allNodes?.nodes?.filter(node =>
			driverIds?.find(driver => driver?.id === node?.id),
		)
		const changed = !isEqual(driverNodes, beatDrivers)
		if (changed) {
			setBeatDrivers(driverNodes)
		}
	}, [driverIds, graphData.allNodes])

	// useEffect(() => {
	// 	const nodeId =
	// 		referenceElementType !== 'Chapter'
	// 			? beat?.parentId
	// 			: beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
	// 			? beat?.beatConnections?.[0]?.sourceNode?.id
	// 			: beat?.beatConnections?.[0]?.destNode?.id
	// 	const node = graphData.allNodes?.nodes?.find(node => node?.id === nodeId) || null
	// 	const changed = !isEqual(originNode, node)
	// 	if (changed) {
	// 		setOriginNode(node)
	// 	}
	// }, [beat, graphData.allNodes])

	

	useEffect(() => {
		setIsDraggingShadowBeat(isDragging && isShadow)
		setIsSetModifiers(isDragging && isShadow)
	}, [isDragging, isShadow])
	return (
		<>
			{(beatId && isShadow && !props?.isOverlay) || (isDragging && false) ? (
				<ShadowBeat
					{...props}
					key={beat?.id}
					beatId={beatId}
					beat={beat}
					beatDrivers={beatDrivers}
					// originNode={originNode}
					cursor={cursor || 'grab'}
				/>
			) : beatId && (!isDraggingShadowBeat || !props?.isOverlay) ? (
				<TimelineContent
					ref={setNodeRef}
					{...attributes}
					{...listeners}
					style={{
						padding: '0px 0px',
						width: 'calc(100% - 10px)',
						touchAction: 'none',
					}}
				>
					<div
						style={{
							//transition,
							//transform: CSS.Translate.toString(transform),
							//opacity: (isDragging || beat?.beatConnections?.length === 0) && 'unset',
							opacity: isDragging ? '0.5' : 'unset',
							padding: '5px 0',
							position: 'relative',
						}}
					>
						<BeatCard
							{...props}
							beatId={beatId}
							key={beat?.id}
							cursor={cursor || 'grab'}
							setIsDragDisabled={setIsDragDisabled}
							beat={beat}
							driversList={driversList}
							driverIds={driverIds}
							beatDrivers={beatDrivers}
							// originNode={originNode}
							isDragging={isDragging}
							chapterId={chapterId}
						/>
					</div>
				</TimelineContent>
			) : (
				<TimelineContent
					ref={setNodeRef}
					{...attributes}
					{...listeners}
					style={{
						padding: '0px 0px',
						width: 'calc(100% - 10px)',
						touchAction: 'none',
					}}
				>
					<div
						style={{
							opacity: 'unset',
							padding: '5px 0',
							position: 'relative',
						}}
					>
						<Beat isMenuOpen data-testid="beat" milestoneBeat={props.type === 'milestoneBeat'} cursor={cursor}>
							<div className="quill-container">
								<QuillContainer>
									<div className="ql-editor">{""}</div>
								</QuillContainer>
							</div>

							<DriversContainer>
								<MilestoneBeatWrapper visible={true}>
									<MilestoneBeatLabel>Milestone Beat</MilestoneBeatLabel>
									<TemplateText>
										{props?.templateText}
									</TemplateText>
								</MilestoneBeatWrapper>
							</DriversContainer>
						</Beat>
					</div>
				</TimelineContent>
			)}
		</>
	)
}

export default React.memo(Draggable)
