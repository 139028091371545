import React, { useState, memo, useContext } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Tooltip from '@mui/material/Tooltip'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { makeStyles } from '@mui/styles'
import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import BeatCard from '@lynit/shared/src/ui/BeatCard'
import missingChapterIcon from '@lynit/shared/src/images/missingChapter.svg'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { nodeTypeForId } from '../../utils/utils'

const useStyles = makeStyles(() => ({
	initialsTooltip: {
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineDot: {
		margin: 0,
		backgroundColor: '#78C091',
	},
	timelineChapter: {
		margin: 0,
		backgroundColor: nodeColors.Chapter,
		width: '16px',
		height: '16px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '14px',
		color: 'rgba(255, 255, 255, 1)',
		border: 0,
	},
	timelineMissingChapter: {
		margin: 0,
		backgroundColor: 'transparent',
		width: '16px',
		height: '16px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '14px',
		color: 'rgba(255, 255, 255, 1)',
		borderColor: 'transparent',
		border: 0,
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: 'rgba(76, 76, 76, 1)',
		width: '10px',
		height: '2px',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: '10px',
		height: '1px',
	},
	timelineSeparator: {
		flexDirection: 'row',
	},
}))

const DraggableBeats = props => {
	const {
		beatId,
		referenceElement,
		connectionId,
		elementName,
		setIsSetModifiers,
		location,
		locationId,
		connectionTypeField,
		templateMilestoneBeat,
	} = props
	const classes = useStyles()

	const [isDragDisabled, setIsDragDisabled] = useState(false)
	const graphData = useContext(graphDataStateContext)

	const { setNodeRef, attributes, listeners, isDragging, transition, transform, active } = useSortable({
		id: beatId + "-" +location+ "-" + referenceElement?.id,
		//id: connectionId,
		data: {
			type: 'beat',
			referenceElement,
			location,
			locationId,
			connectionId,
			connectionTypeField,
			id: beatId,
		},
		disabled: isDragDisabled,
	})
	
	const beatConnnection = graphData?.nodes?.[beatId]?.beatConnections?.[0]
	const chapterId = nodeTypeForId(beatConnnection?.destNode?.id) !=="Beat" ? beatConnnection?.destNode?.id : beatConnnection?.sourceNode?.id
	const chapter = graphData?.nodes[chapterId]

	const getTrimmedNodeName = node => {
		if (node?.name) {
			return node?.name?.length > 26 ? `${node?.name?.substring(0, 26)} ...` : node?.name
		}
		return ''
	}

	return (
		<>
			<TimelineSeparator className={classes.timelineSeparator}>
				{/* {index !== 0 ? (
					<TimelineConnector className={classes.timelineConnector} />
				) : (
					<TimelineConnector className={classes.hiddenTimelineConnector} />
				)} */}
				{chapter ? (
					<Tooltip
						title={`Ch:${chapter?.number || 0} ${getTrimmedNodeName(chapter)}`}
						placement="right"
						arrow
						classes={{
							tooltip: classes.initialsTooltip,
							arrow: classes.arrow,
						}}
					>
						<TimelineDot
							ref={setNodeRef}
							{...attributes}
							{...listeners}
							className={classes.timelineChapter}
							style={{
								transition,
								transform: CSS.Translate.toString(transform),
								touchAction: 'none',
							}}
						>
							{chapter?.number || 0}
						</TimelineDot>
					</Tooltip>
				) : (
					<Tooltip
						title={`Drag Beat to Chapter`}
						disableHoverListener = {templateMilestoneBeat}
						placement="right"
						arrow
						classes={{
							tooltip: classes.initialsTooltip,
							arrow: classes.arrow,
						}}
					>
						<TimelineDot
							ref={setNodeRef}
							{...attributes}
							{...listeners}
							className={classes.timelineMissingChapter}
							style={{
								transition,
								transform: CSS.Translate.toString(transform),
								touchAction: 'none',
							}}
						>
							{ !templateMilestoneBeat && (<img src={missingChapterIcon} alt="missing-driver" width={'100%'} height={'100%'} />)}
						</TimelineDot>
					</Tooltip>
				)}
				{/* {index === driverConnections.length - 1 ? (
					<TimelineConnector className={classes.dashedTimelineConnector} />
				) : ( */}
				<TimelineConnector 
					ref={setNodeRef}
					{...attributes}
					{...listeners}
					className={classes.timelineConnector} 
					/>
				{/* )} */}
			</TimelineSeparator>
			<TimelineContent
				ref={setNodeRef}
				{...attributes}
				{...listeners}
				style={{
					padding: '0px 0px',
					width: 'calc(100% - 10px)',
					touchAction: 'none',
				}}
			>
				<div
					style={{
						transition,
						transform: CSS.Translate.toString(transform),
						opacity: active?.data?.current?.id && ((active?.data?.current?.id || active?.id?.slice(0,40)) === beatId) ? '0.5' : 'unset',
						padding: '0px',
					}}
				>
					<BeatCard
						key={beatId}
						id={beatId}
						{...props}
						setIsSetModifiers={setIsSetModifiers}
						setIsDragDisabled={setIsDragDisabled}
						elementName={elementName}
						isDragging={isDragging}
						setNodeRef={setNodeRef}
						attributes={attributes}
						listeners={listeners}
						transition={transition}
						transform={transform}
					/>
				</div>
			</TimelineContent>
		</>
	)
}

export default memo(DraggableBeats)
