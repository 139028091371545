import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { DragHandler, LynitTask as LynitTaskContainer } from './styles'
import lynitTaskIcon from '../../../images/lynit-task-icon.svg'
import dismissIcon from '../../../images/dismiss-icon.svg'
import dragHandleIcon from '../../../images/task-dragger-icon.svg'
import { toastHandler } from '../../../utils/backendHandler'
import { sharedContext } from '../../../state/sharedProvider'
import { openRecommendation } from '../../../state/actions'
import makeDraggable from '../makeDraggable'
import { useDeleteTaskMutation, useLazyGetTasksQuery } from '../../../hooks'
import { systemStateContext } from '../../../state/systemProvider'
import { throttle } from 'lodash'

const Promotion = 'Promotion'

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: '0',
		padding: '3px 5px',
		fontSize: '9px',
		background: '#55534F',
		position: 'relative',
		top: '15px',
		left: '5px',
	},
}))

function LynitTask({ task, attributes, listeners }) {
	const { recommendation } = task
	const history = useHistory()
	const classes = useStyles()

	const {
		dispatch,
	} = useContext(sharedContext)

	const {createLog} = useContext(systemStateContext)

	const [deleteTask] = useDeleteTaskMutation()
	const [, { refetch: refetchTasks }] = useLazyGetTasksQuery()

	const dismissHandler = async taskId => {
		try {
			await deleteTask({
				variables: {
					id: taskId,
				},
			})
			await refetchTasks()
		} catch (error) {
			console.log(error)
			toastHandler('error', 'error dismissing the task, try again.')
		}
	}
	
	const throttleDismissHandler = useRef(
		
		throttle(() => dismissHandler(task.id),9999, { 'trailing': false })
	 ).current

	const taskClickHandler = recommendation => {
		createLog('Lynit Task Clicked', `{"recommendationId":"${recommendation?.id}"}`, 'TaskBar', null)
		if (recommendation?.progress !== 100) {
			if (recommendation?.type?.includes(Promotion)) {
				createLog('Go to plan picker', `{}`, 'TaskBar', null)
				history.push('/plan-picker')
			} else {
				dispatch(openRecommendation(recommendation?.id))
			}
		}
	}

	return (
		<LynitTaskContainer key={task?.id} isDisabled={recommendation?.progress === 100}>
			<div className="left">
				<DragHandler
					className="hidden"
					src={dragHandleIcon}
					alt="drag handle icon "
					{...attributes}
					{...listeners}
				/>
				<img className="lynit-task-icon" src={lynitTaskIcon} alt="lynit task icon" />
				<p className="description" onClick={() => taskClickHandler(recommendation)}>
					{task.description}
				</p>
			</div>
			<div className="right">
				<span className="progress">{recommendation?.progress || 0}%</span>
				<Tooltip
					title="Dismiss"
					sx={{ padding: 0, margin: 0 }}
					classes={{ tooltip: classes.tooltip }}
					placement="top-start"
				>
					<img
						className="dismiss-icon hidden"
						src={dismissIcon}
						alt="dismiss icon"
						onClick={throttleDismissHandler}
					/>
				</Tooltip>
			</div>
		</LynitTaskContainer>
	)
}

export default makeDraggable(LynitTask)
