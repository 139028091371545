import styled from 'styled-components'

const InputContainer = styled.div`
	width: auto;
	height: auto;
	display: ${props => (props?.isHidden ? 'none' : 'flex')};
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	cursor: not-allowed;
	@media (max-width: 600px) {
		font-size: 1rem;
	}
	input {
		width: ${props => props.size};
		height: auto;
		font-family: 'Source Sans Pro', sans-serif;
		font-size: 18px;
		border: none;
		background-color: ${({ isFocused }) => (isFocused ? 'white' : 'transparent')};
		font-weight: 700;
		align-self: flex-start;
		@media (max-width: 600px) {
			font-size: 0.8rem;
		}
	}
`

const Text = styled.div`
	font-size: 18px;
	font-weight: 700;
	max-width: 100%;
	word-break: ${props => props.isDeepMode && 'break-all'};
	white-space: ${props => (!props.isNumber && !props.isDeepMode ? 'nowrap' : '')};
	text-overflow: ${props => (!props.isNumber && !props.isDeepMode ? 'ellipsis' : '')};
	overflow: ${props => (!props.isNumber ? 'hidden' : '')};
	padding-left: ${props => (!props.isNumber ? '' : '')};

	@media (min-width: 601px) and (max-width: 950px) {
		max-width: ${props => (!props.isNumber ? (props.isCollapsed ? '100%' : '90%') : '')};
	}
	@media (max-width: 400px) {
		font-size: 0.8rem;
	}

	:hover {
		border: ${props => props.isSelected && !props?.isCollapsed && '1px solid #3185FC'};
		border-radius: 3px;
		cursor: ${props => (!props?.isCollapsed ? 'text' : '')};
		padding-right: ${props => props.isSelected && '3px'};
	}
`

export { InputContainer, Text }
