/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState, useContext, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, withStyles } from '@mui/styles'
import { useApolloClient } from '@apollo/client'
import { Popover, useMediaQuery } from '@mui/material'

import BreadCrumb from '../BreadCrumb'
import TaskBar from '../TaskBar'
import DeleteModal from '../../shared-ui/DeleteModal'
import UpgradeToPro from '../../shared-ui/UpgradeToPro'
import AutoSave from '../AutoSave'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'

import {
	deleteCurrentStory,
	logout,
	setCurrentStoryId,
	setUser,
	setCurrentStory,
	setDeepModeElement,
	setIsCatelogOpen,
	setActiveConnections,
	setNewElement,
	setElementToDelete,
	setServerError,
	setNoElementError,
	setIsExporting,
} from '../../state/actions'
import { sharedContext } from '../../state/sharedProvider'
import { autoSaveStateContext } from '../../state/autoSaveProvider'
import {
	useCreateBeatMutation,
	useCreateNodeMutation,
	useDeleteConnectionMutation,
	useDeleteNodeMutation,
	useDeleteStoryMutation,
	useEditStoryMutation,
	useGetCustomerPortalQuery,
	useGetRelationshipsQuery,
	useLazyGetUserQuery,
	useReorderNodesMutation,
} from '../../hooks'

import { toastHandler } from '../../utils/backendHandler'
import { createNodeId, getNodeIcon, trimName, userNameLengthFormat } from '../../utils/utils'
import OutsideClickHandler from '../../utils/OutsideClickHandler'
import { colorStyles } from '../../utils/commonStyles'
import {
	createCacheElement,
	deleteCacheConnections,
	deleteCacheElement,
	deleteStoryCache,
	updateCacheField,
} from '../../utils/apollo'

import lynit from '../../images/lynit.svg'
import LogoutIcon from '../../images/logout.svg'
import storyIcon from '../../images/storyIcon.svg'
import SupportIcon from '../../images/support.svg'
import dropdownIcon from '../../images/downArrow.svg'
import SettingsIcon from '../../images/engine_icon.svg'
import trashIcon from '../../images/IconDelete.svg'
import outliningIcon from '../../images/outlining-icon.svg'
import writingIcon from '../../images/writing-icon.svg'
import Search from '../Search'
import SearchIcon from '../../images/search-icon.svg'
import PlusSign from '../../images/PlusIcon.svg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import chapterIcon from '@lynit/layout/src/images/chapterIcon.svg'
import exportIcon from '@lynit/story-outline/src/images/export-icon.svg'

import {
	Box,
	Container,
	LynitLogo,
	NameButton,
	HelperDiv,
	MenuContent,
	LogoutButton,
	MenuContainer,
	ProfileOptions,
	ProfileContainer,
	HeaderLeftContainer,
	FrameOption,
	OutlliningLogo,
	WritingLogo,
	ViewLabel,
	ViewContainer,
	ItemWrapper,
	ItemIcon,
	ItemLabel,
	CreateElementBar,
	CreateStoryElementButton,
	IconContainer,
	MenuOption,
} from './styles'
import { userDispatchContext, userStateContext } from '../../state/userProvider'
import { systemStateContext, systemDispatchContext } from '../../state/systemProvider'
import { beatsDataDispatchContext } from '../../state/beatsProvider'
import { useDeleteUserMutation } from '../../hooks/deleteUser'
import { throttle } from 'lodash'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { updateNodeMutations } from '@lynit/shared/src/data'
import { useQuill } from 'react-quilljs'
import buildStoryQuill from '@lynit/story-outline/src/utils/ExportData/buildStoryQuill'
import exportData from '@lynit/story-outline/src/utils/ExportData/exportData'
import buildStoryTextQuill from '@lynit/story-outline/src/utils/ExportData/buildStoryTextQuill'

const styles = {
	root: {
		flexGrow: 1,
	},
	barStyles: {
		boxShadow: 'none',
		backgroundColor: '#FFFFFF',
		zIndex: 200,
	},
	leftComponents: {
		flexGrow: 1,
	},
	link: {
		textDecoration: 'none',
		color: colorStyles.black,
	},
	name: {
		borderRadius: '30px',
		minWidth: '10rem',
		fontSize: 'smaller',
	},
	menu: {
		color: '#000000',
		minWidth: '7rem',
	},
	bottomBar: {
		alignSelf: 'flex-end',
		margin: '0 0 7px 0',
		minHeight: 'fit-content',
	},
	notesButton: {
		fontWeight: 'bold',
		fontSize: '.75rem',
		border: 'none',
	},
	icon: {
		margin: '0 5px',
	},
	back: {
		color: '#555555',
		fontSize: 'smaller',
		textDecoration: 'none',
		transform: 'translateX(-1rem)',
		paddingTop: '1rem',
	},
	notesDiv: {
		width: '100px',
		borderRadius: '10px 10px 0 0',
	},
}

const Navbar = React.memo(({ isDashboard, isLayout, dispatch }) => {
	const isMobile = useMediaQuery('(max-width: 600px)')
	const isSmallScreen = useMediaQuery('(max-width: 868px)')
	const client = useApolloClient()
	const history = useHistory()
	const [performDeleteConnectionMutation] = useDeleteConnectionMutation({ ignoreResults: true })

	const [reorderNodes] = useReorderNodesMutation()

	const {
		loading,
		data: relationships,
		refetch: refetchRelations,
	} = useGetRelationshipsQuery({ fetchPolicy: 'cache-only' , component:"Navbar"})
	const [deleteNode, { data: deletedNode }] = useDeleteNodeMutation({ ignoreResults: true })

	const {
		state: {
			currentStory,
			isExporting,
			deleteStory: isDeleteOpen,
			activeConnections,
			isCatelogOpen,
			elementToDelete,
		},
		dispatch: dispatchAction,
	} = useContext(sharedContext)
	const { isSaving } = useContext(autoSaveStateContext)
	const user = useContext(userStateContext)
	const userDataDispatch = useContext(userDispatchContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const systemDispatch = useContext(systemDispatchContext)
	const graphData = useContext(graphDataStateContext)

	const { isLoggedIn, createLog, toastHandler } = useContext(systemStateContext)

	const [createBeat] = useCreateBeatMutation({ ignoreResults: true })

	//const [isShow, setActive] = useState(false)
	const [isVisible, setVisible] = useState(false)
	const [isDeleteUser, setIsDeleteUser] = useState(false)
	const [isCreateElementBar, setIsCreateElementBar] = useState(false)

	const [getUser] = useLazyGetUserQuery({
		notifyOnNetworkStatusChange: true,
	})
	const { data: urlCustomerPortal, refetch: refetchCustomerPortal } = useGetCustomerPortalQuery()
	const [deleteStory] = useDeleteStoryMutation()
	const [deleteUser] = useDeleteUserMutation()

	const createNodeMap = {}
	Object.keys(updateNodeMutations).forEach(key => {
		createNodeMap[key] = useCreateNodeMutation(key, { ignoreResults: true })[0]
	})

	const storyDelete = async () => {
		deleteStoryCache(client, elementToDelete?.id)
		try {
			await deleteStory({
				variables: {
					id: elementToDelete?.id,
				},
			})
			const storyCount = user?.user.userSummary.storyCount - 1
			const userSummary = { ...user?.user.userSummary, storyCount }

			updateCacheField(client, { id: user?.user.id, __typename: 'User' }, { userSummary })

			
			//toastHandler('success', 'Story deleted successfully.')
			history.push('/dashboard')
			beatsDataDispatch(setCurrentStoryId(null))
		} catch (error) {
			if (error?.message?.includes('Upgrade')) {
				toastHandler('error', error.message)
				createLog(
					`ToastHandler Error Message`,
					`{"errorMessage":"${error.message}"}`,
					'Navbar',
					'Story Deletion',
				)
				if (
					user?.user?.subscriptionStatus?.status === 'trialing' ||
					user?.user?.subscriptionStatus?.status === 'active'
				) {
					window.location.reload()
				}
			} else {
				toastHandler('error', error?.message)
				createLog(
					`ToastHandler Error Message`,
					`{"errorMessage":"${error.message}"}`,
					'Navbar',
					'Story Deletion',
				)
			}
		}
	}

	const splitString = string => {
		let newStringArr = ''
		if (string[string?.length - 1] === ' ') {
			newStringArr = string
				.slice(0, -1)
				.split(' ')
				.map(word => word[0]?.toUpperCase())
				?.join('')
		} else {
			newStringArr = string
				.split(' ')
				.map(word => word[0]?.toUpperCase())
				?.join('')
		}
		return newStringArr
	}

	const createStoryElement = elementType => {
		// const elementName = {
		// 	Character: 'Main Character',
		// 	Arc: 'Main Plot',
		// 	Theme: 'Central Theme',
		// }
		const tempObj = {
			id: createNodeId(elementType),
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			order: String(graphData?.nodesByType[elementType]?.length),
			__typename: elementType,
			name: `New ${elementType}`,
			description: '',
			contents: '',
			title: '',
			number: '0',
			beatConnections: [],
			driverConnections: [],
			childConnections: [],
			staticConnections: [],
			noteConnections: [],
			premiseConnections: [],
			xCoordinate: null,
			yCoordinate: null,
			vizLocSaved: null,
			firstBeat: '',
			coreElement: activeConnections.appView ==="Premise"? 'true':'false',
			storyText: '',
			wordCount: '0',
			innerConflict: '',
			externalConflict: '',
			type: '',
			goalsAndNeeds: '',
			centralConflict: '',
			culture: '',
			society: '',
			magicTech: '',
			environment: '',
			variations: '',
			moralArgument: '',
			motifs: '',
			lastDocView: '',
			lastOpenedDate: '',
			templateText: '',
			milestone: false,
			nameAliases:[],
			aiRecommendations:'',
		}
		const optimisticResponse = {}
		optimisticResponse[`create${elementType}`] = tempObj
		let node
		if (elementType === 'Note') {
			node = {
				id: tempObj.id,
				contents: tempObj.contents,
				order: tempObj.order,
			}
			createNodeMap[elementType]({
				node,
				optimisticResponse,
				tempObj,
				hasOder: elementType !== 'Note',
			})
			dispatchAction(setIsCatelogOpen('Notes'))
			if (isSmallScreen) {
				dispatchAction(
					setActiveConnections({
						isActive: false,
						elementId: null,
						elementType: null,
						docType: null,
						appView: activeConnections.appView,
						isMobileTempClose: true,
					}),
				)
			}
		} else if (elementType === 'Beat') {
			optimisticResponse[`create${elementType}`] = { beat: tempObj }
			createCacheElement(client, optimisticResponse, false, true)
			createBeat({
				variables: {
					beat: {
						id: optimisticResponse.createBeat.beat.id,
						description: optimisticResponse.createBeat.beat.description,
					},
				},
				ignoreResults: true,
			}).catch(async error => {
				await deleteCacheElement(client, optimisticResponse.createBeat.beat)

				toastHandler(
					'error',
					`There was an error creating the Beat, try again.`,
					null,
					'ListElement',
					'Beat Creation',
				)
				console.error(error)
			})
			dispatchAction(setIsCatelogOpen('Beats'))
			if (isSmallScreen) {
				dispatchAction(
					setActiveConnections({
						isActive: false,
						elementId: null,
						elementType: null,
						docType: null,
						appView: activeConnections.appView,
						isMobileTempClose: true,
					}),
				)
			}
		} else if (elementType === 'Chapter') {
			node = {
				id: tempObj.id,
				name: tempObj.name,
				number: '0',
				description: '',
				order: tempObj.order,
			}
			createNodeMap[elementType]({
				node,
				optimisticResponse,
				tempObj,
			})
			dispatchAction(
				setActiveConnections({
					isActive: true,
					elementId: tempObj.id,
					elementType: tempObj.__typename,
					docType: 'Chapter',
					appView: 'Writing',
				}),
			)
			
		} else {
			node = {
				id: tempObj.id,
				name: tempObj.name,
				description: '',
				order: tempObj.order,
				coreElement: tempObj.coreElement,
			}
			createNodeMap[elementType]({
				node,
				optimisticResponse,
				tempObj,
				hasOder: elementType !== 'Note',
			})
			dispatchAction(
				setActiveConnections({
					isActive: true,
					elementId: tempObj.id,
					elementType: tempObj.__typename,
					docType: 'Driver',
					appView: activeConnections.appView ==="Premise"? "Premise" : "Outlining",
				}),
			)
			
		}
	}
	const { quill, quillRef } = useQuill({
		modules: {
			toolbar: false,
			history: {
				delay: 1000,
				maxStack: 500,
				userOnly: true,
			},
		},
		formats: [
			'font',
			'header',
			'size',
			'color',
			'align',
			'background',
			'bold',
			'italic',
			'underline',
			'strike',
			'blockquote',
			'list',
			'indent',
			'link',
		],
	})
	//import styled from "styled-components";
	//import ToolbarItem from "./ToolbarItem";

	const toolbarItems = [
		{ label: 'Character', elementType: 'Character' },
		{ label: 'Arc', elementType: 'Arc' },
		{ label: 'Theme', elementType: 'Theme' },
		{ label: 'Setting', elementType: 'Setting' },
		{ label: 'Event', elementType: 'Event' },
		{ label: 'Beat', elementType: 'Beat' },
		{ label: 'Note', elementType: 'Note' },
		{ label: 'Chapter', elementType: 'Chapter' },
	]

	const CreateElementDropdown = ({ toolbarItems }) => {
		return (
			<CreateElementBar>
				{toolbarItems.map((item, index) => (
					<CreateElementBarButtons
						key={index}
						icon={getNodeIcon(item.elementType)}
						label={item.label}
						elementType={item.elementType}
					/>
				))}
			</CreateElementBar>
		)
	}

	const CreateElementBarButtons = ({ icon, label, elementType }) => {
		const [isHovered, setIsHovered] = useState(false)
		return (
			<ItemWrapper
				onClick={() => {
					createLog(
						`Element Creation Confirmed`,
						{workflowStep:2, elementType},
						'Navbar',
						'Element Creation',
					)
					dispatchAction(setNewElement({ name: elementType }))
					createStoryElement(elementType)
					setIsCreateElementBar(null)
				}}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<IconContainer isSelected={isHovered} elementType={elementType}>
					<ItemIcon
						src={elementType === 'Chapter' && !isHovered ? chapterIcon : icon}
						alt={`${elementType} icon`}
					/>
				</IconContainer>

				<ItemLabel isSelected={isHovered}>{label}</ItemLabel>
			</ItemWrapper>
		)
	}
	const reorderNodesAfterDelete = async deletedNodeId => {
		const mutationData = []

		const deletedNode = graphData.nodes[deletedNodeId]

		graphData.nodesByType[deletedNode.__typename].forEach(element => {
			if (element?.order > deletedNode?.order) {
				mutationData.push({
					id: element?.id,
					order: String(element?.order - 1),
				})
			}
		})

		await reorderNodes({
			variables: {
				nodeType: deletedNode.__typename,
				newNodeOrder: mutationData,
			},
		})
			.then(res => {
				// if (res && serverError) {
				// 	beatsDataDispatch(setServerError(false))
				// 	clearInterval(serverErrorInterval.current)
				// }
			})
			.catch(async err => {
				if (err.message.includes('Cannot update')) {
					beatsDataDispatch(setNoElementError(true))
				}
				if (err.message.includes('Failed to fetch') || err.message.includes('NetworkError')) {
					beatsDataDispatch(setServerError(true))
				}
			})
			.finally(() => {
				setTimeout(() => user.refetchUser(), 0)
			})
	}

	const removeElement = async (id, type) => {
		const deletedConnections = []
		const deletedElements = [graphData.nodes[id]]
		const updatedData = []

		const staticConnections = graphData?.nodes?.[id]?.staticConnections
		const driverConnections = graphData?.nodes?.[id]?.driverConnections
		await deleteNode({
			variables: {
				id,
			},
		})
			.then(async () => {
				user.refetchUser()
				
				if (type === 'Chapter') {
					const deletedNode = graphData.nodes[id]
					deletedNode?.beatConnections.forEach(beatConnection => {
						deletedConnections.push(beatConnection)
						deleteCacheConnections(client, beatConnection)
					})
				}
				await deleteCacheElement(client, elementToDelete, true)

				reorderNodesAfterDelete(id)
				if (staticConnections?.length) {
					staticConnections.forEach(async connection => {
						deletedConnections.push(connection)
						deleteCacheConnections(client, connection, true)
					})
				}
				if (driverConnections?.length) {
					driverConnections.forEach(async connection => {
						deletedConnections.push(connection)
						deleteCacheConnections(client, connection, true)
					})
				}
			})
			.catch(e => {
				console.error(e)

				if (e.message.includes('Upgrade')) {
					toastHandler('error', e.message, null, 'RelationshipList', 'Element Deletion')

					if (
						user?.subscriptionStatus?.status === 'trialing' ||
						user?.subscriptionStatus?.status === 'active'
					) {
						window.location.reload()
					}
				} else {
					toastHandler(
						'error',
						`${elementToDelete?.name} could not be deleted.`,
						null,
						'RelationshipList',
						'Element Deletion',
					)
				}
			})
	}
	const removeConnection = async connToDelete => {
		let elementToDelete

		elementToDelete = relationships?.relations?.find(e => e?.id === connToDelete?.id)
		await performDeleteConnectionMutation({
			id: connToDelete?.type !== 'Note' ? connToDelete?.id : connToDelete?.id,
			client,
			elementToDelete,
			isCreateCacheConnection: true,
		})
	}

	const exportStoryHandler = async () => {
		dispatchAction(setIsExporting(true))
		createLog('Story export Attempted', `{"workflowStep":${1}}`, 'Summary', 'Story Export')
		await buildStoryQuill(quill, graphData, currentStory?.name ? currentStory?.name : 'My story')
			.then(() => {
				createLog('Story export success', `{"workflowStep":${1}}`, 'Summary', 'Story Export')
				setTimeout(() => dispatchAction(setIsExporting(false)), 2000)
				exportData(quill.getContents(), `Outline - ${currentStory?.name}`).catch(err => {
					createLog(
						'Story export failed',
						`{"workflowStep":${1}, "errorMessage":"${err.message}"}`,
						'Summary',
						'Story Export',
					)
					setTimeout(() => dispatchAction(setIsExporting(false)), 1000)
					toastHandler('error', 'Export failed')
					createLog(`Export Story`, `{"errorMessage":"Export failed"}`, 'Summary', 'Export Story')
				})
			})
			.catch(e => {
				createLog(
					'Story export failed',
					`{"workflowStep":${1},"errorMessage":"${e.message}"}`,
					'Summary',
					'Story Export',
				)
				setTimeout(() => dispatchAction(setIsExporting(false)), 1000)
				toastHandler('error', 'Export failed')
				createLog(`Export Story`, `{"errorMessage":"Export failed"}`, 'Summary', 'Export Story')
			})
		await buildStoryTextQuill(quill, graphData, 'Chapter')
			.then(() => {
				createLog('Story export success', `{"workflowStep":${1}}`, 'Summary', 'Story Export')
				setTimeout(() => dispatchAction(setIsExporting(false)), 2000)
				exportData(quill.getContents(), 'Story Text').catch(e => {
					createLog(
						'Story export failed',
						`{"workflowStep":${1},"errorMessage":"${e.message}"}`,
						'Summary',
						'Story Export',
					)
					setTimeout(() => dispatchAction(setIsExporting(false)), 1000)
					toastHandler('error', 'Export failed')
					createLog(
						`ToastHandler Error Message`,
						`{"errorMessage":"${e.message}"}`,
						'Summary',
						'Export Story',
					)
				})
			})
			.catch(e => {
				createLog(
					'Story export failed',
					`{"workflowStep":${1},"errorMessage":"${e.message}"}`,
					'Summary',
					'Story Export',
				)
				setTimeout(() => dispatchAction(setIsExporting(false)), 1000)
				toastHandler('error', 'Export failed')
				createLog(
					`ToastHandler Error Message`,
					`{"errorMessage":"${e.message}"}`,
					'Summary',
					'Export Story',
				)
			})
	}

	return (
		<>
			<Container data-public data-dd-privacy="allow" className="mystorycontainer" id="navBar">
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<HeaderLeftContainer>
						{
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
								className="logo-container"
							>
								<LynitLogo
									src={lynit}
									alt="LynitIcon"
									width={72}
									height={30}
									onClick={() => history.push('/dashboard')}
								/>
							</Box>
						}
						{!isSmallScreen && isLoggedIn && (
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
								className="logo-container"
							>
								<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
									<ProfileContainer
										data-public
										data-dd-privacy="allow"
										onClick={() => {
											createLog(`Account Options Clicked`, `{}`, 'Navbar', null)
											//activeButton()
											setVisible(true)
											refetchCustomerPortal()
											if (!user?.user) {
												getUser()
											}
										}}
									>
										<p>{userNameLengthFormat(user?.user?.name)}</p>
										<img src={dropdownIcon} alt="User dropdown icon" width={12} height={8} />
									</ProfileContainer>
									{isVisible && (
										<ProfileOptions>
											<LogoutButton
												onClick={event => {
													setVisible(false)
												}}
											>
												<LogoutButton
													data-public
													data-dd-privacy="allow"
													className="userNameLabel"
													onClick={() => {
														createLog(`Account Options Clicked`, `{}`, 'Navbar', null)
														//activeButton()
														refetchCustomerPortal()
														if (!user?.user) {
															getUser()
														}
													}}
												>
													<AccountCircleIcon
														sx={{
															height: '20px',
															width: '20px',
														}}
														fontSize="large"
													/>
													<p style={{ fontSize: '1rem' }}>{user?.user?.name}</p>
												</LogoutButton>
											</LogoutButton>
											{isLayout && isSmallScreen && (
												<LogoutButton
													onClick={event => {
														createLog(
															`Element Creation Attempted`,
															{workflowStep:1},
															'Navbar',
															'Element Creation',
														)
														setIsCreateElementBar(event.currentTarget)
														setVisible(false)
													}}
												>
													<img src={PlusSign} alt="plus-icon" />
													<p>Create a Story Element</p>
												</LogoutButton>
											)}
											{isLayout && isSmallScreen && (
												<LogoutButton
													onClick={() => {
														dispatchAction(setIsCatelogOpen('Recent'))
														setVisible(false)
														dispatchAction(
															setActiveConnections({
																isActive: null,
																elementId: null,
																elementType: null,
																docType: null,
																appView: activeConnections.appView,
																isMobileTempClose: isSmallScreen,
															}),
														)
													}}
												>
													<img src={SearchIcon} alt="search-icon" />
													<p>Search Catalog</p>
												</LogoutButton>
											)}
											{!isDashboard && (
												<LogoutButton
													onClick={() => {
														history.push('/dashboard')
													}}
												>
													<div
														style={{
															width: '100%',
															display: 'flex',
															flexDirection: 'row',
															wordBreak: 'normal',
														}}
													>
														<img src={storyIcon} alt="Story icon" />
														<p>Back to My Stories</p>
													</div>
												</LogoutButton>
											)}
											{user?.user?.subscriptionStatus?.status === 'trialing' &&
												!user?.user?.subscriptionStatus?.subscriptionSchedule && (
													<UpgradeToPro
														message={
															<>
																Your trial period will expire <br />
																in <b>{user?.user?.subscriptionStatus?.daysLeft}.</b>
															</>
														}
													/>
												)}

											<LogoutButton
												onClick={() => {
													if (
														(user?.user?.subscriptionStatus?.status === 'trialing' &&
															!user?.user?.subscriptionStatus?.subscriptionSchedule) ||
														user?.user?.subscriptionStatus?.status === 'canceled' ||
														user?.user?.subscriptionStatus?.status === 'past_due'
													) {
														createLog('Go to plan picker', `{}`, 'Navbar', null)
														history.push('/plan-picker')
													} else {
														createLog('Open Customer Portal', `{}`, 'Navbar', null)
														window.location = urlCustomerPortal?.createCustomerPortalSession
													}
												}}
											>
												<div
													style={{
														width: '100%',
														display: 'flex',
														flexDirection: 'row',
														wordBreak: 'normal',
													}}
												>
													<img src={SettingsIcon} alt="Support button icon" />
													<p>Manage subscription</p>
												</div>
											</LogoutButton>
											<LogoutButton
												onClick={() => {
													createLog('Contact Support Clicked', `{}`, 'Navbar', null)
													if (localStorage.token && user?.user && window.Beacon) {
														if (!window.Beacon('info')) {
															window.Beacon('init', process.env.REACT_APP_BEACON_SECRET)
														}
														window.Beacon('identify', { name: user?.user?.name, email: user?.user?.email })
													}
													window.Beacon('open')
													window.Beacon('once', 'close', () => { window.Beacon('destroy')})
													setVisible(false)
												}}
											>
												<img src={SupportIcon} alt="Support button icon" />
												<p>Contact support</p>
											</LogoutButton>
											{!isDashboard &&isLayout&& <LogoutButton
												onClick={() => {
													exportStoryHandler()
													setVisible(false)
												}}
											>
												<div ref={quillRef} style={{ display: 'none' }} />
												<img src={exportIcon} alt="Support button icon" />
												<p>Export Story</p>
											</LogoutButton>}
											<LogoutButton
												onClick={async () => {
													createLog('User Logout', `{}`, 'Navbar', null)
													systemDispatch(logout())
													localStorage.clear()
													client.cache.reset()
													history.push('/login')
													userDataDispatch(setUser())
													document.body.setAttribute('style', 'background: white;')
												}}
											>
												<img src={LogoutIcon} alt="Logout icon" />
												<p>Logout</p>
											</LogoutButton>
											<LogoutButton
												onClick={() => {
													createLog(
														'User Deletion Attempted',
														`{"workflowStep":${1}}`,
														'Navbar',
														'User Deletion',
													)
													setVisible(false)
													isLoggedIn &&
														dispatchAction(
															setElementToDelete({
																isActive: true,
																type: 'user',
																nodeName: `All of your Account Data will be removed. `,
															}),
														)
												}}
												style={{ color: '#ff8080' }}
											>
												<img src={trashIcon} alt="Trash button icon" />
												<p>Delete Account</p>
											</LogoutButton>
										</ProfileOptions>
									)}
								</OutsideClickHandler>
							</Box>
						)}

						{!isMobile && isLoggedIn && <BreadCrumb />}
						{isLoggedIn && !isDashboard && (
							<>
								<HelperDiv
									style={{
										visibility: isSaving || isExporting ? 'inherit' : 'hidden',
										width: '32px',
									}}
								>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<AutoSave />
										</div>
									</div>
								</HelperDiv>
							</>
						)}
						{isLoggedIn && isDashboard && (
							<Box>
								{(isSaving || isExporting) && window.innerWidth < 500 && (
									<AutoSave isMobile={window.innerWidth < 500} />
								)}
							</Box>
						)}
					</HeaderLeftContainer>
				</div>

				{isLoggedIn && isLayout && (!isSmallScreen || !isCatelogOpen) && (
					<FrameOption>
						<ViewContainer
							display="flex"
							flexDirection="column"
							alignItems="center"
							className="logo-container"
							isActive={activeConnections?.appView === 'Premise'}
							onClick={async () => {
								createLog(
									`Writing Stage Button Clicked`,
									{newWritingStage:"Premise", currentWritingStage:activeConnections?.appView},
									'Navbar',
									'Navigation Between Writing Stages',
								)
								dispatchAction(
									setActiveConnections({
										isActive: true,
										elementId: null,
										elementType: null,
										docType: null,
										appView: 'Premise',
									}),
								)
								
								if(isCatelogOpen){
									dispatchAction(setIsCatelogOpen(false))
								}

								dispatch({ key: 'currentView', value: 'visualization' })
							}}
						>
							<OutlliningLogo
								src={getNodeIcon('Premise')}
								alt="PremiseViewLogo"
								width={72}
								height={30}
								style={{
									width: 'min-content',
								}}
							/>
							<ViewLabel isActive={activeConnections?.appView === 'Premise'}>Premise</ViewLabel>
						</ViewContainer>
						<ViewContainer
							display="flex"
							flexDirection="column"
							alignItems="center"
							className="logo-container"
							isActive={activeConnections?.appView === 'Outlining'}
							onClick={async () => {
								createLog(
									`Writing Stage Button Clicked`,
									{newWritingStage:"Outlining", currentWritingStage:activeConnections?.appView},
									'Navbar',
									'Navigation Between Writing Stages',
								)
								dispatchAction(
									setActiveConnections({
										isActive: true,
										elementId: null,
										elementType: null,
										docType: null,
										appView: 'Outlining',
									}),
								)
								
								if(isCatelogOpen){
									dispatchAction(setIsCatelogOpen(false))
								}

								dispatch({ key: 'currentView', value: 'visualization' })
							}}
						>
							<OutlliningLogo
								src={outliningIcon}
								alt="OutliningViewLogo"
								width={72}
								height={30}
								style={{
									width: 'min-content',
								}}
							/>
							<ViewLabel isActive={activeConnections?.appView === 'Outlining'}>Outlining</ViewLabel>
						</ViewContainer>
						<ViewContainer
							display="flex"
							flexDirection="column"
							alignItems="center"
							className="logo-container"
							isActive={activeConnections?.appView === 'Writing'}
							onClick={async () => {

								createLog(
									`Writing Stage Button Clicked`,
									{newWritingStage:"Writing", currentWritingStage:activeConnections?.appView},
									'Navbar',
									'Navigation Between Writing Stages',
								)

								dispatchAction(
									setActiveConnections({
										isActive: true,
										elementId: null,
										elementType: null,
										docType: null,
										appView: 'Writing',
									}),
								)
								if (isCatelogOpen) {
									dispatchAction(setIsCatelogOpen(false))
								}

								dispatch({ key: 'currentView', value: 'timeline' })
							}}
						>
							<WritingLogo
								src={writingIcon}
								alt="WritingViewLogo"
								width={72}
								style={{
									width: 'min-content',
								}}
								height={30}
							/>
							<ViewLabel isActive={activeConnections?.appView === 'Writing'}>Writing</ViewLabel>
						</ViewContainer>
					</FrameOption>
				)}
				{isLoggedIn && (
					<MenuContainer isSmallScreen={isSmallScreen}>
						{isSmallScreen && (
							<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
								<IconButton
									edge="start"
									color="inherit"
									aria-label="open drawer"
									onClick={() => setVisible(true)}
									sx={{
										mr: isCatelogOpen ? 0 : 0,
									}}
								>
									<MenuIcon />
								</IconButton>
								{isVisible && (
									<MenuOption>
										<LogoutButton
											onClick={event => {
												setVisible(false)
											}}
										>
											<Box
												display="flex"
												flexDirection="row"
												alignItems="center"
												className="logo-container"
												style={{
													width: '100%',
												}}
											>
												<LogoutButton
													data-public
													data-dd-privacy="allow"
													className="userNameLabel"
													onClick={() => {
														createLog(`Account Options Clicked`, `{}`, 'Navbar', null)
														//activeButton()
														refetchCustomerPortal()
														if (!user?.user) {
															getUser()
														}
													}}
												>
													<AccountCircleIcon
														sx={{
															height: '20px',
															width: '20px',
														}}
														fontSize="large"
													/>
													<p style={{ fontSize: '1rem' }}>
														{userNameLengthFormat(user?.user?.name)}
													</p>
												</LogoutButton>
											</Box>
										</LogoutButton>
										{isLayout && (
											<LogoutButton
												onClick={event => {
													setIsCreateElementBar(event.currentTarget)
													setVisible(false)
												}}
											>
												<img src={PlusSign} alt="plus-icon" />
												<p>Create a Story Element</p>
											</LogoutButton>
										)}
										{isLayout && (
											<LogoutButton
												onClick={() => {
													dispatchAction(setIsCatelogOpen('Recent'))
													setVisible(false)
													dispatchAction(
														setActiveConnections({
															isActive: null,
															elementId: null,
															elementType: null,
															docType: null,
															appView: activeConnections.appView,
															isMobileTempClose: true,
														}),
													)
												}}
											>
												<img src={SearchIcon} alt="search-icon" />
												<p>Search Catalog</p>
											</LogoutButton>
										)}
										{!isDashboard && (
											<LogoutButton
												onClick={() => {
													history.push('/dashboard')
												}}
											>
												<div
													style={{
														width: '100%',
														display: 'flex',
														flexDirection: 'row',
														wordBreak: 'normal',
													}}
												>
													<img src={storyIcon} alt="Story icon" />
													<p>Back to My Stories</p>
												</div>
											</LogoutButton>
										)}
										{user?.user?.subscriptionStatus?.status === 'trialing' &&
											!user?.user?.subscriptionStatus?.subscriptionSchedule && (
												<UpgradeToPro
													message={
														<>
															Your trial period will expire <br />
															in <b>{user?.user?.subscriptionStatus?.daysLeft}.</b>
														</>
													}
												/>
											)}

										<LogoutButton
											onClick={() => {
												if (
													(user?.user?.subscriptionStatus?.status === 'trialing' &&
														!user?.user?.subscriptionStatus?.subscriptionSchedule) ||
													user?.user?.subscriptionStatus?.status === 'canceled' ||
													user?.user?.subscriptionStatus?.status === 'past_due'
												) {
													createLog('Go to plan picker', `{}`, 'Navbar', null)
													history.push('/plan-picker')
												} else {
													createLog('Open Customer Portal', `{}`, 'Navbar', null)
													window.location = urlCustomerPortal?.createCustomerPortalSession
												}
											}}
										>
											<div
												style={{
													width: '100%',
													display: 'flex',
													flexDirection: 'row',
													wordBreak: 'normal',
												}}
											>
												<img src={SettingsIcon} alt="Support button icon" />
												<p>Manage subscription</p>
											</div>
										</LogoutButton>
										<LogoutButton
											onClick={() => {
												createLog('Contact Support Clicked', `{}`, 'Navbar', null)
												window.Beacon('toggle')
												setVisible(false)
											}}
										>
											<img src={SupportIcon} alt="Support button icon" />
											<p>Contact support</p>
										</LogoutButton>
										<LogoutButton
											onClick={async () => {
												createLog('User Logout', `{}`, 'Navbar', null)
												systemDispatch(logout())
												localStorage.clear()
												client.cache.reset()
												history.push('/login')
												userDataDispatch(setUser())
												document.body.setAttribute('style', 'background: white;')
											}}
										>
											<img src={LogoutIcon} alt="Logout icon" />
											<p>Logout</p>
										</LogoutButton>
										<LogoutButton
											onClick={() => {
												createLog(
													'User Deletion Attempted',
													`{"workflowStep":${1}}`,
													'Navbar',
													'User Deletion',
												)
												// setIsDeleteUser(true)
												setVisible(false)
												isLoggedIn &&
													dispatchAction(
														setElementToDelete({
															isActive: true,
															type: 'user',
															nodeName: `All of your Account Data will be removed. `,
														}),
													)
											}}
											style={{ color: '#ff8080' }}
										>
											<img src={trashIcon} alt="Trash button icon" />
											<p>Delete Account</p>
										</LogoutButton>
									</MenuOption>
								)}
							</OutsideClickHandler>
						)}
						{!isSmallScreen && isLayout && (
							<CreateStoryElementButton
								onClick={event => {
									setIsCreateElementBar(event.currentTarget)
								}}
							>
								+ Create a Story Element
							</CreateStoryElementButton>
						)}

						{isCreateElementBar && (
							<Popover
								anchorEl={isCreateElementBar}
								open={!!isCreateElementBar}
								onClose={() => {
									setIsCreateElementBar(null)
								}}
								PaperProps={{
									sx: {
										right: isSmallScreen ? '10px' : 'unset',
										left: isSmallScreen ? 'auto !important' : 'unset',
									},
								}}
								anchorOrigin={{
									vertical: isMobile || isSmallScreen ? 50 : 40,
									horizontal: isSmallScreen ? 'top' : 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: isSmallScreen ? 'left' : 'center',
								}}
							>
								<CreateElementDropdown toolbarItems={toolbarItems}></CreateElementDropdown>
							</Popover>
						)}

						{(!isSmallScreen || isCatelogOpen) && isLayout && <Search />}
					</MenuContainer>
				)}
				{elementToDelete.isActive && (
					<DeleteModal
						elementType={elementToDelete?.type?.toLowerCase()}
						nodeName={elementToDelete?.nodeName}
						isShowed={elementToDelete.isActive}
						details={elementToDelete.details}
						isConnection={
							elementToDelete?.type === 'Beat' || elementToDelete?.type === 'Connection'
						}
						acceptHandler={async () => {
							if (elementToDelete.type === 'user') {
								createLog(
									`User Deletion Confirmed`,
									`{"workflowStep":${2}}`,
									'Navbar',
									'User Deletion',
								)
								dispatchAction(
									setElementToDelete({
										isActive: false,
										type: null,
										id: null,
										nodeName: null,
									}),
								)
								await deleteUser()
								
								systemDispatch(logout())
								localStorage.clear()
								client.cache.reset()
								userDataDispatch(setUser())
								history.push('/signup')
								document.body.setAttribute('style', 'background: white;')
							} else if (elementToDelete.type === 'story') {
								createLog(
									`Story Deletion Confirmed`,
									`{"workflowStep":${2}}`,
									'Navbar',
									'Story Deletion',
								)
								storyDelete()
								dispatchAction(
									setElementToDelete({
										isActive: false,
										type: null,
										id: null,
										nodeName: null,
									}),
								)
							} else if (elementToDelete.type === 'Connection') {
								removeConnection(elementToDelete)
								dispatchAction(
									setElementToDelete({
										isActive: false,
										type: null,
										id: null,
										nodeName: null,
									}),
								)
								createLog(
									'Connection Deletion Confirmed',
									`{"workflowStep":${2},"connectionType":"Static"}`,
									'ElementList',
									'Connection Deletion',
								)
							} else if (elementToDelete.type === 'Relationship') {
								removeConnection(elementToDelete)
								dispatchAction(
									setElementToDelete({
										isActive: false,
										type: null,
										id: null,
										nodeName: null,
									}),
								)
								createLog(
									'Connection Deletion Confirmed',
									`{"workflowStep":${2},"connectionType":"Static"}`,
									'ElementList',
									'Connection Deletion',
								)
								
							} else {
								
								createLog(
									`Element Deletion Confirmed`,
									`{"workflowStep":${2},"elementType":"${elementToDelete?.type}"}`,
									'ElementList',
									'Element Deletion',
								)
								removeElement(elementToDelete?.id, elementToDelete?.type)
								dispatchAction(
									setElementToDelete({
										isActive: false,
										type: null,
										id: null,
										nodeName: null,
									}),
								)
								if (activeConnections?.elementId === elementToDelete?.id) {
									dispatchAction(
										setActiveConnections({
											isActive: true,
											elementId: null,
											elementType: null,
											appView: currentStory.lastDocView,
										}),
									)
								}
							}
						}}
						closeHandler={() => {
							createLog(
								'Deletion Cancelled',
								`{"workflowStep":${2}, "elementType":"${elementToDelete?.type}"}`,
								'Navbar',
								'Deletion',)
							dispatchAction(
								setElementToDelete({
									isActive: false,
									type: null,
									id: null,
									nodeName: null,
								}),
							)
						}}
					/>
				)}
			</Container>
			{/* {isLoggedIn && isLayout && isMobile && <TaskBar />} */}
		</>
	)
})

export default withStyles(styles)(Navbar)
