import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_RELATIONSHIPS } from '../data'

export const useGetRelationshipsQuery = (options = {}) => {
	const result = useQuery(GET_RELATIONSHIPS, options)
	//console.log("useGetRelationshipsQuery Called with options", options)
	return result
}

export const useLazyGetRelationshipsQuery = (options = {}) => {
	const result = useLazyQuery(GET_RELATIONSHIPS, options)
	//console.log("useLazyGetRelationshipsQuery Called with options", options)
	return result
}
