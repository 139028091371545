import styled from 'styled-components'
import { nodeColors } from '../../utils/commonStyles'
const getColor = type => {
	switch (type) {
		case 'Chapter':
			return nodeColors.Chapter
		case 'Character':
			return nodeColors.Character
		case 'Arc':
			return nodeColors.Arc
		case 'Event':
			return nodeColors.Event
		case 'Theme':
			return nodeColors.Theme
		case 'Note':
			return nodeColors.Note
		default:
			return 'white'
	}
}

const DriverWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	.artist-icon {
		width: unset;
	}
`
const DriverBeatsRelationWrapper = styled.div`
	border: 1px solid rgba(232, 232, 232, 1);
	border-radius: 4px;
	flex: 1;
	height: min-content;
`
const DriverBeatsRelationHeader = styled.div`
	display: flex;
`
const DriverBeatsRelationLabel = styled.div`
	border: 1px solid rgba(232, 232, 232, 1);
	border-radius: 4px;
	padding: 7px 12px 7px 12px;
	flex-grow: 1;
	font-family: Source Sans Pro;
	font-size: 14px;
	font-weight: 700;
	line-height: 17.6px;
	text-align: left;
	background: white;
	margin-left: -1px;
	margin-top: -2.8px;
`
const DriverBeatsRelationCount = styled.div`
	max-width: 46px;
	width: 100%;
`

const RelationshipContainer = styled.div`
	position: relative;
	height: 100%;
	gap: 15px;
	flex-direction: column;
	position: relative;
	height: 100%;
	.icon-container {
		display: flex;
		width: 1rem;
		height: 1rem;
		justify-content: center;
		align-items: center;
		border: 1px solid ${props => nodeColors[props.type]};
		border-radius: 50%;
		object-fit: contain;
		background: ${props => nodeColors[props.type]};
		//margin-bottom: 10px;
		img {
			width: 10px;
			height: auto;
		}
	}

	@media (min-width: 401px) and (max-width: 1024px) {
		width: 100%;
	}
`

const ConnectionElement = styled.div`
	display: flex;
	padding: 10px;
	flex-direction: column;
	gap: 15px;
`
const ConnectionDescription = styled.div`
	border-radius: 0 0 4px 4px;
	position: relative;
	/* -webkit-transform: translateY(0%);
	-ms-transform: translateY(0%);
	transform: translateY(0%); */
	margin: 0 auto;
	/* padding: 10px; */
	/* top: 0px; */
	/* left: -1px; */
	height: auto;
	width: calc(100% - 2px);
	background-color: white;
	border: 1px solid rgba(232, 232, 232, 1);
	border-top: 0;
	top: -3px;
	/* z-index: 1; */
	display: ${props => (props.isVisible ? `inline-block` : `none`)};
	svg {
		position: absolute;
		z-index: -1;
		border-radius: 4px;
		top: -1%;
	}
	.ql-container {
		background-color: transparent;
		position: relative;
		word-break: break-word;
		box-sizing: border-box;
		-moz-text-overflow: ellipsis;
		width: 95%;
		font-size: 11px;
		display: inline-block;
		margin: 0 5px;
	}
	.ql-editor {
		padding: 6px 5px;
	}
	@media (min-width: 950px) and (max-width: 1024px) {
		//left: -25px;
		.ql-container {
			//width: 70%;
			font-size: 10px;
			//left: 10px;
		}
	}
	@media (min-width: 401px) and (max-width: 949px) {
		//left: -25px;
		.ql-editor {
			//padding: 6px 5px;
			line-height: 1;
		}
		.ql-container {
			//left: 10px;
			font-size: 10px;
			//width: 63%;
		}
	}
	@media (max-width: 400px) {
		//left: -32px;
		.ql-editor {
			//padding: 6px 15px;
			line-height: 1;
		}
		.ql-container {
			//left: 10px;
			font-size: 10px;
			//width: 58%;
		}
	}
`
const Connection = styled.div`
	/* flex-grow: 1; */
	display: flex;
	justify-content: center;
	align-items: center;
	height: 12px;
	position: relative;
	background: ${props => (props.isTransparent ? 'transparent' : 'rgba(241, 241, 241, 1)')};
	border-radius: 4px;
	margin-left: -12px;
	border: 1px solid rgba(232, 232, 232, 1);
	padding: 9px 2px 9px 2px;
	img {
		cursor: pointer;
	}
	/* flex: 0; */
`
const DriverLabel = styled.div`
	display: flex;
	border: 1px solid rgba(232, 232, 232, 1);
	padding: 7px 12px;
	gap: 5px;
	background: rgba(255, 255, 255, 1);
	background: rgba(255, 255, 255, 1);
	position: relative;
	border-radius: 4px;
	align-items: center;
	width: 55%;
	z-index: 99;
	flex: 1;
`
const BeatsTextWrapper = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: ${props => (props.bordered ? `2px solid rgba(232, 232, 232, 1)` : `none`)};
	height: 100%;
	gap: 2px;
	width: ${props => (props.widthAuto ? `100%` : `60px`)};
	display: flex;
	/* width: 100%; */
`
const DriverListWrapper = styled.div`
	display: flex;
	/* justify-content: center; */
	flex-direction: column;
	width: 100%;
	gap: 12px;
	flex: 1;
`
const RecentRelationshipHeader = styled.div`
	display: grid;
	row-gap: 30px;
	column-gap: 7px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`
const DriverContainer = styled.div`
	margin-bottom: -7px;
	border-radius: 4px;
	position: relative;
	cursor: pointer;
`
const DriverRelationList = styled.div`
	padding: 9px 10px 20px 9px;
	display: flex;
	flex-direction: column;
	gap: 12px;
`

const ElementName = styled.div`
	display: flex;
	border: 1px solid rgba(232, 232, 232, 1);
	padding: 7px 12px;
	gap: 5px;
	background: rgba(255, 255, 255, 1);
	background: rgba(255, 255, 255, 1);
	position: relative;
	border-radius: 4px;
	align-items: center;
	width: 110px;
	z-index: 99;
`
const ElementNameLable = styled.div`
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: left;
	color: rgba(76, 76, 76, 1);
	p {
		margin: 0;
		font-family: Source Sans Pro;
		font-size: 10px;
		font-weight: 400;
		line-height: 12.57px;
		text-align: left;
		color: rgba(199, 199, 199, 1);
		background: white;	
	}
	:hover {
		text-decoration: underline;
	}
`

const BeatsCountLabel = styled.div`
	font-family: Source Sans Pro;
	font-size: 13px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: right;
	color: ${props => (props.isActive ? `rgba(76, 76, 76, 1)` : `rgba(199, 199, 199, 1)`)};
`
const IconContainer = styled.div`
	position: relative;
	width: 20px;
	height: 20px;
	border-radius: 100px;
	text-align: center;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${props => (props.isSelected ? nodeColors[props.color] : 'transparent')};

	img {
		width: ${props =>
			props.color === 'Arc'
				? '20px'
				: props.color === 'Event'
				? '8px'
				: props.color === 'Character'
				? 'unset'
				: '0.8rem'};
	}
`

const ExistingBeatLabel = styled.div`
	color: rgba(120, 192, 145, 1);
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: left;
`
const ExistingBeat = styled.div`
    color: rgba(120, 192, 145, 1);
    background: #f2fff6;
    outline: none;
    padding: 5px 10px;
    border-radius: 4px;
    text-align: start;
    border: 1.8px solid rgba(120, 192, 145, 1);
	cursor: pointer;
	&:hover {
        background: rgba(120, 192, 145, 0.1);
        border-color: rgba(120, 192, 145, 0.8); 
    }
`

const CreateBeatWrapper = styled.div`
	border-radius: 4px;
    border: 1px dashed rgba(120, 192, 145, 1);
    background: rgba(242, 255, 246, 1);
    padding: 10px;
    /* margin-top: 10px; */
    width: calc(100% - 33px);
	cursor: pointer;
	&:hover {
        background: rgba(120, 192, 145, 0.1);
        border-color: rgba(120, 192, 145, 0.8); 
    }
`

export {
	DriverWrapper,
	DriverLabel,
	IconContainer,
	ElementNameLable,
	ElementName,
	BeatsTextWrapper,
	Connection,
	BeatsCountLabel,
	DriverListWrapper,
	ConnectionDescription,
	DriverContainer,
	RelationshipContainer,
	ConnectionElement,
	DriverBeatsRelationCount,
	DriverBeatsRelationLabel,
	DriverBeatsRelationHeader,
	DriverBeatsRelationWrapper,
	DriverRelationList,
	RecentRelationshipHeader,
	ExistingBeatLabel,
	ExistingBeat,
	CreateBeatWrapper
}
