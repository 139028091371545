import styled from 'styled-components'

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: ${({ blur }) => (blur ? 'unset' : 'rgba(217, 217, 217, 0.61)')};
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`
const ModalContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	background-color: #fffbec;
	border-radius: 6px;
	padding: 20px;
	transform: translateX(-50%) translateY(-50%);
	display: ${({ blurOnly }) => (blurOnly ? 'none' : 'block')};
`

export { Wrapper, ModalContainer }
