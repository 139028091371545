import { SET_RECOMMENDATION_OPENED, VIZ_NODE_HOVER } from './actionTypes'

export const storyOutlineReducer = (state, action) => {
	switch (action.type) {
		case SET_RECOMMENDATION_OPENED:
			return { ...state, isRecommendationOpen: action.payload }
			case VIZ_NODE_HOVER: {
				const { id } = action.payload
				return {
					...state,
					hoveredElem: {
						type: 'node',
						context: 'viz',
						id,
					},
				}
			}
		default:
			return state
	}
}
