import Quill from 'quill'
import { Fonts, Sizes } from '../../utils/utils'



const Font = Quill.import('attributors/style/font')
//const FontAttributor = Quill.import('formats/font')
//const FontAttributor = Quill.import('attributors/class/font')

Font.whitelist = Fonts
//FontAttributor.whitelist = Fonts
//Quill.register(FontAttributor, true)
Quill.register(Font, true)


const Size = Quill.import('attributors/style/size')

Size.whitelist = [...Sizes.map(size => `${Math.round(size / 0.75)}px`)]

Quill.register(Size, true)

const Parchment = Quill.import('parchment')

const pixelLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
const TAB_MULTIPLIER = 30

// eslint-disable-next-line
class IndentAttributor extends Parchment.StyleAttributor {
	add(node, value) {
		if (value === '+1' || value === '-1') {
			const indent = this.value(node) || 0
			value = value === '+1' ? indent + 1 : indent - 1
		}
		if (value === 0) {
			this.remove(node)
			return true
		}

		return super.add(node, `${+value * TAB_MULTIPLIER}px`)
	}

	canAdd(node, value) {
		if (node.nodeName === 'LI' && this.keyName === 'text-indent') {
			this.keyName = 'margin-left'
		} else if (this.keyName === 'margin-left' && !(node.nodeName === 'LI')) {
			this.keyName = 'text-indent'
		}
		return super.canAdd(node, value) || super.canAdd(node, parseInt(value, 10))
	}

	value(node) {
		return parseFloat(super.value(node)) / TAB_MULTIPLIER || undefined // Don't return NaN
	}
}

const IndentStyle = new IndentAttributor('indent', 'text-indent', {
	scope: Parchment.Scope.BLOCK,
	whitelist: pixelLevels.map(value => `${value * TAB_MULTIPLIER}px`),
})

Quill.register(IndentStyle, true)


export default Quill