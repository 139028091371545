import gql from 'graphql-tag'
import {
	nodeFieldsFragment,
	TASK_FRAGMENT,
	connectionsFragment,
	driverConnectionsFragment,
	childConnectionsFragment,
	staticConnectionsFragment,
	premiseConnectionsFragment,
	noteConnectionsFragment,
	relationshipFieldsFragment
} from '../../utils/fragment'
import { nodeTypes } from '../../utils/nodeTypes'

export const CREATE_EVENTLOG = gql`
	mutation createEventLog($input: EventLogInput!) {
		createEventLog(input: $input)
	}
`

export const CREATE_TASK = gql`
	mutation createTask($input: TaskInput!) {
		createTask(input: $input) {
			...taskFields
		}
	}
	${TASK_FRAGMENT}
`

export const DELETE_STORY = gql`
	mutation deleteStory($id: ID!) {
		deleteStory(id: $id)
	}
`

export const DELETE_USER = gql`
	mutation deleteUser {
		deleteUser
	}
`

export const DELETE_TASK = gql`
	mutation deleteTask($id: ID!) {
		deleteTask(id: $id)
	}
`

export const EDIT_STORY = gql`
	mutation updateStory($id: ID!, $input: StoryInput!) {
		updateStory(id: $id, input: $input)
	}
`

export const REORDER_TASKS = gql`
	mutation reorderTasks($newNodeOrder: [NodeOrderInput]!) {
		reorderTasks(newNodeOrder: $newNodeOrder)
	}
`

export const UPDATE_TASK = gql`
	mutation updateTask($id: ID!, $input: TaskInput!) {
		updateTask(id: $id, input: $input)
	}
`

export const ADD_DRIVERS_TO_BEATS = gql`
	mutation addDriversToBeats($beatId: ID!, $addedDriverConnections: [RelationshipInput], $driversToDelete:[RelationshipInput], $newStaticConnections:[RelationshipInput]) {
		addDriversToBeats(beatId: $beatId, addedDriverConnections: $addedDriverConnections, driversToDelete: $driversToDelete, newStaticConnections: $newStaticConnections ){
			id
			
			
		}
	}
`

export const CREATE_CHARACTER = gql`
	mutation createCharacter($input: CharacterInput!) {
		createCharacter(input: $input) {
			id
			
		}
	}
	
`

export const CREATE_CHAPTER = gql`
	mutation createChapter($input: ChapterInput!) {
		createChapter(input: $input) {
			id
		}
	}
`

export const CREATE_ARC = gql`
	mutation createArc($input: ArcInput!) {
		createArc(input: $input) {
			id
		}
	}
`

export const CREATE_NOTE = gql`
	mutation createNote($input: NoteInput!) {
		createNote(input: $input) {
			id
		}
	}
`

export const CREATE_EVENT = gql`
	mutation createEvent($input: EventInput!) {
		createEvent(input: $input) {
			id
		}
	}
`

export const CREATE_THEME = gql`
	mutation createTheme($input: ThemeInput!) {
		createTheme(input: $input) {
			id
		}
	}
`

export const CREATE_SETTING = gql`
	mutation createSetting($input: SettingInput!) {
		createSetting(input: $input) {
			id
		}
	}
`
export const CREATE_PREMISE = gql`
	mutation createPremise($input: PremiseInput!) {
		createPremise(input: $input) {
			id
		}
	}
`
export const CREATE_OUTLINING = gql`
	mutation createOutlining($input: OutliningInput!) {
		createOutlining(input: $input) {
			id
		}
	}
`
export const CREATE_WRITING = gql`
	mutation createWriting($input: WritingInput!) {
		createWriting(input: $input) {
			id
		}
	}
`

export const CREATE_USER = gql`
	mutation createUser($input: CreateUserInput!) {
		createUser(input: $input) {
			user {
				id
				name
				email
			}
			token
		}
	}
`

export const CREATE_BEAT = gql`
	mutation createBeat($parentId: ID, $beat: BeatInput!, $relationship:RelationshipInput) {
		createBeat(parentId: $parentId, beat: $beat, relationship: $relationship) {
			beat {
				id
			}
			relationship {
				id
			}
		}
	}
`

export const CREATE_MULTIPLE_BEATS = gql`
	mutation createMultipleBeats($multipleBeats:[BeatAndRelInput]) {
		createMultipleBeats(multipleBeats: $multipleBeats)
	}
`


export const CREATE_SHARED_BEAT = gql`
	mutation createSharedBeat($beat: BeatInput!, $addedDriverConnections: [RelationshipInput!]!, $connectionId: ID!) {
		createSharedBeat(beat: $beat, addedDriverConnections: $addedDriverConnections, connectionId: $connectionId) {
			beat {
				id
			}
			relationships {
				id
			}
		}
	}
`

export const UPDATE_RECOMMENDATION_STATUS = gql`
	mutation updateRecommendationStatus(
		$level: String!
		$recommendationId: String!
		$status: String!
		$type: String!
	) {
		updateRecommendationStatus(
			level: $level
			recommendationId: $recommendationId
			status: $status
			type: $type
		)
	}
`

export const UPDATE_RECOMMENDATION_VISIBILITY = gql`
	mutation updateRecommendationVisibility(
		$level: String!
		$recommendationId: String!
		$visibility: String!
		$type: String!
	) {
		updateRecommendationVisibility(
			level: $level
			recommendationId: $recommendationId
			visibility: $visibility
			type: $type
		)
	}
`

export const CHANGE_IS_FIRST_LOGIN = gql`
	mutation {
		changeIsFirstLogin {
			isFirstLogin
		}
	}
`

export const CREATE_STORY = gql`
	mutation createStory($template: String) {
		createStory(template: $template) {
			id
			name
			description
			template
			lastDocView
		}
	}
`

export const UPDATE_RELATIONSHIP = gql`
	mutation updateRelationship($id: ID!, $input: UpdateRelationshipInput!) {
		updateRelationship(id: $id, input: $input)
	}
`

export const CREATE_CONNECTION = gql`
	mutation linkNodes(
		$id: ID
		$sourceId: ID!
		$destId: ID!
		$relName: String!
		$connectionType: String!
		$structureTag: String
		$order: String
		$name: String
		$description: String
		$arc: ID
		$arcStage: String
		$beatsDriven: String
		$sharedBeats: [BeatInput]
		$firstBeat: String
	) {
		linkNodes(
			id: $id
			sourceId: $sourceId
			destId: $destId
			relName: $relName
			connectionType: $connectionType
			structureTag: $structureTag
			order: $order
			name: $name
			description: $description
			arc: $arc
			arcStage: $arcStage
			beatsDriven: $beatsDriven
			sharedBeats: $sharedBeats
			firstBeat: $firstBeat
		) {
			id
		}
	}
`

export const CREATE_CONNECTION_AND_ELEMENT = gql`
	mutation fastCreateElementAndRelationship(
		$newRelationship: RelationshipInput!
		$newElement: NodeInput!
	) {
		fastCreateElementAndRelationship(
			newRelationship: $newRelationship
			newElement: $newElement
		) {
			id
		}
	}
`

export const DELETE_CONNECTION = gql`
	mutation deleteRelationship($id: ID!) {
		deleteRelationship(id: $id)
	}
`

export const REORDER_NODES = gql`
	mutation reorderNodes($nodeType: String!, $newNodeOrder: [NodeOrderInput]!) {
		reorderNodes(nodeType: $nodeType, newNodeOrder: $newNodeOrder)
	}
`

export const REORDER_BEATS = gql`
	mutation reorderBeats(
		$referenceElementId: ID
		$newNodeOrder: [NodeOrderInput]!
		$connectionType: String
	) {
		reorderBeats(
			referenceElementId: $referenceElementId
			newNodeOrder: $newNodeOrder
			connectionType: $connectionType
		)
	}
`

export const DELETE_NODE = gql`
	mutation deleteNode($id: ID!) {
		deleteNode(id: $id)
	}
`

const UPDATE_CHARACTER = gql`
	mutation updateCharacter($id: ID!, $input: CharacterInput!) {
		updateCharacter(id: $id, input: $input)
	}
`

const UPDATE_CHAPTER = gql`
	mutation updateChapter($id: ID!, $input: ChapterInput!) {
		updateChapter(id: $id, input: $input)
	}
`

const UPDATE_ARC = gql`
	mutation updateArc($id: ID!, $input: ArcInput!) {
		updateArc(id: $id, input: $input)
	}
`

const UPDATE_NOTE = gql`
	mutation updateNote($id: ID!, $input: NoteInput!) {
		updateNote(id: $id, input: $input)
	}
`

const UPDATE_EVENT = gql`
	mutation updateEvent($id: ID!, $input: EventInput!) {
		updateEvent(id: $id, input: $input)
	}
`

const UPDATE_THEME = gql`
	mutation updateTheme($id: ID!, $input: ThemeInput!) {
		updateTheme(id: $id, input: $input)
	}
`
const UPDATE_SETTING = gql`
	mutation updateSetting($id: ID!, $input: SettingInput!) {
		updateSetting(id: $id, input: $input)
	}
`
const UPDATE_PREMISE = gql`
	mutation updatePremise($id: ID!, $input: PremiseInput!) {
		updatePremise(id: $id, input: $input)
	}
`
const UPDATE_OUTLINING = gql`
	mutation updateOutlining($id: ID!, $input: OutliningInput!) {
		updateOutlining(id: $id, input: $input)
	}
`
const UPDATE_WRITING = gql`
	mutation updateWriting($id: ID!, $input: WritingInput!) {
		updateWriting(id: $id, input: $input)
	}
`

const UPDATE_BEAT = gql`
	mutation updateBeat($id: ID!, $input: BeatInput!) {
		updateBeat(id: $id, input: $input)
	}
`

export const MOVE_BEAT = gql`
	mutation moveBeat($input: moveBeatInput!) {
		moveBeat(input: $input) {
			newRelationship {
				id
			}
		}
	}
`
export const RESET_VIZ_COORDINATES = gql`
	mutation resetVizCoordinates {
		resetVizCoordinates
	}
`

export const IDENTIFY_DRIVERS = gql`
	mutation identifyDrivers($driverId: ID!, $fieldName: String!, $fieldText: String!, $elementType: String!, $elementName: String!, $fieldPlaceholder:String!){
		identifyDrivers(driverId: $driverId, fieldName: $fieldName, fieldText: $fieldText, elementType: $elementType, elementName: $elementName, fieldPlaceholder: $fieldPlaceholder)
	}
`

export const createNodeMutations = {
	[nodeTypes.Character.typeName]: CREATE_CHARACTER,
	[nodeTypes.Chapter.typeName]: CREATE_CHAPTER,
	[nodeTypes.Arc.typeName]: CREATE_ARC,
	[nodeTypes.Note.typeName]: CREATE_NOTE,
	[nodeTypes.Event.typeName]: CREATE_EVENT,
	[nodeTypes.Theme.typeName]: CREATE_THEME,
	[nodeTypes.Setting.typeName]: CREATE_SETTING,
	[nodeTypes.User.typeName]: CREATE_USER,
	[nodeTypes.Beat.typeName]: CREATE_BEAT,
	[nodeTypes.Task.typeName]: CREATE_TASK,
	[nodeTypes.Story.typeName]: CREATE_STORY,
	[nodeTypes.Premise.typeName]: CREATE_PREMISE,
	[nodeTypes.Outlining.typeName]: CREATE_OUTLINING,
	[nodeTypes.Writing.typeName]: CREATE_WRITING,
	[nodeTypes.Relationship.typeName]: CREATE_CONNECTION,
}

export const updateNodeMutations = {
	[nodeTypes.Character.typeName]: UPDATE_CHARACTER,
	[nodeTypes.Chapter.typeName]: UPDATE_CHAPTER,
	[nodeTypes.Arc.typeName]: UPDATE_ARC,
	[nodeTypes.Note.typeName]: UPDATE_NOTE,
	[nodeTypes.Event.typeName]: UPDATE_EVENT,
	[nodeTypes.Theme.typeName]: UPDATE_THEME,
	[nodeTypes.Setting.typeName]: UPDATE_SETTING,
	[nodeTypes.Beat.typeName]: UPDATE_BEAT,
	[nodeTypes.Task.typeName]: UPDATE_TASK,
	[nodeTypes.Story.typeName]: EDIT_STORY,
	[nodeTypes.Premise.typeName]: UPDATE_PREMISE,
	[nodeTypes.Outlining.typeName]: UPDATE_OUTLINING,
	[nodeTypes.Writing.typeName]: UPDATE_WRITING,
	[nodeTypes.Relationship.typeName]: UPDATE_RELATIONSHIP,
}
