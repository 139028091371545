import React, { createContext, useReducer } from 'react'
import { useGetUserQuery } from '../hooks'
import { sharedReducer } from './sharedReducer'

export const userStateContext = createContext()
export const userDispatchContext = createContext()

const initialState = {
	user: null,
}

export const setUserData = payload => {
	return {
		type: 'USER_DATA',
		payload,
	}
}

export const userReducer = (state, action) => {
	switch (action.type) {
		case 'USER_DATA':
			return action.payload
		default:
			return state
	}
}

export const UserProvider = ({ children }) => {
	const [state, dispatch] = useReducer(sharedReducer, initialState)
	const { refetch: refetchUser } = useGetUserQuery({ fetchPolicy: 'cache-first' })

	return (
		<userStateContext.Provider value={{ ...state, refetchUser }}>
			<userDispatchContext.Provider value={dispatch}>{children}</userDispatchContext.Provider>
		</userStateContext.Provider>
	)
}

export default UserProvider
