/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import styled from 'styled-components'
import '../../css/default.css'

const QuillContainer = styled.div`
	//min-height: 15rem;
	font-size: 16px;
	height: auto !important;
	opacity: 1;
	cursor: default;
	padding: 0;
	.ql-editor {
		min-height:  ${({type}) => type === "Chapter"? "50rem" : "15rem"};
		padding: 12px 5px !important;
		${({ isNarrow }) => (isNarrow ? 'margin: 0 !important;' : '')}
	}

	.ql-hidden {
		display: none;
	}
`

const TextContainer = styled.div`
	/* max-width: 800px; */
	//padding: 14px 20px;
	margin: auto auto 0 auto;
	border-radius: 4px;
	grid-row: 1/3;
	grid-column: 2/3;
	background-color: #fff;
	/* min-height: 1000px; */

	.ql-editor {
		margin: 10px 10px;
		/* min-height: 500px; */
		padding: 0 !important;
		line-height: 2;
		color: #000;
		@media (max-width: 576px) {
			margin: 20px;
		}
	}
	.ql-container {
		font-family: 'Arial';
		font-style: normal;
		font-size: ${Math.round(11 / 0.75)}px;
		color: #000;
		padding: 0;
		line-height: 1.5;
	}

	.ql-editor.ql-blank::before {
		left: unset;
		right: unset;
	}

	.ql-picker.ql-size .ql-picker-label::before,
	.ql-picker.ql-size .ql-picker-item::before {
		content: attr(data-value) !important;
		font-family: attr(data-value) !important;
	}

	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		background: #eeeeee;
	}

	::-webkit-scrollbar {
		width: 0.5rem;
		height: 0;
		margin: 0rem 0 0rem 0;
		padding: 0rem 1rem 0rem 0;
	}

	@media (max-width: 600px) {
		margin: 5px;
	}
`

const StyledButton = styled(Button)`
	background: #5c5c5c;
	color: white;
	font-family: 'Source Sans Pro';
	font-weight: 600;
	width: 100px;

	:hover {
		background: #5c5c5c;
	}
`

const StyledCancelButton = styled(StyledButton)`
	background: #fff;
	color: #5c5c5c;
	border: 1px solid #5c5c5c;

	:hover {
		background: #fff;
	}
`

const StyledDialog = styled(Dialog)`
	.MuiPaper-root,
	.MuiTypography-root {
		color: #5c5c5c;
		font-family: 'Source Sans Pro';
	}

	.MuiTypography-h6 {
		font-weight: 600;
		font-size: 20px;
	}

	.MuiDialogContentText-root {
		color: #4a4a4a;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
	}
`

export { QuillContainer, TextContainer, StyledButton, StyledCancelButton, StyledDialog }
