/* eslint-disable prettier/prettier */
import React, { createRef } from 'react'

class OutsideClickHandler extends React.Component {
	wrapperRef = createRef()

	static defaultProps = {
		onOutsideClick: () => {},
		width: () => {},
		height: () => {},
		noZIndex: false,
	}

	componentDidMount() {
		document?.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	handleClickOutside = event => {
		if (this.wrapperRef.current && !this.wrapperRef.current.contains(event?.target)) {
			this.props.onOutsideClick()
		}
	}

	render() {
		const { children } = this.props

		return (
			<div
				className="innermodal"
				style={{
					width: `${this.props.width}` || 'auto',
					height: `${this.props.height}` || 'auto',
					position: `${this.props.position ? this.props.position : 'relative'}`,
				}}
				ref={this.wrapperRef}
			>
				{children}
			</div>
		)
	}
}

export default OutsideClickHandler
