import { useMutation } from '@apollo/client'
import { REORDER_BEATS } from '../data'

export const useReorderBeatsMutation = (options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const result = useMutation(REORDER_BEATS, options)
	return result
}
