import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

const BorderLinearProgress = styled(LinearProgress)(({ barcolor, primarycolor, height = 4 }) => ({
	borderRadius: '10px',
	height: `${height}px`,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: primarycolor,
		borderRadius: '10px',
	},
	[`& .${linearProgressClasses.bar}`]: {
		backgroundColor: barcolor,
		borderRadius: '10px',
	},
}))

export default BorderLinearProgress
