import styled from 'styled-components'

const TutorialTab = styled.div`
	padding: ${({ isDashboard }) => (isDashboard ? '0' : '10px')};
	box-sizing: border-box;
	${({ isNarrow }) =>
		!isNarrow
			? `
        height: calc(100% - 40px);
        overflow-y: scroll;
    `
			: ''}
`

const TutorialSetsContainer = styled.div`
	width: 100%;
	${({ isDashboard, leftToggleTabs, rightToggleTabs }) =>
		isDashboard
			? ''
			: `
        @media (min-width: 769px) {
            ${
							!(leftToggleTabs || rightToggleTabs)
								? `
                width: 65%;
            `
								: ''
						}
        }
        @media (min-width: 1025px) {
            ${
							!(leftToggleTabs && rightToggleTabs)
								? `
                width: 60%;
            `
								: ''
						}
        }
        @media (min-width: 1441px) {
            width: 60%;
        }
    `}
`

const CollapsedTutorialSet = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 24px;
	user-select: none;
	cursor: ${({notClickable}) => notClickable? 'default': 'pointer'};
`

const TutorialTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	.title {
		font-size: 14px;
		font-weight: bold;
	}
	.bullet {
		border-radius: 50%;
		background-color: #515151;
		width: 3px;
		height: 3px;
	}
	
`

const TutorialCount = styled.span`
	width: 17px;
	height: 11px;
	border-radius: 15px;
	background-color: #f9dc5c;
	display: flex;
	justify-content: center;
	font-size: 9px;
`

const CollapsedTutorialCount = styled.span`
	font-size: 10px;
`

const IconContainer = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	img {
		width: 16px;
	}

	.non-clickable {
		cursor: default;
	}
`

const CreateCompanionStoryBtn = styled.button`
	cursor: pointer;
	border: none;
	background-color: #ffc300;
	border-radius: 50px;
	font-size: 10px;
	padding: 3.5px 7px;
	:hover {
		transform: translateY(-2px);
		box-shadow: -2px 2px 1px 1px #ffc3008f;
	
`

const NoCompanionStoryTag = styled.div`
	border: none;
	background-color: #fff2c9;
	border-radius: 50px;
	font-size: 10px;
	padding: 3.5px 7px;
`

const TutorialSetContainer = styled.div`
	margin-bottom: 10px;
	background-color: #f8f8f8;
	padding: 10px;
	border-radius: 6px;

	.tutorial-set-description {
		font-size: 12px;
	}
	${({ isCollapsed }) =>
		isCollapsed
			? `
        :hover {
            box-shadow: 0 0 11px rgba(33,33,33,.2);
        }
    `
			: ''}
`

const Card = styled.div`
	height: 50px;
	background-color: #ffffff;
	margin: 5px 0;
	padding: 8px 0;
	box-sizing: border-box;
	display: flex;
	border: 1px solid #bababa;
	border-radius: 4px;
	cursor: pointer;
`

const TutorialStatusIcon = styled.img`
	width: 17px;
	margin: 0 12px;
	align-self: center;
	border-radius: 50%;
	height: fit-content;

	${({ isCompleted }) =>
		!isCompleted
			? `
        cursor: pointer;
        :hover {
            box-shadow: 0 0 10px 0px #6EEA89;
        }
    `
			: ''}
`

const TutorialInfo = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	p {
		margin: 0;
	}

	.title {
		font-size: 8px;
		font-weight: 600;
		width: fit-content;
		cursor: pointer;
		:hover {
			color: #56617d;
			text-decoration: underline;
		}
	}

	@media (min-width: 601px) {
		.title {
			font-size: 11px;
			font-weight: 600;
		}
	}
`

const Info = styled.div`
	flex: 1;
	display: flex;
	align-items: center;

	.time-icon {
		width: 7px;
		height: 7px;
		margin-right: 3px;
	}
	.time-text {
		font-size: 7px;
	}
	.difficulty-text {
		text-transform: capitalize;
		font-size: 7px;
		margin-right: 5px;
	}

	@media (min-width: 601px) {
		.time-icon {
			width: 10px;
			height: 10px;
			margin-right: 3px;
		}
		.time-text {
			font-size: 9px;
		}
		.difficulty-text {
			font-size: 9px;
		}
	}
`

const PropValues = styled.div`
	margin: 0 6px;
	display: flex;
	gap: 6px;
	align-items: center;
`

const ValuePropImg = styled.img`
	width: 17px;
	@media (min-width: 601px) {
		width: 20px;
	}
`

const DifficultyLevelIcon = styled.img`
	width: 7px;
	margin-right: 3px;
`

const RequestCourseButton = styled.div`
	cursor: pointer;
	display: flex;
	gap: 5px;
	.req-course {
		font-size: 12px;
		color: #0066ff;
	}
`

export {
	TutorialTab,
	TutorialSetsContainer,
	CollapsedTutorialSet,
	Card,
	TutorialSetContainer,
	TutorialStatusIcon,
	TutorialInfo,
	PropValues,
	ValuePropImg,
	Info,
	DifficultyLevelIcon,
	TutorialTitle,
	CreateCompanionStoryBtn,
	IconContainer,
	TutorialCount,
	CollapsedTutorialCount,
	NoCompanionStoryTag,
	RequestCourseButton,
}
