import React, { useState, useContext, useEffect, memo } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Tooltip from '@mui/material/Tooltip'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { makeStyles } from '@mui/styles'
import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import BeatCard from '@lynit/shared/src/ui/BeatCard'
import { graphDataStateContext } from '../../state/graphDataProvider'

const useStyles = makeStyles(() => ({
	initialsTooltip: {
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timelineDot: {
		margin: 0,
		backgroundColor: '#78C091',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineElement: {
		margin: 0,
		width: '10px',
		height: '10px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		color: '#000000',
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: '#78C091',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: 0,
	},
}))

const DraggableBeats = props => {
	const {
		index,
		// node,
		referenceElement,
		connectionId,
		referenceElementType,
		beatId,
		beatsList,
		beatConnection,
		location,
		locationId,
		chapterId
	} = props
	const classes = useStyles()

	const graphData = useContext(graphDataStateContext)

	const [isDragDisabled, setIsDragDisabled] = useState(false)

	const isShadow = Boolean(
		referenceElementType !== 'Chapter' && graphData?.nodes?.[beatId]?.beatConnections?.length,
	)

	const { setNodeRef, attributes, listeners, isDragging, transition, transform, active } = useSortable(
	
	{
		id: beatId + "-" + chapterId,
		data: {
			type: 'beat',
			referenceElement,
			location,
			locationId,
			connectionId,
			connectionTypeField:"beatConnections",
			id: beatId
		},
		disabled: isDragDisabled
	}
	)

	const getTrimmedNodeName = node => {
		if (node?.name) {
			return node?.name?.length > 26 ? `${node?.name?.substring(0, 26)} ...` : node?.name
		}
		return ''
	}

	return (
		<>
			<TimelineSeparator>
				{index !== 0 ? (
					<TimelineConnector className={classes.timelineConnector} />
				) : (
					<TimelineConnector className={classes.hiddenTimelineConnector} />
				)}
					<TimelineDot
						className={classes.timelineDot}
						style={{
							//backgroundColor: nodeColors[node?.__typename],
							transition,
							transform: CSS.Translate.toString(transform),
							zIndex: 1,
						}}
						ref={setNodeRef}
						{...attributes}
						{...listeners}
					>
						{/* {node?.name
							?.match(/[a-zA-Z0-9\s]/g)
							?.join('')
							?.match(/\b(\w)/g)
							?.join('')
							?.slice(0, 2)
							?.toUpperCase()} */}
					</TimelineDot>

					
				{index === beatsList?.length - 1 ? (
					<TimelineConnector className={classes.dashedTimelineConnector} />
				) : (
					<TimelineConnector className={classes.timelineConnector} />
				)}
			</TimelineSeparator>
			<TimelineContent
				className={classes.timelineContent}
				ref={setNodeRef}
				{...attributes}
				{...listeners}
			>
				<div
					style={{
						transition,
						transform: CSS.Translate.toString(transform),
						opacity: (active?.data?.current?.id || active?.id?.slice(0,40)) === beatId ? '0.5' : 'unset',
						padding: '0px',
					}}
				>
					<BeatCard
						{...props}
						isDragging={isDragging}
						isShadow={isShadow}
						setIsDragDisabled={setIsDragDisabled}
						setNodeRef={setNodeRef}
						attributes={attributes}
						listeners={listeners}
						transition={transition}
						transform={transform}
						chapterId={chapterId}
					/>
				</div>
			</TimelineContent>
		</>
	)
}

export default memo(DraggableBeats)
