import { useMutation } from '@apollo/client'
import { CREATE_CONNECTION } from '../data'
import { toastHandler } from '../utils/backendHandler'
import { createCacheConnection, deleteCacheConnections, deleteCacheElement, moveBeatCacheUpdate, updateCacheOrderBeat } from '../utils/apollo'

export const useCreateConnectionMutation = (options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const [createConnection, { data, loading, error }] = useMutation(CREATE_CONNECTION, options);

	const performCreateConnectionMutation = async (
		{
			client,
			newConnection,
			user,
			existingOrders,
			moveBeatCacheUpdateData,
			
		}
	) => {

		if (!client) {
			throw new Error('Missing client for Create Connection')
		}

		if (!newConnection) {
			throw new Error('Missing newConnection for Create Connection')
		}

		if (!user) {
			throw new Error('Missing user for Create Connection')
		}

		if (moveBeatCacheUpdateData) {
			moveBeatCacheUpdate(
				client,
				{},
				moveBeatCacheUpdateData,
				newConnection,
				true
			)
		}else{
			createCacheConnection(client, newConnection)
		}

		const processCacheConnection = async () => {
			try {
				await createConnection({
					variables: {
						id: newConnection?.id,
						destId: newConnection.destNode.id,
						sourceId: newConnection.sourceNode.id,
						description: newConnection.description,
						connectionType: newConnection.connectionType,
						structureTag: newConnection.structureTag,
						arcStage: newConnection.arcStage,
						arc: newConnection.arc,
						name: newConnection.name,
						relName: newConnection.relName,
						beatsDriven: newConnection.beatsDriven,
						sharedBeats: newConnection.sharedBeats?.map(beat => { return { id: beat.id } }) || [],
						firstBeat: newConnection.firstBeat
					},
				})
			} catch (err) {

				if (existingOrders?.length) {
					updateCacheOrderBeat(client, existingOrders)
				}
				deleteCacheConnections(client, newConnection)

				if (err.message.includes('Upgrade')) {
					toastHandler('error', err.message)
					if (
						user?.user?.subscriptionStatus?.status === 'trialing' ||
						user?.user?.subscriptionStatus?.status === 'active'
					) {
						window.location.reload()
					}
				} else {
					toastHandler('error', 'There was an error creating the relationship.')
				}
			}

		}
		await processCacheConnection()

	}
	return [performCreateConnectionMutation, { data, loading, error }]
}
