import styled from 'styled-components'
import dropdown from '../../images/arrowFull.svg'

const DeepModeBarContainer = styled.div`
	width: auto;
	border-radius: 2px 2px 0 0;
	//background-color: #ffffff;
	display: flex;
    justify-content: center;

	li {
		cursor: pointer;
		p.active {
			border-bottom: 2px solid #ffc300;
			padding: 0 8px;
			:hover {
				text-decoration: none;
			}
		}
		p:hover {
			text-decoration: underline;
		}
	}

	.MuiBreadcrumbs-separator {
		font-size: 24px;
		margin-right: 0;
	}

	.ql-picker.ql-size  {
		width:50px ;
	}

	.ql-toolbar.ql-snow {
		background: #ffffff;
		border-width: 1px 1px 0px 1px;
		border-style: solid;
		border-color: #efeef0;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08); 
		position: relative;
		z-index: 1;
		border: 0;
		select,
		span,
		button {
			//margin-left: 2px;
			margin-right: 2px;
		}
	}

	.ql-snow .ql-picker.ql-font {
		width: auto;
		min-width: 108px;
	}

	.ql-picker .ql-picker-label {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: none;
		::after {
			content: url(${dropdown});
		}
		svg {
			display: none;
		}
	}
	.ql-icon-picker,
	.ql-color-picker {
		.ql-picker-label {
			::after {
				content: '';
			}
			svg {
				display: block;
			}
		}
	}
`

const StoryTextContainer = styled.div`
	flex: 1;
	overflow-y: auto;
	${({ isExpanded }) => (isExpanded ? 'width: 100%; margin: auto auto 0 auto;' : '')}
	padding: 0 12px;
	border-radius: 5px;
	::-webkit-scrollbar-thumb {
		background: #9d9d9d;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		border-radius: 100px;
	}

	::-webkit-scrollbar {
		width: 0.5rem;
		height: 0.5rem;
	}

	&.Pstory {
		box-sizing: border-box;
		width: 100%;
		/* padding: 20px; */
		margin: 0 auto;
		@media (max-width: 600px) {
			/* min-height: 650px; */
			height: 100%;
			position: relative;
			display: ${({ isExpanded }) => (isExpanded ? '' : 'none')};
		}
	}
`

const MiddlePanel = styled.div`
	width: auto;
	height: auto;
	grid-column: ${({ gridColumn }) => gridColumn};
	grid-row: ${({ gridRow }) => gridRow};
	@media (max-width: 600px) {
		grid-column: ${({ gridMobColumn }) => gridMobColumn};
		grid-row: ${({ gridMobRow }) => gridMobRow};
		z-index: ${({ leftToggleTabs, rightToggleTabs }) =>
			leftToggleTabs || rightToggleTabs ? '-1' : 'unset'};
	}
`

export { DeepModeBarContainer, StoryTextContainer, MiddlePanel }
