/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/named */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
/* eslint-disable max-classes-per-file */
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Quill from '@lynit/shared/src/ui/Quill/quill-lynit'  //quill'
//import Quill from 'quill'
//import 'quill/dist/quill.snow.css'
import debounce from 'lodash/debounce'
import { useApolloClient } from '@apollo/client'
import {
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	useMediaQuery,
} from '@mui/material'

import { onSaveModal, setServerError, setWordCount } from '@lynit/shared/src/state/actions'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { updateCacheStoryTextElement } from '@lynit/shared/src/utils/apollo'
import { useUpdateNodeMutation } from '@lynit/shared/src/hooks'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import Toast from '@lynit/shared/src/ui/CustomToast'
import useQuillAutoSave from '@lynit/shared/src/ui/AutoSave/useQuillAutoSave'

import {
	QuillContainer,
	StyledButton,
	TextContainer,
	StyledCancelButton,
	StyledDialog,
} from './styles'
import '../../css/default.css'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'

const Delta = Quill.import('delta')


const equivalentElements = (prevProps, nextProps) => {
	return (
		prevProps.id === nextProps.id &&
		prevProps.story === nextProps.story
	)
}

const useStoryEditorWidth = () => {
	const isMobile = useMediaQuery('(max-width: 1024px)')
	const isLaptop = useMediaQuery('(max-width: 1280px)')
	const isLaptopL = useMediaQuery('(max-width: 1440px)')
	const {
		state: { leftToggleTabs, rightToggleTabs },
	} = useContext(sharedContext)

	const isNarrow =
		isMobile ||
		(isLaptop && (leftToggleTabs || rightToggleTabs)) ||
		(isLaptopL && leftToggleTabs && rightToggleTabs)
	return { isNarrow }
}

const StoryEditor = ({
	id,
	story,
	placeholder,
	isRightPanel,
	type,
	updatedAt =()=> new Date().toISOString(),
}) => {
	//let change = new Delta()

	//const client = useApolloClient()
	const {
		state: { leftToggleTabs, rightToggleTabs, isModalOpen },
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	const { currentStoryId: storyId, serverError, noElementError } = useContext(beatsDataStateContext)

	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const systemRelatedData = useContext(systemStateContext)
	const {toastHandler} = systemRelatedData

	const { user } = useContext(userStateContext)

	const timeOut = useRef()
	const serverErrorInterval = useRef()

	const history = useHistory()
	const { isNarrow } = useStoryEditorWidth()

	const [isError, setError] = useState(false)
	const [isFocused, setFocused] = useState(false)
	const [isNavigate, setIsNavigate] = useState(false)



	const [updateElement] = useUpdateNodeMutation(type, { ignoreResults: true })


	const update = (updateId, content, text) => {
		const wordCount = text?.trim() ? String(text?.trim()?.split(/\s+/)?.length || 0) : '0'
		updateElement({
			variables: {
				id: updateId,
				input: {
					storyText: content,
					wordCount,
					updatedAt:updatedAt(),
				},
			},
		})
			.then(res => {
				if (res && serverError) {
					beatsDataDispatch(setServerError(false))
					clearInterval(serverErrorInterval.current)
				}
				
				
				sharedDispatch(setWordCount({ id: updateId, count: wordCount }))
				setError(false)
			})
			.catch(err => {
				quill?.blur()
				if (err.message.includes('Cannot update')) {
					toastHandler('error', 'There was an error saving. Try again.',null,"StoryEditor","Element Update")
					
				} else if (
					err.message.includes('Failed to fetch') ||
					err.message.includes('NetworkError')
				) {
					beatsDataDispatch(setServerError(true))
				} else if (err.message.includes('Response not successful:')) {
					setError(true)
				} else {
					toastHandler('error', 'There was an error saving. Try again.',null,"StoryEditor","Element Update")

				}
			})
			
	}
	
	const { quill, quillRef } = useQuillAutoSave(
		{
			modules: {
				toolbar: `#toolbar-${id}`
			},
			formats: [
				'font',
				'header',
				'size',
				'color',
				'align',
				'background',
				'bold',
				'italic',
				'underline',
				'strike',
				'blockquote',
				'list',
				'indent',
				'link',
			],
			placeholder,
			scrollingContainer: '.Pstory',
		},
		update,
		id,
		"storyText",
		story,
	)


	

	useEffect(() => {
		if (quill) {
			if (serverError || noElementError) {
				quill.disable()
			} else {
				quill.enable()
			}
			if (!user?.subscriptionStatus?.editDescriptionfield) {
				quill.disable()
			}
		}
	}, [serverError, noElementError, quill, user?.subscriptionStatus?.editDescriptionfield])

	useEffect(() => {
		if (serverError && quill) {
			serverErrorInterval.current = setInterval(() => {
				update(id, JSON.stringify(quill.getContents()), quill.getText())
			}, 5000)
		}
		if (!serverError) {
			clearInterval(serverErrorInterval.current)
		}
	}, [serverError])

	

	useEffect(() => {
		if (isError) {
			timeOut.current = setTimeout(() => {
				toastHandler('dark', 
				'',
				<Toast
						onRetry={() => {
							update(id, JSON.stringify(quill.getContents()), quill.getText())
							setError(false)
						}}
						isRetry
						content={'Content not updated. Try again later.'}
					/>
					,"StoryEditor",
					"Element Update")

			}, 1000)
		}
	}, [isError])	

	return (
		<div>
			{/* <StyledDialog open={isModalOpen || isNavigate} onClose={handleClose}>
				<DialogTitle id="alert-dialog-title">Hey, wait!</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						There are unsaved changes. Are you sure you want to leave?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<StyledCancelButton onClick={handleClose}>Disagree</StyledCancelButton>
					<StyledButton onClick={handleClick} autoFocus>
						Agree
					</StyledButton>
				</DialogActions>
			</StyledDialog> */}
			<TextContainer
				className="text-container chapter-text-area"
				rightToggleTabs={rightToggleTabs}
				leftToggleTabs={leftToggleTabs}
				onClick={(e)=>{
					if(quill?.getText().length ===1 ){
						quill?.setSelection(0)

					}
				}}
			>
				<QuillContainer
					data-testid="quill"
					className="quillcon123"
					isEnabled={user?.subscriptionStatus?.editDescriptionfield}
					translate="no"
					ref={quillRef}
					id={id}
					type={type}
					data-fieldname={"storyText"}
					style={isRightPanel ? { border: 'none' } : {}}
					onFocus={() => {
						setFocused(true)
					}}
					onBlur={e => {
						
						setFocused(false)
					}}
					isNarrow={isNarrow}
				/>
			</TextContainer>
		</div>
	)
}

export default React.memo(StoryEditor, equivalentElements)
