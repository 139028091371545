import React, { useContext, useEffect, useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { systemDispatchContext, systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'
import { setIsNewUser, setSummaryCardVisible, setSummaryView } from '@lynit/shared/src/state/actions'
import { useStoryOutlineWidth } from '@lynit/shared/src/utils/utils'
import { storyOutlineContext } from '@lynit/story-outline/src/state/storyOutlineProvider'

import summaryIcon from '../../images/summary-icon.svg'
import visIcon from '../../images/vis-icon.svg'
import timelineIcon from '../../images/timeline-button-icon.svg'
import notificationIcon from '../../images/notification-icon.svg'
import RadarChart from './RadarChart'
import {
	Menu,
	NotificationIconContainer,
	SummaryIcon,
	TimelineIcon,
	VisIcon,
	VizMenu,
} from './styles'

const useStyles = makeStyles(() => ({
	recommendationsTooltip: {
		position: 'relative',
		bottom: '15px',
		right: '13px',
	},
	visualizationTooltip: {
		position: 'relative',
		top: '40px',
		right: '25px',
	},
	timelineTooltip: {
		position: 'relative',
		top: '53px',
		right: '75px',
	},
}))

function VisualizationMenu({ currentView, setCurrentView, setConnectionType }) {

	const [hovered, setHovered] = useState(false)
	const [isFiltersDisabled, setIsFiltersDisabled] = useState(false)
	const [isRecommendationMessageVisible, setIsRecommendationMessageVisible] = useState(false)

	const { state:{isSummaryView, beatsInChapters, unseenRecommendationCount:{total:unseenRecommendationCount}},dispatch:sharedDispatch } = useContext(sharedContext)
	const {isNewUser, createLog} = useContext(systemStateContext)

  const systemDataDispatch = useContext(systemDispatchContext)

	const { connectionData } = useContext(beatsDataStateContext)
	const { state: { isRecommendationOpen } } = useContext(storyOutlineContext)

	const classes = useStyles()
	const { isNarrow } = useStoryOutlineWidth()

	const resetHover = () => {
		if (hovered) {
			setHovered(false)
		}
	}

	const setConnectionTypeFilter = connectionType => {
		if (!isFiltersDisabled) {
			setConnectionType(connectionType)
		}
	}

	const getTitleByFilterName = filterName => {
		if (isFiltersDisabled) {
			return 'No Connections or Beats in Chapters to filter'
		}
		return filterName
	}

	useEffect(() => {
		if (isNewUser) {
			setIsRecommendationMessageVisible(true)
		}
	}, [isNewUser])

	useEffect(() => {
		if (isSummaryView) {
			setCurrentView('summary')
			setIsRecommendationMessageVisible(false)
		}
	}, [isSummaryView])

	// useEffect(() => {
	// 	if (!beatsInChapters && currentView === 'timeline') {
	// 		setCurrentView('visualization')
	// 	}
	// }, [beatsInChapters])

	useEffect(() => {
		if (beatsInChapters || connectionData?.Total) {
			setIsFiltersDisabled(false)
		} else {
			setIsFiltersDisabled(true)
		}
	}, [beatsInChapters, connectionData])

	return (
		<VizMenu isOpen={isRecommendationOpen} isNarrow={isNarrow}>
		<Menu onMouseLeave={resetHover} visHovered={hovered}>
				<Tooltip
					title="Recommendations"
					placement="right"
					classes={{ tooltip: classes.recommendationsTooltip }}
				>
					<div
						className="pie pie1"
						onClick={() => {
							if (isNewUser) {
								systemDataDispatch(setIsNewUser(false))
							}
							if (isRecommendationMessageVisible) {
								setIsRecommendationMessageVisible(false)
							}
							sharedDispatch(setSummaryView(true))
							createLog('Radar Summary Clicked', '{}', 'VisualizationMenu', null)
						}}
						onMouseEnter={resetHover}
					>
						<div className={`pie-color pie1 ${currentView === 'summary' ? 'active' : ''}`} />
						<SummaryIcon src={summaryIcon} alt="summaryIcon" />
					</div>
				</Tooltip>
				<Tooltip
					title={'Visualization'}
					placement="right"
					classes={{ tooltip: classes.visualizationTooltip }}
				>
					<div
						className="pie pie2"
						onClick={() => {
							if (isNewUser) {
								systemDataDispatch(setIsNewUser(false))
							}
							setCurrentView('visualization')
							createLog('Radar Visualization Clicked', '{}', 'VisualizationMenu', null)
						}}
						onMouseEnter={() => {
							// if (currentView === 'visualization') {
							// 	setHovered(true)
							// }
						}}
					>
						<div
							className={`pie-color pie-color2 ${currentView === 'visualization' ? 'active' : ''}`}
						/>
						<VisIcon src={visIcon} alt="visIcon" />
					</div>
				</Tooltip>
				<Tooltip
					title={beatsInChapters ? 'Timeline' : 'Put Beats in Chapters to unlock Timeline'}
					placement="right"
					classes={{ tooltip: classes.timelineTooltip }}
				>
					<div
						className={`pie pie3 ${beatsInChapters ? '' : 'disabled'}`}
						onClick={() => {
							createLog(
								'Radar Timeline Clicked',
								`{"isTimelineDisabled": "${!beatsInChapters}"}`,
								'VisualizationMenu',
								null,
							)
							if (beatsInChapters) {
								setCurrentView('timeline')
							}
						}}
						onMouseEnter={resetHover}
					>
						<div className={`pie-color pie-color3 ${currentView === 'timeline' ? 'active' : ''}`} />
						<TimelineIcon src={timelineIcon} alt="timelineIcon" />
					</div>
					</Tooltip>
				{/* <Tooltip title={getTitleByFilterName('All')} placement="right">
					<AllVisIcon
						src={allVisIcon}
						alt="allVisIcon"
						hovered={hovered}
						onClick={() => setConnectionTypeFilter('')}
						className={isFiltersDisabled ? 'filter-disabled' : ''}
					/>
				</Tooltip>
				<Tooltip title={getTitleByFilterName('Connections')} placement="right">
					<ConnectionVisIcon
						src={connectionVisIcon}
						alt="connectionVisIcon"
						hovered={hovered}
						onClick={() => setConnectionTypeFilter('Static')}
						className={isFiltersDisabled ? 'filter-disabled' : ''}
					/>
				</Tooltip>
				<Tooltip title={getTitleByFilterName('Drivers')} placement="right">
					<BeatsVisIcon
						src={beatsVisIcon}
						alt="beatsVisIcon"
						hovered={hovered}
						onClick={() => setConnectionTypeFilter('Driver')}
						className={isFiltersDisabled ? 'filter-disabled' : ''}
					/>
				</Tooltip> */}
			</Menu>
			<RadarChart />
			{!!unseenRecommendationCount &&
				currentView !== 'summary' &&
				isRecommendationMessageVisible && (
					<NotificationIconContainer>
						<div className="notification-icon-container">
							<img src={notificationIcon} alt="notification icon" />
						</div>
						<p>You have a new recommendation</p>
					</NotificationIconContainer>
				)}
		</VizMenu>
	)
}

export default VisualizationMenu
