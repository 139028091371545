import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDndContext, DragOverlay } from '@dnd-kit/core'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import BeatCard from '@lynit/shared/src/ui/BeatCard'
import ChapterCard from '@lynit/story-outline/src/ui/ChapterView/ChapterCard'
import ListElement from '@lynit/layout/src/ui/ListElement'
import { nodeTypeForId } from '@lynit/shared/src/utils/utils'
import { restrictToParentElement } from '@dnd-kit/modifiers'

const DragOverlayComponent = ({ relationships, isDeepMode, response , isSetModifiers}) => {

	const graphData = useContext(graphDataStateContext)
	const { user } = useContext(userStateContext)

	const [draggingItem, setDraggingItem] = useState({})

	const dndContext = useDndContext()
	const { element } = useParams()

	useEffect(() => {
		if (dndContext.active?.data?.current.id) {
			
			const item = graphData.nodes[dndContext.active?.data?.current.id]
			setDraggingItem(item||{})
		} else {
			setDraggingItem({})
		}
	}, [dndContext.active?.id])

	const isNotDroppable = dndContext?.over?.id?.includes("view-container") || dndContext?.over?.id?.includes("document-container")
	let beatId 
	let draggingItemType
	if(draggingItem?.id){
		draggingItemType = nodeTypeForId(draggingItem?.id)
		switch (draggingItemType) {
			case 'Beat':
			  beatId = draggingItem?.id
			  break;
			case 'Relationship':
				beatId = draggingItem.sourceNode?.id?.startsWith('bea')
				? draggingItem.sourceNode?.id
				: draggingItem.destNode?.id
			  break;
			default:
				beatId = draggingItem?.id
		  }
		 
	}
	

	return (
		<DragOverlay 
			dropAnimation={dndContext?.active?.data?.current?.location ==="Chapter Card"? 200: null}
			>
			{draggingItem?.id && Object.keys(draggingItem).length && !isSetModifiers ? (
				draggingItem?.__typename ==="Beat"  ? (
					<BeatCard
						beatId={
							draggingItem.id
						}
						isOverlay={true}
						cursor={(dndContext?.over?.id?.includes("view-container") || (dndContext?.over?.id?.includes("document-container") && !element?.startsWith("chp"))) ? "not-allowed" : "grabbing"}
					/>
				) : (
					<ChapterCard
						key={draggingItem.id}
						id={draggingItem.id}
						name={draggingItem.name}
						description={draggingItem?.description}
						type={draggingItem.__typename}
						isOverlay={true}
						number={draggingItem.number}
					/>
					
				)
			) : null}
		</DragOverlay>
	)
}

export default DragOverlayComponent
