import { useMutation } from '@apollo/client'
import { REORDER_NODES } from '../data'

export const useReorderNodesMutation = (options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const result = useMutation(REORDER_NODES, options)
	return result
}
