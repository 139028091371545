export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const VIZ_NODE_HOVER = 'VIZ_NODE_HOVER'
// export const SET_ACTIVE_CONNECTIONS = 'SET_ACTIVE_CONNECTIONS'
export const SET_ACTIVE_ELEMENT = 'SET_ACTIVE_ELEMENT'
export const RESET_ACTIVE_ELEMENT = 'RESET_ACTIVE_ELEMENT'
export const UI_NODE_HOVER = 'UI_NODE_HOVER'
export const IS_SAVING = 'IS_SAVING'
export const IS_EXPORTING = 'IS_EXPORTING'
export const DELETE_STORY = 'DELETE_STORY'
export const REORDER_ELEMENT = 'REORDER_ELEMENT'
export const MOVE_BEAT = 'MOVE_BEAT'
export const SET_CURRENT_ELEMENT = 'SET_CURRENT_ELEMENT'
export const SET_DIVING_DEEP = 'SET_DIVING_DEEP'
export const SET_LEFT_TOGGLE_TABS = 'SET_LEFT_TOGGLE_TABS'
export const SET_RIGHT_TOGGLE_TABS = 'SET_RIGHT_TOGGLE_TABS'
export const SET_USER_SUBSCRIBED = 'SET_USER_SUBSCRIBED'
export const SET_SHOW_TOOLTIP = 'SET_SHOW_TOOLTIP'
export const SET_GRAPH_DATA = 'SET_GRAPH_DATA'
export const RESET_GRAPH_DATA = 'RESET_GRAPH_DATA'
export const SET_IS_MOBILE = 'SET_IS_MOBILE'
export const EDITOR_TEXT_CHANGE = 'EDITOR_TEXT_CHANGE'
export const ON_SAVE_MODAL = 'ON_SAVE_MODAL'
export const SET_CURRENT_STORY_ID = 'SET_CURRENT_STORY_ID'
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION'
export const SET_LOGIN_USER = 'SET_LOGIN_USER'
export const SET_BEATS_IN_CHAPTERS = 'SET_BEATS_IN_CHAPTERS'
export const SET_CONNECTION_DATA = 'SET_CONNECTION_DATA'
export const SET_UNSEEN_RECOMMENDATION_COUNT = 'SET_UNSEEN_RECOMMENDATION_COUNT'
export const SET_SUMMARY_VIEW = 'SET_SUMMARY_VIEW'
export const SET_IS_NEW_USER = 'SET_IS_NEW_USER'
export const SET_RECOMMENDATION_OPENED = 'SET_RECOMMENDATION_OPENED'
export const OPEN_TUTORIAL = 'OPEN_TUTORIAL'
export const EXPAND_TUTORIAL_SET = 'EXPAND_TUTORIAL_SET'
export const TUTORIAL_SET_EXPANDED = 'TUTORIAL_SET_EXPANDED'
export const TUTORIAL_OPENED = 'TUTORIAL_OPENED'
export const OPEN_RECOMMENDATION = 'OPEN_RECOMMENDATION'
export const RECOMMENDATION_OPENED = 'RECOMMENDATION_OPENED'
export const SET_IS_PROMOTION_VISIBLE = 'SET_IS_PROMOTION_VISIBLE'
export const SET_CACHE_CONN_DESC = 'SET_CACHE_CONN_DESC'
export const SET_NEW_CONN_ID = 'SET_NEW_CONN_ID'
export const SET_CACHE_CONN_SELECTION = 'SET_CACHE_CONN_SELECTION'
export const SET_CURRENT_FLOW = 'SET_CURRENT_FLOW'
export const SET_CHAPTER_VIEW_EXPANDED = 'SET_CHAPTER_VIEW_EXPANDED'
export const SET_VISULIZATION_EXPANDED = 'SET_VISULIZATION_EXPANDED'