import { colorStyles } from '@lynit/shared/src/utils/commonStyles'
import styled from 'styled-components'

export const NotesContainer = styled.div`
	box-sizing: border-box;
	height: 100%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 6px;
	@media (max-width: 786px) {
		width: 15rem;
		height: 100%;
		overflow-x: hidden;
	}
	@media (max-width: 500px) {
		width: 100%;
	}
`

export const NotesContent = styled.div`
	width: auto;
	overflow-y: auto;
	height: 100%;

	::-webkit-scrollbar-thumb {
		background: #9d9d9d;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		border-radius: 100px;
		background: #fbfbfb;
	}

	::-webkit-scrollbar {
		width: ${({ noteNodesLength }) => (noteNodesLength >= 3 ? '0.3rem' : 0)};
		height: 0.5rem;
	}
`

export const muiStyle = {
	noteCard: {
		width: '80%',
		padding: 24,
		display: 'flex',
		flexDirection: 'column',
	},
	notesContainer: {
		width: '80%',
		overflowY: 'auto',
	},
	root: {
		paddingLeft: 0,
		paddingRight: 0,
	},
}

export const styles = {
	mainContainer: {
		background: '#EAEAEA',
		height: '100%',
		padding: 24,
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		width: '400px',
	},
	headerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '12px',
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		gap: '5px',
	},
	img: {
		margin: 3,
		height: 16,
	},
	list: {
		display: 'inline-block',
		background: '#C3C3C3',
		borderRadius: 4,
		padding: 10,
		marginRight: 4,
	},
	pill: {
		display: 'inline-block',
		width: 30,
		height: 15,
		lineHeight: '15px',
		marginTop: 4,
		fontSize: 11,
		fontWeight: 700,
		textAlign: 'center',
		color: colorStyles.lightGrey,
		backgroundColor: colorStyles.darkGrey,
		borderRadius: 8,
	},
	noteTextBlock: {
		flex: '2',
	},
	iconsContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	noteTitle: {
		marginTop: 0,
	},
	addIcon: {
		width: 20,
		cursor: 'pointer',
	},
	contentAndIcons: {
		display: 'flex',
	},
	connectionsContainer: {
		display: 'flex',
	},
	connection: {
		padding: '4px 8px',
		backgroundColor: '#D8D8D8',
		color: '#666',
		marginRight: 8,
		borderRadius: 8,
	},
	countTab: {
		display: 'flex',
		flexDirection: 'row',
		width: 'auto',
		maxWidth: '22.5rem',
		overflowX: 'auto',
		whiteSpace: 'nowrap',
		marginTop: '5px',
		scrollbarWidth: 'none',
		marginLeft: '2px',
	},
}
