import React, { useState, useEffect, useContext, useMemo, Suspense } from 'react'
import {
	FilterLabel,
	Filters,
	SearchCatelogStyled,
	FilterContainer,
	SearchResultLabel,
	MobileExtraFilter,
	ExitSearchIcon,
	ExitSearchIconSmall,
} from './styled'
import { sharedContext } from '../../state'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { useGetRelationshipsQuery } from '../../hooks'
import { setIsCatelogOpen } from '../../state/actions'
import { useMediaQuery } from '@mui/material'

const SearchArea = React.lazy(() => import('./SearchArea'))

const SearchCatelog = () => {
	const filterLists = useMemo(() => ['Recent', 'Drivers', 'Relationships', 'Beats', 'Notes'], [])
	const isSmallDevice = useMediaQuery('(max-width: 768px)')

	const [highlightCount, setHighlightCount] = useState(0)
	const graphData = useContext(graphDataStateContext)
	const { data: relationships } = useGetRelationshipsQuery({fetchPolicy: 'cache-only', component:"SearchCatelog"})
	const {
		state: { searchResult, isCatelogOpen },
		dispatch: dispatchAction,
	} = useContext(sharedContext)
	const [activeFilter, setActiveFilter] = useState(isCatelogOpen || 'Recent')
	useEffect(() => {
		if(isCatelogOpen) {
			setTimeout(() => {
				let highlightCount = Array.from(document.querySelectorAll('.highlighted-text'))
				setHighlightCount(highlightCount.length)
			}, 2000)
		} 
	}, [searchResult, graphData.hash, isCatelogOpen])
	const FilterSection = ({ searchResult, highlightCount, filterLists, activeFilter }) => {
		const [elementCounts, setElementCounts] = useState(0)
		useEffect(() => {
			if (isCatelogOpen) {
				setActiveFilter(isCatelogOpen)
			}
		}, [isCatelogOpen])

		useEffect(() => {
			const output = {}

			output.Drivers =
				Object.values(graphData?.nodes).filter(node =>
					['Character', 'Arc', 'Event', 'Theme', 'Setting'].includes(node.__typename),
				).length || 0
			output.Relationships =
				relationships?.relations?.filter(rel => rel.connectionType === 'Static')?.length || 0
			output.Beats =
				Object.values(graphData?.nodes).filter(node => ['Beat'].includes(node.__typename)).length ||
				0
			output.Notes =
				Object.values(graphData?.nodes).filter(node => ['Note'].includes(node.__typename)).length ||
				0
			output.Recent = output.Drivers + output.Relationships + output.Beats + output.Notes

			setElementCounts(output)
		}, [Object.values(graphData?.nodes).length, relationships?.relations?.length])

		return (
			<FilterContainer>
				<SearchResultLabel hide={!isSmallDevice}>
				{(<ExitSearchIconSmall
					onClick={e => {
						e.stopPropagation()
						dispatchAction(setIsCatelogOpen(false))
					}}
				>
					{isSmallDevice ? 'X' : 'Close'}
				</ExitSearchIconSmall>)}
					{searchResult?.length > 0 &&
						`${highlightCount || 0} search results`}
				</SearchResultLabel>
				
				
				<Filters>
					{filterLists.map(label => (
						<FilterLabel
							key={label}
							isActive={label === activeFilter}
							onClick={() => dispatchAction(setIsCatelogOpen(label))}
						>
							{label}
						</FilterLabel>
					))}
				</Filters>
				<SearchResultLabel hide={!isSmallDevice}>
					{`${elementCounts[activeFilter] || 0} ${
						activeFilter === 'Recent' ? 'Elements' : activeFilter
					} in Catalog`}
				</SearchResultLabel>
				<MobileExtraFilter>
					<SearchResultLabel hide={isSmallDevice}>
						{searchResult?.length > 0 &&
							`${highlightCount || 0} search results`}
					</SearchResultLabel>
					<SearchResultLabel hide={isSmallDevice}>
						{`${elementCounts[activeFilter] || 0} ${
							activeFilter === 'Recent' ? 'Elements' : activeFilter
						} in Catalog`}
					</SearchResultLabel>
				</MobileExtraFilter>
			</FilterContainer>
		)
	}

	const memoizedSearchArea = useMemo(() => {
		return (
			<Suspense fallback={<div style={{ minHeight: '85vh', width: '100%' }}>Loading.......</div>}>
				<SearchArea
					key={new Date().toString()}
					activeFilter={activeFilter}
					setActiveFilter={setActiveFilter}
				/>
			</Suspense>
		)
	}, [activeFilter, setActiveFilter])

	return (
		<SearchCatelogStyled>
			<FilterSection
				searchResult={searchResult}
				highlightCount={highlightCount}
				filterLists={filterLists}
				activeFilter={activeFilter}
			/>
			{memoizedSearchArea}
		</SearchCatelogStyled>
	)
}

export default SearchCatelog
