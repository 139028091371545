import { useMutation } from '@apollo/client'
import { UPDATE_RELATIONSHIP } from '../data'
import { updateCacheField } from '../utils/apollo'
import { setFieldError, setServerError } from '../state/actions'
import { toastHandler } from '../utils/backendHandler'
import { useContext } from 'react'
import { beatsDataDispatchContext, beatsDataStateContext } from '../state/beatsProvider'
import { systemStateContext } from '../state/systemProvider'

export const useUpdateRelationshipMutation = (options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const [updateRelation, { data, loading, error }] = useMutation(UPDATE_RELATIONSHIP, options);
	const beatsRelatedData = useContext(beatsDataStateContext);
	const systemRelatedData = useContext(systemStateContext);
	const beatsDataDispatch = useContext(beatsDataDispatchContext);

	const performUpdateRelationshipMutation = async (
		{
			id,
			input,
			client,
			__typename,
			newMapValue,
			broadcast = true,
			serverErrorInterval
		}
	) => {

		if (!client) {
			throw new Error('Missing client for Update Relationship')
		}

		if (!id) {
			throw new Error('Missing Id for Update Relationship')
		}

		if (newMapValue) {
			await updateCacheField(client, { id, __typename }, newMapValue, broadcast)
		}
		try {
			await updateRelation({
				variables: {
					id,
					input
				},
			})

			if (beatsRelatedData?.serverError && serverErrorInterval) {
				beatsDataDispatch(setServerError(false))
				clearInterval(serverErrorInterval.current)
			}

			if (serverErrorInterval) {
				beatsDataDispatch(setFieldError(false))
			}
			
		} catch (err) {
			if (serverErrorInterval) {
				if (
					err.message.includes('Failed to fetch') ||
					err.message.includes('NetworkError')
				) {
					beatsDataDispatch(setServerError(true))
				} else if (err.message.includes('Response not successful:')) {
					beatsDataDispatch(setFieldError(true))
				} else {
					toastHandler('error', 'There was an error updating a relationship, try again.')
					systemRelatedData?.createLog(
						`ToastHandler Error Message`,
						`{"errorMessage":"${err.message}."}`,
						'DescriptionField',
						'Relationship Updation',
					)
				}
			}

			throw err
		}
	}
	return [performUpdateRelationshipMutation, { data, loading, error }]
}
