import styled from 'styled-components'

const CollapsibleContainer = styled.div`
	position: relative;
	width: 286px;
	height: 40px;
	z-index: 2;

	@media (max-width: 600px) {
		width: 184px;
		height: 30px;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
		width: 200px;
	}
`

const Container = styled.div`
	box-sizing: border-box;
	padding: 4px 8px;
	width: 286px;
	background-color: #ffffff;
	border-radius: 4px;
	outline: none;
	transition: all 0.3s;
	height: 40px;
	border: 1px solid #ffffff;
	overflow: hidden;

	.title-bar {
		cursor: pointer;
	}

	.expand-icon {
		transform: rotate(90deg);
		transition: transform 0.3s;
		opacity: 0.6;
	}

	@media (max-width: 600px) {
		width: 184px;
		height: 30px;
		padding-top: 0;
	}

	@media (min-width: 601px) and (max-width: 1024px) {
		width: 200px;
	}

	${({ isExpanded }) =>
		isExpanded
			? `
        height: fit-content !important;
        max-height: 290px;
        border: 1px solid #E1E1E1;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        .expand-icon {
            transform: rotate(-90deg);
        }
    `
			: ''}
`

const Title = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 9px;
	margin-bottom: 10px;
	.title {
		font-family: 'Source Sans 3';
		font-weight: 700;
		font-size: 12px;
		line-height: 17px;
		letter-spacing: 0.015em;
		color: #181818;
	}

	.status {
		font-family: 'Source Sans 3';
		font-size: 11px;
		line-height: 14px;
		letter-spacing: 0.015em;
		color: #535353;
	}

	.left {
		display: flex;
		align-items: center;
		gap: 7px;
	}

	@media (max-width: 600px) {
		.title {
			font-size: 9px;
		}
		.status {
			font-size: 7px;
		}
		margin-bottom: 0;
	}
`

const HorizontalLine = styled.div`
	width: 100%;
	height: 0;
	border-bottom: 1px solid #dfdfdf;
	margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '10px')};
`

const RecommendationsContainer = styled.div`
	max-height: 200px;
	overflow: auto;
	margin-top: 5px;

	::-webkit-scrollbar-thumb {
		background: #b8b8b8;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar {
		width: 0.3rem;
	}
`

const MobileLowerContainer = styled.div`
	display: none;
	width: auto;
	background-color: #ffffff;
	margin-bottom: 1px;
	@media (max-width: 600px) {
		width: 100vw;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		border-bottom: 0.5px solid #c4c4c4;
		height: 30px;
		margin-bottom: 0.5rem;
	}
`

const NewTaskBtn = styled.button`
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 5px 10px;
	margin: 5px auto;
	background-color: #ffd67a;
	font-size: 11px;
	border-radius: 4px;
`

const EmptyState = styled.div`
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	p {
		font-size: 11px;
	}
`

export {
	CollapsibleContainer,
	Container,
	HorizontalLine,
	MobileLowerContainer,
	NewTaskBtn,
	RecommendationsContainer,
	Title,
	EmptyState,
}
