import { withStyles } from '@mui/styles'
import Tooltip from '@mui/material/Tooltip'

const ToolTip = withStyles({
	tooltip: {
		fontSize: '1em',
		padding: '0.5rem',
	},
})(Tooltip)

export default ToolTip
