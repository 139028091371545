import { colorStyles, nodeColors } from '@lynit/shared/src/utils/commonStyles'
import styled from 'styled-components'

const getColor = type => {
	switch (type) {
		case 'Chapter':
			return nodeColors.Chapter
		case 'Character':
			return nodeColors.Character
		case 'Arc':
			return nodeColors.Arc
		case 'Event':
			return nodeColors.Event
		case 'Theme':
			return nodeColors.Theme
		case 'Setting':
			return nodeColors.Setting
		case 'Note':
			return nodeColors.Note
		default:
			return 'white'
	}
}

const Card = styled.div`
	width: auto;
	height: auto;
	border-radius: 5px;
	display: 'flex';
	flex-direction: column;
	margin-bottom: 10px;
	background: #ffffff;
	border: 0.5px solid #97979773;
	padding: 9px 7px;
`

const CardContent = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
`
const IconContainer = styled.div`
	display: flex;
	width: 18px;
	height: 18px;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	border: 1px solid ${({ iselement, type }) => (iselement ? 'transparent' : nodeColors[type])};
	border-radius: 50%;
	object-fit: contain;
	margin-right: 0.4rem;
	background: ${({ isTitle, iselement, type }) =>
		isTitle ? '#FFF' : iselement ? `${nodeColors[type]}80` : nodeColors[type]};
`

const TopContainer = styled.div`
	gap: 10px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	@media (max-width: 500px) {
		width: 90%;
	}
`

const TagView = styled.div`
	max-width: 100%;
	justify-content: end;
	white-space: nowrap;
	scrollbar-width: none;
	overflow: auto;
	display: flex;
	gap: 2px;
	align-items: center;
	::-webkit-scrollbar-thumb {
		background: #9d9d9d;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		border-radius: 100px;
		background: #fbfbfb;
	}

	::-webkit-scrollbar {
		width: 0.5rem;
		height: 0.2rem;
	}
`

const ButtonContainer = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	user-select: none;

	p {
		color: #7c7c7c;
		margin: 0;
		padding: 0;
		cursor: pointer;
		font-family: Source Sans Pro;
		font-size: 10px;
		font-weight: 400;
		line-height: 10.06px;
		letter-spacing: 0.1em;
		text-align: left;
	}
`

const ConnectionButton = styled.button`
	font-family: Source Sans Pro;
	font-size: 10px;
	font-weight: 400;
	line-height: 10.06px;
	letter-spacing: 0.1em;
	text-align: left;

	background: transparent;
	color: #666666;
	border: 1px solid #666666;
	border-radius: 100px;
	padding: 3px 20px;
	cursor: pointer;

	:disabled {
		background-color: #cacaca;
		color: #ffffff;
		border: none;
		cursor: not-allowed;
	}
`

const DateContainer = styled.span`
	white-space: pre;
	text-align: right;
	/* padding-bottom: 9px; */
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 10px;
	color: #555555;
`
const InputContainer = styled.div`
	background: #f1f1f1;
	border-radius: 2px;
	margin-bottom: 5px;
	width: 100%;
	position: sticky;
	display: flex;
	flex-direction: row;
	background: ${colorStyles.lightGrey};
	border: none;
	border-radius: 3px;
	input {
		width: 100%;
		border: none;
		background: transparent;
	}
	img {
		width: 16px;
		opacity: 0.6;
		padding: 0.3rem;
	}
`

export {
	Card,
	CardContent,
	TagView,
	ButtonContainer,
	DateContainer,
	ConnectionButton,
	TopContainer,
	InputContainer,
	IconContainer,
}
