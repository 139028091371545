import styled from 'styled-components'

const Overlay = styled.div`
	display: ${props => (props.isShow ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.35) !important;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
`

const NoNetworkImg = styled.img`
	margin-right: ${props => (props.isTop ? '0.5rem' : '2rem')};
`

const TopContainer = styled.div`
	background-color: #313131;
	width: 100vw;
	height: 1.75rem;
	position: absolute;
	top: 0;
	z-index: 1000;
`

const TopInnerContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

const TopCenterContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	p {
		font-size: 0.688rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}
`

const TopRightContainer = styled.div`
	position: absolute;
	right: 0.5rem;

	p {
		font-size: 0.688rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}
`

const ModalContainer = styled.div`
	background: #fcfcfc;
	border: 1px solid #e3e3e3;
	box-sizing: border-box;
	border-radius: 5px;
	width: 31.375rem;
	height: 13.188rem;
	position: relative;
	padding: 20px 40px 20px 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	& .title {
		font-size: 1.313rem;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000;
		width: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	& .content {
		width: auto;
		height: auto;
		margin: 0.938rem 1rem 0 0;
		font-size: 1.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #4a4a4a;
	}
	@media (max-width: 500px) {
		width: 90%;
	}
`

const ButtonContainer = styled.div`
	display: flex;
	width: 105%;
	height: auto;
	justify-content: space-evenly;
	flex-direction: row;
	align-items: center;
	@media (max-width: 500px) {
		width: 110%;
		flex-direction: column;
	}
`

const StatusNotification = styled.div`
	width: auto;
	height: 3rem;
	color: white;
	background: #3c3c3c;
	align-self: flex-end;
	border-radius: 5px;
	margin-bottom: 1rem;
	display: flex;
	padding: 0 1rem;
	align-items: center;
	font-size: 0.9rem;
`

export {
	Overlay,
	ModalContainer,
	ButtonContainer,
	NoNetworkImg,
	TopContainer,
	TopInnerContainer,
	TopCenterContainer,
	TopRightContainer,
	StatusNotification,
}
