import { useContext, useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'

import { sharedContext } from '../state/sharedProvider'
import { toastHandler } from '../utils/backendHandler'
import { setUnseenRecommendationCount } from '../state/actions'
import { useGetRecommendationsQuery } from './getRecommendationData'

const operations = {
	'===': (a, b) => a === b,
	'!==': (a, b) => a !== b,
}

const getProgress = recommendation => {
	const sumOfProgresses = recommendation.completeConditions?.reduce((sum, ruleCondition) => {
		if (Number.isNaN(Number(ruleCondition.value))) {
			return (
				sum +
				Number(
					operations[ruleCondition.operation](
						ruleCondition.value,
						ruleCondition.metricCurrentValue,
					),
				)
			)
		}
		const metricCurrentValue = Number(ruleCondition.metricCurrentValue)
		const value = Number(ruleCondition.value)
		if (metricCurrentValue > value) return sum + 1
		return sum + metricCurrentValue / value
	}, 0)

	return Math.round((sumOfProgresses / recommendation.completeConditions?.length) * 100)
}

export const useRecommendations = () => {
	const {
		state: { story },
		dispatch,
	} = useContext(sharedContext)

	const {
		data: recommendationData,
		refetch: refetchRecommendations,
		loading: fetchingRecommendations,
	} = useGetRecommendationsQuery({ fetchPolicy: 'network-only' })

	const [previousCompletedRecommendations, setPreviousCompletedRecommendations] = useState(null)
	const [previousStory, setPreviousStory] = useState('')
	const [activeSeenOpenedRecommendations, setActiveSeenOpenedRecommendations] = useState([])
	const [completedRecommendations, setCompletedRecommendations] = useState([])
	const [dismissedRecommendations, setDismissedRecommendations] = useState([])
	const [craftRecommendations, setCraftRecommendations] = useState([])
	const [speedRecommendations, setSpeedRecommendations] = useState([])
	const [habitRecommendations, setHabitRecommendations] = useState([])

	useEffect(() => {
		if (recommendationData?.recommendations) {
			const activeRecommendationsWithProgress =
				recommendationData?.recommendations?.active_seen_opened?.map(recommendation => {
					const progress = getProgress(recommendation)
					return { ...recommendation, progress }
				})
			setActiveSeenOpenedRecommendations(activeRecommendationsWithProgress)
			setCompletedRecommendations(
				recommendationData?.recommendations?.completed_rec?.map(rec => ({ ...rec, progress: 100 })),
			)
			setDismissedRecommendations(recommendationData?.recommendations?.dismissed)
			const craftRec = []
			const speedRec = []
			const habitRec = []
			recommendationData?.recommendations?.active_seen_opened?.forEach(recommendation => {
				const progress = getProgress(recommendation)

				if (recommendation.valueProps?.includes('craft')) {
					craftRec.push({ ...recommendation, progress })
				}
				if (recommendation.valueProps?.includes('speed')) {
					speedRec.push({ ...recommendation, progress })
				}
				if (recommendation.valueProps?.includes('habit')) {
					habitRec.push({ ...recommendation, progress })
				}
			})
			setCraftRecommendations(craftRec)
			setSpeedRecommendations(speedRec)
			setHabitRecommendations(habitRec)
		}

		if (
			previousCompletedRecommendations &&
			previousStory === story &&
			completedRecommendations?.length - previousCompletedRecommendations?.length === 1
		) {
			toastHandler('success', 'Good job. You completed a recommendation.')
		}
		if (
			recommendationData?.recommendations?.completed_rec &&
			!isEqual(previousCompletedRecommendations, recommendationData?.recommendations?.completed_rec)
		) {
			setPreviousCompletedRecommendations(recommendationData?.recommendations?.completed_rec)
		}
	}, [recommendationData, story])

	useEffect(() => {
		const counts = {
			total: 0,
			craft: 0,
			speed: 0,
			habit: 0,
		}
		activeSeenOpenedRecommendations?.forEach(recommendation => {
			if (recommendation.visibility === 'unseen') {
				counts.total = ++counts.total
				recommendation.valueProps?.forEach(valueProp => {
					counts[valueProp] = ++counts[valueProp]
				})
			}
		})
		dispatch(setUnseenRecommendationCount(counts))
	}, [activeSeenOpenedRecommendations])

	useEffect(() => {
		if (!isEqual(previousStory, story)) {
			setPreviousStory(story)
		}
	}, [story])

	return {
		activeSeenOpenedRecommendations,
		completedRecommendations,
		dismissedRecommendations,
		craftRecommendations,
		speedRecommendations,
		habitRecommendations,
		refetchRecommendations,
		fetchingRecommendations,
	}
}

export default useRecommendations
