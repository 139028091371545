import React from 'react'
import lensIcon from '@lynit/shared/src/images/emptyLens.svg'
import arcVizIcon from '../../images/VizEmpty.svg'
import { BottomBar, Container, EmptyContent, TopBar } from './styles'

const EmptyState = ({ name, isArcViz }) => {
	return (
		<Container>
			<TopBar>{name}</TopBar>
			<EmptyContent>
				<img src={isArcViz ? arcVizIcon : lensIcon} alt="Empty State Icon" />
				{isArcViz ? <p>There are no Drivers to visualize.</p> : <p>There are no Drivers to connect.</p>}
			</EmptyContent>
			<BottomBar />
		</Container>
	)
}

export default EmptyState
