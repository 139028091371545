import styled from 'styled-components'
import { Link } from 'react-router-dom'

const FallbackUIContainer = styled.div`
	height: 100vh;
	width: 94vw;
	margin: 0 auto;
	display: flex;
	overflow-x: hidden;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding-left: 1rem;
	padding-right: 1rem;
	@media (max-width: 768px) {
		width: 90vw;
	}
`
const TopContainer = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	padding-top: 1rem;
`

const LynitLogo = styled.img`
	width: 5rem;
	cursor: pointer;
	@media (max-width: 768px) {
		width: 3rem;
	}
`
const Icon = styled.img`
	@media (max-width: 768px) {
		display: none;
	}
`
const AlertIcon = styled.img`
	height: 44px;
`

const BackButton = styled(Link)`
	width: auto;
	height: auto;
	border: none;
	padding-top: 0.35rem;
	font-size: 16px;
	background-color: transparent;
	cursor: pointer;
	text-decoration: none;
	color: #666666;
	@media (max-width: 768px) {
		font-size: 12px;
	}
`
const TopInnerContainer = styled.div``

const MiddleContainer = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
`
const ErrorModal = styled.div`
	height: 20rem;
	width: 40rem;
`
const ErrorModalTopContainer = styled.div`
	height: 23%;
	width: 100%;
	background-color: #ffc300;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
`
const ErrorModalTopTitle = styled.h3`
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	line-height: 26px;
	color: #000000;
	margin-left: -40px;
	@media (max-width: 768px) {
		font-size: 14px;
		margin-left: -30px;
	}
`
const ErrorModalTopText = styled.p`
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: normal;
	font-size: 21px;
	line-height: 26px;
	text-align: center;
	color: #000000;
	@media (max-width: 768px) {
		font-size: 14px;
	}
`

const ErrorModalBottomContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	height: 77%;
	width: 100%;
	background: #f9f9f9;
`
const ErrorModalBottomText = styled.p`
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 23px;
	color: #000000;
	padding-left: 2rem;
	padding-right: 2rem;
	@media (max-width: 768px) {
		font-size: 14px;
		padding-left: 1.1rem;
		padding-right: 1rem;
	}
`

const ReloadButton = styled.button`
	width: 100px;
	height: 50px;
	background-color: #5c5c5c;
	color: #fff;
	border-radius: 5px;
	border-style: none;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.0777287);
	cursor: pointer;
`

const BottomContainer = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 3rem;
`
const BottomText = styled.p`
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
	color: #555555;
	@media (max-width: 768px) {
		font-size: 14px;
	}
`
const BottomLink = styled.a`
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
	color: #ffc300;
	text-decoration: none;
	@media (max-width: 768px) {
		font-size: 14px;
	}
`

export {
	FallbackUIContainer,
	TopContainer,
	TopInnerContainer,
	MiddleContainer,
	BottomContainer,
	LynitLogo,
	Icon,
	AlertIcon,
	BackButton,
	BottomText,
	BottomLink,
	ErrorModal,
	ErrorModalTopContainer,
	ErrorModalBottomContainer,
	ErrorModalTopTitle,
	ErrorModalTopText,
	ErrorModalBottomText,
	ReloadButton,
}
