import styled from 'styled-components'

export const Button = styled.button`
	border: none;
	background-color: transparent;
	padding: 0;
	cursor: pointer;
	font-family: 'Source Sans Pro', sans-serif;
	color: #3185fc;
	font-weight: bold;
	font-size: inherit;
`

export const StyledAnchorTag = styled.a`
	color: #3185fc;
	font-weight: bold;
	text-decoration: none;
	font-size: inherit;
`
