/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { v4 as uuid } from 'uuid'
import React, { createContext, useState, useEffect } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'



import {
	useCreateEventlogMutation,
	useGetUserQuery,
} from '@lynit/shared/src/hooks'



const defaultValues = {
	isTourOpen: false,
	setIsTourOpen: () => {},
	step: 0,
	setStep: () => {},
	user: '',
	setUser: () => {},
	contextdevice: '',
	setContextDevice: () => {},
	story: '',
	setStory: () => {},
	storiesId: [],
	setStoriesId: () => {},
	browser: '',
	setBrowser: () => {},
	device: '',
	setDevice: () => {},
	deepModeElement: '',
	isNoteDrawerMutation: false,
	setNoteDrawerMutation: () => {},
	setDeepModeElement: () => {},
	sessionUser: '',
	setSessionUser: () => {},
	lastEventSentTime: 0,
	setLastEventSentTime: () => {},
	isSaving: false,
	setSaving: () => {},
	isOnline: false,
	setIsOnline: () => {},
	wasOffline: false,
	setWasOffline: () => {},
	serverError: false,
	setServerError: () => {},
	noElementError: false,
	setNoElementError: () => {},
	contextDeepModeConns: [],
	setContextDeepModeConns: () => {},
	eBoundariesError: null,
	setEBoundariesError: () => {},
	isStaticNoteMutation: false,
	setStaticNoteMutation: () => {},
	createElementRecomm: {},
	setcreateElementRecomm: () => {},
	isRecommendationShown: false,
	setRecommendationShown: () => {},
	currentRecommendation: {},
	setCurrentRecommendation: () => {},
	isNewRecommendation: false,
	setNewRecommendation: () => {},
	connectionRecommendation: null,
	setConnectionRecommendation: () => {},
	WTDNRecommendation: null,
	setWTDNRecommendation: () => {},
	openToolTip: null,
	setOpenToolTip: () => {},
	refetchUser: () => {},
	currentStory: {},
	setCurrentStory: () => {},
	isNewStory: {},
	setNewStory: () => {},
}

export const AppContext = createContext(defaultValues)
export const ParentContext = createContext()

const ContextProvider = ({ children }) => {
	const client = useApolloClient()
	const startStep = 0

	const LSStep = localStorage.getItem('tour')
		? JSON.parse(localStorage.getItem('tour')).LSStep
		: startStep

	const LSIsTourOpen = localStorage.getItem('tour')
		? JSON.parse(localStorage.getItem('tour')).LSIsTourOpen
		: false

	const { loading: loadingUser, data: userData, refetch: refetchUser } = useGetUserQuery()
	const [createEventLog] = useCreateEventlogMutation({ignoreResults:true})

	const [currentRecommendation, setCurrentRecommendation] = useState(null)
	const [isRecommendationShown, setRecommendationShown] = useState(false)
	const [isNewRecommendation, setNewRecommendation] = useState(false)
	const [createElementRecomm, setcreateElementRecomm] = useState(null)
	const [isOnline, setIsOnline] = useState(window.navigator.onLine)
	const [wasOffline, setWasOffline] = useState(false)
	const [serverError, setServerError] = useState(false)
	const [noElementError, setNoElementError] = useState(false)
	const [isDifferentVersion, setIsDifferentVersion] = useState(false)
	const [contextDeepModeConns, setContextDeepModeConns] = useState([])
	const [eBoundariesError, setEBoundariesError] = useState(null)
	const [isNoteDrawerMutation, setNoteDrawerMutation] = useState(false)
	const [isStaticNoteMutation, setStaticNoteMutation] = useState(false)
	const [connectionRecommendation, setConnectionRecommendation] = useState(null)
	const [WTDNRecommendation, setWTDNRecommendation] = useState(null)
	const [openToolTip, setOpenToolTip] = useState(null)

	const [user, setUser] = useState('')
	const [device, setDevice] = useState('')
	const [browser, setBrowser] = useState('')
	const [storiesId, setStoriesId] = useState([])
	const [step, setStep] = useState(LSStep || startStep)
	const [contextDevice, setContextDevice] = useState('')
	const [deepModeElement, setDeepModeElement] = useState('')
	const [isSaving, setSaving] = useState(false)
	const [story, setStory] = useState(sessionStorage?.getItem('story'))
	const [isTourOpen, setIsTourOpen] = useState(LSIsTourOpen || false)
	const [sessionUser, setSessionUser] = useState(localStorage.getItem('su') || `${uuid()}`)
	const [currentStory, setCurrentStory] = useState({})
	const [isNewStory, setNewStory] = useState(null)

	useEffect(() => {
		if (eBoundariesError) {
			createEventLog({
				variables: {
					input: {
						name: `Error Boundaries`,
						details: `{"errorMesage":'${eBoundariesError.errorMessage}',"errorStackTrace": '${eBoundariesError.errorStack}', "componentStack": '${eBoundariesError.componentStack}'}`,
						page: '/',
						component: 'ParentContainer',
						workflow: `Error Boundaries`,
						browser,
						geo: '',
						device,
						createdAt: Date.now(),
					},
				},
			}).then(() => setEBoundariesError(null))
		}
	}, [eBoundariesError])
	

	const [isActive, setActive] = useState(null)

	useEffect(() => {
		if (
			(isActive && user?.subscriptionStatus?.status === 'active') ||
			user?.subscriptionStatus?.status === 'trialing'
		) {
			setActive(false)
		}
		if (
			(!isActive && user?.subscriptionStatus?.status === 'past_due') ||
			user?.subscriptionStatus?.status === 'canceled'
		) {
			setActive(true)
		}
	}, [user?.subscriptionStatus?.status])

	return (
		<AppContext.Provider
			value={{
				user,
				step,
				story,
				device,
				browser,
				setUser,
				setStep,
				storiesId,
				setStory,
				isTourOpen,
				setIsTourOpen,
				contextDevice,
				setContextDevice,
				setStoriesId,
				deepModeElement,
				setDeepModeElement,
				sessionUser,
				setSessionUser,
				isSaving,
				setSaving,
				isOnline,
				wasOffline,
				serverError,
				setServerError,
				noElementError,
				setNoElementError,
				eBoundariesError,
				setEBoundariesError,
				isDifferentVersion,
				setIsDifferentVersion,
				contextDeepModeConns,
				setContextDeepModeConns,
				isNoteDrawerMutation,
				setNoteDrawerMutation,
				isStaticNoteMutation,
				setStaticNoteMutation,
				createElementRecomm,
				setcreateElementRecomm,
				isRecommendationShown,
				setRecommendationShown,
				currentRecommendation,
				setCurrentRecommendation,
				isNewRecommendation,
				setNewRecommendation,
				connectionRecommendation,
				setConnectionRecommendation,
				WTDNRecommendation,
				setWTDNRecommendation,
				openToolTip,
				setOpenToolTip,
				isActive,
				refetchUser,
				currentStory,
				setCurrentStory,
				isNewStory,
				setNewStory,
			}}
		>
			{children}
		</AppContext.Provider>
	)
}

export default ContextProvider
