import styled from 'styled-components'
import { colorStyles } from '@lynit/shared/src/utils/commonStyles'

export const Container = styled.div`
	width: 100%;
	height: 99%;
	display: flex;
	flex-direction: column;
`

export const TopBar = styled.div`
	width: 98%;
	height: 3rem;
	font-size: 14px;
	color: black;
	font-weight: bold;
	font-style: normal;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	border-radius: 3px 3px 0 0;
	background: #eeeeee;
	padding-left: 1rem;
	box-shadow: 0 4px 4px -2px #c4c4c4;
	@media (max-width: 500px) {
		height: 2rem;
		width: 95.1%;
	}
`

export const BottomBar = styled.div`
	width: 98%;
	height: 3rem;
	font-size: 14px;
	color: black;
	font-weight: bold;
	font-style: normal;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	border-radius: 0 0 3px 3px;
	background: #eeeeee;
	padding-left: 1rem;
	box-shadow: 0 -4px 2px 0 #c4c4c4;
	@media (max-width: 500px) {
		height: 2rem;
		width: 95.1%;
	}
	button {
		border: 1px solid ${colorStyles.lynitYellow};
		background: ${colorStyles.lynitYellow};
		height: 1.8rem;
		border-radius: 3px;
		font-size: 1rem;
		cursor: pointer;
		box-shadow: 0 4px 4px -2px #c4c4c4;
	}
	div {
		display: flex;
		flex-direction: row;
		align-items: center;
		img {
			margin-right: 1rem;
		}
	}
`

export const EmptyContent = styled.div`
	width: 99.6%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 94%;
	border-radius: 0 0 3px 3px;
	background: ${colorStyles.lightGrey};
	text-align: center;
	p {
		font-size: 1.3rem;
		opacity: 0.6;
		span {
			font-weight: bold;
		}
	}
	img {
		opacity: 0.6;
	}
`
