import { useMutation } from '@apollo/client'
import { DELETE_NODE } from '../data'

export const useDeleteNodeMutation = (options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const result = useMutation(DELETE_NODE, options)
	return result
}
