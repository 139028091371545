import styled from 'styled-components'
import { nodeColors } from '../../utils/commonStyles'

const AiRecommendationsCount = styled.div`
	position: absolute;
	
	border: 2px solid #ffc710;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	text-align: center;
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 400;
	line-height: 15.6px;
	text-align: center;
	top: -25px;
	right: 0;
	cursor: pointer;
	${(props) => props.recLoading ? 
	`
	animation: pulsate 1s ease-out infinite;
	@keyframes pulsate {
		0%   { box-shadow: 0 0 1px 1px #ffc300; }
		100%  { box-shadow: 0 0 20px 1px #ffc300; }
		
	}
	
	` : `box-shadow: 0px 0px 10px 1px #ffc300;`}
`
const AiRecommendationsWrapper = styled.div``
const Header = styled.div`
	display: flex;
	justify-content: space-between;
`
const DescriptionWrapper = styled.div`
	.highlighted-word {
		background-color: yellow;
		transition: background-color 0.2s ease;
	}

	.highlighted-word.hover-active {
		background-color: orange; /* Color on hover */
	}
`
const Title = styled.div`
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.6px;
	text-align: right;
	color: #4c4c4c;
	:hover {
		text-decoration: underline;
	}
	cursor: pointer;
`
const ListArea = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 6px;
`
const DriverName = styled.div`
	color: #4c4c4c;
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 400;
	line-height: 15.6px;
	text-align: left;
`
const MenuItemContainer = styled.div`
	display: flex;
	gap: 6px;
	cursor: pointer;
	padding: 3px 10px;
	:hover {
		background: #ffc300;
	}
`
const DriverAction = styled.div`
	display: flex;
	gap: 5px;
`
const MenuListWrapper = styled.div`
	width: 200px;
	padding: 0px 0;
	display: flex;
	flex-direction: column;
`
const CrossIcon = styled.div`
	margin-bottom: 3px;
`

const SignIcon = styled.div`
	height: 15px;
	width: 15px;
	color: white;
	cursor: pointer;
	border-radius: 50%;
	background-color: ${props => (props.cross ? '#EFB2B2' : '#ffc300')};
	display: flex;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: ${props => (props.cross ? '17px' : '22px')};
	padding: 0;
`
const DriverField = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	flex: 1;
	justify-content: space-between;
`

const Dropdown = styled.div`
	border:${props=>`solid ${nodeColors[props.color]}`};
	border-width: 0 2px 2px 0;
	padding: 2px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	position: absolute;
	top: 9%;
	left: 31%;
	box-sizing: border-box;
`
const DropdownContainer = styled.div`
	height: 0;
	width: 0;
	background: #F5F5F8;
	display: flex;
	align-items: flex-start;
	border-radius: 50%;
	padding: 5px;
	position: relative;
	box-sizing: border-box;
`
const DriverList = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`
export {
	ListArea,
	AiRecommendationsCount,
	Title,
	AiRecommendationsWrapper,
	Header,
	DriverField,
	DriverName,
	DriverAction,
	DriverList,
	SignIcon,
	DescriptionWrapper,
	MenuItemContainer,
	MenuListWrapper,
	CrossIcon,
	Dropdown,
	DropdownContainer,
}
