import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_VERSION } from '../data'

export const useGetVersionQuery = (options = {}) => {
	const result = useQuery(GET_VERSION, options)
	return result
}

export const useLazyGetVersionQuery = (options = {}) => {
	const result = useLazyQuery(GET_VERSION, options)
	return result
}
