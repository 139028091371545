import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Grid from '@mui/material/Grid'
import { Provider as ReduxProvider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { SharedContextProvider } from '@lynit/shared/src/state'
import { ApolloClientProvider } from '@lynit/shared/src/apollo-client'
import { StoryOutlineContextProvider } from '@lynit/story-outline/src/state/storyOutlineProvider'
import AutoSaveProvider from '@lynit/shared/src/state/autoSaveProvider'
import GraphDataProvider from '@lynit/shared/src/state/graphDataProvider'
import UserProvider from '@lynit/shared/src/state/userProvider'
import SystemProvider from '@lynit/shared/src/state/systemProvider'
import BeatsProvider from '@lynit/shared/src/state/beatsProvider'
import AnalyticsDataProvider from '@lynit/shared/src/state/analyticsDataProvider'

import LoadingSpinner from '@lynit/shared/src/shared-ui/LoadingSpinner'

import Layout from '@lynit/layout/src/ui//Layout/layout'

import ErrorBoundary from '../errorBoundaries/ErrorBoundary'
import ContextProvider from '../context/AppContext'
import globalStateStore from '../redux/store'
import styles from './App.module.css'

import TutorialContextProvider from '../context/TutorialContext'

// retry 5 times and if failed all the time then reject
function componentHandler(lazyComponent, count = 5) {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch(error => {
				if (count === 1) {
					reject(error)
					return
				}
				componentHandler(lazyComponent, --count).then(resolve, reject)
			})
	})
}

const ChangePassword = lazy(() =>
	componentHandler(() => import('@lynit/authentication/src/ui/ChangePassword')),
)

const ForgotPassword = lazy(() => componentHandler(() => import('@lynit/authentication/src/ui/ForgotPassword')))

const Verification = lazy(() =>
	componentHandler(() => import('@lynit/authentication/src/ui/Verification'))
)

const Signup = lazy(() => componentHandler(() => import('@lynit/authentication/src/ui/Signup')))

const Login = lazy(() => componentHandler(() => import('@lynit/authentication/src/ui/Login')))

const StoryOutline = lazy(() => componentHandler(() => import('@lynit/story-outline/src/ui/StoryOutline')))

const PlanPicker = lazy(() => componentHandler(() => import('@lynit/subscription/src/ui/PlanPicker')))

const MyStoriesDashboard = lazy(() => componentHandler(() => import('@lynit/dashboard/src/ui/MyStoriesDashboard')))

//const DeepModeElement = lazy(() => componentHandler(() => import('@lynit/deep-mode-element/src/ui/DeepModeElement')))

const App = () => (
	<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
		<ApolloClientProvider>
			<ContextProvider>
				<TutorialContextProvider>
					<AutoSaveProvider>
						<BeatsProvider>
							<UserProvider>
								<SharedContextProvider>
									<SystemProvider>
										<GraphDataProvider>
											<AnalyticsDataProvider>
												<StoryOutlineContextProvider>
													<BrowserRouter basename="/">
														<ReduxProvider store={globalStateStore}>
															<AppCore />
														</ReduxProvider>
													</BrowserRouter>
												</StoryOutlineContextProvider>
											</AnalyticsDataProvider>
										</GraphDataProvider>
									</SystemProvider>
								</SharedContextProvider>
							</UserProvider>
						</BeatsProvider>
					</AutoSaveProvider>
				</TutorialContextProvider>
			</ContextProvider>
		</ApolloClientProvider>
	</GoogleOAuthProvider>
)

// TODO: Persist login state in local storage

const AppCore = () => {
	// disabled promotion alert for now.

	return (
		<ErrorBoundary>
			<div className={styles.container}>
				<ToastContainer data-public data-dd-privacy="allow" />
				<Grid container spacing={1} className={styles.customContainer}>
					<Suspense fallback={<LoadingSpinner />}>
						<Switch>
							<Route path="/changePassword/:token?" component={ChangePassword} />
							
							<Route path="/forgotPassword" component={ForgotPassword} />
							<Route path="/accountVerification/:token/:email" component={Verification} />
							<Route path="/signup" component={Signup} />
							<Route path="/login" component={Login} />
							<Route path="/plan-picker" component={PlanPicker} />
							<Route exact path="/dashboard" component={MyStoriesDashboard} />
							<Route path="/story/:story">
								<Layout>
									
								</Layout>
							</Route>
							<Route path="*" render={() => <Redirect to="/dashboard" />} />
							{/* <Route exact path="/story/:story">
								<Layout>
									<DeepModeElement />
								</Layout>
							</Route> */}
						</Switch>
					</Suspense>
				</Grid>
			</div>
		</ErrorBoundary>
	)
}

export default App
