import styled from 'styled-components'

const Main = styled.div`
	background-color: #ffffff;
	${({ isRightPanel, isTutorialOpened }) =>
		!isRightPanel
			? `
        flex: 1;
        min-height: ${isTutorialOpened ? '250px' : 'unset'};
    `
			: `
        height: 100%;
    `}
`

const Header = styled.div`
	display: flex;
	border-bottom: 1px solid #d1d1d1;
`

const Tab = styled.div`
	margin: 0;
	padding: 6px 10px;
	font-size: 10px;
	cursor: pointer;
	${({ isActive }) =>
		isActive &&
		`
        border-bottom: 3px solid #646464;
        font-weight: bold;
    `}
`

const RecommendationsContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
	overflow-y: auto;
	padding: 5px;
	min-height: calc(100% - 60px);

	${({ isRightPanel }) =>
		isRightPanel &&
		`
        height: calc(100% - 30px);
        overflow: auto;
    `}
`

const ValuePropContainer = styled.div`
	position: relative;
	box-sizing: border-box;
	width: 100%;
	padding: 0 7px 7px 7px;
	min-width: 160px;
	min-height: 290px;
	margin-top: 35px;
	background-color: ${({ backgroundColor }) => backgroundColor};
	display: flex;
	align-items: center;
	flex-direction: column;

	.value-prop-img {
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.value-prop-title {
		font-weight: bold;
		font-size: 12px;
		margin: 18px 0 0 0;
	}

	.definition {
		font-size: 11px;
		align-self: flex-start;
	}

	${({ isRightPanel, leftToggleTabs, rightToggleTabs }) =>
		!isRightPanel
			? `
        @media (min-width: 768px) {
            width: ${leftToggleTabs || rightToggleTabs ? '100%' : 'calc(50% - 3px)'};
        }

        @media (min-width: 950px) {
            width: ${
							leftToggleTabs && rightToggleTabs
								? '100%'
								: leftToggleTabs || rightToggleTabs
								? 'calc(50% - 3px)'
								: 'calc(33% - 2px)'
						};
        }

        @media (min-width: 1240px) {
            width: ${leftToggleTabs && rightToggleTabs ? 'calc(50% - 3px)' : 'calc(33% - 2px)'};
        }

        @media (min-width: 1440px) {
            width: calc(33% - 2px);
        }
    `
			: ''}
`

const Pill = styled.div`
	width: 24px;
	font-size: 10px;
	text-align: center;
	color: #ffffff;
	border-radius: 10px;
	background-color: ${({ backgroundColor }) => backgroundColor};
	margin: 10px 0;
`

const RecommendationCard = styled.div`
	cursor: pointer;
	box-sizing: border-box;
	width: 100%;
	height: calc(100% - 60px);
	background-color: #ffffff;
	border: 0.5px solid #ebebeb;
	box-shadow: 2.92655px 4.38983px 7.31638px rgba(0, 0, 0, 0.07);
	border-radius: 9px;
	padding: 9px;
	position: relative;

	.title {
		font-size: 12px;
		font-weight: bold;
		text-align: center;
		margin: 0;
		margin-top: 5px;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.message {
		font-size: 11px;
		margin: 0;
		margin-top: 10px;
	}
	.media-container {
		min-height: 90px;
		display: flex;
		justify-content: center;
		margin: 10px 0 5px 0;
		img,
		video {
			max-width: calc(100% - 44px);
			max-height: 100px;
			@media (min-width: 1200px) {
				max-height: 120px;
			}
			@media (min-width: 1440px) {
				max-height: 150px;
			}
		}
		img {
			background-color: #ebebeb;
			object-fit: contain;
		}
		video {
			display: block;
			margin: 0 auto;
		}
	}
	.prev-btn {
		position: absolute;
		left: 5px;
		top: 50%;
		transform: translate(0, -50%);
		img {
			margin-right: 1px;
		}
	}
	.next-btn {
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translate(0, -50%);
		img {
			margin-left: 1px;
		}
	}
	.progress-title {
		margin: 0;
		margin-bottom: 3px;
		font-size: 11px;
		font-weight: bold;
	}
`

const Button = styled.button`
	cursor: pointer;
	width: 22px;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	border: 1px solid #e6e6e6;
	border-radius: 50%;
	padding: 0;
	box-shadow: -2.92655px 1.46328px 4.38983px rgba(0, 0, 0, 0.05);
	transition: box-shadow 0.1s;
	:hover {
		box-shadow: -1.93px 3px 5px rgba(0, 0, 0, 0.17);
	}
`

const EmptyState = styled.div`
	background: rgba(255, 255, 255, 0.5);
	width: 100%;
	flex: 1;
	border-radius: 2px;
	border: ${({ color }) => `1px dashed ${color}`};
	p {
		color: ${({ color }) => color};
		text-align: center;
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const RecommendationDetailContainer = styled.div`
	box-sizing: border-box;
	padding: 12px 22px;
	min-height: calc(100% - 30px);
	display: flex;
	flex-direction: column;
	${({ isRightPanel }) =>
		isRightPanel
			? `
        height: calc(100% - 30px);
        overflow: auto;
        padding: 0 22px 12px 22px;
    `
			: `
        @media (min-width: 1200px) {
            flex-direction: row;
            gap: 20px;
            height: calc(100% - 30px);
        }
    `}

	${({ isRightPanel, isRecommendationOpen }) =>
		!isRightPanel && isRecommendationOpen
			? `
            height: auto;
            overflow: visible;
        @media (min-width: 1200px) {
            height: calc(100% - 30px);
            overflow: visible;
        }
    `
			: ''}
`

const RecommendationDescription = styled.div`
	.message-container {
		p {
			font-size: 12px;
		}

		video,
		img {
			max-width: min(100%, 422px);
			display: block;
			margin: 0 auto;
		}
	}

	video,
	.recommendation-img {
		max-width: min(100%, 422px);
		display: block;
		margin: 0 auto;
	}

	${({ isRightPanel }) =>
		isRightPanel
			? `
        .message {
            margin-top: 0;
        }
    `
			: `
        @media (min-width: 1200px) {
            flex: 0.6;
            height: 100%;
            overflow: auto;
        }
    `}

	::-webkit-scrollbar-thumb {
		background: #9d9d9d80;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar {
		width: 0.3rem;
	}
`

const RecommendationHeader = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	.title {
		margin: 0;
		font-weight: bold;
		font-size: 13px;
		flex: 1;
	}

	${({ isRightPanel }) =>
		isRightPanel &&
		`
        padding: 10px 0;
    `}
`

const ProgressContainer = styled.div`
	.progress-value {
		font-size: 11px;
	}

	${({ isRightPanel }) =>
		!isRightPanel &&
		`
        @media (min-width: 1200px) {
            flex: 0.4;
            height: 100%;
            overflow: auto;
        }
    `}

	::-webkit-scrollbar-thumb {
		background: #9d9d9d80;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar {
		width: 0.3rem;
	}
`

const ProgressTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0;
	.title {
		font-weight: bold;
		font-size: 11px;
	}
	gap: 10px;

	> div {
		flex: 1;
	}
`

const ProgressMetricsContainer = styled.div`
	display: flex;
	gap: 25px;
	flex-wrap: wrap;
	max-width: 200px;
	margin-top: 10px;
	p {
		margin: 0;
	}
	.metric-name {
		font-size: 9px;
		font-weight: bold;
	}

	.current-value {
		font-size: 20px;
		font-weight: bold;
	}

	.target-value {
		font-size: 9px;
	}
`

const VerticalLine = styled.div`
	border-left: 0.5px solid #1e1e1e;
`

const RecommendationIndicator = styled.span`
	width: 6px;
	height: 6px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 5px;
	background-color: #49c100;
`

export {
	Main,
	Header,
	Tab,
	RecommendationsContainer,
	ValuePropContainer,
	Pill,
	RecommendationCard,
	Button,
	EmptyState,
	RecommendationDetailContainer,
	RecommendationDescription,
	RecommendationHeader,
	ProgressContainer,
	ProgressTitle,
	ProgressMetricsContainer,
	VerticalLine,
	RecommendationIndicator,
}
