import { useLazyQuery, useQuery } from "@apollo/client"
import { GET_USER } from "../data"

export const useGetUserQuery = (options={}) => {
  const result = useQuery(GET_USER, options)
  return result
}

export const useLazyGetUserQuery = (options={}) => {
  const result = useLazyQuery(GET_USER, options)
  return result
}