import styled from 'styled-components'

export const PillWrapper = styled.div`
	border-radius: 4.90769px;
	display: flex;
	align-items: center;
	padding: 3px;
	gap: 3px;
	width: fit-content;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.015em;
	background: ${props => props.color.background};
	position: relative;
	font-weight: normal !important;
	span {
		border-radius: 40.8974px;
		font-weight: 700;
		font-size: 13.0872px;
		line-height: 16px;
		letter-spacing: 0.015em;
		color: #242424;
		padding: 1px 8px;
		background: ${props => props.color.badge};
	}
`

export const Menu = styled.div`
	position: absolute;
	top: ${props => (props.sm ? '7px' : '100%')};
	transform: ${props => !props.sm && 'translateY(-50%)'};
	z-index: 99999999999;
	padding: 12px;
	gap: 12px;
	box-shadow: -2px 4px 10px 4px #c4c4c470;
	background: #fefefe;
	border: 1px solid #c4c4c4;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	width: 100px;

	span {
		background: #8e8e8e;
		border-radius: 6.31579px;
		color: #fff;
		font-weight: 700;
		font-size: 10px;
		line-height: 12px;
		padding: 0 10px;
	}

	& .wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
		color: #000;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		z-index: 1;
		left: ${props => !props.sm && '-6.2px'};
		top: ${props => (props.sm ? '-6px' : '45%')};
		transform: ${props => (props.sm ? 'translateX(-50%)' : 'translateY(-50%)')};
		width: 10px;
		height: 10px;
		background: #ffffff;
		border-right: 1px solid #c4c4c4;
		border-bottom: 1px solid #c4c4c4;
		transform: ${props => (props.sm ? 'rotate(-135deg)' : 'rotate(135deg)')};
		z-index: 999;
	}
`

export const color = {
	beat: {
		background: '#D7EDDE',
		badge: '#78C091',
		name: 'Beats',
	},
	connection: {
		background: '#f6e3ff',
		badge: '#dd9dfb',
		name: 'Connections',
	},
	wordCount: {
		background: '#E3F2FF',
		badge: '#7190FD',
		name: 'Word Count',
	},
}
