import styled from 'styled-components'

export const StyledBreadcrumb = styled.div`
	font-size: 12px;
	max-width: 168px;
	width: 100%;
	color: #666666;
	font-weight: 600;
	${({ isActive }) =>
		!isActive
			? `
		cursor: pointer;
		:hover {
			text-decoration: underline;
		}
	`
			: ''}
	display: flex;
	align-items: center;
	input {
		border: none;
		font-size: 12px;
		max-width: 168px;
		width: 100%;

		color: #666666;
		font-weight: 600;
		:focus {
			//outline: none;
		}
	}
	@media (max-width: 1023px) {
		max-width: 96px;
		width: 100%;
	}
`
