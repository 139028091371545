/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useContext, useEffect } from 'react'
import { useQuill } from 'react-quilljs'
import debounce from 'lodash/debounce'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'

import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import {
	deleteCurrentStory,
	setClickedValueProp,
	setIsExporting,
} from '@lynit/shared/src/state/actions'
import { useStoryOutlineWidth } from '@lynit/shared/src/utils/utils'
import UpgradeToPro from '@lynit/shared/src/shared-ui/UpgradeToPro'


import { storyOutlineContext } from '../../state/storyOutlineProvider'

import {
	Story,
	TextField,
	IconContainer,
	Header,
	DescriptionField,
	Counts,
	Navigation,
	Main,
	Body,
	CountsContainer,
	ValueProps,
	ValueProp,
	TextFieldContainer,
	StorySummary,
} from './styles'

import trashIcon from '../../images/delete-icon.svg'
import exportIcon from '../../images/export-icon.svg'
import Recommendations from '../NewRecommendations'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'

const useStyles = makeStyles(() => ({
	tooltip: {
		position: 'relative',
		bottom: '12px',
		margin: '0',
		padding: '3px 5px',
		fontSize: '9px',
		background: '#55534F',
		textTransform: 'capitalize',
	},
}))

const Summary = () => {
	const classes = useStyles()
	const { isNarrow } = useStoryOutlineWidth()

	const {
		state: {
			currentStory,
			beatsInChapters,
			leftToggleTabs,
			rightToggleTabs,
			isActive,
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)


	const {
		state: { isRecommendationOpen },
	} = useContext(storyOutlineContext)


	

	return (
		<Story isNarrow={isNarrow}>
			
			<Navigation
				data-public
				data-dd-privacy="allow"
				isNarrow={isNarrow}
				isOpen={isRecommendationOpen}
			>
				<ValueProps isNarrow={isNarrow}>
					<ValueProp>
						<div>
							<p className="value-prop-name">Craft</p>
							<p className="link" onClick={() => sharedDispatch(setClickedValueProp('craft'))}>
								Learn more
							</p>
						</div>
						<p className="value-prop-score">{currentStory?.summary?.craftScore || 1}</p>
					</ValueProp>
					<ValueProp>
						<div>
							<p className="value-prop-name">Speed</p>
							<p className="link" onClick={() => sharedDispatch(setClickedValueProp('speed'))}>
								Learn more
							</p>
						</div>
						<p className="value-prop-score">{currentStory?.summary?.speedScore || 1}</p>
					</ValueProp>
					<ValueProp>
						<div>
							<p className="value-prop-name">Habit</p>
							<p className="link" onClick={() => sharedDispatch(setClickedValueProp('habit'))}>
								Learn more
							</p>
						</div>
						<p className="value-prop-score">{currentStory?.summary?.habitScore || 1}</p>
					</ValueProp>
				</ValueProps>
			</Navigation>
			{/* <Main isNarrow={isNarrow} isOpen={isRecommendationOpen}>
				<StorySummary isNarrow={isNarrow} isOpen={isRecommendationOpen}>
					<h6>Story Name:</h6>
					<TextFieldContainer isNarrow={isNarrow}>
						<TextField
							defaultValue={currentStory?.name || ''}
							onChange={handleStoryNameChange}
							onBlur={async e => {
								await updateStory(e.target.value, currentStory.description)
								refetchStories()
							}}
							placeholder="Write down your story name"
						/>
						<Header>
							<Tooltip title={'Delete'} classes={{ tooltip: classes.tooltip }}>
								<IconContainer onClick={handleDeleteStory}>
									<img src={trashIcon} alt="Delete icon" />
								</IconContainer>
							</Tooltip>
							<Tooltip title={'Export'} classes={{ tooltip: classes.tooltip }}>
								<IconContainer onClick={exportStoryHandler}>
									<img src={exportIcon} alt="Export icon" />
								</IconContainer>
							</Tooltip>
						</Header>
					</TextFieldContainer>

					<h6>Summary:</h6>
					<Body isNarrow={isNarrow}>
						<DescriptionField
							ref={descriptionQuillRef}
							onBlur={async e => {
								if (e.nativeEvent.sourceCapabilities) {
									if (descriptionQuill?.getText() !== currentStory?.description) {
										await updateStory(currentStory?.name, descriptionQuill?.getText())
									}
									refetchStories()
								}
							}}
							placeholder="Write your story summary"
						/>
						<CountsContainer>
							<Counts leftToggleTabs={leftToggleTabs} rightToggleTabs={rightToggleTabs}>
								<div>
									<p className="title">Connections</p>
									<p className="count">
										{isActive && connectionData?.Total
											? `${connectionData?.Total}/20`
											: connectionData?.Total || 0}
									</p>
								</div>
								<div>
									<p className="title">Beats in Chapters</p>
									<p className="count">
										{isActive && beatsInChapters ? `${beatsInChapters}/20` : beatsInChapters || 0}
									</p>
								</div>
								<div>
									<p className="title">Words</p>
									<p className="count">{currentStory?.summary?.wordCount ?? 0}</p>
								</div>
							</Counts>
							{isActive && (connectionData?.Total === 20 || beatsInChapters === 20) && (
								<UpgradeToPro
									message="Upgrade to Pro for Unlimited outlining."
									style={{ marginTop: '10px', padding: '6px 16px' }}
								/>
							)}
						</CountsContainer>
					</Body>
				</StorySummary>
			</Main> */}
			<Recommendations data-public data-dd-privacy="allow" />
		</Story>
	)
}

export default Summary
