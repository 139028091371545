/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect, useRef } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
	arrayMove,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Quill from 'quill'
import orderBy from 'lodash/orderBy'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { useHistory } from 'react-router-dom'
import { useDndContext, useDroppable } from '@dnd-kit/core'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import {
	elementReordered,
	setActiveConnections,
	setConnectionCount,
	setCurrentStory,
	setDeepModeElement,
	setElementToDelete,
	setIsCatelogOpen,
	setOpenToolTip,
	setScrollPosition,
} from '@lynit/shared/src/state/actions'
import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'
import useQuillAutoSave from '@lynit/shared/src/ui/AutoSave/useQuillAutoSave'

import { userStateContext } from '@lynit/shared/src/state/userProvider'

import addBeatIcon from '@lynit/shared/src/images/addBeatIcon.svg'

import {
	createNodeId,
	getNodeIcon,
	nodeTypeForId,
	sortNodesByCreatedAt,
} from '@lynit/shared/src/utils/utils'

import {
	createCacheConnection,
	createCacheElement,
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheField,
} from '@lynit/shared/src/utils/apollo'

import {
	BeatsContainer,
	CreateBeatQuill,
	AddBeat,
	CreateBeat,
	EmptyDriverWrapper,
	EmptyDriverLabel,
	EmptyDriverDescription,
	ExistingBeatLabel,
	ExistingBeat,
	CreateBeatWrapper,
} from './styles'

import {
	useGetRelationshipsQuery,
	useReorderBeatsMutation,
	useReorderNodesMutation,
	useUpdateNodeMutation,
	useDeleteConnectionMutation,
	useCreateBeatMutation,
	useDraggable,
	useCreateSharedBeatMutation,
} from '@lynit/shared/src/hooks'

import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import Arrow from '../../../../shared-package/src/images/arrow.svg'
import DraggableBeats from '@lynit/shared/src/ui/BeatsContainer/DraggableBeats'
import { UPDATE_RELATIONSHIP } from '../../data'
import { useMediaQuery } from '@mui/material'

const Delta = Quill.import('delta')

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: 0,
		background: '#55534F',
		display: props => props.isTooltip && 'none',
	},
	tooltip1: {
		display: props => !props.isCollapsed && 'none',
		background: '#55534F',
	},
	initialsTooltip: {
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timeline: {
		margin: '0',
		padding: '7px 5px',
	},
	timelineItem: {
		'&:before': {
			display: 'none',
		},
		minHeight: 'unset',
		boxSizing: 'border-box',
		justifyContent: 'center',
		alignItems: 'center',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineDot: {
		margin: 0,
		backgroundColor: '#78C091',
	},
	timelineChapter: {
		margin: 0,
		backgroundColor: nodeColors.Chapter,
		width: '8px',
		height: '8px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		color: '#000000',
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: '#78C091',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: 0,
	},
	tab: {
		color: '#888585',
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: 'normal',
		textTransform: 'none',
		minWidth: '70px',
		padding: '2px 5px',
	},
	tabSelected: {
		color: '#000000 !important',
	},
	indicator: {
		height: '6px',
		borderRadius: '10px',
		backgroundColor: '#FFC300',
	},
	tabs: {
		borderBottom: '6px solid #fff5d4',
		borderRadius: '4px',
	},
}))

const BeatsContainerComponent = React.memo(
	({
		referenceElement,
		location,
		locationId,
		beatsList,
		droppable,
		connectionTypeField,
		hideCreateDragBeat = true,
	}) => {
		const client = useApolloClient()
		const {
			state: { deleteNode, activeConnections },
			dispatch: sharedDispatch,
		} = useContext(sharedContext)

		const {
			currentElement: currentDeepModeElement,
			currentStoryId: storyId,
			deepModeElement,
			serverError,
			noElementError,
		} = useContext(beatsDataStateContext)

		const beatsDataDispatch = useContext(beatsDataDispatchContext)
		const { createLog, toastHandler, isMobile } = useContext(systemStateContext)
		const graphData = useContext(graphDataStateContext)
		const { user, refetchUser } = useContext(userStateContext)

		const [beatContentChanged, setBeatContentChanged] = useState(false)

		const [createBeat] = useCreateBeatMutation({ ignoreResults: true })
		const [reorderNodes] = useReorderNodesMutation()

		const [performDeleteConnectionMutation] = useDeleteConnectionMutation()
		const { data: relationships } = useGetRelationshipsQuery({fetchPolicy: 'cache-only', component:"BeatContainer"})
		const [performCreateSharedBeat] = useCreateSharedBeatMutation({ ignoreResults: true })
		const [newBeat, setNewBeat] = useState(null)

		const type = referenceElement?.__typename

		const classes = useStyles({
			isTooltip: true,
			isCollapsed: false,
		})

		const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 768px)')

		const [updateElement] = useUpdateNodeMutation(type, { ignoreResults: true })

		const checkBeatContentChanged = (updateId, content, text) => {
			if (text.length > 1) {
				setBeatContentChanged(true)
			} else {
				setBeatContentChanged(false)
			}
			updateElement({
				variables: {
					id: updateId,
					input: {
						firstBeat: content,
						updatedAt: new Date().toISOString(),
					},
				},
			})
		}

		const { quill, quillRef } = useQuillAutoSave(
			{
				modules: {
					toolbar: false,
				},
				formats: [],
				placeholder:
					referenceElement.__typename === 'Relationship'
						? 'Demonstrate this relationship with Beats'
						: 'Type new Beat here',
			},
			checkBeatContentChanged,
			referenceElement?.id,
			'firstBeat',
			referenceElement.firstBeat,
		)
		const createBeatHandler = async (beatContent = JSON.stringify({ ops: [{ insert: '\n' }] })) => {
			quill.setText('', Quill.sources.USER)

			setBeatContentChanged(false)

			updateCacheField(client, referenceElement, {
				firstBeat: '',
			})

			const beatId = createNodeId('Beat')
			const relId = createNodeId('Relationship')
			let relationship

			let tempData 
            const beat = {
                id: beatId,
                beatConnections: [],
                driverConnections: [],
                description: beatContent,
                driverConnections: [],
                order: null,
                staticConnections: [],
                noteConnections: [],
                premiseConnections: [],
                templateText: '',
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                xCoordinate: null,
                yCoordinate: null,
                vizLocSaved: false,
                firstBeat: '',
                __typename: 'Beat',
				milestone:false,
				aiRecommendations:'',

            }
			if (referenceElement?.__typename === 'Relationship') {
				relationship = {
					arc: null,
					connectionType: location==='Premise Planner' ? 'Premise' : 'Driver',
					sourceNode: {
						id: beatId,
						__typename: 'Beat',
					},
					id: relId,
					order: String(
						graphData?.nodes[referenceElement?.sourceNode?.id]?.driverConnections?.length || 0,
					),
					destNode: {
						id: referenceElement?.sourceNode?.id,
						__typename: referenceElement?.sourceNode?.__typename,
					},
					name: null,
					description: null,
					arcStage: null,
					relName: null,
					__typename: 'Relationship',
					structureTag: null,
					beatsDriven: null,
					sharedBeats: [],
					firstBeat: '',
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
				}

				const destNodeDriverConnection = {
					arc: null,
					connectionType: 'Driver',
					sourceNode: {
						id: beatId,
						__typename: 'Beat',
					},
					id: createNodeId('Relationship'),
					order: String(
						graphData?.nodes[referenceElement?.destNode?.id]?.driverConnections?.length || 0,
					),
					destNode: {
						id: referenceElement?.destNode?.id,
						__typename: referenceElement?.destNode?.__typename,
					},
					name: null,
					description: null,
					arcStage: null,
					relName: null,
					__typename: 'Relationship',
					structureTag: null,
					beatsDriven: null,
					sharedBeats: [],
					firstBeat: '',
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
				}

				tempData = {
					createBeat: {
						beat,
						relationship,
					},
				}

				const staticConnectionUpdateFields = {
					beatsDriven: String(+beatsList.length + 1),
					sharedBeats: (beatsList || []).concat([tempData.createBeat.beat]),
					updatedAt: new Date().toISOString(),
				}

				performCreateSharedBeat({
					tempData,
					destNodeDriverConnection,
					staticConnectionUpdateFields,
					id: referenceElement?.id,
					sharedBeats: beatsList,
					beatsDriven: beatsList?.length,
					client,
				})
			} else {
				relationship = {
					arc: null,
					connectionType:location==='Premise Planner' ? 'Premise' : referenceElement?.__typename === 'Chapter' ? 'Beat' : 'Driver',
					sourceNode: {
						id: beatId,
						__typename: 'Beat',
					},
					id: relId,
					order: String(beatsList.length || 0),
					destNode: {
						id: referenceElement?.id,
						__typename: referenceElement?.__typename,
					},
					name: null,
					description: null,
					arcStage: null,
					relName: null,
					__typename: 'Relationship',
					structureTag: null,
					beatsDriven: null,
					sharedBeats: [],
					firstBeat: '',
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
				}

				tempData = {
					createBeat: {
						beat,
						relationship,
					},
				}

				await createCacheElement(client, tempData, false, false)
				await createCacheConnection(client, tempData.createBeat.relationship)
					createBeat({
						variables: {
							beat: {
								id: tempData.createBeat.beat.id,
								description: tempData.createBeat.beat.description,
							},
							relationship: {
								id: tempData.createBeat.relationship.id,
								destNodeId: tempData.createBeat.relationship.destNode.id,
								sourceNodeId: tempData.createBeat.relationship.sourceNode.id,
								description: tempData.createBeat.relationship.description,
								connectionType: tempData.createBeat.relationship.connectionType,
								structureTag: tempData.createBeat.relationship.structureTag,
								relName: 'RELATED',
								beatsDriven: tempData.createBeat.relationship.beatsDriven,
								sharedBeats:
									tempData.createBeat.relationship.sharedBeats?.map(beat => {
										return { id: beat.id }
									}) || [],
								firstBeat: '',
								order: tempData.createBeat.relationship.order,
							},
							parentId: referenceElement?.id,
						},
						ignoreResults: true,
					}).catch(async error => {
						await deleteCacheElement(client, tempData.createBeat.beat)
						await deleteCacheConnections(client, tempData.createBeat.relationship)
						toastHandler(
							'error',
							`There was an error creating the Beat, try again.`,
							null,
							'RelationshipList',
							'Beat Creation',
						)
						console.error(error)
					})
			}

			// const elem = document.getElementById(`${referenceElement?.id}-beatContainer`)
			// elem.scrollTo({
			//     top: elem.scrollHeight,
			//     behavior: 'smooth',
			// })
			//})

			createLog(
				`Beat Creation Attempted`,
				`{"workflowStep":${1},"parentNodeType":"${type}"}`,
				'Beats Tab',
				'Beat Creation',
			)
		}


		const handleBeatDelete = ({ description, name, id, type }) => {
			sharedDispatch(
				setElementToDelete({
					isActive: true,
					type,
					id,
					nodeName: name,
				}),
			)
		}
		return (
			<>
				<BeatsContainer
					id={`${referenceElement?.id}-beatContainer`}
					isVisible={true}
					isEmpty={!beatsList?.length}
					className="beatsContainer"
					ref={droppable}
				>
					<Timeline className={classes.timeline}>
						{/* Create first beat */}

						{/* Beats */}

						{
							<SortableContext
								items={beatsList?.map(beat => {
									return beat.id + '-' + location + '-' + referenceElement?.id
								})}
								//items={driverConnections.map(connection => connection?.id )}
								strategy={verticalListSortingStrategy}
							>
								{beatsList?.map((beatItem, index) => {
									const beat = graphData.nodes[beatItem.id]
									const nodeId = beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
										? beat?.beatConnections?.[0]?.sourceNode?.id
										: beat?.beatConnections?.[0]?.destNode?.id
									const node = graphData.nodes[nodeId]
									return (
										<TimelineItem key={`${index}-${beat?.id}`} className={classes.timelineItem}>
											<DraggableBeats
												index={index}
												key={index}
												node={node}
												beatsList={beatsList}
												beatId={beat?.id}
												beat={beat}
												milestoneBeat={beat?.premiseConnections.length}
												setCurrentElement={handleBeatDelete}
												referenceElementType={referenceElement?.__typename}
												referenceElement={referenceElement}
												connectionId={beatItem?.connectionIc}
												beatConnection={beat?.beatConnections?.[0]}
												setIsSetModifiers={() => {}}
												locationId={locationId}
												location={location}
												isNewBeat={false}
												setNewBeat={setNewBeat}
												connectionTypeField={connectionTypeField}
											/>
										</TimelineItem>
									)
								})}
							</SortableContext>
						}
						<TimelineItem
							className={classes.timelineItem}
							style={{ display: hideCreateDragBeat ? 'flex' : 'none' }}
						>
							<TimelineSeparator>
								<TimelineConnector className={classes.hiddenTimelineConnector} />
								<TimelineDot className={classes.timelineDot} />
								<TimelineConnector className={classes.hiddenTimelineConnector} />
								{beatContentChanged || quill?.getText().length > 1 ? (
									<div style={{ height: '20px' }} />
								) : (
									<></>
								)}
							</TimelineSeparator>
							<TimelineContent className={classes.timelineContent}>
								<CreateBeatQuill
									ref={quillRef}
									key={referenceElement?.id}
									id={referenceElement?.id}
									data-fieldname={'firstBeat'}
									translate="no"								/>
								{(beatContentChanged || quill?.getText().length > 1) && (
									<AddBeat onClick={() => createBeatHandler(JSON.stringify(quill.getContents()))}>
										{location==='Premise Planner' ? '+ Create Milestone Beat' : '+ Create Beat'}
									</AddBeat>
								)}
							</TimelineContent>
						</TimelineItem>
						{hideCreateDragBeat && (
							<div
								style={{
									marginLeft: '16px',
									marginTop: '5px',
								}}
							>
								{graphData?.nodesByType?.['Beat'].length > 0 &&
									!['Search Connection Card', 'Viz Connection Card', 'Driver Card'].includes(
										location,
									) && (
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '6px',
											}}
										>
											<div>
												<img src={Arrow} />
											</div>
											<div
												style={{
													width: `calc(100% - 30px)`,
												}}
											>
												{false && <ExistingBeatLabel>What will happen next?</ExistingBeatLabel>}
												{
													<ExistingBeat
														onClick={() => {
															createLog(
																`Drag Existing Beat Button Clicked`,
																{location, parentNodeType:type},
																'Beats Container',
																'Add Beat to List',
															)
															sharedDispatch(setIsCatelogOpen('Beats'))
															if (isTablet || isMobile) {
																sharedDispatch(
																	setActiveConnections({
																		isActive: null,
																		elementId: null,
																		elementType: null,
																		docType: null,
																		appView: activeConnections.appView,
																		isMobileTempClose: true,
																	}),
																)
															}
														}}
													>
														Or drag existing beat from Catalog
													</ExistingBeat>
												}
											</div>
										</div>
									)}
							</div>
						)}
					</Timeline>
				</BeatsContainer>
			</>
		)
	},
)

const Draggable = props => {
	const { attributes, listeners, active, over } = useDndContext()

	const graphData = useContext(graphDataStateContext)

	// const newBeatList = props?.connectionTypeField === "sharedBeats" ?
	// 		relationships?.relations.find(relation => relation?.id === props?.referenceElement?.id)?.[props?.connectionTypeField].map((beat,index)=> {return {...beat,order:(index)}})
	// 		 : graphData?.nodes[props?.referenceElement?.id]?.[props?.connectionTypeField].map((connection) => {
	// 			const beatId = connection?.sourceNode?.id?.startsWith('bea')
	// 								? connection?.sourceNode?.id
	// 								: connection?.destNode?.id
	// 			return { id:beatId,connectionId:connection.id, order: +connection.order }
	// 		})


	// const orderedBeatList = orderBy(newBeatList, ['order'], ['asc'])
	const beatsList = useDraggable(
		props?.referenceElement?.id,
		active,
		over,
		[],
		props?.connectionTypeField,
	)
	
	const emptyDroppable = useDroppable({
		id: `rel-empty-beat-connections-${props?.referenceElement?.id}-${props.location}`,
		disabled: beatsList?.length,
		data: {
			type: 'beat',
			referenceElement: { id: props?.referenceElement?.id },
			id: `rel-empty-beat-connections-${props?.referenceElement?.id}-${props.location}`,
			connectionTypeField: props?.connectionTypeField,
			location:  props.location,
			locationId: props.locationId,
		},
	})

	const beatsContainer = useDroppable({
		id: `container-${props?.referenceElement?.id}-${props?.location}`,
		//disabled: !!referenceElement?.beatConnections?.length,
		data: {
			type: 'beat',
			referenceElement: { id: props?.referenceElement?.id },
			id: `container-${props?.referenceElement?.id}-${props.location}`,
			connectionTypeField: props?.connectionTypeField,
			location:  props.location,
			locationId: props.locationId,
		},
	})

	return (
		<>
			{props?.referenceElement?.id && (
				<BeatsContainerComponent
					{...props}
					attributes={attributes}
					listeners={listeners}
					beatsList={beatsList}
					droppable={beatsList?.length ? beatsContainer.setNodeRef : emptyDroppable.setNodeRef}
				/>
			)}
		</>
	)
}

export default React.memo(Draggable)
