import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_RECOMMENDATIONS } from '../data'

export const useGetRecommendationsQuery = (options = {}) => {
	const result = useQuery(GET_RECOMMENDATIONS, options)
	return result
}

export const useLazyGetRecommendationsQuery = (options = {}) => {
	const result = useLazyQuery(GET_RECOMMENDATIONS, options)
	return result
}
