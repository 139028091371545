import React, { useEffect } from 'react'
import { ModalContainer, Wrapper } from './style'

function DragHelperModal({ description, blurOnly, blur = false, target, isOpen }) {
	useEffect(() => {
		const targetElem = document.getElementById(target)
		if (isOpen) {
			targetElem.style.position = 'relative'
			if (blur) {
				targetElem.childNodes.forEach(elem => {
					if (elem.id !== 'drag-modal-wrapper') {
						elem.style.filter = 'blur(3px)'
					}
				})
			}
		} else {
			targetElem.childNodes.forEach(elem => {
				elem.style.filter = 'blur(0px)'
			})
		}
	}, [isOpen])

	return (
		<Wrapper blur={blur} isOpen={isOpen} id="drag-modal-wrapper">
			<ModalContainer blurOnly={blurOnly} style={{ filter: 'blur(0px)' }}>
				{description}
			</ModalContainer>
		</Wrapper>
	)
}

export default DragHelperModal
