import { withStyles } from '@mui/styles'
import Tooltip from '@mui/material/Tooltip'

const HtmlTooltip = withStyles({
	tooltip: {
		background: 'transparent',
		margin: 0,
		paddingRight: 0,
		cursor: 'pointer',
		marginRight: `0 !important`,
		left:0,
		
	},
	arrow: {
		color: '#A1E4FA',
	},
})(Tooltip)

export default HtmlTooltip
