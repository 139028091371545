import React from 'react'

import styled from 'styled-components'
import { colorStyles } from '../../utils/commonStyles'

const Content = styled.div`
	p {
		span {
			color: ${colorStyles.lynitYellow};
			margin: 0 0.5rem 0 1.5rem;
		}
	}
`

const Toast = ({ onRetry, content, isRetry }) => {
	const handleClick = () => {
		onRetry()
	}
	return (
		<Content>
			<p>
				{content}
				{isRetry ? <span onClick={handleClick}>Try now</span> : ''}
			</p>
		</Content>
	)
}

export default Toast
