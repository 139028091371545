import { VIZ_NODE_HOVER, UI_NODE_HOVER, IS_SAVING, REORDER_ELEMENT, MOVE_BEAT, EDITOR_TEXT_CHANGE, ON_SAVE_MODAL, SET_ACTIVE_ELEMENT, IS_EXPORTING, DELETE_STORY, OPEN_TUTORIAL, TUTORIAL_OPENED, OPEN_RECOMMENDATION, RECOMMENDATION_OPENED, EXPAND_TUTORIAL_SET, TUTORIAL_SET_EXPANDED, RESET_ACTIVE_ELEMENT, SET_CHAPTER_VIEW_EXPANDED, SET_VISULIZATION_EXPANDED } from '../actionTypes'

const initialActiveElement = {
	id: null,
	type: null
}

const initialState = {
	hoveredElem: {
		type: 'node', // 'node' or 'relationship'
		context: 'ui', // 'ui' or 'viz'
		id: null,
	},
	// activeConnections: {
	// 	isActive: false,
	// 	elementId: null,
	// 	elementType: null
	// },
	activeElement: initialActiveElement,
	isSaving: false,
	isExporting: false,
	deleteStory: false,
	reorderedPosition: null,
	movedPosition: null,
	editorData: null,
	isModalOpen:false,
	tutorialId: '',
	tutorialSetId: '',
	recommendationId: '',
	isChapterViewExpanded: false,
	isVisualizationExpanded: false,
}

// Reducer
export default (state = initialState, action) => {
	switch (action.type) {
		case VIZ_NODE_HOVER: {
			const { id } = action.payload
			return {
				...state,
				hoveredElem: {
					type: 'node',
					context: 'viz',
					id,
				},
			}
		}

		case UI_NODE_HOVER: {
			const { id } = action.payload
			return {
				...state,
				hoveredElem: {
					id,
					type: 'node',
					context: 'ui',
				},
			}
		}

		case IS_SAVING:
			return {
				...state,
				isSaving: action.payload,
			}
		
		case IS_EXPORTING:
			return {
				...state,
				isExporting: action.payload
			}

		case DELETE_STORY:
			return {
				...state,
				deleteStory: action.payload
			}

		case REORDER_ELEMENT:
			return {
				...state,
				reorderedPosition: action.payload
			}

		case MOVE_BEAT:
			return {
				...state,
				movedPosition: action.payload
			}

		case EDITOR_TEXT_CHANGE:
			return {
				...state,
				editorData: action.payload
			}

		case ON_SAVE_MODAL:
			return {
				...state,
				isModalOpen: action.payload
			}

		case SET_ACTIVE_ELEMENT: 
			return {
				...state,
				activeElement: action.payload,
			}

		case RESET_ACTIVE_ELEMENT:
			return {
				...state,
				activeElement: initialActiveElement,
			}
		
		// case SET_ACTIVE_CONNECTIONS:
		// 	return {
		// 		...state,
		// 		activeConnections: action.payload
		// 	}
		
		case OPEN_TUTORIAL:
			return {
				...state,
				tutorialId: action.payload
			}
		
		case TUTORIAL_OPENED:
			return {
				...state,
				tutorialId: ''
			}
		
		case OPEN_RECOMMENDATION:
			return {
				...state,
				recommendationId: action.payload
			}
		
		case RECOMMENDATION_OPENED:
			return {
				...state,
				recommendationId: ''
			}
		
		case EXPAND_TUTORIAL_SET:
			return {
				...state,
				tutorialSetId: action.payload
			}
		
		case TUTORIAL_SET_EXPANDED:
			return {
				...state,
				tutorialSetId: ''
			}
		
		case SET_CHAPTER_VIEW_EXPANDED:
			return {
				...state,
				isChapterViewExpanded: action.payload
			}

		case SET_VISULIZATION_EXPANDED:
			return {
				...state,
				isVisualizationExpanded: action.payload
			}	

		default:
			return state
	}
}
