import { createContext, useReducer } from 'react'
import { storyOutlineReducer } from './storyOutlineReducer'

export const storyOutlineContext = createContext()

const initialState = {
	isRecommendationOpen: false,
	hoveredElem: {
		type: 'node', // 'node' or 'relationship'
		context: 'ui', // 'ui' or 'viz'
		id: null,
	},
}

export const StoryOutlineContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(storyOutlineReducer, initialState)

	return (
		<storyOutlineContext.Provider value={{ state, dispatch }}>
			{children}
		</storyOutlineContext.Provider>
	)
}
