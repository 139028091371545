import { toast } from 'react-toastify'

export const toastHandler = (state, message, content) => {
	//Error handling
	if (typeof state !== 'string') {
		throw new Error(
			`TypeError: Expected a string as a value for the "state" parameter, instead got ${typeof state}`
		)
	}
	// Cases handling for different states of "state" parameter
	if (state === 'success') {
		toast.success(message)
	} else if (state === 'warning') {
		toast.warning(message)
	} else if (state === 'error') {
		toast.error(message)
	} else if (state === 'dark') {
		toast.dark(content, {
			onClose: props => props.close(),
			position: 'bottom-right',
			autoClose: props => props.autoClose,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			toastId:message,
			progress: undefined
		})
	} else if (state === 'deepDark') {
		toast.dark(content, {
			position: 'top-left',
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		})
	} else if (state === 'dark1') {
		toast.dark(content, {
			onClose: props => props.close(),
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			position: 'bottom-center'
		})
	} else if (state === 'dark2') {
		toast.dark(content, {
			onClose: props => props.close(),
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		})
	} else {
		throw new Error(
			'InputError: Expected either "success", "warning" or "error" as values for the "state" parameter'
		)
	}
}
