import styled from 'styled-components'

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: rgba(158, 158, 158, 0.4);
	display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`

const TextDiv = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 245px;
	box-sizing: border-box;
	background-color: #fffbec;
	border-radius: 7px;
	padding: 20px;
	transform: translateX(-50%) translateY(-50%);
	font-family: 'Source Sans Pro';
	line-height: 16px;
	font-size: 12px;
	text-align: center;
`

export { Wrapper, TextDiv }
