import styled from 'styled-components'

const Story = styled.div`
	display: flex;
	height: 100%;
	flex-direction: ${({ isNarrow }) => (isNarrow ? 'column' : 'row')};
`

const TextFieldContainer = styled.div`
	display: flex;
	max-width: max(50%, 450px);
	gap: 13px;
	margin: 5px 0 20px 0;
	flex-direction: ${({ isNarrow }) => (isNarrow ? 'column' : 'row')};
`

const Header = styled.div`
	display: inline-flex;
	vertical-align: middle;
	gap: 9px;
`

const TextField = styled.input`
	box-sizing: border-box;
	border: none;
	outline: none;
	background: #ffffff;
	font-size: 12px;
	line-height: 15px;
	border-bottom: 1px solid #000000;
	padding: 4px;
	flex: 1;
`

const DescriptionField = styled.div`
	width: 100%;
	height: 92px !important;
	background: #f8f8f8;
	border-radius: 5px;
	border: 1px solid #ececec;
	margin-top: 5px;

	@media (min-width: 1440px) {
		width: 50%;
	}
`

const IconContainer = styled.button`
	cursor: pointer;
	background-color: transparent;
	padding: 0;
	img {
		width: 24px;
	}
	border: 0;
`

const CountsContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 0 12px;

	@media (min-width: 1440px) {
		width: 50%;
		padding: 0 20px;
	}
`

const Counts = styled.div`
	display: flex;
	gap: 20px;
	box-sizing: border-box;
	.title {
		font-size: 10px;
	}
	.count {
		font-size: 16px;
		font-weight: 700;
		margin: 0;
	}
	> div {
		display: flex;
		flex-direction: column;
	}

	@media (min-width: 1024px) {
		gap: 48px;
	}

	${({ leftToggleTabs, rightToggleTabs }) =>
		leftToggleTabs && rightToggleTabs
			? `
		@media (min-width: 1200px) and (max-width: 1440px) {
			gap: 30px;
		}
	`
			: ''}
`

const Navigation = styled.div`
	margin-top: 160px;
	display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};

	${({ isNarrow }) =>
		!isNarrow
			? `
		background-color: #ebebef;
		width: 130px;
		margin: 0;
		display: block;
	`
			: ''}
`

const Main = styled.div`
	box-sizing: border-box;
	background-color: #f3f3f6;
	padding: 5px 8px;
	display: flex;
	flex-direction: column;
	h6 {
		margin: 0;
	}
	height: ${({ isOpen }) => (isOpen ? '100%' : 'auto')};

	${({ isNarrow }) =>
		!isNarrow
			? `
		width: calc(100% - 130px);
		height: 100%;
		overflow: auto;
	`
			: ''}

	::-webkit-scrollbar-thumb {
		background: #9d9d9d80;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		background: #ececec;
	}

	::-webkit-scrollbar {
		width: 0.3rem;
	}
`

const Body = styled.div`
	display: flex;
	flex-direction: column;

	${({ isNarrow }) =>
		!isNarrow
			? `
		@media (min-width: 1025px) {
			flex-direction: row;
		}
	`
			: ''}
`

const ValueProps = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: absolute;
	top: 5px;
	left: 50px;
	width: 40%;
	max-width: 115px;

	${({ isNarrow }) =>
		!isNarrow
			? `
		top: 145px;
		left: 5px;
		width: 115px;
	`
			: ''}
`

const ValueProp = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	p {
		margin: 0;
	}
	.value-prop-name {
		font-weight: 700;
		font-size: 9px;
	}

	.link {
		font-size: 7px;
		color: #0366fb;
		cursor: pointer;
	}

	.value-prop-score {
		font-weight: 700;
		font-size: 21px;
		line-height: 15px;
	}
`

const StorySummary = styled.div`
	display: ${({ isOpen, isNarrow }) => (isNarrow && isOpen ? 'none' : 'block')};
	margin-bottom: 40px;
`

export {
	Story,
	TextField,
	IconContainer,
	TextFieldContainer,
	Header,
	DescriptionField,
	Counts,
	Navigation,
	Main,
	Body,
	CountsContainer,
	ValueProps,
	ValueProp,
	StorySummary,
}
