/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { select, event, selectAll } from 'd3-selection'
import { zoom, zoomTransform } from 'd3-zoom'
import { forceSimulation, forceLink, forceX, forceY, forceCollide } from 'd3-force'
import { drag } from 'd3-drag'
import { arc } from 'd3-shape'
import {
	setActiveConnections,
	setDeepModeElement,
	setSummaryCardVisible,
} from '@lynit/shared/src/state/actions'
import { updateCacheFieldAllNodes , getRelationsByConnectionType, updateCacheField} from '@lynit/shared/src/utils/apollo'
import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import { getNodeIcon, isArcComplete, nodeTypeForId, vizTitleLengthFormat } from '@lynit/shared/src/utils/utils'
import suppotingTagIcon from '@lynit/shared/src/images/supporting-coonection.svg'
import opposingTagIcon from '@lynit/shared/src/images/opposing-connection.svg'
import leftHalfCircle from '../../images/left-half-circle.svg'
import rightHalfCircle from '../../images/right-half-circle.svg'
import supportingIcon from '../../images/structure-tag-no-color.svg'
import opposingIcon from '../../images/friction.svg'
import missingStructureTagIcon from '@lynit/shared/src/images/missingStructureTag.svg'
import missingPremiseIcon from '@lynit/shared/src/images/missing-premise-driver.svg'
import premiseRing from '../../images/premise_ring.svg'
import connectionLineInfo from '../../images/Connection Hover Info.svg'
import missingConnLineInfo from '../../images/missing-connection-hover-info.svg'
import premiseCharacterIcon from '../../images/premise-ring-char.svg'
import premiseArcIcon from '../../images/premise-ring-arc.svg'
import premiseThemeIcon from '../../images/premise-ring-tsm.svg'
import openSummaryIcon from '../../images/open-summary.svg'
import premiseHoverCharIcon from '../../images/premise-hover-char.svg'
import premiseHoverArcIcon from '../../images/premise-hover-arc.svg'
import premiseHoverThemeIcon from '../../images/premise-hover-tsm.svg'
import premiseElementDrag from '../../images/premise-drag2.gif'
import premiseDragText from '../../images/premise-drag-text.svg'

// import {symbol, symbolTriangle} from "d3-shape";
// Render a visualization of the graph data with D3

export default function renderGraph(
	nodes,
	edges,
	width,
	height,
	vizNodeHover,
	history,
	createLog,
	updateMap,
	client,
	sharedDispatch,
	currentStory,
	isSupporting,
	isOpposing,
	isUndefined,
	missingConnections,
	existingConnections,
	summaryCardProgressData,
	setCurrentElementPremise,
	addNodeFunc,
	isSummaryCardVisible,
	systemDataDispatch,
	connectionsGroups,
	isChapterViewExpanded,
	globalDragRef,
	selectedElementRef,
	beatsDataDispatch,
	handleDriverConnections,
	setVisualizationToolitip,
	activeConnections
) {
	// if (nodes?.length + edges?.length === 0) return
	const svg = select('.graph-viz')
	// svg.append("svg:defs").append("svg:marker")
	// .attr("id", "arrowhead")
	// .attr("fill",'#609D68')
	// .attr("refX", 35)
	// .attr("refY", 3.5)
	// .attr("markerWidth", 10)
	// .attr("markerHeight", 7)
	// .attr("orient", "auto")
	// .append("polygon")
	// .attr("points", "0 0, 10 3.5, 0 7");
	let startTime, endTime
	svg.style('background-color', '#f3f3f6').style('display', 'flex')

	// TODO I think the actual width and height are not important, its the aspect ratio.
	// Also, hardcoding the width and height into the viewbox (svg user space) makes
	// the scale a bit unpredictable (e.g. setting a radius of 3 will mean a different
	// thing depending on the specific width + height measured.
	// Might be better to try always setting width to 100 and height to aspect_ratio * width.
	// Then you'd always have a stable userspace width.

	svg
	.attr('viewBox', [-width / 2, -height / 2, width, height])

	.on('contextmenu', function (d) {
		//event.preventDefault()
		select(this).on('click')()
	})
	
	.on('click',(d)=> {

		if(( false &&( (event?.target?.getAttribute("id") ==="graph-viz" && (selectedElementRef.current|| isSummaryCardVisible)) || d )) ) {
			
			
			
			setDefaultNodeStyles(node)
			setDefaultLinkStyles(rings)

			// sharedDispatch(setActiveConnections({ isActive: false, elementId: "", elementType:null }))
			//systemDataDispatch(setSummaryCardVisible(false))
			selectedElementRef.current = null
			
			

		}

		
	})
	
	const g = svg
				.select('g')
				.attr('cursor', 'pointer')
				
				
				
	const setDefaultNodeStyles = (node) => {

		node.style('opacity', 1)
			node
			.select('circle')
			//.filter(o => o.id === selectedElementRef.current?.id)
			.style('filter', 'unset')

			
			node
			.select('.node-name')
			.attr('x', d => iconSize(d) / 2)
			.attr('y', d => -Math.max(iconSize(d), 8) / 2)
			.attr("font-weight",500)
			.attr('font-size', d => `${0.75}em`)

			nodes.forEach(node => {
				node.clicked = false
			})
	}
	const setDefaultLinkStyles = (rings) => {


			Object.keys(rings).forEach((ringName) => {

				rings[ringName].link
				.style('opacity', 1)
				.selectAll('.structureTag')
				.style('display', o => (edges.length >=3 ? 'none' : 'block'))
				.style('fill', '#000')
				
				rings[ringName].link.selectAll('path').style('stroke', '#000')
				
				rings[ringName].link
				.selectAll('.structureTagIcon')
				.attr('xlink:href', d =>
					supportingStructureTags.includes(d.structureTag)
						? supportingIcon
						: opposingStructureTags.includes(d.structureTag)
						? opposingIcon
						: missingStructureTagIcon,
				)
				.style('display', 'block')



			})
			
			
	}
	// Set up zoom + pan
	// https://www.freecodecamp.org/news/get-ready-to-zoom-and-pan-like-a-pro-after-reading-this-in-depth-tutorial-5d963b0a153e/
	// Zoom callback handler
	const zoomed = () => {
		const { transform } = event
		if (transform.toString() !== 'translate(NaN,NaN) scale(NaN)') {
			g.attr('transform', transform.toString())
		}
	}
	// Zoom behavior
	const myZoom = zoom().filter(() =>  !event.button).on('zoom', zoomed)
	// Bind zoom behavior to callback and base element
	svg.call(myZoom, zoomed).on("dblclick.zoom", null)

	// Scale to fit the graph
	// TODO Currently doing this in a set timeout to allow the simulation time to
	// complete so the dimensions of the graph are established before we measure
	// them. Is there a way to do so explicitly upon completion of the simulation?

	const zoomGraph = () => {
		const isRingZoom = activeConnections.appView === "Premise" && !activeConnections.elementId //!!isSummaryCardVisible
		const node = isRingZoom ? rings["Premise"].ringElement.node() : svg.node()
		const bounds =  isRingZoom ? node.getBoundingClientRect() : node.getBBox()
		//const premiseRingBounds =  rings[isSummaryCardVisible].ringElement.node().getBoundingClientRect()
		const parent = svg.node().parentElement
		const fullWidth = parent?.clientWidth || parent?.parentNode?.clientWidth || 1
		const fullHeight = parent?.clientHeight || parent?.parentNode?.clientHeight || 1
		const w = bounds.width || 1
		const h = bounds.height || 1
		let scaleFactor = isRingZoom ? .9 : 0.6
		let scale =  scaleFactor / Math.max(w / fullWidth, h / fullHeight)
		
		if(w ===1 || h === 1 ){
			return
		}
		const centerXOfSVG = 0 //fullWidth / 2
		const centerYOfSVG = 0 //fullHeight / 2

		const boundsAfterZoom = {
			x: bounds.x * scale,
			y: bounds.y * scale,
			width: bounds.width * scale,
			height: bounds.height * scale,
		}
		const centerXOfGAfterZoom = 0//isSummaryCardVisible * premiseRadius + 0
			// boundsAfterZoom.x +
			// boundsAfterZoom.width / 2 +
			// centerXOfSVG +
			// isSummaryCardVisible * premiseRadius
		const centerYOfGAfterZoom = 0 //boundsAfterZoom.y + boundsAfterZoom.height / 2 + centerYOfSVG

		const currentView =  zoomTransform(node)

		if(.95 < scale && scale <= 1 || (1< scale && scale < 1.05)){
			scale = 1
		}

		let diffX = -currentView.x //- (!!isSummaryCardVisible ? 230/2 * currentView.k : 0)  //(centerXOfSVG - centerXOfGAfterZoom) / 2
		let diffY = -currentView.y  //(centerYOfSVG - centerYOfGAfterZoom) / 2

		if(!selectedElementRef.current){
			
			// diffX = -currentView.x  - isSummaryCardVisible * premiseRadius * scale  //(centerXOfSVG - centerXOfGAfterZoom) / 2
			// diffY = -currentView.y 
			// diffX = -(currentView.x + parseFloat(selectedElementRef.current.xCoordinate))
			// diffY = -(currentView.y + parseFloat(selectedElementRef.current.yCoordinate))
			// diffX = 0
			// diffY = 0
		
		}
		
		//I still need a way to deselect a selected node. Also I could not tackle the selected node yet.


		if(Math.abs(diffX) <= .05*fullWidth && Math.abs(diffY)<+ .05* fullHeight ){

			diffX = 0
			diffY = 0

		}

		const transform = currentView
			.translate(diffX /currentView.k, diffY/currentView.k)
			.scale(scale)
			
		// const resetButtonVisible = Object.values(nodes)
		// 	.filter(element => element.__typename !== 'Beat' && element.__typename !== 'Chapter')
		// 	.some(element => element.vizLocSaved === true)

		// if(!resetButtonVisible){
		// 	svg.transition().duration(750).call(myZoom.transform, transform)
		// }

		svg.transition().duration(500).call(myZoom.transform, transform)

		
		
		
		
	}
	let isRendered = true
	const supportingStructureTags = ['Supporting', 'Ally', 'Protagonist']
	const opposingStructureTags = ['Opposing', 'Opponent', 'Antagonist']

	const totalProgress = !Number.isNaN(summaryCardProgressData.totalProgressSummaryCard.current)
		? Math.round(summaryCardProgressData.totalProgressSummaryCard.current)
		: 1

	const progress = totalProgress <= 1 ? 1 : Math.round(summaryCardProgressData.totalProgressSummaryCard.current)

	const progressToAngle =(progressNumber, maxAngle) => {

		
		return (progressNumber)* maxAngle * (Math.PI/ 180)
		

	}

	const startAngle = progressToAngle(progress/100,360)
	
	const totalConnectionCount = existingConnections.length + missingConnections.length

	const totalYellowLines =
		totalConnectionCount === missingConnections.length
			? missingConnections.length
			: totalConnectionCount - missingConnections.length


	const totalMissingConnectionSupposedArc = progressToAngle((1/(totalConnectionCount>3?totalConnectionCount :3)),120)

	const premiseElementEdges = edges.filter(
		data =>
			data.source.coreElement === 'true' &&
			data.target.coreElement === 'true' &&
			data.beatsDriven === '0',
	)

	const totalMissingBeatSupposeArc = progressToAngle((1/(totalConnectionCount>3?totalConnectionCount:3)),120)

	
	
	


	//g.select('.ring').selectAll('*').remove()


	

	const data = [{ progress }]

	const rings = connectionsGroups



	const premiseCharacterNode = rings.Premise.nodes.find(
		element => element.__typename === 'Character' ,
	)

	const premiseArcNode = rings.Premise.nodes.find(
		element => element.__typename === 'Arc' ,
	)

	const premiseThemeNode = rings.Premise.nodes.find(
		element => element.__typename === 'Theme' ,
	)
	
	const ringBuilder = (g,data,ringName) => {
		//rings[ringName] = {}
		rings[ringName].innerRingRadius = rings[ringName].order * (75) //+ Math.max((rings[ringName].order-1),0) * 50
		rings[ringName].outerRingRadius = rings[ringName].innerRingRadius + 75
		


		g.select(`.${ringName}Ring`).selectAll('*').remove()
		rings[ringName].ring = g.select(`.${ringName}Ring`).selectAll('g').data(data)
		rings[ringName].ring.exit().remove() 
		rings[ringName].ringElement = rings[ringName].ring.enter()
			.append('g')
			.attr('class', `${ringName}RingHolder ringHolder`)
			.style('cursor', 'pointer')
		
		
		
		const backgroundArc = arc()
		.innerRadius(rings[ringName].outerRingRadius - 5)
		.outerRadius(rings[ringName].outerRingRadius)
		.startAngle(0)
		.endAngle(Math.PI * 2)

		const mainArc = arc()
			.innerRadius(rings[ringName].outerRingRadius - 5)
			.outerRadius(rings[ringName].outerRingRadius)
			// .cornerRadius(10)
			.startAngle(0)
			.endAngle(function (d) {
				return (d.progress / 100) * Math.PI * 2
			})


		// rings[ringName].ringElement 
		// 	.append('circle')
		// 	.attr('class', `${ringName}RingBackground ringBackground`)
		// 	.attr('r', d => rings[ringName].outerRingRadius - 5)
		// 	.attr('fill','#f3f3f6')
			//.attr('fill','#f3f3f6')
			//.style('transition', 'stroke-width 0.2s')
			//.style('stroke', (ringName ==="Premise" ? '#FFC300' : nodeColors[ringName]))
			
		rings[ringName].ringElement 
			.append('circle')
			.attr('class', `${ringName}RingBackground ringBackground`)
			.attr('r', d => rings[ringName].outerRingRadius - 5)
			.attr('fill', (ringName ==="Premise" ? '#FFF4CF' : `${nodeColors[ringName]}80`))
		
		
		


		rings[ringName].ringElement
			.append('svg:image')
			.attr('class', `${ringName}OpenSummary ${ringName}SummaryHoverArea openSummary`)
			.attr('xlink:href', openSummaryIcon)
			.attr('x', rings[ringName].outerRingRadius - 35)
			.attr('y', -18)
			.attr('width', 77)
			.attr('height', 34)
			.attr('display', 'none')
			.style('cursor', 'pointer')

		rings[ringName].ringElement
			.append('svg:image')
			.attr('class', `${ringName}OpenSummary ${ringName}SummaryHoverArea openSummary`)
			.attr('xlink:href', openSummaryIcon)
			.attr('x', rings[ringName].outerRingRadius - 35)
			.attr('y', -18)
			// .attr('x', rings[ringName].outerRingRadius - 35)
			// .attr('y', 20)
			.attr('width', 77)
			.attr('height', 34)
			.attr('display', 'none')
			.style('cursor', 'pointer')
			.attr(
				'transform',
				d =>
					`rotate(${180}, ${
						0
					}, ${0})`,
			)
		
		

		if(ringName ==="Premise"){
			if (nodes.length && (!premiseCharacterNode || !premiseArcNode || !premiseThemeNode)) {
				rings[ringName].ringElement
					.append('svg:image')
					.attr('class',`${ringName}DragText ${ringName}SummaryHoverArea`)
					.attr('xlink:href', premiseDragText)
					.attr('x', -30)
					.attr('y', 15)
					.attr('width', 60)
					.attr('height', 60)
			}

			// rings[ringName].ringElement
			// .append('svg:image')
			// .attr('class', `${ringName}Ring`)
			// .attr('xlink:href', premiseRing)
			// .attr('x', -rings[ringName].outerRingRadius - 5)
			// .attr('y', -rings[ringName].outerRingRadius - 5)
			// .attr('width', 2 * rings[ringName].outerRingRadius - 0.5)
			// .attr('height', 2 * rings[ringName].outerRingRadius + 0.5)

			// rings[ringName].ringElement
			// .append('svg:text')
			// .attr('class',  `${ringName}CurrentStoryName ${ringName}SummaryHoverArea`)
			// .text(isSummaryCardVisible ? '' : currentStory?.name)
			// .attr('font-size', `8px`)
			// .attr('font-weight', '700')
			// .style('fill', '#C0C0C0')
			// .style('text-anchor', 'middle')
			// .attr('startOffset', '50%')
			// .attr('dy', -rings[ringName].outerRingRadius - 15)

			if (nodes.length && rings.Premise.nodes.length ===0) {
				rings[ringName].ringElement
					.append('svg:image')
					.attr('class',  `${ringName}ElementDrag ${ringName}SummaryHoverArea`)
					.attr('xlink:href', premiseElementDrag)
					.attr('x', -rings[ringName].outerRingRadius - 61)
					.attr('y', -12)
					.attr('width', 150)
					.attr('height', 30)
			}

			// if (rings.Character.nodes ==0 && !premiseCharacterNode) {
			// 	rings[ringName].ringElement
			// 		.append('svg:image')
			// 		.attr('class', `${ringName}RingCharacter`)
			// 		.attr('xlink:href', premiseCharacterIcon)
			// 		.attr('x', -rings[ringName].outerRingRadius + 57)
			// 		.attr('y', -rings[ringName].outerRingRadius + 35)
			// 		.attr('width', 32)
			// 		.attr('height', 32)
			// 		.attr('display', 'block')
			// 		.style('cursor', 'pointer')
			// 		.on('mouseover', function () {
			// 			select(this)
			// 				.attr('xlink:href', premiseHoverCharIcon)
			// 				.attr('width', 22)
			// 				.attr('height', 22)
			// 				.attr('x', -rings[ringName].outerRingRadius + 64)
			// 				.attr('y', -rings[ringName].outerRingRadius + 41)
			// 			//selectAll(`.${ringName}OpenSummary`).attr('display', 'none')
			// 			// select(`.${ringName}SupposedArc`)
			// 			// 	.attr('display', premiseElementEdges.length ? 'none' : 'block')
			// 			// 	.attr(
			// 			// 		'd',
			// 			// 		arc()({
			// 			// 			innerRadius: rings[ringName].outerRingRadius - 6,
			// 			// 			outerRadius: rings[ringName].outerRingRadius + 1,
			// 			// 			startAngle,
			// 			// 			endAngle: startAngle + progressToAngle((1/3),120),
			// 			// 		}),
			// 			// 	)
			// 		})
			// 		.on('mouseout', function () {
			// 			select(this)
			// 				.attr('xlink:href', premiseCharacterIcon)
			// 				.attr('x', -rings[ringName].outerRingRadius + 57)
			// 				.attr('y', -rings[ringName].outerRingRadius + 35)
			// 				.attr('width', 32)
			// 				.attr('height', 32)
			// 		// 	select(`.${ringName}SupposedArc`).attr('display', 'none')
			// 		 })
			// 		.on('click', async function () {
			// 			setCurrentElementPremise('Character')
			// 			const newNode = {
			// 				name: "Main Character",
			// 				elementType:"Character",
			// 				coreElement: "true"
			// 			}
			// 			await addNodeFunc(newNode)
			// 			createLog('Element Creation Attempted', `{"coreElement" : "true", "elementType":"Character"}`, 'StoryViz', null)
			// 		})
			// }
	
			// if (rings.Arc.nodes ==0 && !premiseArcNode) {
			// 	rings[ringName].ringElement
			// 		.append('svg:image')
			// 		.attr('class', `${ringName}RingArc`)
			// 		.attr('xlink:href', premiseArcIcon)
			// 		.attr('x', -rings[ringName].outerRingRadius + 32)
			// 		.attr('y', -rings[ringName].outerRingRadius + 70)
			// 		.attr('width', 32)
			// 		.attr('height', 32)
			// 		.style('cursor', 'pointer')
			// 		.on('mouseover', function () {
			// 			select(this)
			// 				.attr('xlink:href', premiseHoverArcIcon)
			// 				.attr('x', -rings[ringName].outerRingRadius + 39)
			// 				.attr('y', -rings[ringName].outerRingRadius + 76)
			// 				.attr('width', 22)
			// 				.attr('height', 22)
			// 			selectAll(`.${ringName}OpenSummary`).attr('display', 'none')
			// 			// select(`.${ringName}SupposedArc`)
			// 			// 	.attr('display', premiseElementEdges.length ? 'none' : 'block')
			// 			// 	.attr(
			// 			// 		'd',
			// 			// 		arc()({
			// 			// 			innerRadius: rings[ringName].outerRingRadius - 6,
			// 			// 			outerRadius: rings[ringName].outerRingRadius + 1,
			// 			// 			startAngle,
			// 			// 			endAngle: startAngle + progressToAngle((1/3),120),
			// 			// 		}),
			// 			// 	)
			// 		})
			// 		.on('mouseout', function () {
			// 			select(this)
			// 				.attr('xlink:href', premiseArcIcon)
			// 				.attr('x', -rings[ringName].outerRingRadius + 32)
			// 				.attr('y', -rings[ringName].outerRingRadius + 70)
			// 				.attr('width', 32)
			// 				.attr('height', 32)
			// 			// select(`.${ringName}SupposedArc`).attr('display', 'none')
			// 		})
			// 		.on('click', async function () {
			// 			setCurrentElementPremise('Arc')
			// 			const newNode = {
			// 				name: "Main Plot",
			// 				elementType:"Arc",
			// 				coreElement: "true"
			// 			}
			// 			await addNodeFunc(newNode)
			// 			createLog('Element Creation Attempted', `{"coreElement" : "true", "elementType":"Arc"}`, 'StoryViz', null)
			// 		})
			// }
	
			// if (rings.Theme.nodes ==0 && !premiseThemeNode) {
			// 	rings[ringName].ringElement
			// 		.append('svg:image')
			// 		.attr('class', `${ringName}RingTheme`)
			// 		.attr('xlink:href', premiseThemeIcon)
			// 		.attr('x', -rings[ringName].outerRingRadius + 82)
			// 		.attr('y', -rings[ringName].outerRingRadius + 70)
			// 		.attr('width', 32)
			// 		.attr('height', 32)
			// 		.style('cursor', 'pointer')
			// 		.on('mouseover', function () {
			// 			select(this)
			// 				.attr('xlink:href', premiseHoverThemeIcon)
			// 				.attr('x', -rings[ringName].outerRingRadius + 89)
			// 				.attr('y', -rings[ringName].outerRingRadius + 76)
			// 				.attr('width', 22)
			// 				.attr('height', 22)
			// 			selectAll(`.${ringName}OpenSummary`).attr('display', 'none')
			// 			// select(`.${ringName}SupposedArc`)
			// 			// 	.attr('display', premiseElementEdges.length ? 'none' : 'block')
			// 			// 	.attr(
			// 			// 		'd',
			// 			// 		arc()({
			// 			// 			innerRadius: rings[ringName].outerRingRadius - 6,
			// 			// 			outerRadius: rings[ringName].outerRingRadius + 1,
			// 			// 			startAngle,
			// 			// 			endAngle: startAngle + 0.711,
			// 			// 		}),
			// 			// 	)
	
	
			// 		})
			// 		.on('mouseout', function () {
			// 			select(this)
			// 				.attr('xlink:href', premiseThemeIcon)
			// 				.attr('x', -rings[ringName].outerRingRadius + 82)
			// 				.attr('y', -rings[ringName].outerRingRadius + 70)
			// 				.attr('width', 32)
			// 				.attr('height', 32)
			// 			// select(`.${ringName}SupposedArc`).attr('display', 'none')
			// 		})
			// 		.on('click', async function () {
			// 			setCurrentElementPremise('Theme')
			// 			const newNode = {
			// 				name: "Central Theme",
			// 				elementType:"Theme",
			// 				coreElement: "true"
			// 			}
			// 			await addNodeFunc(newNode)
			// 			createLog('Element Creation Attempted', `{"coreElement" : "true", "elementType":"Character"}`, 'StoryViz', null)
			// 		})
			// }
		} else{

			rings[ringName].ringElement
					.append('svg:image')
					.attr('class', `${ringName}RingCharacter`)
					.attr('xlink:href', premiseCharacterIcon)
					.attr('x', -rings[ringName].outerRingRadius + 10)
					.attr('y', - 16)
					.attr('width', 32)
					.attr('height', 32)
					.attr('display', 'block')
					.style('cursor', 'pointer')
					.on('mouseover', function () {
						select(this)
							.attr('xlink:href', premiseHoverCharIcon)
							.attr('width', 22)
							.attr('height', 22)
							.attr('y', - 11)
							.attr('x', -rings[ringName].outerRingRadius + 15)
							
						selectAll(`.${ringName}OpenSummary`).attr('display', 'none')
						// select(`.${ringName}SupposedArc`)
						// 	.attr('display', premiseElementEdges.length ? 'none' : 'block')
						// 	.attr(
						// 		'd',
						// 		arc()({
						// 			innerRadius: rings[ringName].outerRingRadius - 6,
						// 			outerRadius: rings[ringName].outerRingRadius + 1,
						// 			startAngle,
						// 			endAngle: startAngle + 0.711,
						// 		}),
						// 	)
					})
					.on('mouseout', function () {
						select(this)
							.attr('xlink:href', premiseCharacterIcon)
							.attr('y', -16)
							.attr('width', 32)
							.attr('height', 32)
					})
					.on('click', async function () {
						setCurrentElementPremise(ringName)
						const newNode ={
							elementType:ringName,
							name:`New ${ringName}`,
							coreElement:"false"
						}
						await addNodeFunc(newNode)
						createLog('Element Creation Attempted', `{"coreElement" : "false", "elementType":"${ringName}"}`, 'StoryViz', null)
					})
		}
		

		rings[ringName].ringElement
			.append('path')
			.attr('class', `${ringName}SummaryHoverArea`)
			.attr('d', backgroundArc)
			.attr('fill', (ringName ==="Premise" ? '#FFF4CF' :`${nodeColors[ringName]}80`))

		rings[ringName].ringElement
			.append('path')
			.attr('class', `${ringName}SummaryHoverArea`)
			.attr('d', mainArc)
			.attr('fill', (ringName ==="Premise" ? '#FFC300' : nodeColors[ringName]))

		// rings[ringName].ringElement
		// 	.append('path')
			
		// 	.attr('class', `${ringName}SupposedArc ${ringName}SummaryHoverArea`)
		// 	.attr(
		// 		'd',
		// 		arc()({
		// 			innerRadius: rings[ringName].outerRingRadius - 6,
		// 			outerRadius: rings[ringName].outerRingRadius + 1,
		// 			startAngle,
		// 			endAngle: 0,
		// 		}),
		// 	)
		// 	.attr('fill', '#FFF4CF')
		// 	.attr('filter', 'drop-shadow(0px 0px 5px #FFC300)')
		// 	.attr('display', 'none')

		

		


		rings[ringName].ringElement
			.append('svg:text')
			.attr('class',  `${ringName}Text ${ringName}SummaryHoverArea`)
			.text(ringName)
			.attr('font-size', `8px`)
			.attr('font-weight', '700')
			.style('fill', '#C0C0C0')
			.style('text-anchor', 'middle')
			.attr('startOffset', '50%')
			.attr('dy', -rings[ringName].outerRingRadius - 8)


		rings[ringName].ringElement
			.append('svg:text')
			.attr('class', `${ringName}ProgressPercentage ${ringName}SummaryHoverArea`)
			.text(
				isSummaryCardVisible ===  ringName
					? ''
					: `${
							!Number.isNaN(summaryCardProgressData.totalProgressSummaryCard.current) ? Math.round(summaryCardProgressData.totalProgressSummaryCard.current) : 0
					}% Complete`,
			)
			.attr('font-size', `8px`)
			.attr('font-weight', '700')
			.style('fill', '#C0C0C0')
			.style('text-anchor', 'middle')
			.attr('startOffset', '50%')
			.attr('display', summaryCardProgressData.totalProgressSummaryCard.current === 100 ? 'none' : 'block')
			.attr('dy', rings[ringName].outerRingRadius + 10)

		
		


		//ring.on('mouseover', function () {}).on('mouseout', function () {})

		

		rings[ringName].ring = rings[ringName].ringElement.merge(rings[ringName].ring)

	}




	ringBuilder(g,data,'Premise')
	// ringBuilder(g,data,'Character')
	// ringBuilder(g,data,'Theme')
	// ringBuilder(g,data,'Setting')
	// ringBuilder(g,data,'Event')
	// ringBuilder(g,data,'Arc')
	
	

	// LINKS
	// Link data bind
	// TODO rename all references to links to edges
	const linkedByIndex = {}
	edges.forEach(d => {
		linkedByIndex[`${d.source?.id},${d.target?.id}`] = 1
		linkedByIndex[`${d.target?.id},${d.source?.id}`] = 1
	})
	//average_connections_size/
	const linkBuilder = (g,data,ringName) => {

		g.select(`.${ringName}Links`).selectAll('*').remove()
		rings[ringName].link = g
		.select(`.${ringName}Links`)
		.selectAll('g')
		.data(data, d => `${d.source}|${d.target}`)
		// Link exit
		rings[ringName].link.exit().remove()

		// Link enter

		rings[ringName].linkEnter = rings[ringName].link.enter().append('g').attr('class', 'lineHolder')

		rings[ringName].linkEnter
		.append('path')
		.attr('class', 'lineClass')
		.attr('id', d => `path-${d.id}`)
		.style('stroke-width', '0.5')
		.style('stroke', d => (d?.type?.includes('Static') ? '#000000' : '#FFC300'))
		.style('stroke-dasharray', d =>
			d?.type.includes('Static') && d?.beatsDriven > 0 ? '0, 0' : '3, 3',
		)

		rings[ringName].linkEnter
		.append('rect')
		.attr('id', d => `rect-${d.id}`)
		.attr('width', 10)
		.attr('height', 1)
		.attr('class', 'structureTagIcon structureTagIconBackground')
		.style('fill', '#f3f3f6')

		rings[ringName].linkEnter
		.append('svg:text')
		.attr('id', d => `structureTag-${d.id}`)
		.attr('class', 'structureTag')
		// .attr('dy', d =>  d.source.x === d.target.x ? -5 : 5 )
		.attr('font-size', d => `6px`)
		.style('text-anchor', 'middle')
		.attr('startOffset', '150%')
		.text(function (d) {
			return d.structureTag
		})
		.style('display', edges.length >= 3 ? 'none' : 'block')

		rings[ringName].linkEnter
		.append('svg:image')
		.attr('class', 'connLineInfo')
		.attr('xlink:href', connectionLineInfo)
		.attr('x', -190)
		.attr('y', 60)
		.attr('width', 134)
		.attr('height', 20)
		.attr('display', 'none')

		rings[ringName].linkEnter
		.append('rect')
		.attr('class', 'lineRectangle')
		.style('fill', 'transparent')
		.style('cursor', 'pointer')

		rings[ringName].linkEnter
		.append('rect')
		.attr('class', 'lineRectangle2')
		.style('fill', 'transparent')
		.style('cursor', 'pointer')

		rings[ringName].linkEnter
			.append('svg:image')
			.attr('id', d => `structureTagIcon-${d.id}`)
			.attr('class', 'structureTagIcon')
			.attr('xlink:href', d =>
				supportingStructureTags.includes(d.structureTag)
					? supportingIcon
					: opposingStructureTags.includes(d.structureTag)
					? opposingIcon
					: missingStructureTagIcon,
			)
			.attr('width', 10)
			.attr('height', 10)
			.on('mouseenter', function (d) {
				if (!d.structureTag) {
					setVisualizationToolitip({
						isOpen: true,
						type: 'missingStructureTag',
						anchorEl: event?.srcElement,
					})
				}
			})
			.on('mouseleave', function () {
				setVisualizationToolitip({
					isOpen: false,
					type: null,
					anchorEl: null,
				})
			})

		// Link enter + update
	rings[ringName].link = rings[ringName].linkEnter.merge(rings[ringName].link)

	rings[ringName].link
		.on('mouseover', function (d) {
			select(this)
				.select('.lineClass')
				.style(
					'stroke-dasharray',
					d.source.coreElement === 'true' && d.target.coreElement === 'true' && '0, 0',
				)
			select('.connLineInfo').attr(
				'display',
				d.source.coreElement === 'true' && d.target.coreElement === 'true' && d.beatsDriven === '0'
					? 'block'
					: 'none',
			)
			// select(`.${ringName}SupposedArc`)
			// 	.attr(
			// 		'display',
			// 		d.source.coreElement === 'true' &&
			// 			d.target.coreElement === 'true' &&
			// 			d.beatsDriven === '0'
			// 			? 'block'
			// 			: 'none',
			// 	)
				// .attr(
				// 	'd',
				// 	arc()({
				// 		innerRadius: rings[ringName].outerRingRadius - 6,
				// 		outerRadius: rings[ringName].outerRingRadius + 1,
				// 		startAngle,
				// 		endAngle: startAngle + totalMissingBeatSupposeArc,
				// 	}),
				// )
			createLog(
					'Relationship Hover',
					`{"structureTag": "${d.structureTag}", "ring":"${ringName}"}`,
					'StoryViz',
					null,
				)
		})
		.on('mouseout', function () {
			select(this)
				.select('.lineClass')
				.style('stroke-dasharray', d =>
					d?.type.includes('Static') && d?.beatsDriven > 0 ? '0, 0' : '3, 3',
				)
			select('.connLineInfo').attr('display', 'none')
			select(`.${ringName}SupposedArc`).attr('display', 'none')
		})
		.on('click', function (values, id) {
			if (data[id] && data[id].structureTag) {
				handleDriverConnections('connectionCard', data[id]?.id, ringName)
			} else {
				
				handleDriverConnections('missingStructureTagConnection', data[id]?.id, ringName)
			}
			createLog(
				'Relationship Clicked',
				`{"structureTag": "${data[id].structureTag}", "ring":"${ringName}"}`,
				'StoryViz',
				null,
			)
		})

	}
	
	linkBuilder(g,rings["Premise"].connections,"Premise")
	linkBuilder(g,rings["Character"].connections,"Character")
	linkBuilder(g,rings["Theme"].connections,"Theme")
	linkBuilder(g,rings["Setting"].connections,"Setting")
	linkBuilder(g,rings["Event"].connections,"Event")
	linkBuilder(g,rings["Arc"].connections,"Arc")
	
	const missingLinkBuilder =(g,data,ringName) => {

		// Missing-links Connections
		g.select(`.${ringName}MissingLinks`).selectAll('*').remove()

		

		rings[ringName].missingLink = g
			.select(`.${ringName}MissingLinks`)
			.selectAll('g')
			.data(data, d => `${d.source}|${d.target}`)

		rings[ringName].missingLink.exit().remove()

		rings[ringName].missingLinkEnter = rings[ringName].missingLink.enter().append('g').attr('class', `${ringName}MissingLineHolder`)

		rings[ringName].missingLinkEnter
			.append('path')
			.attr('class', 'missingLineClass')
			.style('stroke-width', '0.8')
			.style('stroke', '#FFC300')
			.style('stroke-dasharray', '3, 3')
			.style('filter', 'drop-shadow(0px 0px 5px #FFC300)')

		rings[ringName].missingLinkEnter
			.append('svg:image')
			.attr('class', 'missingConnLineInfo')
			.attr('xlink:href', missingConnLineInfo)
			.attr('width', 134)
			.attr('height', 20)
			.attr('x', -190)
			.attr('y', 60)
			.attr('display', 'none')

		rings[ringName].missingLinkEnter
			.append('rect')
			.attr('class', 'missingLineRectangle')
			.style('fill', 'transparent')
			.style('cursor', 'pointer')

		rings[ringName].missingLinkEnter
			.append('rect')
			.attr('class', 'missingLineRectangle2')
			.style('fill', 'transparent')
			.style('cursor', 'pointer')

		rings[ringName].missingLink = rings[ringName].missingLinkEnter.merge(
			rings[ringName].missingLink,
		)
		rings[ringName].missingLinkEnter
			.append('svg:image')
			.attr('class', 'missingPremiseConnection')
			.attr('xlink:href', missingPremiseIcon)
			.attr('width', 10)
			.attr('height', 10)
			.on('mouseenter', function () {
				setVisualizationToolitip({
					isOpen: true,
					type: 'premise',
					anchorEl: event?.srcElement,
				})
			})
			.on('mouseleave', function () {
				setVisualizationToolitip({
					isOpen: false,
					type: null,
					anchorEl: null,
				})
			})

		rings[ringName].missingLink
			.on('mouseover', function () {
				select(this).select('.missingLineClass').style('stroke', '#000000')
				select('.missingConnLineInfo').attr('display', 'block')
				// select(`.${ringName}SupposedArc`)
				// 	.attr('display', 'block')
					// .attr(
					// 	'd',
					// 	arc()({
					// 		innerRadius: rings[ringName].outerRingRadius - 6,
					// 		outerRadius: rings[ringName].outerRingRadius + 1,
					// 		startAngle,
					// 		endAngle: startAngle + totalMissingConnectionSupposedArc,
					// 	}),
					// )
				createLog(
					'Relationship Hover',
					`{"structureTag": "Missing Premise", "ring":"${ringName}"}`,
					'StoryViz',
					null,
				)
			})
			.on('mouseout', function () {
				select(this).select('.missingLineClass').style('stroke', '#FFC300')
				select('.missingConnLineInfo').attr('display', 'none')
				// select(`.${ringName}SupposedArc`).attr('display', 'none')
			})
			.on('click', function (values, id) {
				handleDriverConnections('missingPremiseConnection', data[id], ringName)
				// createLog(
				// 	'Relationship Clicked',
				// 	`{"structureTag": "Missing Premise", "ring":"${ringName}"}`,
				// 	'StoryViz',
				// 	null,
				// )
				// createLog(
				// 	'Connection Creation Attempted',
				// 	{"workflowStep":1,"connectionType":"Static", "connectionModalType":"Supporting"
				// 	},
				// 	'StoryViz',
				// 	'Connection Creation',
				// )
			})
	}

	missingLinkBuilder(g,missingConnections,"Premise")

	// missingLinkBuilder(g,[],"Character")
	// missingLinkBuilder(g,[],"Theme")
	// missingLinkBuilder(g,[],"Event")
	// missingLinkBuilder(g,[],"Arc")
	
	const linkColor = (structureTag, style, element) => {
		let output = '#000'

		if (style === 'stroke' && element === 'path') {
			if (supportingStructureTags.includes(structureTag)) {
				output = '#4DBB5E'
			} else if (opposingStructureTags.includes(structureTag)) {
				output = '#D00'
			}
		} else if (style === 'fill' && element === '.structureTag') {
			if (supportingStructureTags.includes(structureTag)) {
				output = '#1D902F'
			} else if (opposingStructureTags.includes(structureTag)) {
				output = '#970000'
			}
		} else if (style === 'xlink:href' && element === '.structureTagIcon') {
			output = null
			if (supportingStructureTags.includes(structureTag)) {
				output = suppotingTagIcon
			} else if (opposingStructureTags.includes(structureTag)) {
				output = opposingTagIcon
			} else {
				output = missingStructureTagIcon
			}
		}
		return output
	}

	// const linkStrokeColor = (structureTag) => {
	// 	let output = '#000'
	// 	if(	supportingStructureTags.includes(structureTag)) {
	// 		output = '#4DBB5E'
	// 	} else if(opposingStructureTags.includes(structureTag)){
	// 		output = '#D00'
	// 	}
	// 		return output
	// }

	

	// NODES: Each node has an svg group containing a circle + a text label
	// Node group data bind

	nodes?.sort((a, b) => {
		return b.connections && a.connections ? b?.connections?.length - a?.connections?.length : null
	})

	const rankSummary = { 1: 0 }
	let rank = 1
	for (let i = 0; i < nodes?.length; i++) {
		// increase rank only if current score less than previous
		if (i > 0 && nodes[i]?.connections?.length < nodes[i - 1]?.connections?.length) {
			rank++
		}
		nodes[i].rank = rank
		rankSummary[`${rank}`] = (rankSummary[`${rank}`] || 0) + 1

		if (nodes[i].vizLocSaved) {
			//nodes[i].clicked = true
			//nodes[i].drag = true
			nodes[i].fx = parseFloat(nodes[i].xCoordinate)
			nodes[i].fy = parseFloat(nodes[i].yCoordinate)
			//nodes[i].x = parseFloat(nodes[i].xCoordinate)
			//nodes[i].y = parseFloat(nodes[i].yCoordinate)
		} else {
			nodes[i].x = parseFloat(nodes[i].xCoordinate)
			nodes[i].y = parseFloat(nodes[i].yCoordinate)
		}
		nodes[i].clicked = selectedElementRef.current?.id === nodes[i].id
	}

	const parent = svg.node().parentElement
	const fullWidth = parent?.clientWidth || parent?.parentNode?.clientWidth || 1
	const fullHeight = parent?.clientHeight || parent?.parentNode?.clientHeight || 1
	const rankLength = Object.values(rankSummary).length
	const rank_mid = (rankLength + 1) / 2

	g.select('.nodes').selectAll('*').remove()

	let node = g
		?.select('.nodes')
		?.selectAll('g')
		?.data(nodes, d => d?.id)

	const nodeSize = d => {
		let size = 8
		if (d?.driverConnections?.length) {
			size = Math.min(7 + 1 * d?.driverConnections?.length, 16)
		}
		return size

		//d?.connections?.length ? 8 : 4
		//Math.min(4 + 1 * d?.connections?.length, 15)
	}
	const iconSize = d => {
		const circleRadius = nodeSize(d)

		const squareLength = circleRadius / Math.sqrt(2)
		return squareLength * 2
	}

	// Node group exit
	node.exit().remove()

	// Node group enter
	const nodeEnter = node.enter().append('g').attr('class', d => (`node`) )

	// Circle enter
	nodeEnter
		.append('g')
		.attr('class', d => (`element ${d.__typename} ringLevel-${d.coreElement}`) )
		//.style('cursor', 'grab')
		.append('circle')
		.attr('r', d => nodeSize(d))
		.attr('fill', d => (isArcComplete(d) ? nodeColors[d.__typename] : nodeColors[d.__typename]))
		.style('transition', 'stroke-width 0.2s')
		.style('stroke', d => (isArcComplete(d) ? nodeColors[d.__typename] : nodeColors[d.__typename]))
		

	// Label enter
	nodeEnter
		.append('text')
		.attr('class', 'node-name')
		.text(d =>
			 vizTitleLengthFormat(
						d.__typename === 'Chapter'
							? `Ch ${d.number || 0}: ${d.name}`
							: d.__typename === 'Note'
							? 'Note'
							: d.name,
				  )
		)
		.style('display', d => (d.coreElement === 'true' || nodes.length <=10 ? 'block' : 'none'))
		.attr('x', d => iconSize(d) / 2)
		.attr('y', d => -Math.max(iconSize(d), 8) / 2)
		.attr('font-size', d => `${0.75}em`)

	//Node Icon enter

	nodeEnter
		.select('.element')
		.append('svg:image')
		.attr('xlink:href', d => getNodeIcon(d.__typename))
		.attr('x', d => -iconSize(d) / 2)
		.attr('y', d => -iconSize(d) / 2)
		.attr('width', d => iconSize(d))
		.attr('height', d => iconSize(d))

	nodeEnter
		.select('.element')
		.append('g')
		.attr('class', 'beat-count-button count-button')
		.style('display', 'none')
		.style('overflow', 'visible')
		.style('cursor', d =>
			['Arc', 'Character', 'Event', 'Theme', 'Chapter'].includes(d.__typename)
				? 'pointer'
				: 'default',
		)
	

	nodeEnter
		.select('.beat-count-button')
		.append('svg:image')
		.attr('xlink:href', leftHalfCircle)
		.style('width', d => 3 * nodeSize(d))
		.style('height', d => 5 * nodeSize(d))
		.style('transform', d => `translate(${-2.75 * nodeSize(d)}px, -${2.5 * nodeSize(d)}px)`)

	nodeEnter
		.select('.beat-count-button')
		.append('path')
		.attr('id', d => d.id)
		.attr('fill', 'none')
		.attr('d', 'M30,50 Q0,30 30,10')

	nodeEnter
		.select('.beat-count-button')
		.append('text')
		.style(
			'transform',
			d => `translate(${-2.5 * nodeSize(d)}px, ${-2 * nodeSize(d)}px) scale(${nodeSize(d) / 15})`,
		)
		.append('textPath')
		.attr('xlink:href', d => `#${d.id}`)
		.text(
			d =>
				`${
					(d?.__typename === 'Chapter'
						? d?.beatConnections?.length
						: d?.driverConnections?.length) || 0
				} Beats`,
		)
		.style('font-size', d =>
			((d?.__typename === 'Chapter' ? d?.beatConnections?.length : d?.driverConnections?.length) ||
				0) < 10
				? '12px'
				: '11px',
		)
		.style('font-weight', '600')
		.style('letter-spacing', d =>
			((d?.__typename === 'Chapter' ? d?.beatConnections?.length : d?.driverConnections?.length) ||
				0) < 100
				? '1px'
				: 'unset',
		)

	nodeEnter
		.select('.element')
		.append('g')
		.attr('class', 'connection-count-button count-button')
		.style('display', 'none')
		.style('overflow', 'visible')
	
	nodeEnter
		.select('.connection-count-button')
		.append('svg:image')
		.attr('xlink:href', rightHalfCircle)
		.style('width', d => 3 * nodeSize(d))
		.style('height', d => 5 * nodeSize(d))
		.style('transform', d => `translate(-${nodeSize(d) / 100}px, ${-2.5 * nodeSize(d)}px)`)

	nodeEnter
		.select('.connection-count-button')
		.append('path')
		.attr('id', d => `right-${d.id}`)
		.attr('fill', 'none')
		.attr('d', 'M20,4 Q39,11 39,29 Q37,44 20,49')

	nodeEnter
		.select('.connection-count-button')
		.append('text')
		.style(
			'transform',
			d => `translate(${-nodeSize(d)}px, ${-1.7 * nodeSize(d)}px) scale(${nodeSize(d) / 15})`,
		)
		.append('textPath')
		.attr('xlink:href', d => `#right-${d.id}`)
		.text(d => `${d?.staticConnections?.length || 0} Connections`)
		.style('font-size', '8.5px')
		.style('letter-spacing', d =>
			(d?.staticConnections?.length || 0) < 10
				? '0.8px'
				: (d?.staticConnections?.length || 0) < 100
				? '0.4px'
				: 'unset',
		)
		.style('fill', '#FFFFFF')
		.style('font-weight', '600')

	// Enter + update on node group
	node = nodeEnter.merge(node)
	const nodeByConnectionType = getRelationsByConnectionType(client)

	const supportingRel = new Set()
	const opposingRel = new Set()
	const undefinedRel = new Set()

	const suppotingRelations = nodeByConnectionType.Supporting
	suppotingRelations?.forEach(d1 => {
		supportingRel.add(d1.sourceNode?.__ref.split(':')[1])
		supportingRel.add(d1.destNode?.__ref.split(':')[1])
	})

	const opposingRelations = nodeByConnectionType.Opposing
	opposingRelations?.forEach(d1 => {
		opposingRel.add(d1.sourceNode?.__ref.split(':')[1])
		opposingRel.add(d1.destNode?.__ref.split(':')[1])
	})

	const undefinedRelations = nodeByConnectionType.Undefined
	undefinedRelations?.forEach(d1 => {
		undefinedRel.add(d1.sourceNode?.__ref.split(':')[1])
		undefinedRel.add(d1.destNode?.__ref.split(':')[1])
	})
	//let globalDrag = false
	
	let globalCoreElementChange = false

	//const nodesInRing = rings[isSummaryCardVisible]?.nodes || []
	
	// node.style('opacity', o => {
	// 	return ( isSummaryCardVisible === o.ring || (activeConnections.appView === "Outlining" && o.__typename === isSummaryCardVisible ) )? 1 : 0.2})


	const nodesInRing = rings["Premise"]?.nodes || []

	if(activeConnections.appView === "Premise"  ){
		//selectedElementRef.current = null
		node.style('opacity', o => {
			//return (neighboring(nodesInRing, o, true) || isSummaryCardVisible === o.ring )? 1 : 0.2})
			return (activeConnections.appView === o.ring )? 1 : 0.2
		})
	} else if (activeConnections.appView === "Outlining" ) {
		node.style('opacity', o => {

			return ("Premise" !== o.ring )? 1 : 0.2
			
		})
	} 
	
	// else if (isSummaryCardVisible) {
	// 	node.style('opacity', o => {

	// 		return (neighboring(nodesInRing, o, true) || isSummaryCardVisible === o.ring )? 1 : 0.2
			
	// 	})
	// }
	
	Object.keys(rings).forEach((ringName)=> {
		//if(isSummaryCardVisible === ringName )

		if(activeConnections.appView === "Premise" ){

		
			//rings[ringName].ringElement?.selectAll(`.${ringName}SummaryHoverArea`).on('mouseover', function () {}).on('mouseout', function () {}).on('click', function () {})
			
			rings[ringName].ringElement?.selectAll(`.${ringName}RingBackground`)
				//.attr('fill', (ringName ==="Premise" ? '#FFF4CF' : `${nodeColors[ringName]}80`))
				.style('display', 'block')
			


			rings[ringName].link
				?.style('opacity', o => (isNeighboringEdge(nodesInRing, o, true, true) ? 1 : 0.1))
				.selectAll('path')
				.style('stroke', o => (isNeighboringEdge(nodesInRing, o, true, true) ? linkColor(o.structureTag, 'stroke', 'path') : '#000'))


			

				
			rings[ringName].link
				?.selectAll('.structureTag')
				.style('display', o => (isNeighboringEdge(nodesInRing, o, true, true)  ? 'block' : 'none'))
				.style('fill', o =>
					isNeighboringEdge(nodesInRing, o, true, true)  ? linkColor(o.structureTag, 'fill', '.structureTag') : '#000',
				)
			rings[ringName].link
				?.selectAll('.structureTagIcon')
				.attr('xlink:href', o =>
					isNeighboringEdge(nodesInRing, o, true, true) 
						? linkColor(o.structureTag, 'xlink:href', '.structureTagIcon')
						: null,
				)
				.style('display', o => (isNeighboringEdge(nodesInRing, o, true, true)  ? 'block' : 'none'))


		
	
		}
		else {

			if(ringName !== "Premise"){

				rings[ringName].link
					?.style('opacity', 1)
					.selectAll('.structureTag')
					.style('display', o => (edges.length < 10 ? 'block' : 'none'))
					.style('fill', '#000')
				
				rings[ringName].link
					?.selectAll('path').style('stroke', '#000')
				
				rings[ringName].link
					?.selectAll('.structureTagIcon')
					.attr('xlink:href', d =>
						supportingStructureTags.includes(d.structureTag)
							? supportingIcon
							: opposingStructureTags.includes(d.structureTag)
							? opposingIcon
							: missingStructureTagIcon,
					)
					.style('display', 'block')
	
				rings[ringName].ringElement
					?.selectAll(`.${ringName}RingBackground`)
					.style('display', 'none')


			} 
			else if(ringName === "Premise"){
				rings[ringName].ringElement?.selectAll(`.${ringName}RingBackground`)
				.style('display', 'none')

				rings[ringName].link
				?.style('opacity', o => (isNeighboringEdge(nodesInRing, o, true) ? 0.1 : 1))
				.selectAll('path')
				.style('stroke', o =>
					isNeighboringEdge(nodesInRing, o, true) ? '#000' : linkColor(o.structureTag, 'stroke', 'path'),
				)
				rings[ringName].link
					?.selectAll('.structureTag')
					.style('display', o => (isNeighboringEdge(nodesInRing, o, true)  ? 'none' : 'block'))
					.style('fill', o =>
						isNeighboringEdge(nodesInRing, o, true)  ? '#000' : linkColor(o.structureTag, 'fill', '.structureTag'),
					)
				rings[ringName].link
					?.selectAll('.structureTagIcon')
					.attr('xlink:href', o =>
						isNeighboringEdge(nodesInRing, o, true) 
							? null
							: linkColor(o.structureTag, 'xlink:href', '.structureTagIcon'),
					)
					.style('display', o => (isNeighboringEdge(nodesInRing, o, true)  ? 'none'  : 'block'))

				

				

			}

			
			
			// if(ringName !== "Premise"){

			// 	rings[ringName].link
			// 		?.style('opacity', 1)
			// 		.selectAll('.structureTag')
			// 		.style('display', o => (edges.length < 10 ? 'block' : 'none'))
			// 		.style('fill', '#000')
				
			// 	rings[ringName].link
			// 		?.selectAll('path').style('stroke', '#000')
				
			// 	rings[ringName].link
			// 		?.selectAll('.structureTagIcon')
			// 		.attr('xlink:href', d =>
			// 			supportingStructureTags.includes(d.structureTag)
			// 				? supportingIcon
			// 				: opposingStructureTags.includes(d.structureTag)
			// 				? opposingIcon
			// 				: missingStructureTagIcon,
			// 		)
			// 		.style('display', 'block')
	
			// 	rings[ringName].ringElement
			// 		?.selectAll(`.${ringName}RingBackground`)
			// 		.style('display', 'none')


			// } 
			// else if(ringName === "Premise"){
			// 	rings[ringName].ringElement?.selectAll(`.${ringName}RingBackground`)
			// 	.style('display', 'none')

			// 	rings[ringName].link
			// 	?.style('opacity', o => (isNeighboringEdge(nodesInRing, o, true) ? 0.1 : 1))
			// 	.selectAll('path')
			// 	.style('stroke', o =>
			// 		isNeighboringEdge(nodesInRing, o, true) ? '#000' : linkColor(o.structureTag, 'stroke', 'path'),
			// 	)
			// 	rings[ringName].link
			// 		?.selectAll('.structureTag')
			// 		.style('display', o => (isNeighboringEdge(nodesInRing, o, true)  ? 'none' : 'block'))
			// 		.style('fill', o =>
			// 			isNeighboringEdge(nodesInRing, o, true)  ? '#000' : linkColor(o.structureTag, 'fill', '.structureTag'),
			// 		)
			// 	rings[ringName].link
			// 		?.selectAll('.structureTagIcon')
			// 		.attr('xlink:href', o =>
			// 			isNeighboringEdge(nodesInRing, o, true) 
			// 				? null
			// 				: linkColor(o.structureTag, 'xlink:href', '.structureTagIcon'),
			// 		)
			// 		.style('display', o => (isNeighboringEdge(nodesInRing, o, true)  ? 'none'  : 'block'))

			// 	rings[ringName].ringElement?.selectAll(`.${ringName}RingBackground`)
			// 		.style('display', 'none')

				
			// }




			rings[ringName].ringElement?.selectAll(`.${ringName}SummaryHoverArea`).on('click', function () {
				//systemDataDispatch(setSummaryCardVisible(ringName))
				
				sharedDispatch(
					setActiveConnections({
						isActive: true,
						elementId: null,
						elementType: null,
						docType: null,
						appView: ringName //ringName === "Premise"? "Premise" : "Outlining",
					}),
				)

				//createLog('Visualization Summary Card Opened', `{"ring":"${ringName}"}`, 'StoryViz', 'Opened Summary Card')
	
			})
			.on('mouseover', function (d) {
				selectAll(`.${ringName}OpenSummary`).attr('display', 'block')
				if(rings[ringName].summaryCardHoveringTimeout){
					clearTimeout(rings[ringName].summaryCardHoveringTimeout);
				}
				
			})
			.on('mouseout', function (d) {
				
				rings[ringName].summaryCardHoveringTimeout = setTimeout(() => selectAll(`.${ringName}OpenSummary`).attr('display', 'none'), 500 )
				
			})
		} 
		
		if( ringName === isSummaryCardVisible){
			rings[ringName].ringElement?.selectAll(`.${ringName}SummaryHoverArea`).on('click', function () {
				// //systemDataDispatch(setSummaryCardVisible(ringName))
				// sharedDispatch(
				// 	setActiveConnections({
				// 		isActive: true,
				// 		elementId: null,
				// 		elementType: null,
				// 		docType: null,
				// 		appView: ringName === "Premise" ? "Premise" : "Outlining",
				// 	}),
				// )

				// //createLog('Visualization Summary Card Opened', `{"ring":"${ringName}"}`, 'StoryViz', 'Opened Summary Card')
	
			})
			.on('mouseover', function (d) {
				// selectAll(`.${ringName}OpenSummary`).attr('display', 'block')
				// if(rings[ringName].summaryCardHoveringTimeout){
				// 	clearTimeout(rings[ringName].summaryCardHoveringTimeout);
				// }
				
			})
			.on('mouseout', function (d) {
				
				// rings[ringName].summaryCardHoveringTimeout = setTimeout(() => selectAll(`.${ringName}OpenSummary`).attr('display', 'none'), 500 )
				
			})
		}


		if (isSupporting) {
			
			node.style('opacity', d => (supportingRel.has(d.id) ? 1 : 0.2))


			rings[ringName].link
				?.style('opacity', d => (supportingStructureTags.includes(d.structureTag) ? 1 : 0.1))
				.selectAll('path')
				.style('stroke', d =>  supportingStructureTags.includes(d.structureTag) ? linkColor(d.structureTag, 'stroke', 'path') : '#000',)

			
			rings[ringName].link
				?.selectAll('.structureTag')
				.style('fill', d => (supportingStructureTags.includes(d.structureTag) ? linkColor(d.structureTag, 'fill', '.structureTag') : '#000'))
				.style('display', d => (supportingStructureTags.includes(d.structureTag) ? 'block' : 'none'))

			rings[ringName].link
				?.selectAll('.structureTagIcon')
				.attr('xlink:href', d =>
					supportingStructureTags.includes(d.structureTag)
						? suppotingTagIcon
						: missingStructureTagIcon,
				)

			rings[ringName].ringElement?.selectAll(`.${ringName}RingBackground`)
				.style('display', (isSummaryCardVisible === ringName ? "block" : "none"))
		} 
		else if (isOpposing) {
			node.style('opacity', d => (opposingRel.has(d.id) ? 1 : 0.2))
			rings[ringName].link
				?.style('opacity', d => (opposingStructureTags.includes(d.structureTag) ? 1 : 0.1))
				.selectAll('path')
				.style('stroke', d => (opposingStructureTags.includes(d.structureTag) ?  linkColor(d.structureTag, 'stroke', 'path') : '#000'))
				
			rings[ringName].link
				?.selectAll('.structureTagIcon')
				.attr('xlink:href', d =>
					opposingStructureTags.includes(d.structureTag) ? opposingTagIcon : null,
				)
			rings[ringName].link
				?.selectAll('.structureTag')
				.attr('fill', d => (opposingStructureTags.includes(d.structureTag) ?  linkColor(d.structureTag, 'stroke', 'path') : '#000000'))
				.style('display', d => (opposingStructureTags.includes(d.structureTag) ? 'block' : 'none'))

			rings[ringName].ringElement?.selectAll(`.${ringName}RingBackground`)
				.style('display', (isSummaryCardVisible === ringName ? "block" : "none"))
		} 
		else if (isUndefined) {
			node.style('opacity', d => (undefinedRel.has(d.id) ? 1 : 0.2))
			rings[ringName].link
				?.style('opacity', d =>
						!d.structureTag &&
						d.type === 'Static'
							? 1
							: 0.1,
					)
				.selectAll('path')
				
			rings[ringName].link
				?.selectAll('.structureTagIcon')
				
			rings[ringName].link
				?.selectAll('.structureTag')
				
				.style('display', d =>
					!opposingStructureTags.includes(d.structureTag) &&
					!supportingStructureTags.includes(d.structureTag)
						? 'none'
						: 'block',
				)

			rings[ringName].ringElement?.selectAll(`.${ringName}RingBackground`)
				.style('display', (isSummaryCardVisible === ringName ? "block" : "none"))

		} else if (selectedElementRef.current ){
			const d = selectedElementRef.current
			
			node.style('opacity', o => (neighboring(d, o) || o.id===d.id ? 1 : 0.2))
			select(this).select('circle').style('filter', 'unset')

			Object.keys(rings).forEach((ringName) => {
	
				rings[ringName].link
					?.style('opacity', o => (isNeighboringEdge(d, o) ? 1 : 0.1))
					.selectAll('path')
					.style('stroke', o =>
						isNeighboringEdge(d, o) ? linkColor(o.structureTag, 'stroke', 'path') : '#000',
					)
				rings[ringName].link
					?.selectAll('.structureTag')
					.style('display', o => (isNeighboringEdge(d, o) ? 'block' : 'none'))
					.style('fill', o =>
						isNeighboringEdge(d, o) ? linkColor(o.structureTag, 'fill', '.structureTag') : '#000',
					)
				rings[ringName].link
					?.selectAll('.structureTagIcon')
					.attr('xlink:href', o =>
						isNeighboringEdge(d, o)
							? linkColor(o.structureTag, 'xlink:href', '.structureTagIcon')
							: null,
					)
					.style('display', o => (isNeighboringEdge(d, o) ? 'block' : 'none'))

				
				rings[ringName].ringElement?.selectAll(`.${ringName}RingBackground`)
					.style('display', (isSummaryCardVisible === ringName ? "block" : "none"))
			
	
			})

			node
				.select('.node-name')
				.attr('x', d => iconSize(d)/2)
				.attr('y', d => -Math.max(iconSize(d), 8)/2)
				.attr("font-weight",500)
				.filter(o => (neighboring(d, o) || o.id===d.id))
				.style('display', 'block')
				.filter(o => (o.id===d.id))
				.attr('x', d => iconSize(d))
				.attr('y', d => -Math.max(iconSize(d), 8))
				.attr("font-weight",700)
			

		
							
		} 
		// else {
		// 	rings[ringName].link
		// 		?.selectAll('path')
		// 		.style('opacity', 1)
		// 		.style('stroke', d => (d?.type?.includes('Static') ? '#000000' : '#609D68'))
		// 	rings[ringName].link?.selectAll('.structureTag').attr('fill', '#000000').style('opacity', 1)
		// 	rings[ringName].link?.selectAll('.structureTagIcon').attr('fill', '#000000').style('opacity', 1)
		// }

		

		
		
			
	})


	

	node
		.on('mouseover', function (d) {
			//vizNodeHover(d?.id)
			select(this)
				.select('.node-name')
				.style('display', 'block')
				
			createLog(
				'Visualization Element Hover',
				`{"elementType":"${d.__typename}"}`,
				'StoryViz',
				null,
			)

			const isVisible =
			select(this).select('.connection-count-button').node().style.display !== 'none'
			if (
				!d.drag &&
				!isVisible && !selectedElementRef.current &&
				['Arc', 'Character', 'Event', 'Theme', 'Chapter', 'Setting'].includes(d.__typename)
			) {
				select(this).select('circle').style('filter', 'drop-shadow(0px 0px 3px #3185FC)')
			}

			// .call(wrap, 50) incase we decide to wrap text

			// ToDO: Consider adding a little tooltip to show you the description of object. Will need to remove on mouseout
		})
		.on('mouseout', function (d) {
			//clearTimeout(d.hoveringTimeout);
			if (!d.drag) {
				vizNodeHover(null)
				if (!d.clicked) {
					select(this)
						.select('.node-name')
						.style('display', d => d.coreElement === 'true' || nodes.length <=10? 'block' :'none')
						
				}
			}
			if (!d.clicked) {
				select(this).select('circle').style('filter', 'unset')
				select(this).selectAll('.count-button').style('display', 'none')
				
			}
		})
		.on('uiNodeHovered', function () {
			if (event.detail?.id) {
				select(this).style('stroke-width', 6)
			} else {
				select(this).style('stroke-width', 0)
			}
		})
		.on('dblclick', d => {
			createLog(
				'Visualization Element Double Clicked',
				`{"elementType":"${d.__typename}"}`,
				'StoryViz',
				null,
			)
			// createLog(
			// 	'Dive Deep Entered',
			// 	`{"elementType":"${d.__typename}", "action":"Viz Element Double Click"}`,
			// 	'ListElement',
			// 	null,
			// )
			
		})
		.on('contextmenu', function (d) {
			event.preventDefault()
			d.clicked = false
			d.fx = null
			d.fy = null
			select(this).select('.node-name').style('display', 'none')
			createLog(
				'Visualization Element Right Clicked',
				`{"elementType":"${d.__typename}"}`,
				'StoryViz',
				null,
			)
		})
		.on('focus', function () {
			select(this).style('outline', 'none')
		})

		.on('click', function (d) {
			
			
			d.clicked = true
			node.style('opacity', o => (neighboring(d, o) || o.id===d.id ? 1 : 0.2))

			Object.keys(rings).forEach((ringName) => {

			

			rings[ringName].link
				.style('opacity', o => (isNeighboringEdge(d, o) ? 1 : 0.1))
				.selectAll('path')
				.style('stroke', o =>
					isNeighboringEdge(d, o) ? linkColor(o.structureTag, 'stroke', 'path') : '#000',
				)
			rings[ringName].link
				.selectAll('.structureTag')
				.style('display', o => (isNeighboringEdge(d, o) ? 'block' : 'none'))
				.style('fill', o =>
					isNeighboringEdge(d, o) ? linkColor(o.structureTag, 'fill', '.structureTag') : '#000',
				)
			rings[ringName].link
				.selectAll('.structureTagIcon')
				.attr('xlink:href', o =>
					isNeighboringEdge(d, o)
						? linkColor(o.structureTag, 'xlink:href', '.structureTagIcon')
						: null,
				)
				.style('display', o => (isNeighboringEdge(d, o) ? 'block' : 'none'))


			})
			

			
			node
				.select('.node-name')
				.attr('x', d => iconSize(d)/2)
				.attr('y', d => -Math.max(iconSize(d), 8)/2)
				.attr("font-weight",500)
				.filter(o => (neighboring(d, o) || o.id===d.id))
				.style('display', 'block')
				.filter(o => (o.id===d.id))
				.attr('x', d => iconSize(d))
				.attr('y', d => -Math.max(iconSize(d), 8))
				.attr("font-weight",700)

			
			if (!d.drag) {
				if (d.__typename !== 'Chapter' && selectedElementRef.current?.id !== d.id) {

					sharedDispatch(setActiveConnections({ 
						isActive: true, 
						elementId: d.id, 
						elementType: d.__typename,
						docType:"Driver", 
						appView: d.coreElement === "true" ? "Premise": "Outlining" }))


					systemDataDispatch(setSummaryCardVisible(d.coreElement === 'true'? "Premise" : false))
					
					
				}
			}
			createLog(
				'Visualization Element Clicked',
				`{"elementType":"${d.__typename}"}`,
				'StoryViz',
				null,
			)
		})
		.call(drag().on('start', dragstarted).on('drag', dragged).on('end', dragended))
		
	

	// eslint-disable-next-line
	function dragstarted(d) {
		
		//select(this).dispatch('mouseout')
		//select(this).select('.element').dispatch('mouseout')
		
		// nodes.forEach(node => {
		// 	node.clicked = node.id === d.id || selectedElementRef.current?.id === node.id
		// 	return node
		// })


		if (!event.active) {
			simulation.alphaMin(0.001).alphaTarget(0.1).restart()
		}
		
	}

	function dragged(d) {
		select(this).style('cursor', 'grabbing')
		d.drag = true
		globalDragRef.current = d.id

		select(this).style('stroke-width', 6)
		select(this)
			.select('.node-name')
			.style('display', 'block')
		
		checkPremiseRingBounds(d, { x: 0, y: 0 }, rings["Premise"].outerRingRadius)
		d.fx = event.x
		d.fy = event.y

		
	}

	function dragended(d) {
		select(this).style('cursor', 'pointer')
		if (!event.active) {
			simulation.alphaTarget(0)
		}

		if(d.drag){
			createLog(
				'Visualization Element Dragged',
				`{"elementType":"${d.__typename}", "coreElementChange":"${globalCoreElementChange}","coreElement":"${d.coreElement}"}`,
				'StoryViz',
				null,
			)
		}

		
		
		//vizNodeHover(null)
		select(this).style('stroke-width', 0)
		d.drag = false
		//globalDrag = false
		const update = updateMap[d.__typename]
		// const resetButtonVisibleBefore = Object.values(nodes)
		// 	.filter(element => element.__typename !== 'Beat' && element.__typename !== 'Chapter')
		// 	?.some(element => element.vizLocSaved === true)
		
		

		
		
		d.vizLocSaved = true
		d.xCoordinate= String(d.x)
		d.yCoordinate= String(d.y)
		if ( true || globalCoreElementChange) {
			updateCacheFieldAllNodes(
				client,
				nodes,
				{
					xCoordinate: { field: 'x' },
					yCoordinate: { field: 'y' },
					vizLocSaved: { field: 'vizLocSaved' },
					coreElement: { field: 'coreElement' },
				},
				true,
			)
		} else {

			updateCacheField(
				client,
				d,
				{
					xCoordinate: String(d.x),
					yCoordinate: String(d.y),
					vizLocSaved: true,
					coreElement: d.coreElement,
				},
				false
			)
			
		}
		if (globalCoreElementChange) {
			globalCoreElementChange = false

		}
		update({
			variables: {
				id: d.id,
				input: {
					xCoordinate: String(d.x),
					yCoordinate: String(d.y),
					vizLocSaved: true,
					coreElement: d.coreElement,
					updatedAt:new Date().toISOString()
				},
			},
		})

		node.select('.element').filter(o => o.id===selectedElementRef.current?.id).node()?.focus()
		
	}

	function neighboring(a, b, multipleNodes=false) {
		if(multipleNodes){
			const output = a.find((node) => {
				return linkedByIndex[`${node?.id},${b?.id}`]
			})

			return !!output
		}
		return linkedByIndex[`${a?.id},${b?.id}`]
	}
	// function neighboringAnyInRing(ringName, node){
	// 	const nodesInRing = nodes.filter((n) => n.ring ===ringName)
	// 	nodesInRing
	// 	rings[ringName].
	// 	if()
	// }

	function isNeighboringEdge(nodes, edge,multipleNodes=false, bothNodesInRing = false) {
		
		let output
		if (bothNodesInRing){
			output = (multipleNodes? nodes : [nodes]).filter((node) => {

				return edge.source.id === node.id || edge.target.id === node.id
	
			})
	
			return output?.length === 2
			
	
		
		} else {
			output = (multipleNodes? nodes : [nodes]).find((node) => {

				return edge.source.id === node.id || edge.target.id === node.id
	
			})
	
			return !!output
			

		}

		
		
	}

	// Label enter + update
	// Updates the line end and circle render positions to their new positions
	// resulting from this tick of force calculations
	function angle(cx, cy, ex, ey) {
		const dy = ey - cy
		const dx = ex - cx
		let theta = Math.atan2(dy, dx) // range (-PI, PI]
		theta *= 180 / Math.PI // rads to degs, range (-180, 180]
		//if (theta < 0) theta = 360 + theta; // range [0, 360)
		return theta
	}

	const checkPremiseRingBounds = (d, center, radius) => {
		const y = d.y - center.y
		const x = d.x - center.x
		const originalCoreElement = d.coreElement
		const distance = Math.sqrt(x * x + y * y)
		if (distance > radius) {
			d.coreElement = 'false'
			d.ring = d.__typename
		} else {
			d.coreElement = 'true'
			d.ring = "Premise"
		}
		if (originalCoreElement !== d.coreElement) {
			globalCoreElementChange = true
		}
		//d.name = `${d.coreElement} :  ${distance}`
	}

	const nodeMoveToCircleContainer = (d, innerRadius = rings.Premise.outerRingRadius, outerRadius = 9999999) => {
		
		const distance = Math.sqrt(d.x * d.x + d.y * d.y)
		let Per_Frame_Distance = 0
		const buffer = (20 + nodeSize(d)) * !(globalDragRef.current === d.id) * !d.vizLocSaved
		let maxTickmove = 500
		if (!isRendered && !globalDragRef.current) {
			maxTickmove = 2
		}
		const Angle = Math.atan2(d.x, d.y)
		
		if (distance < innerRadius + Math.min(buffer, innerRadius)) {
			Per_Frame_Distance = 
				innerRadius + Math.min(buffer, innerRadius) - distance
				 //Math.min(innerRadius-distance,outerRadius-distance)
		} else if (distance > outerRadius - buffer) {
			//Per_Frame_Distance = Math.max((outerRadius - buffer - distance),-maxTickmove) //Math.min(outerRadius-distance,innerRadius)
			Per_Frame_Distance = outerRadius - buffer - distance
		}

		const Sin = Math.sin(Angle) * Per_Frame_Distance
		const Cos = Math.cos(Angle) * Per_Frame_Distance
		d.x = Sin + d.x
		d.y = Cos + d.y
	}

	//Figure out after we have all of other premise ring stuff working. The goal is to maximize angles between edges of an element
	// skip nodes that are vizLocSaved and coreElement
	// a new idea is to check the difference between angles and if a simulation render movement will make the angles narrower, go back in opposide direction.
	//https://stackoverflow.com/questions/64832301/how-to-change-angle-of-nodes-and-links-in-d3-force

	// const calcAnglesDiff = (edges,d) => {
	// 	//let angleDifferenceTotal = 0

	// 	let filteredEdges = edges.filter((edge) => [edge.source.id, edge.target.id].includes(d.id))
	// 	if(filteredEdges?.length<2){
	// 		return
	// 	}

	// 	const idealAngle = 360/filteredEdges.length
	// 	const startingAngle = filteredEdges[0].target.id ===d.id ? Math.atan2(d.x-filteredEdges[0].source.x, d.y-filteredEdges[0].source.y)* ( 180 / Math.PI ) : Math.atan2(d.x-filteredEdges[0].target.x, d.y-filteredEdges[0].target.y) * ( 180 / Math.PI )
	// 	filteredEdges = filteredEdges.map((edge,i) => {

	// 		if(edge.target.id ===d.id){

	// 			edge.angle = Math.atan2(d.x-edge.source.x, d.y-edge.source.y) * ( 180 / Math.PI )
	// 			edge.distance = 30 //Math.sqrt((d.x-edge.source.x)**2 + ( d.y-edge.source.y)**2)
	// 			edge.dest = "source"

	// 		} else {
	// 			edge.angle = Math.atan2(d.x-edge.target.x, d.y-edge.target.y) * ( 180 / Math.PI )
	// 			edge.distance = 30//Math.sqrt((d.x-edge.target.x)**2 + ( d.y-edge.target.y)**2)
	// 			edge.dest = "target"
	// 		}

	// 		edge.idealAngle = idealAngle * i + startingAngle
	// 		edge.angleDiff = edge.idealAngle - edge.angle

	// 		if((!edge[edge.dest].vizLocSaved) && edge[edge.dest].coreElement==="false"){
	// 			edge[edge.dest].x = edge.distance * Math.cos(edge.angle + edge.angleDiff/10)
	// 			edge[edge.dest].y = edge.distance * Math.sin(edge.angle + edge.angleDiff/10)

	// 		}

	// 		//edge.angleDiff = edge.idealAngle - edge.angle

	// 		return edge

	// 	})

	

	// 	// edges.forEach((edge,i)=>{
	// 	// 	if (i === edges.length -1){
	// 	// 		return
	// 	// 	}

	// 	// angleDifferenceTotal = (edge.angle -edges[i+1].angle)**2 + angleDifferenceTotal
	// 	// })
	// 	// return (angleDifferenceTotal)**.5

	// }
	// const spreadOutNodes = (d, edges) => {

	// 	let newLocation = d

	// 	const elementEdges = edges.filter((edge) => [edge.source.id, edge.target.id].includes(newLocation.id))
	// 						.map((edge) => {
	// 							if(edge.target.id ===newLocation.id){

	// 								edge.angle = Math.atan2(newLocation.x-edge.source.x, newLocation.y-edge.source.y)

	// 							} else {
	// 								edge.angle = Math.atan2(newLocation.x-edge.target.x, newLocation.y-edge.target.y)
	// 							}
	// 							return edge
	// 						})

	// 	const anglesBetweenEdges = (edges) => {
	// 		edges.map((edge,i)=> {
	// 			if(i === edges.length -1){
	// 				return
	// 			}
	// 			let newAngle = edge.angle - edges[i+1]
	// 			newAngle = (newAngle + 180) % 360 - 180
	// 		})

	// 	}

	// }

	function ticked() {
		//let tickStart = performance.now()
		node.attr('transform', d => {
			if (!d.drag) {
				
				nodeMoveToCircleContainer(d, rings[d.ring].innerRingRadius, rings[d.ring].outerRingRadius)
				}

			return `translate(${d.x},${d.y})`
		})

		rings["Premise"].missingLink
		.select('path')
		.attr('d', d => {
			return `M${d?.source?.x},${d?.source?.y} L${d?.target?.x}, ${d?.target?.y}`
		})

		rings["Premise"].missingLink
			.selectAll('.missingLineRectangle')
			.attr('x', d => (d.source.x + d.target.x) / 2)
			.attr('y', d => (d.source.y + d.target.y) / 2 - 3)
			.attr(
				'width',
				d => Math.sqrt((d.target.x - d.source.x) ** 2 + (d.target.y - d.source.y) ** 2) / 2,
			)
			.attr('height', 6)
			.attr(
				'transform',
				d =>
					`rotate(${angle(d.source.x, d.source.y, d.target.x, d.target.y)}, ${
						(d.source.x + d.target.x) / 2
					}, ${(d.source.y + d.target.y) / 2})`,
			)

		rings['Premise'].missingLink
			.selectAll('.missingPremiseConnection')
			.attr('x', d => (d.source.x + d.target.x) / 2 - 5)
			.attr('y', d => (d.source.y + d.target.y) / 2 - 5)
			.attr('transform', d =>
					d.source.x < d.target.x
						? `rotate(${angle(d.source.x, d.source.y, d.target.x, d.target.y)}, ${
								(d.source.x + d.target.x) / 2
						}, ${(d.source.y + d.target.y) / 2})`
						: `rotate(${angle(d.target.x, d.target.y, d.source.x, d.source.y)}, ${
								(d.source.x + d.target.x) / 2
						}, ${(d.source.y + d.target.y) / 2})`,
				)

		rings['Premise'].missingLink
			.selectAll('.missingLineRectangle2')
			.attr('x', d => (d.source.x + d.target.x) / 2)
			.attr('y', d => (d.source.y + d.target.y) / 2 - 3)
			.attr(
				'width',
				d => Math.sqrt((d.target.x - d.source.x) ** 2 + (d.target.y - d.source.y) ** 2) / 2,
			)
			.attr('height', 6)
			.attr(
				'transform',
				d =>
					`rotate(${angle(d.target.x, d.target.y, d.source.x, d.source.y)}, ${
						(d.source.x + d.target.x) / 2
					}, ${(d.source.y + d.target.y) / 2})`,
			)
		
		Object.keys(rings).forEach( (ringName) =>{

			rings[ringName].link
			.select('path')
			.attr('d', d => `M${d.source.x},${d.source.y} L${d.target.x}, ${d.target.y}`)

			rings[ringName].link
				.selectAll('.structureTagIcon')
				.attr('x', d => (d.source.x + d.target.x) / 2 - 5)
				.attr('y', d => (d.source.y + d.target.y) / 2 - 5)
				.attr('transform', d =>
					d.source.x < d.target.x
						? `rotate(${angle(d.source.x, d.source.y, d.target.x, d.target.y)}, ${
								(d.source.x + d.target.x) / 2
						}, ${(d.source.y + d.target.y) / 2})`
						: `rotate(${angle(d.target.x, d.target.y, d.source.x, d.source.y)}, ${
								(d.source.x + d.target.x) / 2
						}, ${(d.source.y + d.target.y) / 2})`,
				)
			
				rings[ringName].link
				.selectAll('.structureTagIconBackground')
				.attr('x', d => (d.source.x + d.target.x) / 2 - 5)
				.attr('y', d => (d.source.y + d.target.y) / 2 - .5 )
				.attr(
					'transform',
					d =>
						`rotate(${angle(d.source.x, d.source.y, d.target.x, d.target.y)}, ${
							(d.source.x + d.target.x) / 2
						}, ${(d.source.y + d.target.y) / 2})`,
				)


			rings[ringName].link
				.selectAll('.structureTag') 
				.attr('x', d => (d.source.x + d.target.x) / 2)
				.attr('y', d => (d.source.y + d.target.y) / 2 - 5)
				.attr('transform', d =>
					d.source.x < d.target.x
						? `rotate(${angle(d.source.x, d.source.y, d.target.x, d.target.y)}, ${
								(d.source.x + d.target.x) / 2
						}, ${(d.source.y + d.target.y) / 2})`
						: `rotate(${angle(d.target.x, d.target.y, d.source.x, d.source.y)}, ${
								(d.source.x + d.target.x) / 2
						}, ${(d.source.y + d.target.y) / 2})`,
				)

			rings[ringName].link
				.selectAll('.lineRectangle')
				.attr('x', d => (d.source.x + d.target.x) / 2)
				.attr('y', d => (d.source.y + d.target.y) / 2 - 3)
				.attr(
					'width',
					d => Math.sqrt((d.target.x - d.source.x) ** 2 + (d.target.y - d.source.y) ** 2) / 2,
				)
				.attr('height', 6)
				.attr(
					'transform',
					d =>
						`rotate(${angle(d.source.x, d.source.y, d.target.x, d.target.y)}, ${
							(d.source.x + d.target.x) / 2
						}, ${(d.source.y + d.target.y) / 2})`,
				)

			rings[ringName].link
				.selectAll('.lineRectangle2')
				.attr('x', d => (d.source.x + d.target.x) / 2)
				.attr('y', d => (d.source.y + d.target.y) / 2 - 3)
				.attr(
					'width',
					d => Math.sqrt((d.target.x - d.source.x) ** 2 + (d.target.y - d.source.y) ** 2) / 2,
				)
				.attr('height', 6)
				.attr(
					'transform',
					d =>
						`rotate(${angle(d.target.x, d.target.y, d.source.x, d.source.y)}, ${
							(d.source.x + d.target.x) / 2
						}, ${(d.source.y + d.target.y) / 2})`,
				)


		})
		

		// tickCount = tickCount + 1
		// const tickRunTime = performance.now() - tickStart
		// tickTotalRunTime = tickRunTime + tickTotalRunTime
		//console.log(" tick runtime: ", tickRunTime)
	}

	const vizXCenter = d => {
		let position = d.x
		if (!d.xCoordinate && isRendered && d.coreElement !== 'true' && d.connections.length === 0) {
			// if (d.connections.length === 0 && rank_mid > 1) {

			// } else if (d.connections.length === 0 && rank_mid === 1){
			// 	position = -(fullWidth / 3) * 0.6 * (1 / 2)
			// }
			position = -3*rings.Premise.outerRingRadius
			//  else if(d.coreElement ==="false"){

			// 	const distance = Math.sqrt(d.x * d.x + d.y * d.y)

			// 	const Angle = Math.atan2(d.x, d.y)
			// 	const Per_Frame_Distance = -Math.max(3*premiseRadius - distance,0)

			// 	const Sin = Math.sin(Angle) * Per_Frame_Distance
			// 	position= Sin + d.x

			// }

			if (d.__typename !== 'Note') {
				//position = -(fullWidth/2 )*.6 * ((rank_mid-d.rank)/rank_mid)
				//position = 0//-(fullWidth/2 )*.6 * ((rank_mid-d.rank)/rank_mid)
			}
		}
		//position = d.x
		return position
	}

	const vizYCenter = d => {
		let position = 0
		position = d.y
		if (!d.yCoordinate && isRendered && d.coreElement !== 'true') {
			if (d.connections.length === 0 && rank_mid > 1) {
				//position = -(fullWidth / 2) * 0.6 * ((rank_mid - d.rank) / rank_mid)
			} else if (d.connections.length === 0 && rank_mid === 1) {
				position = 0 // -(fullWidth / 2) * 0.6 * (1 / 2)
			}
		}
		//position = d.y

		return position
	}

	const chargeFunction = d => {
		let charge = -50
		if (d.coreElement !== 'true') {
			charge = -100
		}
		if (d.rank > 3 || d.connections.length === 0) {
			charge = -10
		}
		if (d.__typename === 'Note') {
			charge = 0
		}

		return charge
	}

	const collisionFunction = d => {
		let factor = 1.5
		if (d.__typename === 'Note') {
			factor = 1
		}
		return iconSize(d) * factor
	}

	//startTime = performance.now()
	//console.log("startTime", startTime)
	let tickCount = 0
	let tickTotalRunTime =0
	const simulation = forceSimulation(nodes)
		// .force(
		// 	'link',
		// 	forceLink(edges)
		// 		?.id(d => d?.id)
		// 		.distance(function (d) {
		// 			return 2* rings.Premise.outerRingRadius
		// 		})
		// 		.strength(d => {
		// 			return 0 //.1
		// 		}), // use this to force distance between elements:  .distance(function(d) {return 100;}).strength(0.1)
		// )
		// .force(
		// 	'charge',
		// 	forceManyBody().strength(d => chargeFunction(d)),
		// )
		.force(
			'collision',
			forceCollide().radius(d => collisionFunction(d)),
		)
		// .force(
		// 	'x',
		// 	forceX().x(function (d) {
		// 		return vizXCenter(d)
		// 	}),
		// )
		// .force(
		// 	'y',
		// 	forceY().y(function (d) {
		// 		return vizYCenter(d)
		// 	}),
		// )
		.alpha(.2)
		.alphaMin(0.197)
		.alphaTarget(0)
		.alphaDecay(.7)
		.on('end', () => {
			//console.log("arrival at end time: ", performance.now() - startTime )
			if (isRendered &&!isChapterViewExpanded) {
				if (nodes.length > 0 && !globalDragRef.current) {
					updateCacheFieldAllNodes(
						client,
						nodes,
						{
							xCoordinate: { field: 'x' },
							yCoordinate: { field: 'y' },
							vizLocSaved: { field: 'vizLocSaved' },
						},
						false,
					)
				}
				isRendered = false
				if (!globalDragRef.current) {
					zoomGraph()
					
				} else{
					globalDragRef.current = false
				}

				node.select('.element').filter(o => o.id===selectedElementRef.current?.id).node()?.focus()
			}
			// console.log("simulated on end time: ", performance.now() - startTime )
			// console.log("tickCount", tickCount)
			// console.log("tickTotalRunTime",tickTotalRunTime)
			
		})

	simulation.on('tick', ticked)
}

// Return a svg circle DOM Element (EventTarget) from the D3 Visualization based on node ID.
export const selectD3Node = nodeId => {
	const g = select('.graph-viz').select('g')
	const nodesSelection = g.select('.nodes').selectAll('g')
	const nodeSelection = nodesSelection.filter(d => d.id === nodeId)
	return nodeSelection//.select('.element')//.node() // Get the DOM element
}
export const clickD3Node = nodeId => {
	const g = select('.graph-viz').select('g')
	const nodesSelection = g.select('.nodes').selectAll('g')
	const nodeSelection = nodesSelection.filter(d => d.id === nodeId)
	nodeSelection?.on('click')(nodeSelection.data()[0])
}

export const selectVizSVG = () => {
	const g = select('.graph-viz')
	return g // Get the DOM element
}

export const uiNodeHoveredEvent = nodeId =>
	new CustomEvent('uiNodeHovered', {
		bubbles: true,
		detail: {
			id: nodeId,
			type: 'node',
		},
	})


