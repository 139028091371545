import { List, ListItem } from '@mui/material'
import styled from 'styled-components'
import { nodeColors } from '../../utils/commonStyles'

const getColor = type => {
	switch (type) {
		case 'Chapter':
			return nodeColors.Chapter
		case 'Character':
			return nodeColors.Character
		case 'Arc':
			return nodeColors.Arc
		case 'Event':
			return nodeColors.Event
		case 'Theme':
			return nodeColors.Theme
		case 'Supporting':
			return '#4DBB5E'
		case 'Opposing':
			return '#DD0000'
		default:
			return 'white'
	}
}

const IconContainer = styled.div`
	display: flex;
	width: 16px;
	height: 16px;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	border: 1px solid ${({ iselement, type }) => (iselement ? 'transparent' : nodeColors[type])};
	border-radius: 50%;
	object-fit: contain;
	margin-right: 0.4rem;
	background: ${({ isTitle, iselement, type }) =>
		isTitle ? '#FFF' : iselement ? `${nodeColors[type]}80` : nodeColors[type]};
`

const CustomListItem = styled(ListItem)`
	margin: 5px 0;
	padding: 0;
	display: flex;
	justify-content: space-between;
	border-radius: 15px;

	span {
		font-weight: ${({ iselement }) => (iselement==="true" ? '400' : '700')};
		font-size: 12px;
	}

	img {
		width: 12px;
		height: 12px;
	}

	div {
		display: flex;
		align-items: center;
	}

	:hover {
		background-color: ${({ type, iselement }) => (iselement==="true" ? `${nodeColors[type]}30` : 'unset')};
	}
`

const MenuContainer = styled.div`
	padding: 10px;
	.container {
		width: 180px;
		max-height: 150px;
		width: 230px;
		overflow: auto;

		::-webkit-scrollbar-thumb {
			background: #b8b8b8;
			border-radius: 1rem;
			width: auto;
			height: auto;
		}

		::-webkit-scrollbar {
			width: 0.3rem;
		}
	}
`

const SubList = styled.div`
	padding-left: 24px;
`

const AddElementContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 20px;
	height: 20px;
	${({isEdit, type}) => isEdit ? `
		background-color: ${`${nodeColors[type]}30`};
	` : ''}

	span {
		font-size: 12px;
	}
	margin:0 0 0 0.3rem;
	margin-top:5px;

	.input-container {
		display: flex;
		flex: 1;

		input {
			width: 100%;
			background: transparent;
			border: 0px;
			outline: none;
		}

		button {
			cursor: pointer;
			background-color: ${({ type }) => `${nodeColors[type]}60`};
			border-radius: 20px;
			border: none;
			font-size: 10px;
			font-weight: 700;
			padding: 4px 8px;
		}
	}

	&:hover {
		background-color: ${({ type }) => `${nodeColors[type]}30`};
	}
`

export { CustomListItem, IconContainer, MenuContainer, SubList, AddElementContainer }
