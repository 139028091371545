import styled from 'styled-components'

export const ProUserWarning = styled.div`
	text-align: center;
	background-color: #fffbec;
	border-radius: 7px;
	font-family: 'Source Sans Pro';
	padding: 14px 16px;
	font-size: 12px;
	margin: 1px;
	box-sizing: border-box;
`
