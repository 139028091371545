export const valuePropColors = {
	craft: {
		primaryColor: '#FFF1DE',
		color: '#EE8053',
		backgroundColor: '#FFFAF3',
	},
	speed: {
		primaryColor: '#E8F2FF',
		color: '#1642B0',
		backgroundColor: '#F2F8FF',
	},
	habit: {
		primaryColor: '#F0D4E0',
		color: '#CD5185',
		backgroundColor: '#FFF6FB',
	},
}
