import React, { Fragment, useContext, useEffect, useMemo } from 'react'
import { useMediaQuery } from '@mui/material'

import Markdown from '@lynit/shared/src/shared-ui/Markdown'
import BorderLinearProgress from '@lynit/shared/src/shared-ui/BorderLinearProgress'
import { openTutorial } from '@lynit/shared/src/state/actions'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { imgRegEx } from '@lynit/shared/src/utils/utils'
import {
	useLazyGetRecommendationsQuery,
	useUpdateRecommendationVisibilityMutation,
} from '@lynit/shared/src/hooks'

import {
	ProgressContainer,
	ProgressMetricsContainer,
	ProgressTitle,
	RecommendationDescription,
	RecommendationDetailContainer,
	RecommendationHeader,
	VerticalLine,
} from './styles'
import { valuePropColors } from '../../utils/valuePropColors'
import { setRecommendationOpen } from '../../state/actions'
import { storyOutlineContext } from '../../state/storyOutlineProvider'
import BackButton from '../BackButton'

const RecommendationDetails = ({
	currentRecommendation: { data: recommendation, propName = 'craft', valuePropImage },
	setCurrentRecommendation,
	isRightPanel,
}) => {
	const isLaptop = useMediaQuery('(min-width: 1200px)')

	const {
		state: { isRecommendationOpen },
		dispatch,
	} = useContext(storyOutlineContext)
	const { dispatch: sharedDispatch } = useContext(sharedContext)

	const [updateRecommendationVisibility] = useUpdateRecommendationVisibilityMutation()
	const [, { refetch: refetchRecommendations }] = useLazyGetRecommendationsQuery()

	const isImage = useMemo(() => imgRegEx.test(recommendation?.image), [recommendation])

	useEffect(async () => {
		if (recommendation && recommendation?.visibility !== 'opened') {
			try {
				await updateRecommendationVisibility({
					variables: {
						level: recommendation?.level,
						recommendationId: recommendation?.id,
						visibility: 'opened',
						type: 'Recommendation',
					},
				})
				refetchRecommendations()
			} catch (error) {
				console.error(error.message)
			}
		}
	}, [recommendation])

	return (
		<RecommendationDetailContainer
			isRightPanel={isRightPanel}
			isRecommendationOpen={isRecommendationOpen}
		>
			<RecommendationDescription isRightPanel={isRightPanel}>
				<RecommendationHeader isRightPanel={isRightPanel}>
					<BackButton
						onClick={() => {
							setCurrentRecommendation(null)
							dispatch(setRecommendationOpen(false))
						}}
					/>
					<p className="title">{recommendation?.title}</p>
					{isRightPanel && valuePropImage && <img src={valuePropImage} />}
				</RecommendationHeader>
				<div className="message-container">
					<Markdown
						markdownText={recommendation?.message}
						handleOpenTutorial={tutorialId => sharedDispatch(openTutorial(tutorialId))}
					/>
				</div>
				{recommendation?.image &&
					(isImage ? (
						<img src={recommendation?.image} className="recommendation-img" />
					) : (
						<video controls>
							<source src={recommendation?.image} type="video/mp4" />
						</video>
					))}
			</RecommendationDescription>
			{!isRightPanel && isLaptop && <VerticalLine />}
			<ProgressContainer isRightPanel={isRightPanel}>
				<ProgressTitle>
					<div>
						<p className="title">Progress</p>
						{isLaptop ? (
							<>
								<BorderLinearProgress
									variant="determinate"
									value={recommendation?.progress||0}
									barcolor={valuePropColors[propName]?.color}
									primarycolor={valuePropColors[propName]?.primaryColor}
								/>
								<span className="progress-value">{recommendation?.progress}%</span>
							</>
						) : (
							<></>
						)}
					</div>
					{!isRightPanel && valuePropImage && <img src={valuePropImage} />}
				</ProgressTitle>
				{!isLaptop ? (
					<>
						<BorderLinearProgress
							variant="determinate"
							value={recommendation?.progress||0}
							barcolor={valuePropColors[propName]?.color}
							primarycolor={valuePropColors[propName]?.primaryColor}
						/>
						<span className="progress-value">{recommendation?.progress}%</span>
					</>
				) : (
					<></>
				)}
				<ProgressMetricsContainer>
					{recommendation?.completeConditions?.map(metric =>
						Number.isNaN(Number(metric.value)) ? (
							<Fragment key={metric.userFriendlyMetricName} />
						) : (
							<div key={metric.userFriendlyMetricName}>
								<p className="metric-name">{metric.userFriendlyMetricName}</p>
								<p className="current-value">{metric.metricCurrentValue}</p>
								<p className="target-value">out of {metric.value}</p>
							</div>
						),
					)}
				</ProgressMetricsContainer>
			</ProgressContainer>
		</RecommendationDetailContainer>
	)
}

export default RecommendationDetails
