import React, { createContext, useReducer } from 'react'

export const autoSaveStateContext = createContext()
export const autoSaveDispatchContext = createContext()

export const setSavingData = payload => {
	return {
		type: 'SET_SAVING_DATA',
		payload,
	}
}

export const autoSaveReducer = (state, action) => {
	switch (action.type) {
		case 'SET_SAVING_DATA':
			return { ...state, isSaving: action.payload }
		default:
			return state
	}
}

export const AutoSaveProvider = ({ children }) => {
	const [state, dispatch] = useReducer(autoSaveReducer, { isSaving: false })

	return (
		<autoSaveStateContext.Provider value={state}>
			<autoSaveDispatchContext.Provider value={dispatch}>
				{children}
			</autoSaveDispatchContext.Provider>
		</autoSaveStateContext.Provider>
	)
}

export default AutoSaveProvider
