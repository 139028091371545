import { useContext, useEffect, useState } from 'react'
import orderBy from 'lodash/orderBy'
import { sharedContext } from '../state'
import { beatMoved, elementReordered } from '../state/actions'
import { useGetRelationshipsQuery } from './getRelationshipsData'
import { arrayMove } from '@dnd-kit/sortable'
import { graphDataStateContext } from '../state/graphDataProvider'
import { systemStateContext } from '../state/systemProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from '../state/beatsProvider'
import { nodeTypeForId } from '../utils/utils'

export const useDraggable = (id, active, over,existingBeatConnections = [],connectionTypeField="beatConnections") => {
	const [beatsList, setBeatsList] = useState(existingBeatConnections)

	const {
		state: { movedPosition},
		dispatch,
	} = useContext(sharedContext)

	const beatsRelatedData = useContext(beatsDataStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const systemRelatedData = useContext(systemStateContext)
	const graphData = useContext(graphDataStateContext)

	const { data: relationships } = useGetRelationshipsQuery({ fetchPolicy: 'cache-only' , component:"useDraggable"})

	
	useEffect(() => {

			const cacheBeatList = connectionTypeField === "sharedBeats" ?
			relationships?.relations.find(relation => relation?.id === id)?.[connectionTypeField]?.map((beat,index)=> {return {...beat,order:(index)}})   
			 : graphData?.nodes[id]?.[connectionTypeField]?.map((connection) => {
				const beatId = connection?.sourceNode?.id?.startsWith('bea')
									? connection?.sourceNode?.id
									: connection?.destNode?.id
				return { id:beatId,connectionId:connection.id, order: +connection.order }
			})
			
			const connections = cacheBeatList?.map((connection) => {
				return { ...connection, order: (+connection.order)}
			})
			const orderBeatList = orderBy(connections, ['order'], ['asc'])
			if (orderBeatList !== beatsList){
				setBeatsList(orderBeatList)
			}
		
	}, [graphData?.nodes[id]?.[connectionTypeField], relationships?.relations.find(relation => relation?.id === id),active?.id])



	useEffect(() => {
		
		//Shared beat options
		// Beat already in dest list of beat => don't allow to drop
		// Beat is not in dest list, but belongs to dest reference element => drop and add driver connection and potentially static connection.
		// Beat is not in dest list and does not belong to dest reference element => drop and add driver to both connection elements and potentially create static connection or just update it.
		
		if (active?.data?.current?.type ==='beat' || (!active?.data?.current?.id && active?.id)) {
			
			const activeBeat = graphData?.nodes[active?.data?.current.id] ||  graphData?.nodes[active?.id?.slice(0,40)]
			const overBeat = graphData?.nodes[over?.data?.current.id] 
			const sourceBeat =  beatsList.find(beat => beat?.id === activeBeat?.id)
			const destBeat = beatsList.find(beat => beat?.id === over?.data?.current?.id)
			const overReferenceElementBeatConnections = connectionTypeField === "sharedBeats" ?
			relationships?.relations.find(relation => relation?.id === over?.data?.current?.referenceElement?.id)?.[connectionTypeField].map((beat,index)=> {return {...beat,order:(index)}})   
			 : graphData?.nodes[over?.data?.current?.referenceElement?.id]?.[connectionTypeField].map((connection) => {
				const beatId = connection?.sourceNode?.id?.startsWith('bea')
									? connection?.sourceNode?.id
									: connection?.destNode?.id
				return { id:beatId,connectionId:connection.id, order: +connection.order }
			})

			if(over  
				&& (beatsList?.length !== overReferenceElementBeatConnections?.length)
				&& (over?.data?.current?.id !== activeBeat?.id) 
				&& (activeBeat?.id) 
				&&  (over?.data?.current?.referenceElement?.id === id) ) //manage dragging beat over temp self in chapter before dropping there.
			{
				
				var currentSet = new Set(overReferenceElementBeatConnections.map(conn => conn?.id));
				const tempBeatIndex = beatsList?.findIndex(x => !currentSet.has(x?.id))
				
			
				if(tempBeatIndex>=0){
					
					const newBeatsList = arrayMove(
						beatsList,
						+tempBeatIndex ,
						+over?.data?.current?.sortable?.index ,
					)
					const orderBeatList =  newBeatsList.map((beat,index) => ({
						...beat,
						order: (index),
					})) || []
					
					if (orderBeatList !== beatsList){
						setBeatsList(orderBeatList)

					}

				

				}
				
				return
				

			}

			
		
			let referenceElement
			let beat
			let oldChapterId
			let thisElementBeatList = connectionTypeField === "sharedBeats" ?
			relationships?.relations.find(relation => relation?.id === id)?.[connectionTypeField].map((beat,index)=> {return {...beat,order:(index)}})   
			 : graphData?.nodes[id]?.[connectionTypeField].map((connection) => {
				const beatId = connection?.sourceNode?.id?.startsWith('bea')
									? connection?.sourceNode?.id
									: connection?.destNode?.id
				return { id:beatId,connectionId:connection.id, order: +connection.order }
			})
		
			if (over?.data?.current.location ===  'Chapter Card' && systemRelatedData?.isUserSubscribed) {
				return
			}
			
			referenceElement = active?.data?.current.referenceElement
						 

			 const referenceElementBeatList = connectionTypeField === "sharedBeats" ?
			 relationships?.relations.find(relation => relation?.id === referenceElement?.id)?.[connectionTypeField].map((beat,index)=> {return {...beat,order:(index)}})   
			  : graphData?.nodes[referenceElement?.id]?.[connectionTypeField].map((connection) => {
				 const beatId = connection?.sourceNode?.id?.startsWith('bea')
									 ? connection?.sourceNode?.id
									 : connection?.destNode?.id
				 return { id:beatId,connectionId:connection.id, order: +connection.order }
			 })
			 
			if (active?.data?.current.location === 'Chapter Card') {
				oldChapterId = activeBeat?.[connectionTypeField]?.[0]?.destNode?.id?.startsWith('bea')
					? activeBeat?.[connectionTypeField]?.[0]?.sourceNode?.id
					: activeBeat?.[connectionTypeField]?.[0]?.destNode?.id
			}
		
		
			if (
				!over?.data?.current?.referenceElement?.id &&
				(!over || (activeBeat?.id !== over?.data?.current?.id && over?.data?.current.location !==  'Chapter Card'))
				) 
			{
				
				if (sourceBeat) {
					const newBeatsList =
					thisElementBeatList?.map((beat) => ({
							...beat,
							order: (+beat.order),
						})) || []
						const orderBeatList = orderBy(newBeatsList, ['order'], ['asc'])
						if (orderBeatList.length !== beatsList.length){
							
							setBeatsList(orderBeatList)
	
						}

				}
				
				if (id === referenceElement?.id) {
					
					const newBeatsList =
					referenceElementBeatList?.map((beat) => ({
							...beat,
							order: +beat.order,
						})) || []

						const orderBeatList = orderBy(newBeatsList, ['order'], ['asc'])
						if (orderBeatList.length !== beatsList.length){
							setBeatsList(orderBeatList)
	
						}
						
				}
			} else if (overBeat?.__typename === "Beat" || over?.data?.current?.id.startsWith('rel-empty-beat')) {
				
				// if we drag beat between chapters
				
				if ((sourceBeat || destBeat) && !(sourceBeat && destBeat) ) {
					
					if ( false && sourceBeat && ( over?.data?.current.location==="Chapter Card" || (over?.data?.current.referenceElement.id !==id))) {

						//delete connection from the source chapter
						let newConnections = beatsList?.filter(
							connection => connection?.id !== sourceBeat?.id,
						)
						newConnections =
							newConnections?.map(connection => {
								if (connection.order > +sourceBeat.order) {
									return { ...connection, order: connection.order - 1 }
								}
								return connection
							}) || []
						const orderBeatList = orderBy(newConnections, ['order'], ['asc'])
						if (orderBeatList.length !== beatsList.length){
							
							setBeatsList(orderBeatList)
	
						}
						
					}
					if (destBeat) {
						
						// create connection to the destination chapter
						if (activeBeat?.beatConnections?.[0]) {
							
							const oldChapterId = activeBeat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
								? activeBeat?.[connectionTypeField]?.[0]?.sourceNode?.id
								: activeBeat?.[connectionTypeField]?.[0]?.destNode?.id

							// if beat already exist then return
							if (oldChapterId === id || activeBeat?.id===destBeat.id) {
								return
							}
						}
						if (id !== referenceElement?.id) {
							
							const newBeat = {
								__typename: 'Beat',
								id: activeBeat?.id,
								
								order: destBeat.order,
								connectionId:activeBeat?.connectionId
							}

							let newBeatList =
								beatsList?.map((beat) => {
									if ((beat.order) >= destBeat.order) {
										return { ...beat, order: (beat.order) + 1 }
									}
									return beat
								}) || []

							// if(over?.data?.current?.id !== over?.id && over?.data?.current?.id?.startsWith('rel') && over?.data?.current.location==="Chapter Card"){
							// 	const test = true
							// }
							newBeatList = [...newBeatList, newBeat]

							const orderBeatList = orderBy(newBeatList, ['order'], ['asc'])
							if (orderBeatList.length !== beatsList.length){
								setBeatsList(orderBeatList)
		
							}
							
						} else {
							
							
							const orderBeatList = orderBy(referenceElementBeatList, ['order'], ['asc'])
							if (orderBeatList.length !== beatsList.length){
		
								setBeatsList(orderBeatList)
		
							}
								
						}
					}
				}
				
			// drag the beat to the chapter that does not have beat connections
				if (over?.data?.current?.referenceElement?.id === id && overReferenceElementBeatConnections?.length === 0) {
					

					const newBeat = {
						__typename: 'Beat',
						id: activeBeat?.id,
						
						order: 0,
						connectionId:activeBeat?.id
					}
					
					const orderBeatList = [newBeat]
					if (orderBeatList.length !== beatsList.length){
						
						setBeatsList(orderBeatList)

					}

					
				}


			} else if (
				activeBeat?.id && nodeTypeForId(activeBeat?.id) === "Beat" &&
				over?.data?.current?.id?.startsWith('container') &&
				overReferenceElementBeatConnections?.length !== 0 && !beatsList?.find(connection => connection?.id === activeBeat?.id)
			) {
				
				
				const sourceElement = beatsList?.find(beat => beat?.id === activeBeat?.id)
				const destElement = id === over?.data?.current?.referenceElement?.id

			
				if (sourceElement && destElement) {
					
					let newConnections = beatsList?.filter(
						beat => beat?.id !== sourceBeat?.id,
					)
					newConnections =
						newConnections?.map((beat) => {
							if ((beat.order) > sourceBeat?.order) {
								return { ...beat, order: (beat.order) - 1 }
							}
							return beat
						}) || []

						const newBeat = {
							__typename: 'Beat',
							id: activeBeat?.id,
							
							order: beatsList[beatsList.length - 1].order,
							connectionId:activeBeat?.id
						}
					

					const orderBeatList = orderBy([...newConnections, newBeat], ['order'], ['asc'])
					if (orderBeatList.length !== beatsList.length){
				
						setBeatsList(orderBeatList)

					}

				} else if (sourceElement || destElement) {
					if (sourceElement) {
						
						let newConnections = beatsList.filter(
							connection => connection?.id !== sourceBeat?.id,
						)
						newConnections = newConnections.map(connection => {
							if (connection.order > sourceBeat?.order) {
								return { ...connection, order: connection.order - 1 }
							}
							return connection
						})

						const orderBeatList = orderBy(newConnections, ['order'], ['asc'])
						if (orderBeatList !== beatsList){
							console.log("UseDraggable --spot 4.3")
							setBeatsList(orderBeatList)
	
						}

						
					}
					if (destElement) {
						if (activeBeat?.connectionType === 'Driver') {
							// if beat already exist then return
							if (oldChapterId === id) {
								return
							}
						} 

								
						const newBeat = {
							__typename: 'Beat',
							id: activeBeat?.id,
							
							order:  beatsList?.length
							? beatsList[beatsList.length - 1].order + 1
							: 0,
							connectionId:activeBeat?.connectionId
						}
					
						
						const orderBeatList = orderBy([...beatsList, newBeat], ['order'], ['asc'])
						if (orderBeatList !== beatsList){
							setBeatsList(orderBeatList)
	
						}

						
					}
				}
			}
		}
	}, [over])

	// update order while reordering beat to display updated position before getting response from an api
	useEffect(() => {
		if (
			beatsRelatedData?.reorderedPosition?.referenceElement?.id === id &&
			beatsRelatedData?.reorderedPosition?.source?.index !== beatsRelatedData?.reorderedPosition?.destination?.index
		) {
			setBeatsList(
				arrayMove(
					beatsList,
					beatsRelatedData?.reorderedPosition?.source?.index,
					beatsRelatedData?.reorderedPosition?.destination?.index,
				),
			)
			beatsDataDispatch(elementReordered())
		}
	}, [beatsRelatedData?.reorderedPosition])

	useEffect(() => {
		if (
			movedPosition?.sourceNode?.id === id &&
			beatsList?.find(connection => connection?.id === movedPosition?.sourceBeat?.id)
		) {
			setBeatsList(
				orderBy(
					beatsList?.filter(
						connection => connection?.id !== movedPosition?.sourceBeat?.id,
					),
					['order'],
					['asc'],
				),
			)
			dispatch(beatMoved())
		}
	}, [movedPosition])

	return beatsList
}
