import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_TASKS } from '../data'

export const useGetTasksQuery = (options = {}) => {
	const result = useQuery(GET_TASKS, options)
	return result
}

export const useLazyGetTasksQuery = (options = {}) => {
	const result = useLazyQuery(GET_TASKS, options)
	return result
}
