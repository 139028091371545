import styled from 'styled-components'

export const TutorialContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 10px;
	background-color: #f8f8f8;
	border-radius: 9px;
	box-shadow: -2px 5px 5px rgba(0, 0, 0, 0.05);
	margin-bottom: 10px;
`

export const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;

	.back-btn {
		cursor: pointer;
	}
`

export const BreadCrumbItem = styled.div`
	font-family: 'Source Sans Pro';
	font-size: 14px;
	color: #000000;
	${({ isActive }) =>
		isActive
			? `
        font-weight: 700;
    `
			: `
        cursor: pointer;
        :hover {
            text-decoration: underline;
        }
    `};
`

export const Content = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 15px 22px;
`

export const Info = styled.div`
	flex: 1;
	display: flex;
	align-items: center;

	.time-icon {
		width: 7px;
		height: 7px;
		margin-right: 3px;
	}
	.time-text {
		font-size: 7px;
	}
	.difficulty-text {
		text-transform: capitalize;
		font-size: 7px;
		margin-right: 5px;
	}

	@media (min-width: 601px) {
		.time-icon {
			width: 10px;
			height: 10px;
			margin-right: 3px;
		}
		.time-text {
			font-size: 9px;
		}
		.difficulty-text {
			font-size: 9px;
		}
	}
`

export const DifficultyLevelIcon = styled.img`
	width: 7px;
	margin-right: 3px;
`

export const TutorialDescription = styled.div`
	p {
		font-size: 12px;
	}
	video,
	img {
		max-width: min(100%, 422px);
		display: block;
		margin: 0 auto;
	}
`

export const TutorialImg = styled.img`
	max-width: min(100%, 422px);
	display: block;
	margin: 0 auto;
`

export const TutorialVideo = styled.video`
	max-width: min(100%, 422px);
	display: block;
	margin: 0 auto;
`
