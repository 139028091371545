import React from 'react'
import { BellIcon, BellIconContainer, Count } from './styles'
import notificationIcon from '../../images/notification-icon.svg'

const NotificationIconWithCount = ({ top, right, bottom, left, count }) => (
	<BellIconContainer>
		<BellIcon
			src={notificationIcon}
			alt="bell icon"
			top={top}
			right={right}
			bottom={bottom}
			left={left}
		/>
		<Count top={top} right={right} bottom={bottom} left={left}>
			{count}
		</Count>
	</BellIconContainer>
)

export default NotificationIconWithCount
