import styled from 'styled-components'

const PremiseWrapper = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	-webkit-column-gap: 20px;
	column-gap: 20px;
	row-gap: 25px;
	margin-top: 25px;
`
const PremiseDriver = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`
const DriverLabel = styled.div`
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 700;
	line-height: 15.08px;
	text-align: left;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
`

const DriverContainer = styled.div``
const PremiseRelation = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`
export { PremiseWrapper, PremiseDriver, PremiseRelation, DriverLabel, DriverContainer }
