/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/*
 * Utilities related to the apollo graphql client
 * - parsing apollo query data responses
 */
import hash from 'object-hash'
import _, { orderBy } from 'lodash'
import { sortNodesByName, sortNodesByCreatedAt, sortNodesByNumber, createNodeId } from './utils'

import { GET_RELATIONSHIPS, GET_ALL_NODES, GET_TUTORIALS } from '../data/queries'
import { nodeTypes } from './nodeTypes'

// Parse node objects from the props of a component wrapped by an apollo query.
// Mainly to provide the 2 lookup map versions of the graph
//
// Returns an object:
// {
//    nodes: {nodeId: NodeObject},
//    nodesByType: {nodeType: [NodeObject]},
//    hash: string,
//    loading: bool,
//    error: bool
// }
export const parseGraphData = props => {
	const graphData = {
		nodes: {},
		nodesByType: {},
		loading: false,
		error: null,
		allNodes: {
			nodes: props.data.nodes,
		},
		refetch: props.data.refetch,
	}

	const queryResult = props.data
	// Check loading and error state for this type
	graphData.loading = queryResult.loading
	graphData.error = queryResult.error

	// Initialize nodesByType keys
	Object.keys(nodeTypes).forEach(nodeType => {
		graphData.nodesByType[nodeType] = []
	})

	const nodes = queryResult.nodes || []
	const hashData = []
	if (!graphData.loading) {
		
		nodes.forEach(node => {
			const nodeType = node?.__typename
			graphData.nodesByType[nodeType].push(node)
			graphData.nodes[node.id] = node
			hashData.push( {
				...node,
				xCoordinate: null,
				yCoordinate: null,
				vizLocSaved: null

			})
		})
	}
	graphData.nodesByType.Character = graphData.nodesByType.Character.sort(sortNodesByName)
	graphData.nodesByType.Note = graphData.nodesByType.Note.sort(sortNodesByCreatedAt)
	graphData.nodesByType.Chapter = graphData.nodesByType.Chapter.sort(sortNodesByNumber)
	graphData.nodesByType.Arc = graphData.nodesByType.Arc.sort(sortNodesByName)
	graphData.nodesByType.Theme = graphData.nodesByType.Theme.sort(sortNodesByName)
	graphData.nodesByType.Setting = graphData.nodesByType.Setting.sort(sortNodesByName)
	graphData.nodesByType.Event = graphData.nodesByType.Event.sort(sortNodesByName)

	// The graph hash gives us a quick way to check whether the graph data has changed
	graphData.hash = hash(hashData)

	return graphData
}

// Get node objects of nodeType from graphQL response.
// For example get the array of Character objects from the GET_CHARACTERS response
// TODO depricate
export const getObjects = (data, nodeType) => {
	const { pluralTypeName } = nodeTypes[nodeType]
	if (!data || !data[pluralTypeName]) {
		return []
	}
	return data[pluralTypeName]
}

//CACHE FUNCTIONS
//1.- Connections
//1.1.- Delete connections from sourceNode, destNode and arc (if driver) in cache. Also evicts and garbage collects

export const deleteCacheConnections = async (client, connToDelete, broadcast =true) => {
	try {
		
		
		await client.cache.evict({
			id: client.cache.identify({
				id: connToDelete.id,
				__typename: connToDelete.__typename,
			}),
			broadcast
		})
		
		await client.cache.gc()
		return connToDelete?.id //relations
	} catch (e) {
		console.error(e)
	}
}

//1.2.- Create connections and adds it in the cache in the places it needs to go
const createConnections = (client, connectionType, id, createdConn, broadcast=false) => {

	const requiredRelationshipFields = {
	
		arc: null,
		connectionType: null,
		destNode: null,
		id: createNodeId('Relationship'),
		order: null,
		sourceNode: null,
		name: null,
		description: null,
		arcStage: null,
		relName: null,
		__typename: 'Relationship',
		structureTag: null,
		beatsDriven: null,
		sharedBeats:[],
		firstBeat: '',
		createdAt: new Date().toISOString(),
		updatedAt: new Date().toISOString()
  


	}

	createdConn = {...requiredRelationshipFields, ...createdConn}


	if (connectionType === 'Beat') {
		client.cache.modify({
			id: client.cache.identify(id),
			fields: {
				beatConnections(existingConns,{canRead}) {
					const newArr = [...(existingConns.filter(canRead) || []), createdConn]
					return newArr
				},
			},
			broadcast
		})
	} else if (connectionType === 'Child') {
		client.cache.modify({
			id: client.cache.identify(id),
			fields: {
				childConnections(existingConns,{canRead}) {
					const newArr = [...(existingConns.filter(canRead) || []), createdConn]
					return newArr
				},
			},
			broadcast
		})
	} else if (connectionType === 'Static') {
		client.cache.modify({
			id: client.cache.identify(id),
			fields: {
				staticConnections(existingConns, {canRead}) {
					const newArr = [...(existingConns.filter(canRead) || []), createdConn]
					return newArr
				},
			},
			broadcast
		})
	} else if (connectionType === 'Driver') {
		client.cache.modify({
			id: client.cache.identify(id),
			fields: {
				driverConnections(existingConns, {canRead}) {
					const newArr = [...(existingConns.filter(canRead) || []), createdConn]
					return newArr
				},
			},
			broadcast
		})

	} else if (connectionType === 'Note') {
		client.cache.modify({
			id: client.cache.identify(id),
			fields: {
				noteConnections(existingConns, {canRead}) {
					const newArr = [...(existingConns.filter(canRead) || []), createdConn]
					return newArr
				},
			},
			broadcast
		})
	} else if (connectionType === 'Premise') {
		client.cache.modify({
			id: client.cache.identify(id),
			fields: {
				premiseConnections(existingConns, {canRead}) {
					const newArr = [...(existingConns.filter(canRead) || []), createdConn]
					return newArr
				},
			},
			broadcast
		})
	}
	
}

export const createCacheConnection = async (client, createdConn, multiple, broadcast=true) => {
	try {

		// add created connection to GET_RELATIONSHIP query
		let { relations } = await client.cache.readQuery({ query: GET_RELATIONSHIPS })
		relations = multiple ? [...relations, ...createdConn] : [...relations, createdConn]

		await client.cache.evict({
			fieldName: 'relations',
			broadcast: false,
			// No need to trigger a broadcast here, since writeQuery will take care of that.
		})
		await client.cache.writeQuery({
			query: GET_RELATIONSHIPS,
			data: {
				relations,
			},
			broadcast: false,
		})

		const { nodes } = await client.cache.readQuery({ query: GET_ALL_NODES })

		if(!multiple){
			createdConn = [createdConn]			
		}
		createdConn.forEach(e => {
			const connectionRef = { __ref: `${e.__typename}:${e.id}` }

			if (['Beat'].includes(e.connectionType) ) {
				const beatId =
					e?.sourceNode.__typename === 'Beat'
						? e?.sourceNode.id
						: e?.destNode.id
				const beat = nodes.find(node => node.id === beatId)
				
				beat.driverConnections.forEach((conn) =>{
					const driver = conn.sourceNode.__typename === "Beat"? conn.destNode : conn.sourceNode
					createConnections(client, e.connectionType, driver, connectionRef,false)

				})
				
			}
			// if (['Driver'].includes(e.connectionType))
			// {
			// 	createConnections(client, e.connectionType, parentElement, connectionRef,false)

			// }

			createConnections(client, e.connectionType, e?.sourceNode, connectionRef,broadcast)
			createConnections(client, e.connectionType, e?.destNode, connectionRef,broadcast)
		})


		
		//await client.cache.gc()
	} catch (e) {
		console.error(e)
	}
}

//1.3 Delete element from cache
export const deleteCacheElement = async (client, elToDelete, hasOrder=false, broadcast=true) => {
	try {
		const { nodes } = await client.cache.readQuery({ query: GET_ALL_NODES })

		let newNodes = JSON.parse(JSON.stringify(nodes))
		
		const deletedBeat = newNodes?.find(node => node.id === elToDelete.id)
		const deletedBeatDrivers = deletedBeat?.driverConnections?.map(conn => conn.sourceNode.__typename === "Beat"? conn.destNode : conn.sourceNode)
		const deletedBeatConnectionChapter = deletedBeat?.beatConnections?.map(conn => conn.sourceNode.__typename === "Beat"? conn.destNode : conn.sourceNode )?.[0]


		if (hasOrder) {
			const deletedElementIndex = newNodes.findIndex(element => element?.id === elToDelete?.id)
			const orderOfDeletedElement = newNodes[deletedElementIndex].order
			newNodes.forEach(element => {
				if (element?.__typename === elToDelete?.type && element.order > orderOfDeletedElement) {
					element.order = String(+element.order - 1)
				}

				element.staticConnections = element?.staticConnections?.filter(
					ele => (ele.destNode.id !== elToDelete?.id) && (ele.sourceNode.id !== elToDelete?.id)
				) || []
				element.beatConnections = element?.beatConnections?.filter(
					ele => (ele.destNode.id !== elToDelete?.id) && (ele.sourceNode.id !== elToDelete?.id)
				) || []
				element.driverConnections = element?.driverConnections?.filter(
					ele => (ele.destNode.id !== elToDelete?.id) && (ele.sourceNode.id !== elToDelete?.id)
				) || []
				element.noteConnections = element?.noteConnections?.filter(
					ele => (ele.destNode.id !== elToDelete?.id) && (ele.sourceNode.id !== elToDelete?.id)
				) || []
				element.premiseConnections = element?.premiseConnections?.filter(
					ele => (ele.destNode.id !== elToDelete?.id) && (ele.sourceNode.id !== elToDelete?.id)
				) || []
			})
		}
		newNodes = newNodes.filter(node => node?.id !== elToDelete?.id)

		await client.cache.evict({
			fieldName: 'nodes',
			broadcast: false,
			// No need to trigger a broadcast here, since writeQuery will take care of that.
		})
		await client.cache.writeQuery({
			query: GET_ALL_NODES,
			data: {
				nodes: newNodes,
			},
			broadcast: elToDelete.type !== "Beat"
		})
		

		if( elToDelete.type === "Beat"){
			
			
			const existingStaticConnections = []
			const setUniqueStaticConnections = new Set()

			// find the beatConnections to this beat and reorder that chapter's beats.
			if(deletedBeatConnectionChapter){
				const chapterNode = newNodes.find(node => node.id === deletedBeatConnectionChapter.id)

				const newBeatConnetions = chapterNode?.beatConnections?.filter( conn => ![conn.sourceNode.id,conn.destNode.id].includes(elToDelete.id))?.map(async (conn,index) =>{
					await updateCacheField(client, conn, {
						order: String(index)
					})
					return {...conn, order:String(index)}
				})
			}

			deletedBeatDrivers.map((driver_A, index) => {
				// for each driver, go update their driverConnections order
				const driver_ANode = newNodes.find(node => node.id === driver_A.id)

				const newDriverConnections = driver_ANode?.driverConnections.filter( conn => ![conn.sourceNode.id,conn.destNode.id].includes(elToDelete.id))?.map(async (conn,index) =>{
						await updateCacheField(client, conn, {
							order: String(index)
						})
						return {...conn, order:String(index)}
					})

				deletedBeatDrivers.map(driver_B => {
					const driverPair = [driver_A.id, driver_B.id].sort().toString()
					if (setUniqueStaticConnections.has(driverPair) || driver_A.id === driver_B.id) {
						return
					}

					setUniqueStaticConnections.add(driverPair)
					const existStaticConnection = driver_ANode?.staticConnections?.find(
						conn => {
							return [conn.sourceNode.id, conn.destNode.id].includes(driver_B.id)
						},
					)
					if (existStaticConnection) {
						const newSharedBeats = (existStaticConnection.sharedBeats.filter(beat => beat.id !== elToDelete.id) || [])
						const newBeatsDriven = String(newSharedBeats.length)
						existingStaticConnections.push({
							...existStaticConnection,
							sharedBeats: newSharedBeats,
							beatsDriven: newBeatsDriven,
						})
						
					} 
				})
			})

			existingStaticConnections.map(async conn => {


				await updateCacheField(client, conn, {
					beatsDriven: conn.beatsDriven,
					sharedBeats: conn.sharedBeats,
					updatedAt: new Date().toISOString()
				})
			})
			

		}
		
		await client.cache.gc()
	} catch (e) {
		console.error(e)
	}
}

export const createCacheElement = async (client, inputData, hasOrder=false, broadcast=true, multiple = false) => {
	
	try {
		if (!multiple){
			inputData = [inputData]
		}

		const { nodes } = await client.cache.readQuery({ query: GET_ALL_NODES })
		let newNodes = JSON.parse(JSON.stringify(nodes))
		let createdElements = []
		inputData.map((data)=>{
			let createdEl
		
			if (data?.createArc) {
				createdEl = data?.createArc
			}
			if (data?.createChapter) {
				createdEl = data?.createChapter
			}
			if (data?.createCharacter) {
				createdEl = data?.createCharacter
			}
			if (data?.createEvent) {
				createdEl = data?.createEvent
			}
			if (data?.createTheme) {
				createdEl = data?.createTheme
			}
			if (data?.createSetting) {
				createdEl = data?.createSetting
			}
			if (data?.createNote) {
				createdEl = data?.createNote
			}
			if (data?.createBeat) {
				createdEl = data?.createBeat?.beat
			}
			if (data?.createStory) {
				createdEl = data?.createStory
			}
			createdEl.beatConnections = []
			createdEl.childConnections = []
			createdEl.driverConnections = []
			createdEl.staticConnections = []
			createdEl.premiseConnections = []

			createdElements.push(createdEl)


			if (hasOrder) {

				newNodes.forEach(element => {
					if (element?.__typename === createdEl?.__typename) {
						element.order = String(+element.order + 1)
					}
				})
				newNodes.push({...createdEl,order: '0'})
			} else{
				newNodes.push(createdEl)
			}
		})
		


		

		await client.cache.evict({
			fieldName: 'nodes',
			broadcast: false,
			// No need to trigger a broadcast here, since writeQuery will take care of that.
		})
		await client.cache.writeQuery({
			query: GET_ALL_NODES,
			data: {
				nodes: newNodes,
			},
			broadcast
		})
		
	} catch (e) {
		console.error(e)
	}
}

export const createCacheElementOptimisticResponse = async (cache, inputData, hasOrder=true, broadcast=true) => {

	let data
	if(inputData.data){
		data = inputData.data
	} else {
		data = inputData
	}

	try {
		let createdEl
		if (data?.createArc) {
			createdEl = data?.createArc
		}
		if (data?.createChapter) {
			createdEl = data?.createChapter
		}
		if (data?.createCharacter) {
			createdEl = data?.createCharacter
		}
		if (data?.createEvent) {
			createdEl = data?.createEvent
		}
		if (data?.createTheme) {
			createdEl = data?.createTheme
		}
		if (data?.createNote) {
			createdEl = data?.createNote
		}
		if (data?.createBeat) {
			createdEl = data?.createBeat?.beat
		}

		const { nodes } = await cache.readQuery({ query: GET_ALL_NODES })
		let newNodes = JSON.parse(JSON.stringify(nodes))

		if (hasOrder) {
			newNodes.forEach(element => {
				if (element?.__typename === createdEl?.__typename) {
					element.order = String(+element.order + 1)
				}
			})
			newNodes = [...newNodes, { ...createdEl, order: '0' }]
		} else {
			newNodes = [...(newNodes || []), createdEl]
		}

		await cache.writeQuery({
			query: GET_ALL_NODES,
			data: {
				nodes: newNodes,
			},
			broadcast
		})
	} catch (e) {
		console.error(e)
	}
}

export const updateCacheOrderElement = async (client, mutationData) => {
	try {
		const { nodes } = await client.cache.readQuery({ query: GET_ALL_NODES })
		const newNodes = JSON.parse(JSON.stringify(nodes))

		mutationData.forEach(mutationElement => {
			const index = nodes.findIndex(element => {
				return element.id === mutationElement.id
			})
			if (index !== -1) {
				newNodes[index] = { ...newNodes[index], order: mutationElement.order }
			}
		})

		await client.cache.writeQuery({
			query: GET_ALL_NODES,
			data: {
				nodes: newNodes,
			},
		})
	} catch (e) {
		console.log(e)
	}
}

export const updateCacheOrderBeat = async (client, mutationData,broadcast=true) => {
	try {
		const { relations } = await client.cache.readQuery({ query: GET_RELATIONSHIPS })

		const newRels = JSON.parse(JSON.stringify(relations))

		mutationData.forEach(mutationElement => {
			const index = relations.findIndex(element => {
				return element.id === mutationElement.id
			})
			if (index !== -1) {
				newRels[index] = { ...newRels[index], order: mutationElement.order }
			}
		})

		await client.cache.writeQuery({
			query: GET_RELATIONSHIPS,
			data: {
				relations: newRels,
			},
		})


	} catch (e) {
		console.error(e)
	}
}

export const resetDriverBeatsOrder = async (client, driverConnections,broadcast=true) => {
	try {
		
		const { nodes } = await client.cache.readQuery({ query: GET_ALL_NODES })
		
		let newNodes = JSON.parse(JSON.stringify(nodes))

		const { relations } = await client.cache.readQuery({ query: GET_RELATIONSHIPS })

		const newRels = JSON.parse(JSON.stringify(relations))
		
		driverConnections.forEach((driverConn)=>{
			
			const driverId = driverConn.sourceNode.__typename==="Beat"? driverConn.destNode.id : driverConn.sourceNode.id
			
			
			const nodeIndex = nodes.findIndex(element => {
				return element.id === driverId
			})

			if (nodeIndex !== -1) {
				const orderDriverConnections = orderBy(newNodes[nodeIndex].driverConnections,['order'],['asc'])

				newNodes[nodeIndex].driverConnections = orderDriverConnections.map((conn,driverIndex) => {
					const index = relations.findIndex(element => {
						return element.id === conn.id
					})
					if (index !== -1) {
						newRels[index] = { ...newRels[index], order: String(driverIndex)  }
					}
					return {...conn,order:String(driverIndex)}
				})
			}
			
		})
		
		
		await client.cache.evict({
			fieldName: 'relations',
			broadcast: false,
			// No need to trigger a broadcast here, since writeQuery will take care of that.
		})

		await client.cache.evict({
			fieldName: 'nodes',
			broadcast: false,
			// No need to trigger a broadcast here, since writeQuery will take care of that.
		})

		await client.cache.writeQuery({
			query: GET_RELATIONSHIPS,
			data: {
				relations: newRels,
			},
			broadcast: false
		})

		await client.cache.writeQuery({
			query: GET_ALL_NODES,
			data: {
				nodes: newNodes,
			},
			broadcast,
		})


	} catch (e) {
		console.error(e)
	}
}

export const updateCacheField = async (client, element, newValueMap, broadcast = true) => {
	const fields = {}
	Object.keys(newValueMap).forEach(key => {
		fields[key] = () => {
			return newValueMap[key]
		}
	})
	try {
		await client.cache.modify({
			id: client.cache.identify(element),
			fields,
			broadcast,
		})
	} catch (e) {
		console.error(e)
	}
}

export const updateCacheFieldAllNodes = async (client, inputNodes,newValueMap, broadcast = true) => {
	try {
		const { nodes } = await client.cache.readQuery({ query: GET_ALL_NODES })

		let newNodes = JSON.parse(JSON.stringify(nodes))
		newNodes = newNodes.map(node => {
			
			const updateNode = inputNodes.find((n) => n.id===node.id)
			if(!updateNode){ 
				return node

			}
			Object.keys(newValueMap).forEach(e => {
				if(newValueMap[e].field  ){
					node[e] = updateNode[newValueMap[e].field]
				} else{
					node[e] = newValueMap[e].value
					

				}
				
			})
			return node
		})

		await client.cache.writeQuery({
			query: GET_ALL_NODES,
			data: {
				nodes: newNodes,
			},
			broadcast,
		})
	} catch (e) {
		console.error(e)
	}
}

export const updateCacheDescriptionElement = async (client, element, newDescription) => {
	try {
		await client.cache.modify({
			id: client.cache.identify(element), // delete conn from arc, chr, or evt
			fields: {
				description() {
					return newDescription
				},
			},
		})
	} catch (e) {
		console.error(e)
	}
}

export const updateCacheStructureTagElement = async (client, element, structureTag, broadcast=true) => {
	try {
		await client.cache.modify({
			id: client.cache.identify(element),
			fields: {
				structureTag() {
					return structureTag
				},
			},
			broadcast
		})
	} catch (e) {
		console.error(e)
	}
}

export const updateCacheStoryTextElement = async (client, element, newDescription, wordCount) => {
	try {
		await client.cache.modify({
			id: client.cache.identify(element),
			fields: {
				storyText() {
					return newDescription
				},
				wordCount() {
					return wordCount
				},
			},
		})
	} catch (e) {
		console.error(e)
	}
}

export const updateCacheNameElement = async (client, element, newName) => {
	try {
		await client.cache.modify({
			id: client.cache.identify(element), // delete conn from arc, chr, or evt
			fields: {
				name() {
					return newName
				},
			},
		})
	} catch (e) {
		console.error(e)
	}
}

export const updateCacheNumberElement = async (client, element, newNumber) => {
	try {
		await client.cache.modify({
			id: client.cache.identify(element), // delete conn from arc, chr, or evt
			fields: {
				number() {
					return newNumber
				},
			},
		})
	} catch (e) {
		console.error(e)
	}
}

export const updateCacheNoteContents = async (client, element, newContent) => {
	try {
		await client.cache.modify({
			id: client.cache.identify(element), // delete conn from arc, chr, or evt
			fields: {
				contents() {
					return newContent
				},
			},
		})
	} catch (e) {
		console.error(e)
	}
}

export const deleteStoryCache = async (client, id, broadcast = true) => {
	try {
		await client.cache.evict({
			id: client.cache.identify({
				id,
				__typename: 'Story',
			}),
			broadcast
		})
		await client.cache.gc()
		return id
	} catch (e) {
		console.error(e)
	}
}

export const updateTaskCache = async (client, task) => {
	try {
		const { id, description, completed, __typename } = task
		await client.cache.modify({
			id: client.cache.identify({
				id,
				__typename
			}),
			fields: {
				description() {
					return description
				},
				completed() {
					return completed
				}
			}
		})
	} catch (error) {
		console.error(error)
	}
}

export const getConnectionCount = client => {
	const connectionCount = { Supporting: 0, Opposing: 0, Undefined: 0, Total: 0 }
	//console.log("client.cache.data.data",client.cache.data.data)
	let relations
	const cacheData = client.cache.readQuery({ query: GET_RELATIONSHIPS })
	if(cacheData) {
		relations  = cacheData.relations
	} else{
		return connectionCount
	}
	relations.forEach((value) => {
		if (
			
			value.connectionType === 'Static'
			
		) {
			connectionCount.Total += 1
			if (
				value.structureTag === 'Ally' ||
				value.structureTag === 'Supporting' ||
				value.structureTag === 'Protagonist'
			) {
				connectionCount.Supporting += 1
			} else if (
				value.structureTag === 'Opponent' ||
				value.structureTag === 'Opposing' ||
				value.structureTag === 'Antagonist'
			) {
				connectionCount.Opposing += 1
			} else if (value.structureTag === null) {
				connectionCount.Undefined += 1
			}
		}
	})

	return connectionCount
}

export const getRelationsByConnectionType = client => {
	const relationsByType = Object.entries(client.cache.data.data).reduce((prev, [key, value]) => {
		if (
			key.includes('Relationship:rel-') &&
			value.connectionType === 'Static' &&
			!value.destNode?.__ref.includes('Chapter:chp-') &&
			!value.sourceNode?.__ref.includes('Chapter:chp-')
		) {
			if (
				value.structureTag === 'Ally' ||
				value.structureTag === 'Supporting' ||
				value.structureTag === 'Protagonist'
			) {
				if (prev.Supporting) prev.Supporting.push(value)
				else prev.Supporting = [value]
			} else if (
				value.structureTag === 'Opponent' ||
				value.structureTag === 'Opposing' ||
				value.structureTag === 'Antagonist'
			) {
				if (prev.Opposing) prev.Opposing.push(value)
				else prev.Opposing = [value]
			} else if (value.structureTag === null) {
				if (prev.Undefined) prev.Undefined.push(value)
				else prev.Undefined = [value]
			}
		}
		return prev
	}, {})
	return relationsByType

}

export const updateCacheBeatsDriven = async (client, connectionsId, isRemove, beat,broadcast=true) => {
	try {
		const { relations } = await client.cache.readQuery({ query: GET_RELATIONSHIPS })
		let newConnections = JSON.parse(JSON.stringify(relations))
		newConnections = newConnections.map(newConn => {
			if (connectionsId?.includes(newConn.id)) {

				const beatsDriven = isRemove
				? !!newConn?.beatsDriven && +(newConn?.beatsDriven) >= 0
					? (+(newConn?.beatsDriven) - 1)
					: (0)
				: (+(newConn.beatsDriven) + 1)

				const sharedBeats = isRemove ?
				newConn.sharedBeats.filter(sharedBeat => sharedBeat.id ===beat.id  )
				: newConn.sharedBeats.concat({id:beat.id})

				return {
					...newConn,
					beatsDriven: String(Math.max(beatsDriven,0)),
					sharedBeats: sharedBeats,
				}
			}
			return newConn
		})

		await client.cache.evict({
			fieldName: 'relations',
			broadcast: false,
			// No need to trigger a broadcast here, since writeQuery will take care of that.
		})

		await client.cache.writeQuery({
			query: GET_RELATIONSHIPS,
			data: {
				relations: newConnections,
			},
			broadcast
		})

	} catch (e) {
		console.error(e)
	}
}

export const moveBeatCacheUpdate = async (client, connectionToDelete, connectionsToUpdate, newBeatConnection, broadcast=true) => {

	// needs to update the beatOrder in the place where the beat was deleted.
	
	const requiredRelationshipFields = {
	
		arc: null,
		connectionType: "Beat",
		destNode: null,
		id: createNodeId('Relationship'),
		order: null,
		sourceNode: null,
		name: null,
		description: null,
		arcStage: null,
		relName: null,
		__typename: 'Relationship',
		structureTag: null,
		beatsDriven: null,
		sharedBeats:[],
		firstBeat: '',
		createdAt: new Date().toISOString(),
		updatedAt: new Date().toISOString()
  


	}


	newBeatConnection = {...requiredRelationshipFields, ...newBeatConnection}


	const { relations } =  client.cache.readQuery({ query: GET_RELATIONSHIPS })

	const connectionRef = { __ref: `${newBeatConnection.__typename}:${newBeatConnection.id}` }	
	
	const newRels = JSON.parse(JSON.stringify(relations)).filter((rel)=>{ return rel.id !== connectionToDelete?.id}).concat(newBeatConnection)
	
	if(connectionsToUpdate?.length) {
		connectionsToUpdate?.forEach((beat) => {
			const indexRel = newRels.findIndex((rel) => rel.id === beat.connectionId)
			
			if(indexRel>=0){
				
				newRels[indexRel].order = beat.order
			}

		})
	}


	
	
	const { nodes } =  client.cache.readQuery({ query: GET_ALL_NODES })

	if (newBeatConnection.connectionType === 'Beat') {

		const beatId =
		newBeatConnection?.sourceNode.__typename === 'Beat'
				? newBeatConnection?.sourceNode.id
				: newBeatConnection.destNode.id
		const beat = nodes.find(node => node.id === beatId)

		beat.driverConnections.forEach((conn) =>{
			const driver = conn.sourceNode.__typename === "Beat"? conn.destNode : conn.sourceNode
			createConnections(client, newBeatConnection.connectionType, driver, connectionRef, false )

		})

	} 
	createConnections(client, newBeatConnection.connectionType, newBeatConnection?.sourceNode, connectionRef, false)
	createConnections(client, newBeatConnection.connectionType, newBeatConnection.destNode, connectionRef, false )

	

	client.cache.evict({
		fieldName: 'relations',
		broadcast: false,
		// No need to trigger a broadcast here, since writeQuery will take care of that.
	})

	client.cache.writeQuery({
		query: GET_RELATIONSHIPS,
		data: {
			relations:newRels,
		},
		broadcast: !connectionToDelete?.id && broadcast
	})


	if(connectionToDelete?.id){
		await deleteCacheConnections(client, connectionToDelete, broadcast)
		
	}
}


