import {
    SET_CURRENT_ELEMENT,
    SET_CURRENT_STORY_ID,
    SET_DIVING_DEEP,
    SET_SCROLL_POSITION,
    SET_GRAPH_DATA,
    SET_IS_MOBILE,
    SET_LEFT_TOGGLE_TABS,
    SET_RIGHT_TOGGLE_TABS,
    SET_SHOW_TOOLTIP,
    SET_USER_SUBSCRIBED,
    SET_LOGIN_USER,
    SET_BEATS_IN_CHAPTERS,
    SET_CONNECTION_DATA,
    SET_UNSEEN_RECOMMENDATION_COUNT,
    SET_SUMMARY_VIEW,
    SET_IS_NEW_USER,
    SET_RECOMMENDATION_OPENED,
    SET_IS_PROMOTION_VISIBLE,
    RESET_GRAPH_DATA,
    SET_CACHE_CONN_DESC,
    SET_NEW_CONN_ID,
    SET_CACHE_CONN_SELECTION,
    SET_CURRENT_FLOW,
} from "../actionTypes"

const initialGraphData = {
    nodes: {},
    nodesByType: {},
    loading: false,
    error: null,
    allNodes: {
        nodes: []
    }
}

const initialState = {
    currentElement: "",
    isDivingDeep: false,
    leftToggleTabs: true,
    rightToggleTabs: false,
    isUserSubscribed: false,
    showToolTip: false,
    graphData: initialGraphData,
    isMobile: false,
    currentStoryId:null,
    loginUser: { name: "", defaultStoryId: null },
    scrollPosition: {
        elementList: 0,
        chapterTab: 0
    },
    beatsInChapters: 0,
    connectionData: {},
    unseenRecommendationCount: {
        total: 0,
        craft: 0,
        speed: 0,
        habit: 0
    },
    isSummaryView: false,
    isNewUser: false,
    isRecommendationOpen: false,
    isPromotionVisible: false,
    cacheConnData: {
        description: '',
        newConnId: '',
        selection: null
    },
    flow: ''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_ELEMENT:
            return {...state, currentElement: action.payload}

        case SET_DIVING_DEEP:
            return {...state, isDivingDeep: action.payload}

        case SET_LEFT_TOGGLE_TABS:
            return {...state, leftToggleTabs: action.payload}

        case SET_RIGHT_TOGGLE_TABS:
            return {...state, rightToggleTabs: action.payload}

        case SET_USER_SUBSCRIBED:
            return {...state, isUserSubscribed: action.payload}

        case SET_SHOW_TOOLTIP:
            return {...state, showToolTip: action.payload}

        case SET_GRAPH_DATA:
            return {...state, graphData: action.payload}

        case RESET_GRAPH_DATA:
            return {...state, graphData: initialGraphData}

        case SET_IS_MOBILE:
            return {...state, isMobile: action.payload}

        case SET_CURRENT_STORY_ID:
            return {...state, currentStoryId: action.payload}

        case SET_SCROLL_POSITION:
            return {...state, scrollPosition: {
                ...state.scrollPosition,
                [action.payload.container] : action.payload.position
            }}
        case SET_LOGIN_USER:
            return {...state, loginUser: action.payload}
        case SET_BEATS_IN_CHAPTERS:
            return {...state, beatsInChapters: action.payload}
        case SET_CONNECTION_DATA:
            return {...state, connectionData: action.payload}
        case SET_UNSEEN_RECOMMENDATION_COUNT:
            return {...state, unseenRecommendationCount: action.payload}
        case SET_SUMMARY_VIEW:
            return {...state, isSummaryView: action.payload}
        case SET_IS_NEW_USER:
            return {...state, isNewUser: action.payload}
        case SET_RECOMMENDATION_OPENED:
            return { ...state, isRecommendationOpen: action.payload}
        case SET_IS_PROMOTION_VISIBLE:
            return { ...state, isPromotionVisible: action.payload}
        case SET_CACHE_CONN_DESC:
            return {...state, cacheConnData: {
                ...state.cacheConnData,
                description: action.payload
            }}
        case SET_NEW_CONN_ID:
            return {...state, cacheConnData: {
                ...state.cacheConnData,
                newConnId: action.payload
            }}
        case SET_CACHE_CONN_SELECTION:
            return {
                ...state, cacheConnData: {
                    ...state.cacheConnData,
                    selection: action.payload
                }
            }
        case SET_CURRENT_FLOW:
            return { ...state, flow: action.payload }
        default:
            return state
    }
}
