import React, { useRef, useEffect, useState } from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'

import {
	Card,
	DifficultyLevelIcon,
	Info,
	PropValues,
	TutorialInfo,
	TutorialStatusIcon,
	ValuePropImg,
} from './styles'
import inCompleteIcon from '../../images/incomplete-tutorial-icon.svg'
import completedIcon from '../../images/completed-tutorial-icon.svg'
import craftImage from '../../images/craft.svg'
import outlineImage from '../../images/outline.svg'
import habitImage from '../../images/habit.svg'
import difficultyBeginnerIcon from '../../images/difficulty-beginner-icon.svg'
import difficultyIntermediateIcon from '../../images/difficulty-intermediate-icon.svg'
import difficultyAdvancedIcon from '../../images/difficulty-advanced-icon.svg'
import {
	useLazyGetTutorialsQuery,
	useUpdateRecommendationStatusMutation,
	useUpdateRecommendationVisibilityMutation,
} from '../../hooks'
import { updateCacheField } from '../../utils/apollo'
import { useApolloClient } from '@apollo/client'


export const valuePropImages = {
	craft: craftImage,
	speed: outlineImage,
	habit: habitImage,
}

export const difficultyLevelIcon = {
	beginner: difficultyBeginnerIcon,
	intermediate: difficultyIntermediateIcon,
	advanced: difficultyAdvancedIcon,
}

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: '0',
		padding: '3px 5px',
		fontSize: '9px',
		background: '#55534F',
	},
	valuePropTooltip: {
		position: 'relative',
		bottom: '10px',
		margin: '0',
		padding: '3px 5px',
		fontSize: '9px',
		background: '#55534F',
		textTransform: 'capitalize',
	},
}))

const TutorialCard = ({ tutorial,setOpenedTutorialId }) => {
	const [updateRecommendationVisibility] = useUpdateRecommendationVisibilityMutation()
	const [updateRecommendationStatus] = useUpdateRecommendationStatusMutation()
	//const [getTutorials] = useLazyGetTutorialsQuery({ fetchPolicy: 'cache-only' })
	const client = useApolloClient()
	

	const [isHovered, setIsHovered] = useState(false)

	const classes = useStyles()

	const tutorialCardRef = useRef()

	useEffect(() => {
		if (tutorial?.visibility === 'unseen') {
			const observer = new IntersectionObserver(entries => {
				// isVisible to the user
				if (entries[0].isIntersecting) {
					observer.unobserve(tutorialCardRef?.current)
					
					updateCacheField(client,{id:tutorial?.id, __typename:"Tutorial"},{
						visibility: 'seen',
					},true)
					//getTutorials()
					updateRecommendationVisibility({
						variables: {
							level: tutorial?.level,
							recommendationId: tutorial?.id,
							visibility: 'seen',
							type: 'Tutorial',
						},
					})
						.then(() => {
							//getTutorials()
						})
						.catch(error => {
							observer.observe(tutorialCardRef?.current)
							console.log(error)
						})
				}
			})
			observer.observe(tutorialCardRef?.current)
			return () => {
				observer.disconnect()
			}
		}
	}, [])

	return (
		<Card 
			ref={tutorialCardRef}  
			onClick={(e)=> {
				e.stopPropagation()
				setOpenedTutorialId(tutorial.id)

				const changeStatus = {
					complete:"active",
					active:"complete"
				}
				
				if(tutorial?.status !=="complete"){
					updateCacheField(client,{id:tutorial?.id, __typename:"Tutorial"},{
						status: "complete",
					},true)


					try {
					
						updateRecommendationStatus({
							variables: {
								level: tutorial?.level,
								recommendationId: tutorial?.id,
								status: changeStatus[tutorial?.status],
								type: 'Tutorial',
							},
						})
					} catch (error) {
						console.log(error)
					}
				}
				
				
				}
				
			
			
			}

				

			>
			<Tooltip
				open={tutorial?.status !== 'complete' && isHovered}
				title="Mark as completed"
				sx={{ padding: 0, margin: 0 }}
				classes={{ tooltip: classes.tooltip }}
				placement="bottom-start"
			>
				<TutorialStatusIcon
					src={tutorial?.status === 'complete' ? completedIcon : inCompleteIcon}
					isCompleted={tutorial?.status === 'complete'}
					onClick={async e => {
						e.stopPropagation()
						const changeStatus = {
							complete:"active",
							active:"complete"
						}
						
						try {
							updateCacheField(client,{id:tutorial?.id, __typename:"Tutorial"},{
								status: changeStatus[tutorial?.status],
							},true)
							await updateRecommendationStatus({
								variables: {
									level: tutorial?.level,
									recommendationId: tutorial?.id,
									status: changeStatus[tutorial?.status],
									type: 'Tutorial',
								},
							})
						} catch (error) {
							console.log(error)
						}
						
					}}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				/>
			</Tooltip>
			<TutorialInfo>
				<p className="title" >
					{tutorial?.title}
				</p>
				<Info>
					<DifficultyLevelIcon
						src={difficultyLevelIcon[tutorial?.difficulty]}
						alt="difficulty level icon"
					/>
					<span className="difficulty-text">{tutorial?.difficulty}</span>
					<AccessTimeIcon className="time-icon" />
					<span className="time-text">{tutorial?.timeToComplete} min to Complete</span>
				</Info>
			</TutorialInfo>
			<PropValues>
				{tutorial?.valueProps?.map(valueProp => (
					<Tooltip
						title={valueProp}
						classes={{ tooltip: classes.valuePropTooltip }}
						key={valueProp}
					>
						<ValuePropImg src={valuePropImages[valueProp]} alt="value prop img" />
					</Tooltip>
				))}
			</PropValues>
		</Card>
	)
}

export default TutorialCard
