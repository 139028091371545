import React, { useEffect } from 'react'
import { Wrapper, TextDiv } from './styles'

const SubscriptionFooter = ({ text, isActive, target }) => {
	useEffect(() => {
		const targetElem = document.getElementById(target)
		if (isActive) {
			targetElem.childNodes.forEach(elem => {
				if (elem.id !== 'modal-wrapper') {
					elem.style.filter = 'blur(1.5px)'
				}
			})
		}
	}, [isActive])

	useEffect(() => {
		const targetElem = document.getElementById(target)
		return () => {
			targetElem.childNodes.forEach(elem => {
				elem.style.filter = 'blur(0px)'
			})
		}
	}, [])

	return (
		<Wrapper isActive={isActive} id="modal-wrapper">
			<TextDiv>{text}</TextDiv>
		</Wrapper>
	)
}

export default SubscriptionFooter
