/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect, useRef } from 'react'
import { useApolloClient } from '@apollo/client'
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
	arrayMove,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Quill from 'quill'
import orderBy from 'lodash/orderBy'
import { makeStyles } from '@mui/styles'

import { useHistory } from 'react-router-dom'
import { useDndContext, useDroppable } from '@dnd-kit/core'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import {
	elementReordered,
	setActiveConnections,
	setConnectionCount,
	setCurrentStory,
	setDeepModeElement,
	setIsCatelogOpen,
	setOpenToolTip,
	setScrollPosition,
} from '@lynit/shared/src/state/actions'
import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'

import ChapterBeatsContainer from '@lynit/shared/src/ui/ChapterBeatsContainer'
import { userStateContext } from '@lynit/shared/src/state/userProvider'


import Box from '@mui/material/Box'
import CreateConnectionModal from '@lynit/shared/src/ui/CreateConnectionModal'
// import StyledTooltip from '../../StoryOutline/StyledToolTip'
import { Tooltip } from '@mui/material'
import getConnections from '@lynit/shared/src/utils/getConnections'

import {
	getNodeIcon,
	nodeTypeForId,
	sortNodesByCreatedAt,
} from '@lynit/shared/src/utils/utils'
import relationshipIcon from '@lynit/shared/src/images/active-relationship-icon.svg'
import activeBeatsIccon from '@lynit/shared/src/images/active-beats-icon.svg'
import missingPremiseDriverIcon from '@lynit/shared/src/images/missing-premise-driver.svg'
import missingTag from '@lynit/shared/src/images/missing-tag.svg'

import {
	createCacheConnection,
	createCacheElement,
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheBeatsDriven,
	updateCacheDescriptionElement,
	updateCacheOrderBeat,
} from '@lynit/shared/src/utils/apollo'

import {

	UncollapsedElement,

	ConnectionWrapper,

	NewIndividualConnectionWrapper,
	RelationshipHeaderContainer,
	RelationshipLabelContainer,
	RelationshipLabel,
	BeatsCountContainer,
	TotalBeatCount,
	ExtraBeatsContainer,
	ElementCardContainer,
	ElementLable,
	ElementLableText,
	ElementCard,
	CreateRelation,
	CreateRelationLable,
	Card,
	EmptyDriverWrapper,
	EmptyDriverLabel,
	EmptyDriverDescription,
	BeatSectionWrapper,
	BeatHeader,
	BeatLable,
	BeatDescription,
	DriverTypeDevider,
	BeatsWrapperArea,
	ThirdBeatWrapper,

} from './styles'

import {
	useGetRelationshipsQuery,
	useReorderBeatsMutation,
	useReorderNodesMutation,
	useUpdateNodeMutation,
	useDeleteConnectionMutation,
	useCreateBeatMutation,
	useDraggable,
} from '@lynit/shared/src/hooks'

import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import whitePlusIconYellowBackground from '@lynit/shared/src/images/whitePlusIconYellowBackground.svg'
import Arrow from '../../../../shared-package/src/images/arrow.svg'
import DraggableBeats from '@lynit/shared/src/ui/BeatsContainer/DraggableBeats'
import MissingPremiseDriver from '../ListElement/MissingPremiseDriver'
import NewConnectionCard from '../ListElement/NewConnectionCard'
import MissingStructureTagDriver from '../ListElement/MissingStructureTagDriver'
import BeatsContainer from '@lynit/shared/src/ui/BeatsContainer/BeatsContainer'

const Delta = Quill.import('delta')

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: 0,
		background: '#55534F',
		display: props => props.isTooltip && 'none',
	},
	tooltip1: {
		display: props => !props.isCollapsed && 'none',
		background: '#55534F',
	},
	initialsTooltip: {
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timeline: {
		margin: '0',
		padding: '7px 5px',
	},
	timelineItem: {
		'&:before': {
			display: 'none',
		},
		minHeight: 'unset',
		boxSizing: 'border-box',
		justifyContent: 'center',
		alignItems: 'center',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineDot: {
		margin: 0,
		backgroundColor: '#78C091',
	},
	timelineChapter: {
		margin: 0,
		backgroundColor: nodeColors.Chapter,
		width: '8px',
		height: '8px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		color: '#000000',
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: '#78C091',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: 0,
	},
	tab: {
		color: '#888585',
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: 'normal',
		textTransform: 'none',
		minWidth: '70px',
		padding: '2px 5px',
	},
	tabSelected: {
		color: '#000000 !important',
	},
	indicator: {
		height: '6px',
		borderRadius: '10px',
		backgroundColor: '#FFC300',
	},
	tabs: {
		borderBottom: '6px solid #fff5d4',
		borderRadius: '4px',
	},
}))

const a11yProps = index => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const RelationshipList = React.memo(
	({
		type,
		id,
		active,
		setServerError,
		serverErrorInterval,
		setNoElementError,
		isOverlay,
	}) => {
		const client = useApolloClient()
		const {
			state: { activeConnections, currentStory },
			dispatch: sharedDispatch,
		} = useContext(sharedContext)

		const {
			currentElement: currentDeepModeElement,
			currentStoryId: storyId,
			deepModeElement,
			serverError,
			noElementError,
		} = useContext(beatsDataStateContext)

		const beatsDataDispatch = useContext(beatsDataDispatchContext)
		const { createLog, toastHandler } = useContext(systemStateContext)
		const graphData = useContext(graphDataStateContext)
		const { user, refetchUser } = useContext(userStateContext)
		const driverTypeList = ['Character', 'Arc', 'Theme', 'Setting','Event']
		const [isEdit, setEdit] = useState(false)
		const [beatContentChanged, setBeatContentChanged] = useState(false)
		const [newBeat, setNewBeat] = useState(null)
		const [tempBeatContent, setTempBeatContent] = useState('')
		const [tabValue, setTabValue] = useState(0)
		const [isDocumentHovered, setIsDocumentHovered] = useState(false)
		const [missingBeatConnections, setMissingBeatConnections] = useState(0)
		const [connUsedAsBeatDrivers, setConnUsedAsBeatDrivers] = useState(0)
		const [connectionsArr, setConnectionsArr] = useState([])
		const [connectionModal, setConnectionModal] = useState(false)
		const [connectionModalType, setConnectionModalType] = useState(false)
		const [structureTagConnnectionModal, setStructureTagConnnectionModal] = useState(false)
		const [isTooltipOpen, setTooltipOpen] = useState(false)
		const [missingPremiseConnections, setMissingPremiseConnections] = useState([])
		const [missingStructureTagConnection, setMissingStructureTagConnection] = useState([])
		const [openedConnectionCard, setOpenedConnectionCard] = useState(false)
		const [currentDriverType, setCurrentDriverType] = useState()
		const [isNewStructureTag, setIsNewStructureTag] = useState()
		const [isAutoSelect, setIsAutoSelect] = useState(false)

		const [reorderNodes] = useReorderNodesMutation()
		const [updateBeat] = useUpdateNodeMutation('Beat')
		const { data: relationships } = useGetRelationshipsQuery({ fetchPolicy: 'cache-only' , component:"RelationshipList"})

		const countRef = React.useRef()
		const saveInterval = useRef()
	

		const [beatsList,setBeatslist] = useState([])

		const name = graphData?.nodes?.[id]?.name
		useEffect(()=>{
			const newBeatList = graphData?.nodes?.[id]?.driverConnections?.map(connection => {
				const beatId = connection?.sourceNode?.id?.startsWith('bea')
									? connection?.sourceNode?.id
									: connection?.destNode?.id
				return { id:beatId,connectionId:connection.id, order: +connection.order }
			})
		
			const orderedBeatList= orderBy(newBeatList, ['order'], ['asc'])

			setBeatslist(orderedBeatList)

		},[graphData?.nodes?.[id]?.driverConnections])

		useEffect(async () => {
			if (newBeat && tempBeatContent) {
				client.cache.modify({
					id: client.cache.identify({
						id: newBeat.id,
						__typename: newBeat.__typename,
					}),
					fields: {
						description() {
							return tempBeatContent.description
						},
					},
				})
				try {
					await updateBeat({
						variables: {
							id: newBeat.id,
							input: {
								description: tempBeatContent.description,
								updatedAt: new Date().toISOString(),
							},
						},
					})
				} catch (error) {
					console.error(error)
					client.cache.modify({
						id: client.cache.identify({
							id: newBeat.id,
							__typename: newBeat.__typename,
						}),
						fields: {
							description() {
								return ''
							},
						},
					})
				}
				setNewBeat(null)
				setTempBeatContent('')
			}
		}, [tempBeatContent, newBeat])

		const classes = useStyles({
			isTooltip: true,
			isCollapsed: false,
		})


		
		const displayNewElement = type => {
			switch (type) {
				case 'Theme':
					return `Theme`
				default:
					return type
			}
		}

		const deleteBeatConnectionFromCache = async beatConnection => {
			await deleteCacheConnections(client, beatConnection)

			const connectedChapterId = beatConnection.destNode?.id
			const connectedChapter = graphData.nodesByType['Chapter'].find(
				chapter => chapter?.id === connectedChapterId,
			)

			const mutationData = []

			connectedChapter?.beatConnections.forEach(connection => {
				if (+connection.order > +beatConnection.order) {
					mutationData.push({
						id: connection.id,
						order: String(+connection.order - 1),
					})
				}
			})

			if (mutationData.length) {
				await updateCacheOrderBeat(client, mutationData)
			}
		}

		const reorderNodesAfterDelete = async deletedNodeId => {
			const mutationData = []

			const deletedNode = graphData.nodes[deletedNodeId]

			graphData.nodesByType[deletedNode.__typename].forEach(element => {
				if (element?.order > deletedNode?.order) {
					mutationData.push({
						id: element?.id,
						order: String(element?.order - 1),
					})
				}
			})

			await reorderNodes({
				variables: {
					nodeType: active,
					newNodeOrder: mutationData,
				},
			})
				.then(res => {
					if (res && serverError) {
						beatsDataDispatch(setServerError(false))
						clearInterval(serverErrorInterval.current)
					}
				})
				.catch(async err => {
					if (err.message.includes('Cannot update')) {
						beatsDataDispatch(setNoElementError(true))
					}
					if (err.message.includes('Failed to fetch') || err.message.includes('NetworkError')) {
						beatsDataDispatch(setServerError(true))
					}
				})
				.finally(() => {
					setTimeout(() => refetchUser(), 0)
				})
		}

		const MissingKeyRelationShip = ({ driverType, driverName }) => {
			const description = {
				Character: `Increase ${driverName}’s emotional depth`,
				Theme: `Add thematic significance to ${driverName}`,
				Setting: `Compare and Contrast different Settings`,
				Arc: `Strengthen ${driverName}’s influence on the plot `,
				Event: `Connect ${driverName} to a key moment`,
			}
			return (
				<EmptyDriverWrapper>
					<EmptyDriverLabel>{`${driverName} is missing a${
						['Arc', 'Event'].includes(driverType)
							? 'n ' + displayNewElement(driverType)
							: ' ' + displayNewElement(driverType)
					} relationship`}</EmptyDriverLabel>
					<EmptyDriverDescription>{description[driverType]}</EmptyDriverDescription>
				</EmptyDriverWrapper>
			)
		}

		

		useEffect(() => {
			const connectionCount = {
				Characters: 0,
				Arcs: 0,
				Events: 0,
				Themes: 0,
				Chapters: 0,
				Total: 0,
			}
			const childCount = {
				'In Chapter': 0,
				Unused: 0,
				Total: 0,
			}
			if (graphData?.nodesByType?.[type]?.length) {
				const currentElement = graphData?.nodesByType?.[type]?.find(data => data.id === id)
				if (currentElement?.staticConnections?.length) {
					currentElement?.staticConnections?.forEach(item => {
						if (item?.destNode?.__typename !== 'Note' && item?.sourceNode?.__typename !== 'Note') {
							if (item?.destNode?.id !== id) {
								connectionCount[`${item?.destNode?.__typename}s`] += 1
							} else if (item?.sourceNode?.id !== id) {
								connectionCount[`${item?.sourceNode?.__typename}s`] += 1
							}
							connectionCount['Total'] += 1
						}
					})
				}
				childCount['Total'] = currentElement?.driverConnections?.length || 0
			}
			countRef.current = { connectionCount, childCount }
		}, [type, graphData?.allNodes.nodes])

		useEffect(() => {
		
			let missingBeatConnectionsCount = beatsList?.filter(beat => {
				return !graphData.nodes[beat?.id]?.beatConnections?.[0]
						
			})
			
			setMissingBeatConnections(missingBeatConnectionsCount)
		}, [beatsList])

		useEffect(() => {
			let connUsedAsBeatDrivers = 0
			connectionsArr?.all?.forEach(connections => {
				connUsedAsBeatDrivers =
					connections.beatsDriven && connections.beatsDriven > 0
						? connUsedAsBeatDrivers + 1
						: connUsedAsBeatDrivers
			})
			setConnUsedAsBeatDrivers(connUsedAsBeatDrivers)
			let missingPremiseNodes = []

			if (graphData.nodes[id]?.coreElement === 'true') {
				missingPremiseNodes = graphData.allNodes.nodes.filter(node => {
					if (node.coreElement === 'true' && node.id != id && connectionsArr?.premise) {
						const doesPremiseConnectionExist = connectionsArr?.premise?.find(
							conn => node.id === conn.destNode.id,
						)

						return !doesPremiseConnectionExist
					}

					return false
				})
			} else {
			}

			setMissingPremiseConnections(missingPremiseNodes)
			let missingStructureTagNodes = []
			missingStructureTagNodes = connectionsArr?.all?.filter(rel => {
				return !rel.structureTag
			})

			setMissingStructureTagConnection(missingStructureTagNodes)
		}, [connectionsArr])

		useEffect(() => {
			if (id) {
				if (saveInterval.current) {
					clearTimeout(saveInterval.current)
				}
				saveInterval.current = setTimeout(() => {
					setConnectionsArr(getConnections(id, relationships?.relations, graphData?.nodes))
				}, 100)

				return () => {
					clearTimeout(saveInterval.current)
				}
			}
			
			// if (activeConnections.isActive) {
			// 	console.log("relationships?.relations",relationships?.relations)
			// 	setConnectionsArr(
			// 		getConnections(activeConnections.elementId, relationships?.relations, graphData.nodes),
			// 	)
			// }
		}, [relationships?.relations, graphData.nodes, activeConnections])

		const getTrimmedNodeName = node => {
			if (node?.name) {
				return node?.name?.length > 26 ? `${node?.name?.substring(0, 26)} ...` : node?.name
			}
			return ''
		}
		const tabValueLabel = { 0: 'Connections', 1: 'Beats' }

		

		const handleClick = (event, connectionModalType = { type: 'Opposing' }) => {
			setConnectionModalType(connectionModalType)
			setConnectionModal(event.currentTarget)
			createLog(
				'Connection Creation Attempted',
				`{"workflowStep":${1},"connectionType":"Static", "connectionModalType":"${
					connectionModalType.type
				}"}`,
				'RelationshipList',
				'Connection Creation',
			)
		}
		const handleMissingDriverClick = (event, connectionModalType = { type: 'Opposing' }) => {
			setConnectionModalType(connectionModalType)
			setStructureTagConnnectionModal(event)
			// createLog(
			// 	'Connection Creation Attempted',
			// 	`{"workflowStep":${1},"connectionType":"Static", "connectionModalType":"${
			// 		connectionModalType.type
			// 	}"}`,
			// 	'RelationshipList',
			// 	'Connection Creation',
			// )
		}

	

		return (
			<>
				<div
					className="element-container"
					style={{ cursor: isOverlay ? 'grabbing' : 'unset' }}
				>
					<UncollapsedElement
						isCollapsed={false}
						type={type}
						className="uncollapsed-element"
						// style={{ padding: type === 'Chapter' ? '8px 8px 8px 0' : '8px' }}
					>
						<>
							<Box sx={{ width: '100%', flex: `1 1 0px`, paddingTop: '15px' }}>
								<BeatHeader>
									<BeatLable>Capture the Relationships</BeatLable>
									<BeatDescription>
										Deepen {name}'s structure by Supporting or Opposing other Drivers.
									</BeatDescription>
								</BeatHeader>
								<div
									role="tabpanel"
									hidden={tabValue !== 0}
									id={`simple-tabpanel-${0}`}
									key={'relationships'}
									aria-labelledby={`simple-tab-${0}`}
									style={{ position: 'relative', marginTop: '30px' }}
								>
									<RelationshipHeaderContainer>
										<RelationshipLabelContainer>
											<img src={relationshipIcon} />
											<RelationshipLabel>Relationships</RelationshipLabel>
										</RelationshipLabelContainer>
										<BeatsCountContainer>
											<Tooltip
												title={"Total Count of Relationships"}
												placement="top"
												arrow
												classes={{
													tooltip:  {
														background: '#55534F',
													},
													arrow: {
														color: '#55534F',
													},
												}}
											>
												<TotalBeatCount>
													<p>{`${connectionsArr?.all?.length || 0}`}</p>
													<img src={relationshipIcon} />
												</TotalBeatCount>
											</Tooltip>
											<Tooltip
												title={"Missing Premise Relationships"}
												placement="top"
												arrow
												classes={{
													tooltip:  {
														background: '#55534F',
													},
													arrow: {
														color: '#55534F',
													},
												}}
											>
												<ExtraBeatsContainer>
													<p>{missingPremiseConnections?.length || 0}</p>
													<img src={missingPremiseDriverIcon} />
												</ExtraBeatsContainer>
											</Tooltip> 
										</BeatsCountContainer>
									</RelationshipHeaderContainer>
									<BeatsWrapperArea>
										{driverTypeList.map(driver => {
											const filteredMissingDriversList = missingPremiseConnections.filter(
												driverData => driverData.__typename === driver,
											)
											const filteredDriversList = connectionsArr?.all
												?.filter(
													driverData =>
														driverData.__typename === driver && driverData?.structureTag,
												)
												.sort((a, b) => (a?.createdAt ? sortNodesByCreatedAt(a, b) : -1))
											const missingStructureTagDriversList = missingStructureTagConnection
												?.filter(connection => connection.__typename === driver)
												.sort((a, b) => (a?.createdAt ? sortNodesByCreatedAt(a, b) : -1))
											return (
												<ElementCardContainer key={driver}>
													<ElementCard key={driver}>
														{!filteredDriversList?.length &&
															!filteredMissingDriversList?.length &&
															!missingStructureTagDriversList?.length && (
																<MissingKeyRelationShip
																	driverType={driver}
																	driverName={graphData?.nodes[id]?.name}
																/>
															)}
														<CreateRelation
															onMouseEnter={() =>
																createLog(
																	'Create Relationship Button Hover',
																	`{"driverType":"${driver}", "relationshipGroupDriverType": "${driver}"}`,
																	'List Element',
																	null,
																)
															}
															onClick={e => {
																handleClick(e, { type: 'Supporting' })
																setCurrentDriverType(driver)
															}}
															style={{
																marginBottom:
																	!filteredDriversList?.length &&
																	!filteredMissingDriversList?.length &&
																	!missingStructureTagDriversList?.length
																		? 0
																		: `10px`,
															}}
														>
															<img className ={'create-relation'} src={whitePlusIconYellowBackground} />
															<CreateRelationLable>
																Connect a{["Arc",'Event'].includes(driver) ? "n": ""} {displayNewElement(driver)}
															</CreateRelationLable>
															<ElementLable>
																<img
																	src={getNodeIcon(`Driver-${driver}`)}
																	width={driver === 'Character' ? '12px' : '20px'}
																/>
																<ElementLableText>{`${displayNewElement(
																	driver,
																)}s`}</ElementLableText>
															</ElementLable>
														</CreateRelation>
														<Card>
															{missingStructureTagDriversList?.length > 0 &&
																missingStructureTagDriversList.map((node, index) => {
																	return (
																		<NewIndividualConnectionWrapper
																			key={node.id}
																			firstCard={
																				missingStructureTagDriversList?.length === 0 && index === 0
																			}
																			prevConnCard={missingStructureTagDriversList?.length > 0}
																			type={node.__typename}
																			isMissingDriver={true}
																			beatsDriven={0}
																			isCollapsed={true}
																		>
																			<MissingStructureTagDriver
																				key={node.id}
																				id={node.id}
																				type={node.__typename}
																				text={node.name}
																				handleClick={handleMissingDriverClick}
																				connectionsArr={connectionsArr}
																				connectionModalType={{
																					type: node.__typename,
																					recommendedDriverId: node.id,
																				}}
																				setCurrentDriverType={setCurrentDriverType}
																				referenceElement={graphData?.nodes[id]}
																				sourceElement={graphData?.nodes[node.destNode.id]}
																			/>
																		</NewIndividualConnectionWrapper>
																	)
																})}
															{filteredMissingDriversList?.length > 0 &&
																filteredMissingDriversList.map((node, index) => {
																	return (
																		<NewIndividualConnectionWrapper
																			key={node.id}
																			firstCard={
																				filteredMissingDriversList?.length === 0 && index === 0
																			}
																			prevConnCard={filteredMissingDriversList?.length > 0}
																			type={node.__typename}
																			isMissingDriver={true}
																			beatsDriven={0}
																			isCollapsed={true}
																		>
																			<MissingPremiseDriver
																				key={node.id}
																				id={node.id}
																				text={node.name}
																				handleClick={handleMissingDriverClick}
																				connectionModalType={{
																					type: node.__typename,
																					recommendedDriverId: node.id,
																					isRecommendedDriver: true,
																				}}
																				referenceElement={graphData?.nodes[id]}
																			/>
																		</NewIndividualConnectionWrapper>
																	)
																})}
															{filteredDriversList?.length > 0 && (
																<ConnectionWrapper className="connectionList">
																	{/* <Line /> */}
																	{filteredDriversList?.map((element, index) => {
																		return (
																			<NewIndividualConnectionWrapper
																				key={element?.id}
																				firstCard={index === 0}
																				prevConnCard={false}
																				type={element?.__typename}
																				isMissingDriver={false}
																				beatsDriven={element?.beatsDriven}
																			>
																				<NewConnectionCard
																					key={element?.id}
																					isDefaultOpened={connectionsArr?.all?.length < 5}
																					id={element?.id}
																					name={element?.name}
																					type={element?.__typename}
																					structureTag={element?.structureTag}
																					beatsDriven={element?.beatsDriven}
																					sourceNode={element?.sourceNode}
																					description={element?.description}
																					firstBeat={element?.firstBeat}
																					disableHoverListener={true}
																					createLog={createLog}
																					setCurrentElement={() => {}}
																					sharedBeats={element?.sharedBeats}
																					referenceElement={graphData?.nodes[id]}
																					destNode={
																						element?.sourceNode.id === id
																							? graphData?.nodes[element?.destNode.id]
																							: graphData?.nodes[element?.sourceNode.id]
																					}
																					isNewStructureTag={isNewStructureTag}
																					tabValue={tabValue}
																					setOpenedConnectionCard={setOpenedConnectionCard}
																					openedConnectionCard={openedConnectionCard}
																					parentLocation="Driver Doc"
																				></NewConnectionCard>
																			</NewIndividualConnectionWrapper>
																		)
																	})}
																</ConnectionWrapper>
															)}
														</Card>
													</ElementCard>
												</ElementCardContainer>
											)
										})}
									</BeatsWrapperArea>
								</div>
							</Box>
							<DriverTypeDevider />
							<div
								role="tabpanel"
								hidden={false}
								id={`simple-tabpanel-${1}`}
								style={{
									flex: `1 1 1px`,
									paddingTop: '15px',
								}}
								key={'beats'}
								aria-labelledby={`simple-tab-${1}`}
							>
								<BeatSectionWrapper>
									<BeatHeader>
										<BeatLable>Outline your {type} with Beats.</BeatLable>
										<BeatDescription>
											Detail the sequence of actions and information reveals that develop {name}. Specify who drives the moment.
										</BeatDescription>
									</BeatHeader>
								</BeatSectionWrapper>
								<RelationshipHeaderContainer
									style={{
										marginTop: '30px',
										marginBottom: '18px',
									}}
								>
									<RelationshipLabelContainer>
										<img src={activeBeatsIccon} />
										<RelationshipLabel>Beats</RelationshipLabel>
									</RelationshipLabelContainer>
									<BeatsCountContainer>
										<Tooltip
											title={`Total Beats that ${name} drives.`}
											placement="top"
											arrow
											classes={{
												tooltip:  {
													background: '#55534F',
												},
												arrow: {
													color: '#55534F',
												},
											}}
										>
											<TotalBeatCount>
												<p>{`${beatsList?.length || 0}`}</p>
												<img src={activeBeatsIccon} />
											</TotalBeatCount>
										</Tooltip>
										<Tooltip
											title={"Beats that haven't been placed in Chapters yet."}
											placement="top"
											arrow
											classes={{
												tooltip:  {
													background: '#55534F',
												},
												arrow: {
													color: '#55534F',
												},
											}}
										>
											<ExtraBeatsContainer isMulti>
												<p>{missingBeatConnections.length || 0}</p>
												<img src={missingPremiseDriverIcon} />
											</ExtraBeatsContainer>
										</Tooltip>
									</BeatsCountContainer>
								</RelationshipHeaderContainer>

								{type !== 'Chapter' && (
									<BeatsContainer
										referenceElement={graphData?.nodes?.[id]}
										location={"Driver Doc"}
										locationId={id}
										beatsList={beatsList}
										connectionTypeField={"driverConnections"}
									/>
								) }
							</div>
						</>
					</UncollapsedElement>
				</div>
				{(connectionModal || structureTagConnnectionModal) && connectionModalType && (
					<CreateConnectionModal
						mainElementId={id || activeConnections?.elementId}
						mainElementType={id ? graphData?.nodes?.[id]?.__typename : activeConnections?.elementType}
						isOpen={connectionModal}
						isOpenStructureTagModal={structureTagConnnectionModal}
						setStructureTagConnnectionModal={setStructureTagConnnectionModal}
						suggetionHandleClick={() => {}}
						setConnectionModalType={setConnectionModalType}
						setConnectionModal={setConnectionModal}
						connectionsArr={connectionsArr}
						connectionModalType={connectionModalType}
						setIsAutoSelect={setIsAutoSelect}
						isAutoSelect={isAutoSelect}
						handleClose={() => {
							setStructureTagConnnectionModal(false)
							setConnectionModal(false)
							setConnectionModalType(false)
							setIsAutoSelect(false)
						}}
						setIsNewStructureTag={setIsNewStructureTag}
						currentDriverType={currentDriverType}
					/>
				)}
				
			</>
		)
	},
)



export default React.memo(RelationshipList)


