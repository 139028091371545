/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React from 'react'



import { useCallback } from 'react'
import ThreePartDoc from '../ThreePartDoc'
import DeepModeElement from '@lynit/deep-mode-element/src/ui/DeepModeElement'
import ChapterBeatsContainer from '@lynit/shared/src/ui/ChapterBeatsContainer'

const ChapterDoc = ({
	id,
}) => {

	//const graphData = useContext(graphDataStateContext)
	
	const BottomSectionInputComponent = useCallback(() => {
		return(<DeepModeElement
			id={id}
			placeholder={"Type here."}
		/>)
	},[id])
	
	const middleSectionDecriptionFields = {
		Chapter: [
			{ 
				label: 'Summary', 
				slug: 'description', 
				placeholder: `Summarize what happens in this Chapter.`, },
		],
		
	}

	const MiddleSectionInputComponent = useCallback(() => {

		return(
			<div
			style={{
				maxWidth:"400px",
				display: "flex",
				flexDirection: "column",
				padding: " 10px 0px 0px 0px"
			}
			}>
				<ChapterBeatsContainer
						id={id}
						type={"Chapter"}
						connectionTypeField={"beatConnections"}
						
				/>
			</div>
		)



	},[id])


	// return useMemo(() => {
	return id && (
		<ThreePartDoc
		id={id}
		docType={"Chapter"}
		middleSectionDecriptionFields={middleSectionDecriptionFields["Chapter"]}
		MiddleSectionInputComponent={MiddleSectionInputComponent}
		BottomSectionInputComponent={BottomSectionInputComponent}
		/>
	) 
	
}

export default React.memo(ChapterDoc)
