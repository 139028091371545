import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_CUSTOMER_PORTAL } from '../data'

export const useGetCustomerPortalQuery = (options = {}) => {
	const result = useQuery(GET_CUSTOMER_PORTAL, options)
	return result
}

export const useLazyGetCustomerPortalQuery = (options = {}) => {
	const result = useLazyQuery(GET_CUSTOMER_PORTAL, options)
	return result
}
