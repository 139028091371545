import styled from 'styled-components'
import { nodeColors } from '../../utils/commonStyles'

const getColor = type => {
	switch (type) {
		case 'Chapter':
			return nodeColors.Chapter
		case 'Character':
			return nodeColors.Character
		case 'Arc':
			return nodeColors.Arc
		case 'Event':
			return nodeColors.Event
		case 'Theme':
			return nodeColors.Theme
		case 'Note':
			return nodeColors.Note
		default:
			return 'white'
	}
}

const ElementIconContainer = styled.div`
	display: flex;
	align-items: center;
	border-radius: 7px;
	background-color: #d9d9d9;
	width: 14px;
	height: 14px;
	overflow: hidden;
	cursor: pointer;
	.remove-driver-name {
		white-space: nowrap;
		font-size: 10px;
		padding-left: 2px;
	}
	transition: width 0.5s;
	.remove-driver {
		width: 10px;
		padding: 0 2px;
	}
`

const NodeIconContainer = styled.div`
	min-width: 14px;
	width: 14px;
	height: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: '50%';
	background-color: ${props => nodeColors[props.type]};
	span {
		font-size: 8px;
	}
`

export { ElementIconContainer, NodeIconContainer }
