import React, { createContext, useState } from 'react'

export const TutorialContext = createContext()

const TutorialContextProvider = ({ children }) => {
	const [currentTutorial, setCurrentTutorial] = useState()
	const [clickedValueProp, setClickedValueProp] = useState()

	return (
		<TutorialContext.Provider
			value={{
				currentTutorial,
				setCurrentTutorial,
				clickedValueProp,
				setClickedValueProp,
			}}
		>
			{children}
		</TutorialContext.Provider>
	)
}

export default TutorialContextProvider
