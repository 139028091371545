import { useMutation } from '@apollo/client'
import { CREATE_BEAT } from '../data'
//import { createCacheConnection, createCacheElement } from '../utils/apollo'

export const useCreateBeatMutation = (options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	
	// await createCacheElement(client, newBeat, false, false)
    // await createCacheConnection(client, newDriverConnection,false,true)
	const result = useMutation(CREATE_BEAT, options)
	return result
}