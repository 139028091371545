import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_TUTORIALS } from '../data'

export const useGetTutorialsQuery = (options = {}) => {
	const result = useQuery(GET_TUTORIALS, options)
	return result
}

export const useLazyGetTutorialsQuery = (options = {}) => {
	const result = useLazyQuery(GET_TUTORIALS, options)
	return result
}
