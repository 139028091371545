/* eslint-disable prettier/prettier */
import styled from 'styled-components'
import { colorStyles } from '@lynit/shared/src/utils/commonStyles'

const ElementListContainer = styled.div`
	width: auto;
	height: 100%;
	min-height: 20rem;
	display: flex;
	flex-direction: row;
	z-index: -1;
`

const TabsContainer = styled.div`
	overflow-y: auto;
	width: 70px;
	display: block;
	background-color: #f4f4f4;
	z-index: 0;
	padding-top: 10px;

	user-select: none;
	@media (max-width: 600px) {
		width: 4rem;
		font-size: 0.6rem;
	}
`
const Tab = styled.div`
	max-width: 25px;
	margin: 0 auto;
	width: auto;
	height: auto;
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: center;
	justify-content: center;
	border-bottom: ${props => (props.isActive ? '1px solid #000000' : '1px solid #A6A6A6')};
	border-top: ${props => (props.isActive ? '1px solid #000' : '1px solid #A6A6A6')};
	z-index: 0;
	cursor: pointer;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 0.9rem;
	p,
	.icon-container {
		opacity: ${props => (props.isActive ? 1 : 0.6)};
	}

	.dragger {
		align-self: flex-start;
		margin: 5px 0px 0px 5px;
	}

	&:after {
		content: '';
		position: absolute;
		width: 2px;
		height: 64px;
		background-color: ${props => (props.isActive ? '#222222' : 'none')};
		right: -18px;

		@media (max-width: 600px) {
			right: -16px;
		}
	}
`

const Border = styled.div`
	width: calc(100% - 25px);
	height: 1px;
	margin: 0 auto;
	background: black;
`

const IconContainer = styled.div`
	position: relative;
	margin: 5px 0 25px 0;
	margin-bottom: ${props => props.isSelected && '30px'};
	width: 26px;
	height: 26px;
	border-radius: 100px;
	text-align: center;
	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 1.3rem;

		@media (max-width: 600px) {
			width: 1rem;
		}
	}
`

const TabText = styled.p`
	position: absolute;
	left: 50%;
	top: 37.43%;
	transform: translateX(-52%);

	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 15px;
	text-align: center;

	color: #000000;
	font-weight: ${props => (props.isActive ? 'bold' : 'normal')};
	opacity: ${props => (props.isActive ? '1' : '0.8')};
	@media (max-width: 500px) {
		font-size: 0.6rem;
	}

	.chapetername {
		display: flex;
		text-align: center;
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 700;
		font-size: 10px;
		line-height: 1.1;
		flex-direction: column;

		@media (max-width: 600px) {
			font-size: 0.55rem;
		}
	}

	.element-count {
		opacity: 0.6;
	}
`

const ElementContainer = styled.div`
	width: calc(100% - 64px);
	height: auto;
	display: ${props => (props.rightToggleTabs ? 'flex' : 'none')};
	flex-direction: column;
	justify-content: flex-start;
	background-color: #ececec;
	overflow-y: auto;
	position: relative;

	::-webkit-scrollbar-thumb {
		background: #9d9d9d;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		background: #ececec;
	}

	::-webkit-scrollbar {
		width: 0.3rem;
	}

	@media (max-width: 600px) {
		z-index: ${props => (props.rightToggleTabs ? '1' : 'unset')};
	}
`

const CreateButton = styled.button`
	width: auto;
	height: auto;
	background-color: ${colorStyles.lynitYellow};
	border: none;
	border-radius: 5px;
	color: black;
	font-size: 0.7rem;
	font-weight: bold;
	font-family: 'Source Sans Pro', sans-serif;
	margin-bottom: 0.7rem;
	align-self: center;
	box-shadow: 0 4px 4px -2px #c4c4c4;
	cursor: pointer;
	padding: 0.5rem;
	white-space: nowrap;
	&:hover {
		text-decoration: none;
		transform: translateY(-2px);
	}
	&:active {
		transform: translateY(0);
		transition: background 400ms;
	}
	@media (min-width: 601px) and (max-width: 768px) {
		font-size: 0.6rem;
		padding: 0.5rem 0.5rem;
	}
	@media (max-width: 600px) {
		font-size: 0.6rem;
	}
`

const TypeText = styled.p`
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: 600;
	font-size: 1.1rem;
	line-height: 23px;
	letter-spacing: -1px;
	margin-top: 0;
	color: #4a4a4a;
	@media (min-width: 601px) and (max-width: 1200px) {
		font-size: 0.8rem;
	}
	@media (max-width: 600px) {
		font-size: 1.1rem;
	}
	@media (max-width: 430px) {
		font-size: 0.9rem;
	}
`

const Header = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	div:nth-child(1) {
		width: auto;
		display: flex;
		align-items: flex-start;
	}
`

const CountPill = styled.div`
	width: 30px;
	height: 15px;
	line-height: 15px;
	font-size: 11px;
	font-weight: 700;
	text-align: center;
	color: #000000;
	background-color: #c4c4c4;
	border-radius: 8px;
	margin-left: 0.5rem;
	margin-top: 0.3rem;
	@media (min-width: 601px) and (max-width: 768px) {
		width: 15px;
		margin-left: 0.25rem;
	}
`

const ToggleElementList = styled.button`
	width: 30px;
	height: 30px;
	border: none;
	background: transparent;
	padding: 0;
	margin: 9px 20px;
	img {
		width: 30px;
		margin: 0;
		cursor: pointer;
	}
`
const CollapseDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ececec;
	width: 10px;
	border-radius: 7px 0px 0px 7px;
	:hover {
		background: #e0e0e0;
		cursor: pointer;
		img {
			visibility: visible;
			transform: ${props => (props.toggle ? 0 : 'rotate(180deg)')};
		}
	}
	img {
		visibility: hidden;
	}
`

export {
	Tab,
	ElementContainer,
	ElementListContainer,
	CreateButton,
	TabsContainer,
	TabText,
	IconContainer,
	TypeText,
	Header,
	CountPill,
	Border,
	ToggleElementList,
	CollapseDiv,
}
