import { useMutation } from '@apollo/client'
import { updateNodeMutations } from '../data'

export const useUpdateNodeMutation = (key, options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const result = useMutation(updateNodeMutations[key||"Character"], options)
	return result
}
