import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeVideo from 'rehype-video'
import Link from './Link'

const MarkDown = ({ markdownText, handleOpenTutorial = () => {} }) => {
	return (
		<ReactMarkdown
			rehypePlugins={[[rehypeVideo, { details: false }]]}
			children={markdownText}
			components={{
				a: props => <Link {...props} handleOpenTutorial={handleOpenTutorial} />,
			}}
		/>
	)
}

export default MarkDown
