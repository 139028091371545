import React, { useContext } from 'react'
import { SavingNotification } from './styles'
import { autoSaveStateContext } from '../../state/autoSaveProvider'
import { systemStateContext } from '../../state/systemProvider'
import { beatsDataStateContext } from '../../state/beatsProvider'

const AutoSave = ({ isMobile }) => {
	const beatsRelatedData = useContext(beatsDataStateContext)
	const systemRelatedData = useContext(systemStateContext)
	const {isSaving} = useContext(autoSaveStateContext)

	return (
		<SavingNotification
			isMobile={isMobile}
			isError={beatsRelatedData?.serverError || beatsRelatedData?.noElementError || (window.navigator.onLine && systemRelatedData?.wasOffline)}
		>
			{isSaving ? 'Saving...' : 'Generating Export...'}
		</SavingNotification>
	)
}

export default AutoSave
