import React, { useState, useEffect, useContext } from 'react'
import SwipeableViews from 'react-swipeable-views'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'

import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import BorderLinearProgress from '@lynit/shared/src/shared-ui/BorderLinearProgress'
import { imgRegEx } from '@lynit/shared/src/utils/utils'
import {
	useLazyGetRecommendationsQuery,
	useUpdateRecommendationVisibilityMutation,
} from '@lynit/shared/src/hooks'

import {
	Button,
	EmptyState,
	Pill,
	RecommendationCard,
	RecommendationIndicator,
	ValuePropContainer,
} from './styles'
import leftArrow from '../../images/left-arrow.svg'
import rightArrow from '../../images/right-arrow.svg'
import { valuePropColors } from '../../utils/valuePropColors'
import { setRecommendationOpen } from '../../state/actions'
import { storyOutlineContext } from '../../state/storyOutlineProvider'
import { NotificationIconWithCount } from '../NotificationIcon'

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: '0',
		padding: '3px 5px',
		fontSize: '9px',
		background: '#55534F',
	},
}))

const ValuePropRecommendations = ({
	valuePropImage,
	valuePropTitle,
	recommendations,
	emptyStateImg,
	setCurrentRecommendation,
	propName,
	isRightPanel,
}) => {
	const classes = useStyles()

	const { dispatch } = useContext(storyOutlineContext)
	const {
		state: { unseenRecommendationCount, leftToggleTabs, rightToggleTabs },
	} = useContext(sharedContext)

	const [updateRecommendationVisibility] = useUpdateRecommendationVisibilityMutation()
	const [, { refetch: refetchRecommendations }] = useLazyGetRecommendationsQuery()

	const [activeStep, setActiveStep] = useState(0)

	const handleNext = e => {
		e.stopPropagation()
		setActiveStep(prevActiveStep => prevActiveStep + 1)
	}

	const handleBack = e => {
		e.stopPropagation()
		setActiveStep(prevActiveStep => prevActiveStep - 1)
	}

	const handleStepChange = step => {
		setActiveStep(step)
	}

	useEffect(async () => {
		if (recommendations[activeStep]?.visibility === 'unseen') {
			try {
				await updateRecommendationVisibility({
					variables: {
						level: recommendations[activeStep]?.level,
						recommendationId: recommendations[activeStep]?.id,
						visibility: 'seen',
						type: 'Recommendation',
					},
				})
				refetchRecommendations()
			} catch (error) {
				console.error(error.message)
			}
		}
	}, [recommendations, activeStep])

	return (
		<ValuePropContainer
			backgroundColor={valuePropColors[propName]?.backgroundColor}
			leftToggleTabs={leftToggleTabs}
			rightToggleTabs={rightToggleTabs}
			isRightPanel={isRightPanel}
		>
			{!!unseenRecommendationCount[propName] && (
				<NotificationIconWithCount
					top={-8}
					right={-8}
					count={unseenRecommendationCount[propName]}
				/>
			)}
			<img src={valuePropImage} alt="value prop" className="value-prop-img" />
			<p className="value-prop-title">{valuePropTitle}</p>
			<Pill backgroundColor={valuePropColors[propName]?.color}>{recommendations?.length || 0}</Pill>

			{recommendations?.length ? (
				<RecommendationCard
					onClick={() => {
						setCurrentRecommendation({
							data: recommendations[activeStep],
							propName,
							valuePropImage,
						})
						dispatch(setRecommendationOpen(true))
					}}
				>
					<SwipeableViews index={activeStep} onChangeIndex={handleStepChange}>
						{recommendations.map(recommendation => {
							const isImage = recommendation.image ? imgRegEx.test(recommendation.image) : ''
							return (
								<React.Fragment key={recommendation.id}>
									<p className="title">
										{recommendation.visibility !== 'opened' && <RecommendationIndicator />}
										{recommendation.title}
									</p>
									<p className="message">
										{recommendation.message?.length > 100
											? `${recommendation.message?.slice(0, 100)}...`
											: recommendation.message}
									</p>
									<div className="media-container">
										{recommendation.image &&
											(isImage ? (
												<img src={recommendation.image} alt="recommendation-image" />
											) : (
												<video controls>
													<source src={recommendation.image} />
												</video>
											))}
									</div>
									<p className="progress-title">Progress:</p>
									<BorderLinearProgress
										variant="determinate"
										value={recommendation?.progress||0}
										barcolor={valuePropColors[propName]?.color}
										primarycolor={valuePropColors[propName]?.primaryColor}
									/>
								</React.Fragment>
							)
						})}
					</SwipeableViews>
					{activeStep !== 0 && (
						<Tooltip title="Previous" classes={{ tooltip: classes.tooltip }}>
							<Button className="prev-btn" onClick={handleBack}>
								<img src={leftArrow} alt="left-arrow" />
							</Button>
						</Tooltip>
					)}
					{activeStep !== recommendations?.length - 1 && (
						<Tooltip
							title="Next"
							sx={{ padding: 0, margin: 0 }}
							classes={{ tooltip: classes.tooltip }}
						>
							<Button className="next-btn" onClick={handleNext}>
								<img src={rightArrow} alt="right-arrow" />
							</Button>
						</Tooltip>
					)}
				</RecommendationCard>
			) : (
				<EmptyState color={valuePropColors[propName]?.color}>
					<img src={emptyStateImg} alt="empty state image" />
					<p>
						Keep outlining to get <br /> new recommendations
					</p>
				</EmptyState>
			)}
		</ValuePropContainer>
	)
}

export default ValuePropRecommendations
