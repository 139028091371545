/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */

const buildStoryTextQuill = async (quill, nodesData) => {
	const storyTextType = ['Chapter', 'Character', 'Arc', 'Event', 'Theme']
	const Nodes = nodesData.nodes
	const ElementLists = Object.values(Nodes)

	if (quill) {
		const ElementStory = []
		let quillData

		for (let k = 0; k < storyTextType.length; k++) {
			const elementData = ElementLists.filter(el => el.__typename === storyTextType[k])
			let isStoryText = false

			for (let i = 0; i < elementData.length; i++) {
				if (elementData[i] && elementData[i].storyText) {
					quillData = JSON.parse(elementData[i].storyText)
					if (!isStoryText) {
						if (k !== 0) {
							ElementStory.push({
								insert: '\n',
							})
						}
						ElementStory.push(
							...[
								{
									insert: storyTextType[k],
								},
								{
									insert: '\n',
									attributes: { align: 'center', header: 1, size: '20' },
									pageBreakBefore: true,
								},
							],
						)
					}
					isStoryText = true
				} else {
					quillData = ''
				}
				quill.setContents(quillData)
				const tempData = quill.getText()
				if (quillData && tempData && tempData !== '\n') {
					const chapterTitleText = [
						{
							insert: `\n ${elementData[i].__typename}: ${elementData[i].number || 0}  ${
								elementData[i].name
							} `,
						},
						{
							insert: '\n',
							attributes: { align: 'left', header: 1, size: '20' },
						},
						...quillData.ops,
					]
					const tempQuillDataText = tempData.split('\n')
					let tempNewLines = tempQuillDataText.length + 1
					for (const quillTextData of tempQuillDataText) {
						tempNewLines += Math.floor(quillTextData.length / 82)
					}
					const length = isStoryText ? 25 : 24
					const endIndex = length - (tempNewLines % length)
					if (endIndex < length) {
						for (let tempIndex = 0; tempIndex <= endIndex; tempIndex++) {
							chapterTitleText.push({ insert: '\n' })
						}
					}
					ElementStory.push(...chapterTitleText)
				}
			}
		}
		const document = await quill.setContents([...ElementStory])
		return document
	}
}

export default buildStoryTextQuill
