import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_PROMOTION } from '../data'

export const useGetPromotionQuery = (options = {}) => {
	const result = useQuery(GET_PROMOTION, options)
	return result
}

export const useLazyGetPromotionQuery = (options = {}) => {
	const result = useLazyQuery(GET_PROMOTION, options)
	return result
}
