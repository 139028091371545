import { withStyles } from '@mui/styles'
import Tooltip from '@mui/material/Tooltip'

const SuggestionTooltip = withStyles({
	tooltip: {
		width: '160px',
		backgroundColor: '#A1E4FA',
		color: '#000000',
		padding: '8px',
		boxShadow: '2px 5px 12px rgba(0, 0, 0, 0.12)',
	},
	arrow: {
		color: '#A1E4FA',
	},
})(Tooltip)

export default SuggestionTooltip
