import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_ALL_NODES } from '../data'

export const useGetAllNodesQuery = (options = {}) => {
	// let context = options.context
	
	// context ={...context, serializationKey: 'MUTATION'}
	// options.context = context

	const result = useQuery(GET_ALL_NODES, options)
	return result
}

export const useLazyGetAllNodesQuery = (options = {}) => {
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}
	const result = useLazyQuery(GET_ALL_NODES, options)
	return result
}
