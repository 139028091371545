import styled from 'styled-components'

const BellIcon = styled.img`
	position: absolute;
	width: ${({ width }) => (width ? `${width}px` : '21px')};
	height: ${({ width: height }) => (height ? `${height}px` : '21px')};
	${({ top, right, bottom, left }) => `
        top: ${`${top}px`};
        right: ${`${right}px`};
        bottom: ${`${bottom}px`};
        left: ${`${left}px`};
    `}
`

const BellIconContainer = styled.div`
	position: relative;
	width: 100%;
`

const Count = styled.div`
	position: absolute;
	${({ top, right, bottom, left }) => `
        top: ${`${top - 1}px`};
        right: ${`${right - 2}px`};
        bottom: ${`${bottom}px`};
        left: ${`${left}px`};
    `}

	width: 10px;
	height: 10px;
	background-color: #49c100;
	border-radius: 50%;
	font-size: 8px;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
`

export { BellIcon, BellIconContainer, Count }
