import React from 'react'

import AlertModalIcon from '@lynit/shared/src/images/iconAlert.svg'
import Logo from '@lynit/shared/src/images/lynitYellow.svg'
import LeftIcon from '../images/assetErrorLetf.svg'
import RightIcon from '../images/assetErrorRight.svg'

import {
	FallbackUIContainer,
	TopContainer,
	MiddleContainer,
	BottomContainer,
	LynitLogo,
	Icon,
	AlertIcon,
	BackButton,
	BottomText,
	BottomLink,
	ErrorModal,
	ErrorModalTopContainer,
	ErrorModalBottomContainer,
	ErrorModalTopTitle,
	ErrorModalTopText,
	ErrorModalBottomText,
	ReloadButton,
} from './styles'

const FallbackUI = () => {
	return (
		<FallbackUIContainer>
			<TopContainer>
				<LynitLogo src={Logo} onClick={() => window.location.replace('/dashboard')} />
				<BackButton onClick={() => window.location.replace('/dashboard')}>
					{'< Back to main dashboard'}
				</BackButton>
			</TopContainer>

			<MiddleContainer>
				<Icon src={LeftIcon} />
				<ErrorModal>
					<ErrorModalTopContainer>
						<AlertIcon src={AlertModalIcon} />
						<ErrorModalTopTitle>OH SNAP!</ErrorModalTopTitle>
						<ErrorModalTopText>
							{window.innerWidth <= 768
								? 'An error ocurred'
								: 'An error ocurred while loading the screen'}
						</ErrorModalTopText>
					</ErrorModalTopContainer>{' '}
					<ErrorModalBottomContainer>
						<ErrorModalBottomText>
							We’re having trouble showing this info to you. Our team is already notified so you can
							go back or reload this screen and everything should be fine.
						</ErrorModalBottomText>
						<ReloadButton onClick={() => window.location.reload()}>Reload</ReloadButton>
					</ErrorModalBottomContainer>
				</ErrorModal>
				<Icon src={RightIcon} />
			</MiddleContainer>

			<BottomContainer>
				<BottomText>
					Feel free to{' '}
					<BottomLink target={'_blank'} href={'https://www.lynit.app/contact'}>
						contact us{' '}
					</BottomLink>
					and we’ll be happy to help you.
				</BottomText>
			</BottomContainer>
		</FallbackUIContainer>
	)
}

export default FallbackUI
