/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'

import { useHistory } from 'react-router-dom'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'

import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {

	useGetRelationshipsQuery,
	useLazyGetRelationshipsQuery,
} from '@lynit/shared/src/hooks'

import { useMemo } from 'react'
import { useCallback } from 'react'
import ThreePartDoc from '../ThreePartDoc'

//import StoryStructure from '../StoryStructure/StoryStructure'
import DriverList from '@lynit/shared/src/ui/DriverList'
import getConnections from '@lynit/shared/src/utils/getConnections'
import { DriverContainer, DriverLabel, PremiseDriver, PremiseRelation, PremiseWrapper } from '../PremisePlanner/style'
import AllRelationship from '@lynit/shared/src/ui/Search/AllRelationshipList'
import { analyticsDataStateContext } from '@lynit/shared/src/state/analyticsDataProvider'
import { orderBy } from 'lodash'
import { Tooltip } from '@mui/material'
import { ExtraBeatsContainer } from '../ThreePartDoc/styles'
import missingPremiseDriverIcon from '@lynit/shared/src/images/missing-premise-driver.svg'
import { getNodeIcon } from '@lynit/shared/src/utils/utils'
import { EmptyDriverDescription, EmptyDriverWrapper } from '../ListElement/styles'
import { BeatDescription } from '../DriverDoc/styles'
import CoachMatrics from '../StoryStructure/CoachMatrics'
import RadarChart from '@lynit/story-outline/src/ui/StoryOutline/RadarChart'
import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import { StoryArea, StoryDescription, StoryHeader, StoryHeadingContainer, StoryLabel } from '../StoryStructure/styles'


const OutliningPlanner = ({}) => {
	const graphData = useContext(graphDataStateContext)
	
	const { createLog, isMobile } = useContext(systemStateContext)
	const { data: relationship, loading: loadingRelationship } = useGetRelationshipsQuery({fetchPolicy: 'cache-only', component:"OutliningPlanner"})
	const {
			missingPremiseRelationships
			, premiseRingCompletionPct
			, milestoneBeatsCount
			, missingMilestoneBeatsCount
			, driversByDesignation
			, driversWithABeat
			, relationshipsWithABeat
			, secondaryDriversWithMileStoneBeat
			, minorDriversWithRelationship
			, relationshipsByType
			, beatsByDriverType
			, milestonBeatsByDriverType
		} = useContext(analyticsDataStateContext)
	const [premiseRelationshipList, setpremiseRelationshipList] = useState([])
	
	const outliningID = graphData.nodesByType["Outlining"]?.[0]?.id

// 	- [ ]  Drivers driving at least one beat out of drivers.
// - [ ]  Secondary Drivers should drive at least one milestone beat
// - [ ]  Relationships with at least one beat
// - [ ]  Minor Drivers with relationships out of total minor drivers count

	const metricsConfig = [
		{
			title: 'Drivers driving a Beat',
			description: 'Each Driver should drive at least one Beat',
			count: driversWithABeat.length,
			total: driversByDesignation?.total?.length,
		},
		{
			title: 'Secondary Drivers Driving a Milestone Beat',
			description: 'Each Secondary Driver should drive at least one Milestone Beat',
			count: secondaryDriversWithMileStoneBeat.length,
			total: driversByDesignation.secondary.length,
		},
		{
			title: 'Relationships used',
			description: 'Each Relationship should be captured by at least one Beat',
			count: relationshipsWithABeat.length,
			total: relationshipsByType.static.length,
		},
		{
			title: 'Minor Drivers with Relationships',
			description: 'Each Minor Driver should have at least one Relationship',
			count: minorDriversWithRelationship.length,
			total: driversByDesignation.minor.length,
		},
		{
			title: 'Beats with at least 1 Driver',
			description: 'All Beats should have a Driver',
			count: beatsByDriverType.withDriver.length,
			total:beatsByDriverType.withDriver.length + beatsByDriverType.noDriver.length,
		},
	]

	const activeCoachConfig = {
		isActive: premiseRingCompletionPct === 1,
		progressPct: premiseRingCompletionPct > 0 ? premiseRingCompletionPct * 100 : 5,
		descriptionLabel: `Create ${missingPremiseRelationships.length} missing Premise Relationships and ${missingMilestoneBeatsCount} Milestone Beats to unlock Lynit Coach.`
	}
	const getBeatScore = (driverType) => {
		const ratio = (beatsByDriverType[driverType].length/(beatsByDriverType.withDriver.length || 1)) 
		return Math.round(ratio * 10) || 1
	}

	const getMilestoneBeatScore = (driverType) => {
		const ratio = (milestonBeatsByDriverType[driverType].length/(milestonBeatsByDriverType.withDriver.length || 1)) 
		return Math.round(ratio * 10) || 1
	}

	
	const driversWithNoBeats = driversByDesignation.total.filter(driver => driver?.driverConnections?.length === 0)


	const radarChartConfig ={
		title: "What's driving the story?"
		,minScore: 1
		,maxScore: 5
		, data: {
			labels: ['Characters', 'Arcs', 'Themes', 'Settings', 'Events' ]
			,datasets: [
				// {
				// 	data: [5, 5, 5, 5, 5],
				// 	backgroundColor: 'transparent',
				// 	borderColor: '#000000',
				// 	borderWidth: 1,
				// 	pointBackgroundColor: '#000000',
				// 	pointRadius: 2,
				// 	pointHoverRadius: 2,
				// 	animation: false
				// },
				{
					data: [getMilestoneBeatScore('character'), getMilestoneBeatScore('arc'), getMilestoneBeatScore('theme'), getMilestoneBeatScore('setting'), getMilestoneBeatScore('event')],
					backgroundColor: '#F2E2B180',
					//borderColor: '#41EAD4',
					borderWidth: 1,
					pointStyle: false,
					label: 'Milestone Beats Ratio'
				},
				{
					data: [getBeatScore('character'), getBeatScore('arc'), getBeatScore('theme'), getBeatScore('setting'), getBeatScore('event')],
					backgroundColor: `${nodeColors["Beat"]}80`,
					//borderColor: '#41EAD4',
					borderWidth: 1,
					pointStyle: false,
					label: 'Beats Ratio'
				},
				
			],
		}

	}

	const SavedCoachMetrics = useCallback(() => {

		return(
			<CoachMatrics metricsConfig={metricsConfig} radarChartConfig = {radarChartConfig} activeCoachConfig={activeCoachConfig} />

		)

	},[activeCoachConfig,metricsConfig,radarChartConfig])

	const BottomSectionInputComponent = useCallback(() => {
		return  (
			<div>
				
				<StoryHeadingContainer>
					<StoryHeader>
						<StoryLabel>Strengthen Your Story Web</StoryLabel>
					</StoryHeader>
					<StoryDescription>
						{"Make sure every Driver is contributing to the story with Beats and Relationships."} 
					</StoryDescription>
				</StoryHeadingContainer>
				
				<StoryArea>
					<DriverContainer>
						<DriverLabel>
							Drivers Missing a Beat
							{ (<Tooltip
									title={'These are drivers need to drive at least one Beat.'}
									placement="top"
									arrow
									classes={{
										tooltip: {
											background: '#55534F',
										},
										arrow: {
											color: '#55534F',
										},
									}}
								>
									<ExtraBeatsContainer 
									style={{visibility:  driversWithNoBeats.length > 0 ? 'visible' : 'hidden', height: '16px'}} 
									isMulti>
										<p >
											{driversWithNoBeats.length||0}
										</p>
									</ExtraBeatsContainer>
								</Tooltip>)}
						</DriverLabel>
						<PremiseDriver>
						{driversWithNoBeats.length > 0 ? driversWithNoBeats.map((element, index) => {
							return (
								<DriverList
									key={element?.id}
									id={element?.id}
									type={element?.__typename}
									isDefaultOpened={false}
									render={'driver'}
								/>
							)
						}) : (
							<EmptyDriverWrapper extraPadding>
								<EmptyDriverDescription>{`Well Done! All Drivers drive at least one Beat`}</EmptyDriverDescription>
							</EmptyDriverWrapper>
						)}
						</PremiseDriver>
					</DriverContainer>
					<DriverContainer>
						<PremiseDriver>
							<SavedCoachMetrics/>
						</PremiseDriver>
					</DriverContainer>
				</StoryArea>
			</div>
		
		
		
		
		)
	}, [driversWithNoBeats.length])



	const MiddleSectionInputComponent = useCallback(() => {
		return (
			<PremiseWrapper>
				<DriverContainer>
					<DriverLabel>
						Secondary Drivers
						{ (<Tooltip
								title={'These are drivers that have a relationship to a Premise Driver.'}
								placement="top"
								arrow
								classes={{
									tooltip: {
										background: '#55534F',
									},
									arrow: {
										color: '#55534F',
									},
								}}
							>
								<ExtraBeatsContainer 
								style={{visibility:  driversByDesignation.secondary.length > 0 ? 'visible' : 'hidden', height: '16px'}} 
								isMulti>
									<p >
										{driversByDesignation.secondary.length||0}
									</p>
								</ExtraBeatsContainer>
							</Tooltip>)}
					</DriverLabel>
					<PremiseDriver>
						{driversByDesignation.secondary.length > 0 ? driversByDesignation.secondary.map((element, index) => {
							return (
								<DriverList
									key={element?.id}
									id={element?.id}
									name={element?.name}
									type={element?.__typename}
									description={element?.description}
									relationshipsCount={element?.staticConnections?.length}
									isDefaultOpened={element.source}
									render={'driver'}
								/>
							)
						}) : (
							<EmptyDriverWrapper extraPadding>
								<EmptyDriverDescription>{`No Secondary Drivers. Secondary Drivers have a Relationship to a Premise Driver.`}</EmptyDriverDescription>
							</EmptyDriverWrapper>
						)}
					</PremiseDriver>
				</DriverContainer>
				<DriverContainer>
				<DriverLabel>
						Minor Drivers
						{ (<Tooltip
								title={'These are drivers that have no relationship to a Premise Driver.'}
								placement="top"
								arrow
								classes={{
									tooltip: {
										background: '#55534F',
									},
									arrow: {
										color: '#55534F',
									},
								}}
							>
								<ExtraBeatsContainer 
								style={{visibility:  driversByDesignation.minor.length > 0 ? 'visible' : 'hidden', height: '16px'}} 
								isMulti>
									<p >
										{driversByDesignation.minor.length||0}
									</p>
								</ExtraBeatsContainer>
							</Tooltip>)}
					</DriverLabel>
					<PremiseDriver>
						{driversByDesignation.minor.length > 0 ? driversByDesignation.minor.map((element, index) => {
							return (
								<DriverList
									key={element?.id}
									id={element?.id}
									name={element?.name}
									type={element?.__typename}
									description={element?.description}
									relationshipsCount={element?.staticConnections?.length}
									isDefaultOpened={element.source}
									render={'driver'}
								/>
							)
						}) : (
							<EmptyDriverWrapper extraPadding>
								<EmptyDriverDescription>{`No Minor Drivers. Minor Drivers don't have a Relationship to a Premise Driver.`}</EmptyDriverDescription>
							</EmptyDriverWrapper>
						)}
					</PremiseDriver>
				</DriverContainer>
			</PremiseWrapper>
		)
	}, [driversByDesignation.secondary.length,driversByDesignation.minor.length])

	// return useMemo(() => {
	return outliningID ? (
		<ThreePartDoc
		id={outliningID}
		docType={"Outlining"}
		middleSectionDecriptionFields={[]}
		MiddleSectionInputComponent={MiddleSectionInputComponent}
		BottomSectionInputComponent={BottomSectionInputComponent}
		docInstructionsText={"Secondary and Minor Drivers are variations, expansions, or tools for interrogating concepts defined in the Premise. Be careful of introducing completely new ideas here."}
		/>
	) : (
		<></>
	)
	
}

export default React.memo(OutliningPlanner)
