import BorderLinearProgress from '@lynit/shared/src/shared-ui/BorderLinearProgress'
import {
	CoachHeader,
	CoachInitialSection,
	CoachLabel,
	DescriptionLable,
	DescriptionText,
	FollowDescription,
	LynitCoachWrapper,
	MissingConnectionsContainer,
	SummaryMetricsContainer,
	Title,
} from './styles'
import { useContext, useState } from 'react'
import { CircularProgress, createTheme } from '@mui/material'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { calculateMilestoneBeatWithPremiseDrivers } from './MetricsCalculations'
import { colorStyles } from '@lynit/shared/src/utils/commonStyles'
import { analyticsDataStateContext } from '@lynit/shared/src/state/analyticsDataProvider'
import RadarChart from '@lynit/story-outline/src/ui/StoryOutline/RadarChart'



const CoachMatrics = ({ metricsConfig , radarChartConfig, activeCoachConfig ={isActive:true }}) => {
	const graphData = useContext(graphDataStateContext)
	//const { total: milestoneBeatCount } = calculateMilestoneBeatWithPremiseDrivers(graphData)
	//const {missingPremiseRelationships, premiseRingCompletionPct, milestoneBeatsCount, missingMilestoneBeatsCount} = useContext(analyticsDataStateContext)
	

	return (
		<LynitCoachWrapper>
			<CoachHeader>
				<CoachLabel>Lynit Coach</CoachLabel>
			</CoachHeader>
			<SummaryMetricsContainer visible={activeCoachConfig.isActive }>
				{radarChartConfig && activeCoachConfig.isActive && (
					<div>
						<Title>
							<div className="left">
								<span className="title">{radarChartConfig.title}</span>
							</div>
							
						</Title>
						<RadarChart radarChartConfig ={radarChartConfig}/>
					</div>
				
				)}
				{metricsConfig.map((metric, index) => {
					const {
						title,
						description,
						count,
						total,
						barColor = '#FFC300',
						primaryColor = '#F1F1F1',
						height = 6,
					} = metric

					return (
						<MissingConnectionsContainer
						key={index}
						>
							<div className="missingConneProgress">
								<div className="missingConnProgressItem">
									<Title>
										<div className="left">
											<span className="title">{title}</span>
										</div>
										{total === 0 ? (
											<div></div>
										) : (
											<span className="status">
												<b>
													{count}/{total}
												</b>
											</span>
										)}
									</Title>
									{total === 0 ? (
										<div></div>
									) : (
										<BorderLinearProgress
											variant="determinate"
											value={total > 0 ? (count / total) * 100 : 0}
											barcolor={barColor}
											primarycolor={primaryColor}
											height={height}
										/>
									)}
								</div>
							</div>
							<div className="missingConnectionText">{description}</div>
						</MissingConnectionsContainer>
					)
				})}
			</SummaryMetricsContainer>
			{ !activeCoachConfig.isActive &&  (
				<CoachInitialSection>
					<CircularProgress
						variant="determinate"
						value={activeCoachConfig.progressPct}
						style={{'color': colorStyles.lynitYellow}}
						height={'95px'}
						size={'95px'}
						thickness={2}
						sx={{
							height: '95px',
							width: '95px',
						}}
					/>
					<FollowDescription>
						<DescriptionLable>{activeCoachConfig.descriptionLabel} </DescriptionLable>
						<DescriptionText>
							See your story summarized and where to make improvements.
						</DescriptionText>
					</FollowDescription>
				</CoachInitialSection>
			)}
		</LynitCoachWrapper>
	)
}

export default CoachMatrics
