import {
	BeatsCountContainer,
	ExtraBeatsContainer,
	RelationshipHeaderContainer,
	RelationshipLabel,
	RelationshipLabelContainer,
	TotalBeatCount,
} from '../ThreePartDoc/styles'
import { Stepper, Tooltip } from '@mui/material'
import missingPremiseDriverIcon from '@lynit/shared/src/images/missing-premise-driver.svg'
import activeBeatsIccon from '@lynit/shared/src/images/active-beats-icon.svg'
import {
	SubTemplate,
	MainTemplate,
	StoryHeader,
	StoryLabel,
	StorySectionWrapper,
	StoryTemplate,
	TemplateLabel,
	StoryDescription,
	TemplateButtonWrapper,
	TemplateButton,
	StoryArea,
	LeftStoryPart,
	MileStoneLabel,
	MileStoneHeader,
	SelectTemplate,
	MileStoneDescription,
	TemplateSelectionButton,
	StoryHeadingContainer,
	BackToTemplate,
	MilestoneBeatList,
} from './styles'
import BeatsContainer from '@lynit/shared/src/ui/BeatsContainer'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { useContext, useEffect, useState } from 'react'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import CoachMatrics from './CoachMatrics'
import BackButton from '@lynit/story-outline/src/ui/BackButton'
import {
	calculateMilestoneBeatWithPremiseDrivers,
	calculatePremiseDriverWithMilestoneBeat,
	calculateRelationWithMilestoneBeat,
} from './MetricsCalculations'
import { useCreateBeatMutation, useGetRelationshipsQuery, useLazyGetRelationshipsQuery } from '@lynit/shared/src/hooks'
import { GET_TEMPLATES } from '@lynit/shared/src/data/queries/queries'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { template } from 'lodash'
import TimelineItem from '@mui/lab/TimelineItem'
import DraggableBeats from '@lynit/shared/src/ui/BeatsContainer/DraggableBeats'
import { makeStyles } from '@mui/styles'
import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import {
	createCacheConnection,
	createCacheElement,
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheField,
} from '@lynit/shared/src/utils/apollo'
import { createNodeId } from '@lynit/shared/src/utils/utils'
import { CREATE_MULTIPLE_BEATS } from '@lynit/shared/src/data'
import { analyticsDataStateContext } from '@lynit/shared/src/state/analyticsDataProvider'

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: 0,
		background: '#55534F',
		display: props => props.isTooltip && 'none',
	},
	tooltip1: {
		display: props => !props.isCollapsed && 'none',
		background: '#55534F',
	},
	initialsTooltip: {
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timeline: {
		margin: '0',
		padding: '7px 5px',
	},
	timelineItem: {
		'&:before': {
			display: 'none',
		},
		minHeight: 'unset',
		boxSizing: 'border-box',
		gap: '0px',
		alignItems: 'center',
		justifyContent: 'center',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineDot: {
		margin: 0,
		backgroundColor: '#78C091',
	},
	timelineChapter: {
		margin: 0,
		backgroundColor: nodeColors.Chapter,
		width: '8px',
		height: '8px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		color: '#000000',
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: '#78C091',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: 0,
	},
	tab: {
		color: '#888585',
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: 'normal',
		textTransform: 'none',
		minWidth: '70px',
		padding: '2px 5px',
	},
	tabSelected: {
		color: '#000000 !important',
	},
	indicator: {
		height: '6px',
		borderRadius: '10px',
		backgroundColor: '#FFC300',
	},
	tabs: {
		borderBottom: '6px solid #fff5d4',
		borderRadius: '4px',
	},
}))
const StoryStructure = () => {
	const graphData = useContext(graphDataStateContext)
	const [activeTemplate, setActiveTemplate] = useState(null)
	const [activeStep, setActiveStep] = useState(1)
	const [beatWithoutDriver, setBeatWithoutDriver] = useState(0)
	const premiseID = graphData.nodesByType["Premise"]?.[0]?.id
	const [templateBeats, setTemplateBeats] = useState([])
	const [hoveredTemplateButton,setHoveredTemplateButton] = useState(false)
	const { data: relationship, loading } = useGetRelationshipsQuery({fetchPolicy: 'cache-only', component:"StoryStructure"})
	const [createBeat] = useCreateBeatMutation({ ignoreResults: true })
	const [createMultipleBeats]= useMutation(CREATE_MULTIPLE_BEATS)
	const {missingPremiseRelationships, premiseRingCompletionPct, milestoneBeatsCount, missingMilestoneBeatsCount} = useContext(analyticsDataStateContext)
	
	
	const client = useApolloClient()
	const { createLog, toastHandler, isMobile } = useContext(systemStateContext)

	
	const classes = useStyles({
		isTooltip: false,
		isCollapsed: false,
	})
	const { data } = useQuery(GET_TEMPLATES)

	useEffect(() => {
		if (data) {
			const tempActiveTemplate = data?.getTemplates.flexible.find(template => template.id === activeTemplate?.id)
			setTemplateBeats(
				tempActiveTemplate?.milestoneBeats || [],
			)
			setActiveTemplate(tempActiveTemplate)
		}

	}, [data])

	const milestoneBeatMetrics = calculateMilestoneBeatWithPremiseDrivers(graphData)
	const premiseDriverMetrics = calculatePremiseDriverWithMilestoneBeat(graphData)
	const relationshipMetrics = calculateRelationWithMilestoneBeat(relationship, graphData)

	useEffect(() => {
		if(activeStep ===1 && premiseID ){
			setActiveStep(graphData?.nodes[premiseID]?.premiseConnections.length > 0 ? 2 : 1)
		}

	}, [premiseID])
	useEffect(() => {
		
		
		let milestoneBeatWithoutDriver = 0

		const milestoneBeats = graphData?.nodes[premiseID]?.premiseConnections?.map(d_conn =>
			d_conn?.sourceNode?.id?.startsWith('bea') ? d_conn?.sourceNode : d_conn?.destNode,
		)
		milestoneBeats.forEach(ml => {
			const driverIds = new Set(
				graphData.nodes[ml.id]?.driverConnections?.map(d_conn =>
					!d_conn?.sourceNode?.id?.startsWith('bea') ? d_conn?.sourceNode.id : d_conn?.destNode.id,
				),
			)

			const hasDriver = graphData.allNodes?.nodes?.some(
				node => driverIds.has(node.id) && node.driverConnections.length,
			)

			if (hasDriver) {
				milestoneBeatWithoutDriver += 1
			}
		})
		setBeatWithoutDriver(milestoneBeatWithoutDriver)
	}, [graphData])

	const metricsConfig = [
		{
			title: 'Milestone Beats with Premise Driver',
			description: 'Each Milestone Beat should have at least one Premise Driver',
			count: milestoneBeatMetrics.count,
			total: milestoneBeatMetrics.total,
		},
		{
			title: 'Premise Drivers driving Milestone Beats',
			description: 'Each Premise Driver should drive at least one Milestone Beat',
			count: premiseDriverMetrics.count,
			total: premiseDriverMetrics.total,
		},
		{
			title: 'Premise Relationships Used',
			description: 'Each Premise Relationship should be captured in at least one Milestone Beat',
			count: relationshipMetrics.count,
			total: relationshipMetrics.total,
		},
	]

	const activeCoachConfig = {
		isActive: premiseRingCompletionPct === 1,
		progressPct: premiseRingCompletionPct > 0 ? premiseRingCompletionPct * 100 : 5,
		descriptionLabel: `Create ${missingPremiseRelationships.length} missing Premise Relationships and ${missingMilestoneBeatsCount} Milestone Beats to unlock Lynit Coach.`
	}

	let referenceElement = graphData?.nodes[premiseID]

	const createBeatHandler = async (templateBeats) => {
		

		
		
		let tempNodeList =[]
		let tempRelationshipList = []
		templateBeats.map((templateText, index) => {
			
			let tempData
			const beatId = createNodeId('Beat')
			const relId = createNodeId('Relationship')
			let relationship

			const beat = {
				id: beatId,
				beatConnections: [],
				driverConnections: [],
				description: '',
				driverConnections: [],
				order: null,
				staticConnections: [],
				noteConnections: [],
				premiseConnections: [],
				templateText,
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				xCoordinate: null,
				yCoordinate: null,
				vizLocSaved: false,
				firstBeat: '',
				__typename: 'Beat',
				milestone: false,
				aiRecommendations: '',
			}

			relationship = {
				arc: null,
				connectionType: 'Premise',
				sourceNode: {
					id: beatId,
					__typename: 'Beat',
				},
				id: relId,
				order: String(referenceElement.premiseConnections.length + index),
				destNode: {
					id: referenceElement?.id,
					__typename: referenceElement?.__typename,
				},
				name: null,
				description: null,
				arcStage: null,
				relName: null,
				__typename: 'Relationship',
				structureTag: null,
				beatsDriven: null,
				sharedBeats: [],
				firstBeat: '',
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
			}

			tempData = {
				createBeat: {
					beat,
					relationship,
				},
			}

			tempNodeList.push(tempData)
			tempRelationshipList.push(relationship)
		})
		
	
		await createCacheElement(client, tempNodeList, false, true, true)
		await createCacheConnection(client, tempRelationshipList, true)
		createLog(
			'Beat Creation Attempted',
			`{"workflowStep":${1}}`,
			'Story Structure',
			'Use Milestone Beat Template',
		)
		
		
		const mutationData = tempNodeList.map(tempData => {

			return {
				beat: {
					id: tempData.createBeat.beat.id,
					description: tempData.createBeat.beat.description,
					templateText: tempData.createBeat.beat.templateText
				},
				relationship: {
					id: tempData.createBeat.relationship.id,
					destNodeId: tempData.createBeat.relationship.destNode.id,
					sourceNodeId: tempData.createBeat.relationship.sourceNode.id,
					description: tempData.createBeat.relationship.description,
					connectionType: tempData.createBeat.relationship.connectionType,
					structureTag: tempData.createBeat.relationship.structureTag,
					relName: 'RELATED',
					beatsDriven: tempData.createBeat.relationship.beatsDriven,
					sharedBeats:
						tempData.createBeat.relationship.sharedBeats?.map(beat => {
							return { id: beat.id }
						}) || [],
					firstBeat: '',
					order: tempData.createBeat.relationship.order,
				},
				parentId: referenceElement?.id,
			}
		})

		createMultipleBeats({
			variables: {
				multipleBeats: mutationData
			},
			ignoreResults: true,
		}).catch(async error => {
			mutationData.map(async tempData => {
				await deleteCacheElement(client, tempData.createBeat.beat)
				await deleteCacheConnections(client, tempData.createBeat.relationship)
			})
			
			toastHandler(
				'error',
				`There was an error copying the template, try again.`,
				null,
				'StoryStructure',
				'Beat Creation',
			)
			console.error(error)
		})
		

		
		
		
		
	}

	

	const handleTemplateSelection = async templateBeats => {
		createLog(
			'Selected MilestoneBeat Teplate',
			{templateName: activeTemplate?.name},
			'Story Structure',
			'Use Milestone Beat Template',
		)
		await createBeatHandler(templateBeats)

		// templateBeats.map(async (templateText, index) => {
		// 	await createBeatHandler(templateText, referenceElement.premiseConnections.length + index, index +1 === templateBeats?.length)


		// })
			
		
		
	}

	return (
		<StorySectionWrapper>
			<StoryHeadingContainer>
				<StoryHeader>
					<StoryLabel>Story Structure</StoryLabel>
					<BackToTemplate visible={true} onClick={() => setActiveStep(activeStep === 2 ? 1 : 2)}>
						{activeStep === 2 && (<BackButton />)}
						<div style={{ cursor: 'pointer' }}>{activeStep === 2 ? "Combine a different template's Beats" : "Skip Template Selection" }</div>
					</BackToTemplate>
				</StoryHeader>
				<StoryDescription>{activeStep === 1 ? "Define the key moments that shape your story. Explore and select a template to get started." : "Milestone beats are the critical actions or information reveals that define the overall development of the story. Drag and reorder your Beats as needed."} </StoryDescription>
			</StoryHeadingContainer>
			<StoryTemplate visible={activeStep === 1}>
				<MainTemplate>
					<TemplateLabel>Flexible:</TemplateLabel>
					<TemplateButtonWrapper>
						{data?.getTemplates?.flexible?.map((template, index) => {
							return (
								<TemplateButton
									isActive={activeTemplate?.id === template?.id || hoveredTemplateButton?.id === template?.id}
									key={index}
									onClick={() => {
										setTemplateBeats(template?.milestoneBeats || [])
										setActiveTemplate(template)
									}}
									onMouseEnter={() => {
										setHoveredTemplateButton(template)
									}}
									onMouseLeave={() => {
										setHoveredTemplateButton(false)
									}}
								>
									{template?.name}
								</TemplateButton>
							)
						})}
					</TemplateButtonWrapper>
				</MainTemplate>
				<MainTemplate>
					<TemplateLabel>Genre Based:</TemplateLabel>
					<TemplateButtonWrapper>
						{data?.getTemplates?.genreBased.map((template, index) => {
							return (
								<TemplateButton
									isActive={activeTemplate?.id === template.id || hoveredTemplateButton?.id === template?.id}
									key={index}
									onClick={() => {
										setTemplateBeats(template.milestoneBeats || [])
										setActiveTemplate(template)
									}}
									onMouseEnter={() => {
										setHoveredTemplateButton(template)
									}}
									onMouseLeave={() => {
										setHoveredTemplateButton(false)
									}}
								>
									{template?.name}
								</TemplateButton>
							)
						})}
					</TemplateButtonWrapper>
				</MainTemplate>
			</StoryTemplate>
		{(activeTemplate || activeStep === 2) && (<StoryArea>
			<div>
				<RelationshipHeaderContainer
					style={{
						marginTop: '0',
						marginBottom: '18px',
					}}
				>
					<RelationshipLabelContainer>
						<img src={activeBeatsIccon} />
						<RelationshipLabel>Milestone Beats</RelationshipLabel>
					</RelationshipLabelContainer>
					<BeatsCountContainer>
						<Tooltip
							title={`Total Milestone Beats.`}
							placement="top"
							arrow
							classes={{
								tooltip: {
									background: '#55534F',
								},
								arrow: {
									color: '#55534F',
								},
							}}
						>
							<TotalBeatCount>
								<p>{activeStep === 1
										? templateBeats?.length
										: milestoneBeatMetrics.total}</p>
								<img src={activeBeatsIccon} />
							</TotalBeatCount>
						</Tooltip>
						{/* <Tooltip
							title={'Beats that are missing driver.'}
							placement="top"
							arrow
							classes={{
								tooltip: {
									background: '#55534F',
								},
								arrow: {
									color: '#55534F',
								},
							}}
						>
							<ExtraBeatsContainer isMulti>
								<p>
									{activeStep === 1
										? templateBeats?.length
										: milestoneBeatMetrics.total - beatWithoutDriver}
								</p>
								<img src={missingPremiseDriverIcon} />
							</ExtraBeatsContainer>
						</Tooltip> */}
					</BeatsCountContainer>
				</RelationshipHeaderContainer>
				{activeStep === 1 ? (
					<MilestoneBeatList>
						<SortableContext items={[]} strategy={verticalListSortingStrategy}>
							{templateBeats.map((milestoneBeat, index) => {
								return (
									<TimelineItem key={index} className={classes.timelineItem}>
										<DraggableBeats
											index={index}
											key={index}
											templateText={milestoneBeat}
											type="milestoneBeat"
											readOnly={true}
											templateMilestoneBeat={true}
										/>
									</TimelineItem>
								)
							})}
						</SortableContext>
					</MilestoneBeatList>
				) : (
					<BeatsContainer
						referenceElement={graphData?.nodes[premiseID]}
						location={'Premise Planner'}
						hideCreateDragBeat={activeStep === 2}
						locationId={premiseID}
						connectionTypeField={'premiseConnections'}
					/>
				)}
			</div>
			<LeftStoryPart>
				<SelectTemplate visible={activeStep === 1}>
					<MileStoneHeader>
						<img src={activeBeatsIccon} alt="active-beats-icon" />
						<MileStoneLabel>{activeTemplate?.name}</MileStoneLabel>
					</MileStoneHeader>
					<MileStoneDescription>
						{activeTemplate?.description}
					</MileStoneDescription>
					<TemplateSelectionButton
						onClick={() => {
							handleTemplateSelection(templateBeats)
							setActiveStep(2)
						}}
					>
						{graphData?.nodes[premiseID].premiseConnections.length ? "Add Additional Milestone Beats": "Select Template"}
					</TemplateSelectionButton>
				</SelectTemplate>
				<div
					style={{
						display: activeStep === 2 ? 'block' : 'none',
					}}
				>
					<CoachMatrics metricsConfig={metricsConfig} activeCoachConfig ={activeCoachConfig} />
				</div>
			</LeftStoryPart>
		</StoryArea>)}
		</StorySectionWrapper>
	)
}
export default StoryStructure
