import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_USER_STORIES } from '../data'

export const useGetUserStoryQuery = (options = {}) => {
	const result = useQuery(GET_USER_STORIES, options)
	return result
}

export const useLazyGetUserStoryQuery = (options = {}) => {
	const result = useLazyQuery(GET_USER_STORIES, options)
	return result
}
