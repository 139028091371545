import styled from 'styled-components'
import { colorStyles, nodeColors } from '@lynit/shared/src/utils/commonStyles'

const getColor = type => {
	switch (type) {
		case 'Chapter':
			return nodeColors.Chapter
		case 'Character':
			return nodeColors.Character
		case 'Arc':
			return nodeColors.Arc
		case 'Event':
			return nodeColors.Event
		case 'Theme':
			return nodeColors.Theme
		case 'Note':
			return nodeColors.Note
		default:
			return 'white'
	}
}

const getTotalCountColor = count => {
	switch (true) {
		case count <= 10:
			return '#FF5E67'
		case count <= 20:
			return '#7C7C7C'
		case count > 20:
			return '#2FC144'
		default:
			return 'black'
	}
}

const getElementCountColor = count => {
	switch (true) {
		case count <= 3:
			return '#FF5E67'
		case count <= 10:
			return '#7C7C7C'
		case count > 10:
			return '#2FC144'
		default:
			return 'black'
	}
}

const MyStoryHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	${({ isActiveConnections }) =>
		isActiveConnections &&
		`
        ::before {
            content: "";
            position: absolute;
            border-top: 0.5px solid #000000;
            top: 21px;
            width: calc(50% - 65px);
            left: 44px;
        }
    `}

	.my-story-image {
		width: 42px;
		height: 42px;
		background: ${props =>nodeColors[props.nodeType]};
		border: 1px solid #222222;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}

	.my-story {
		font-weight: 700;
		font-size: 14px;
		display: flex;
		align-items: center;
		color: #222222;
	}

	.story-count {
		font-size: 13px;
		display: flex;
		align-items: center;
		text-align: center;
		color: ${props =>
			props.isDeepMode ? getElementCountColor(props.total) : getTotalCountColor(props.total)};
		background: #fefefe;
		border: 0.3px solid #000000;
		border-radius: 1px;
		padding: 0 7px;
		margin: 5px 0 15px;
		position: relative;

		::after {
			position: absolute;
			content: '';
			left: 50%;
			height: 17px;
			border-left: 1px ${({ total }) => (total ? 'solid' : 'dashed')} #3c40439e;
			bottom: -17px;
		}
	}
`
const MyStoryImageContainer = styled.div`
	position: absolute;
	left: 0;
	width: 42px;
	height: 42px;
	background: ${props => (props.nodeType ?nodeColors[props.nodeType] : '#FFFFFF')};
	border: 1px solid #222222;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	cursor: pointer;
`

const Line = styled.div`
	border-top: 1px ${({ isLength, isDeepMode }) => (!isLength && isDeepMode ? 'dashed' : 'solid')}
		#3c40439e;
	border-left: 1px ${({ isLength, isDeepMode }) => (!isLength && isDeepMode ? 'dashed' : 'solid')}
		#3c40439e;
	padding: 0 0 4px;
	margin: 0 0 4px;
	width: 50%;
`

const Story = styled.div`
	background: #ffffff;
	border: 1px solid #3c40439e;
	border-radius: 3px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 36px;
	width: 100%;
	height: 36px;
	position: relative;

	.icon-container {
		display: flex;
		width: 1.5rem;
		height: 1.5rem;
		justify-content: center;
		align-items: center;
		border: 1px solid ${props => nodeColors[props.type]};
		border-radius: 50%;
		object-fit: contain;
		background: ${props => nodeColors[props.type]};
		margin-bottom: 10px;
		img {
			width: 12px;
			height: auto;
		}
	}

	.element-type {
		font-size: 9px;
		color: #222222;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 42px;
		position: absolute;
		bottom: 2px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		text-align: center;
		white-space: nowrap;
	}

	@media screen and (min-width: 600px) and (max-width: 914px) {
		::after {
			height: 95px;
		}
	}
`

const ConnectionCardStyle = styled.div`
	background: #f7f7f7;
	border: 1px solid #3c40439e;
	border-radius: 4px;
	padding: 8px 8px 22px 8px;
	padding: ${({ isDeepMode }) => (isDeepMode ? '8px 8px 22px 8px' : '16px 12px')};
	position: relative;
	max-width: ${({ isDeepMode }) => (isDeepMode ? 'calc(100% - 30px)' : 'calc(100% - 40px)')};
	width: 100%;
	margin-left: 9px;
	font-size: 12px;
	color: #222222;
	min-height: 30px;
	display: flex;
	gap: 5px;
	align-items: flex-start;
	justify-content: space-between;

	.connection-card-count {
		color: ${props => getElementCountColor(props.count)};
		font-size: 24px;
	}

	::after {
		position: absolute;
		content: '';
		left: -10px;
		height: calc(100% + 10px);
		border-left: 1px solid #3c40439e;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 0;
	}

	::before {
		position: absolute;
		content: '';
		left: -10px;
		width: 10px;
		top: 50%;
		border-top: 1px solid #3c40439e;
	}

	.ql-editor {
		padding: 0 5px;
		min-height: 2.5rem;
		font-size: 12px;

		::before {
			left: 4px;
		}
	}

	.description {
		flex: 1;
		word-break: break-word;
		.structure-tag {
			display: flex;
			align-items: center;
			border-radius: 4px;
			font-weight: 700;
			background-color: #3185fc4d;
			padding: 5px 7px;
			margin-bottom: 5px;

			img{
				margin-right: 3px
			}
		}
	}
`

const SelectStructureTagRole = styled.select`
	border: 0px;
	background-color: ${({ selectedItem }) => (selectedItem ? '#3185fc4d' : '#FFF')};
	border-radius: 4px;
	font-weight: 700;
	font-size: 11px;
	padding: 5px 7px;
	margin-bottom: 5px;
	width: 100%;
	outline: none;

	option {
		background-color: ${({ selectedItem }) => (selectedItem ? '#FFF' : '')} !important;
	}
`
const SelectStructureTag = styled.select`
	border: 0px;
	background-color: transparent;
	font-size: 10px;
	font-weight: 700;
	width: 100%;
	outline: none;
	${({ isShow }) =>
		isShow
			? `
	-webkit-appearance:menulist`
			: `-webkit-appearance:none`}
`

const DeleteButton = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	background: #ffffff;
	border-left: 0.5px solid #222222;
	border-top: 0.5px solid #222222;
	border-radius: 4px 0;
	padding: 2px 3px;
	font-size: 11px;
	cursor: pointer;
	display: flex;
	gap: 3px;
	align-items: center;

	img {
		width: 8px;
		opacity: 0.7;
	}
`

const ConnectionCardWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 8px;

	div:last-child::after {
		height: ${({ isDeepMode }) => !isDeepMode && 'calc(50% + 6px)'};
		top: ${({ isDeepMode }) => !isDeepMode && '14px'};
	}
`

const CreateConnection = styled.div`
	margin-left: 43px;
	border: 1px dashed #3c40439e;
	border-radius: 3px;
	color: #222222;
	font-size: 11px;
	display: flex;
	cursor: pointer;
	padding: 8px;
	position: relative;
	user-select: none;
	flex-direction: column;

	p {
		margin: 0;
	}

	.title {
		font-weight: bold;
		font-size: 12px;
		text-decoration: underline;
		margin-bottom: 5px;
	}

	.description {
		font-size: 10px;
		margin-bottom: 20px;
	}

	::before {
		position: absolute;
		content: '';
		left: -30px;
		border-top: 1px dashed #3c40439e;
		width: 30px;
		top: 50%;
		height: 0;
	}

	::after {
		position: absolute;
		content: '';
		left: -44px;
		border-left: 1px ${({ isLength }) => (isLength ? 'solid' : 'dashed')} #3c40439e;
		width: 0;
		height: calc(50% + 8px);
		top: -8px;
	}
`

const PlusIcon = styled.div`
	position: absolute;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: black;
	left: -30px;
	top: 50%;
	transform: translateY(-50%);
	color: #fff;
	font-size: 24px;
	font-weight: 500;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 1px;
`

const LeftDashLine = styled.div`
	position: absolute;
	left: -44px;
	width: 13px;
	top: 50%;
	border-bottom: 1px ${({ isLength }) => (isLength ? 'solid' : 'dashed')} #3c40439e;
`

const CreateConnectionBtn = styled.div`
	box-sizing: border-box;
	cursor: pointer;
	width: 100%;
	height: 25px;
	padding: 5px 10px;
	border-radius: 20px;
	border: 1px solid ${({ isSupporting }) => (isSupporting ? '#4dbb5e' : '#D00')};
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;

	:hover {
		background-color: ${({ isSupporting }) => (isSupporting ? '#E7F0E9' : '#F6E7E7')};
		border-color: transparent;
	}
`

const CreateButton = styled.button`
	width: auto;
	height: auto;
	background-color: ${colorStyles.lynitYellow};
	border: none;
	border-radius: 2.5px;
	color: black;
	font-family: 'Source Sans Pro', sans-serif;
	align-self: center;
	box-shadow: 0 4px 4px -2px #c4c4c4;
	cursor: pointer;
	font-size: 10px;
	padding: 4px 6px;
	white-space: nowrap;
	img {
		margin-right: 4px;
	}
	&:hover {
		text-decoration: none;
		transform: translateY(-2px);
	}
	&:active {
		transform: translateY(0);
		transition: background 400ms;
	}
`

const Header = styled.div`
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 5px 10px;
	.chapter-view-title {
		font-size: 10px;
		font-weight: 600;
		color: #555555;
		display: flex;
		flex-direction: column;
	}
	.chapters-title {
		margin: 0;
		font-size: 16px;
		font-weight: 700;
		color: #000000;
	}
`

const ListWrapper = styled.div`
	height: calc(100% - 57px);
	padding: 8px;
	overflow: auto;
	position: relative;

	::-webkit-scrollbar-thumb {
		background: #9d9d9d;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		background: #ececec;
	}

	::-webkit-scrollbar {
		width: 0.3rem;
	}

	.element-container {
		background-color: #e7e6e9;
		padding: 0.7rem 0.5rem;

		.uncollapsed-element {
			width: 100%;
			padding: 8px;
			box-sizing: border-box;
			background-color: #e7e6e9;

			.drop-placeholder {
				height: auto;
			}

			.beats-pill-wrapper {
				margin: 8px 0;
				justify-content: space-between;

				.beats-title {
					white-space: normal;
					width: 100%;
					max-width: 100px;
				}

				.beats-heading {
					font-size: 9px;

					span {
						font-size: 11px;
					}
				}
			}
		}
	}
`

const DeepModeConnectionTabWrapper = styled.div`
	padding: 7px;
	overflow-y: auto;
	height: calc(100% - 15px);

	::-webkit-scrollbar-thumb {
		background: #9d9d9d;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		background: #ececec;
	}

	::-webkit-scrollbar {
		width: 0.3rem;
	}
`

const UpgradeToProWrapper = styled.div`
	margin-left: 20px;
	display: flex;
	align-items: center;
	position: relative;

	::before {
		position: absolute;
		content: '';
		left: -20px;
		border-top: 1px solid #3c40439e;
		width: 21px;
		height: 0;
	}

	::after {
		position: absolute;
		content: '';
		left: -20px;
		border-left: 1px solid #3c40439e;
		transform: translateY(calc(-50%));
		width: 0;
		height: calc(50% + 5px);
	}
`

export {
	MyStoryHeader,
	Story,
	Line,
	ConnectionCardStyle,
	DeleteButton,
	ConnectionCardWrapper,
	CreateConnection,
	PlusIcon,
	LeftDashLine,
	CreateConnectionBtn,
	Header,
	CreateButton,
	ListWrapper,
	DeepModeConnectionTabWrapper,
	MyStoryImageContainer,
	UpgradeToProWrapper,
	SelectStructureTag,
	SelectStructureTagRole,
}
