import getConnections from "@lynit/shared/src/utils/getConnections";

export const calculateMilestoneBeatWithPremiseDrivers = (graphData) => {
  const premiseId = graphData?.allNodes?.nodes?.find(node => node?.id.startsWith('prm'))?.id

  let milestoneBeatCount = 0;
  let milestoneBeatWithPremiseDrivers = 0;

  const milestoneBeats = graphData?.nodes[premiseId]?.premiseConnections?.map(d_conn =>
    d_conn?.sourceNode?.id?.startsWith('bea') ? d_conn?.sourceNode : d_conn?.destNode
  );

  milestoneBeats.forEach(ml => {
    milestoneBeatCount += 1;
    
    const driverIds = new Set(
      graphData?.nodes[ml.id]?.driverConnections?.map(d_conn =>
        !d_conn?.sourceNode?.id?.startsWith('bea') ? d_conn?.sourceNode.id : d_conn?.destNode.id
      )
    );

    const hasCoreDriver = graphData?.allNodes?.nodes?.some(
      node => driverIds.has(node.id) && node.coreElement === 'true'
    );

    if (hasCoreDriver) {
      milestoneBeatWithPremiseDrivers += 1;
    }
  });

  return { total: milestoneBeatCount, count: milestoneBeatWithPremiseDrivers };
};

export const calculatePremiseDriverWithMilestoneBeat = graphData => {
  let premiseDriverCount = 0;
  let premiseDriverWithMilestoneBeat = 0;

  const validNodes = Object.values(graphData?.nodes || []).filter(
    node =>
      ['Character', 'Arc', 'Event', 'Theme', 'Setting'].includes(node.__typename) &&
      node.coreElement === 'true'
  );

  validNodes.forEach(node => {
    premiseDriverCount += 1;

    const driverNodeIds = new Set(
      node.driverConnections?.map(d_conn =>
        d_conn?.sourceNode?.id?.startsWith('bea') ? d_conn?.sourceNode.id : d_conn?.destNode.id
      )
    );

    const hasMilestoneBeat = graphData?.allNodes?.nodes?.some(
      driverNode => driverNodeIds.has(driverNode.id) && driverNode?.premiseConnections?.length
    );

    if (hasMilestoneBeat) {
      premiseDriverWithMilestoneBeat += 1;
    }
  });

  return { total: premiseDriverCount, count: premiseDriverWithMilestoneBeat };
};

export const calculateRelationWithMilestoneBeat = (relationship, graphData) => {
  let premiseRelationshipCount = 0;
  let relationWithMilestoneBeat = 0;

  if (relationship?.relations) {
    const filteredRelations = relationship.relations.filter(rel => rel.connectionType === 'Static');

    filteredRelations.forEach(rel => {
      const premiseConnections = getConnections(rel.sourceNode.id, [], graphData?.nodes)?.premise;
      const validConnections = premiseConnections?.filter(
        connection =>
          connection.destNode.id === rel.destNode.id &&
          rel.destNode.__typename !== 'Premise' &&
          rel.sourceNode.__typename !== 'Premise' &&
          graphData?.nodes[rel.sourceNode.id].coreElement === 'true' &&
          graphData?.nodes[rel.destNode.id].coreElement === 'true'
      );

      if (validConnections?.length > 0) {
        premiseRelationshipCount += 1;

        const hasSharedBeats = validConnections?.some(connection =>
          connection.sharedBeats?.some(
            beat => graphData?.nodes[beat.id].premiseConnections.length > 0
          )
        );

        if (hasSharedBeats) {
          relationWithMilestoneBeat += 1;
        }
      }
    });
  }

  return { total: premiseRelationshipCount, count: relationWithMilestoneBeat };
};
