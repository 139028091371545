import React from 'react'
import { Button, StyledAnchorTag } from './styles'

const Link = ({ children, ...props }) => {
	if (props.href.startsWith('#')) {
		const tutorialId = props.href.slice(1)
		return <Button onClick={() => props.handleOpenTutorial(tutorialId)}>{children}</Button>
	}

	return <StyledAnchorTag {...props}>{children}</StyledAnchorTag>
}

export default Link
