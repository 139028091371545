import React from 'react'
import ReactDOM from 'react-dom'
import { install } from 'resize-observer'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import App from './components/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

if (!window.ResizeObserver) install()

if (window.self === window.top) {
	ReactDOM.render(<App />, document.getElementById('root'))
}

serviceWorkerRegistration.register()
