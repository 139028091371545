import React, { memo, useContext, useEffect, useMemo, useState } from 'react'

import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useDndContext, useDroppable } from '@dnd-kit/core'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineContent from '@mui/lab/TimelineContent'
import Tooltip from '@mui/material/Tooltip'
import { useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import isEqual from 'lodash/isEqual'

import { nodeColors } from '../../utils/commonStyles'
import emptyChapterImage from '../../images/empty-chapter.svg'
import helpIcon from '../../images/helpIcon.svg'
import { BeatsContainer, EmptyBeatContainer, BeatsTitle, PillWrapper } from './styles'

import { sharedContext } from '../../state/sharedProvider'
import SuggestionTooltip from '../ToolTip/SuggestionTooltip'
import BeatCard from '../BeatCard'
import PillComponent from '../PillComponent'
import DraggableBeats from './DraggableBeats'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { beatsDataStateContext } from '../../state/beatsProvider'
import {  setActiveConnections, setElementToDelete, setIsCatelogOpen } from '../../state/actions'
import {
	AddBeat,
	BeatContainer,
	CreateBeat,
	CreateBeatQuill,
	CreateBeatWrapper,
	ExistingBeat,
	ExistingBeatLabel,
	WrapBeatSections,
} from '@lynit/shared/src/ui/BeatsContainer/styles'
import { createNodeId } from '../../utils/utils'
import {
	createCacheConnection,
	createCacheElement,
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheField,
} from '../../utils/apollo'
import { useCreateBeatMutation, useDraggable, useUpdateNodeMutation } from '../../hooks'
import { useApolloClient } from '@apollo/client'
import useQuillAutoSave from '../AutoSave/useQuillAutoSave'
import Arrow from '@lynit/shared/src/images/arrow.svg'
import addBeatIcon from '@lynit/shared/src/images/addBeatIcon.svg'
import { orderBy } from 'lodash'
import { systemStateContext } from '../../state/systemProvider'
import Quill from 'quill'

const useStyles = makeStyles(() => ({
	initialsTooltip: {
		background: '#55534F',
	},
	emptyStateTooltip: {
		maxWidth: '220px',
		fontSize: '10px',
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timeline: {
		margin: '0',
		padding: '0',
	},
	timelineItem: {
		'&:before': {
			display: 'none',
		},
		minHeight: 'unset',
		boxSizing: 'border-box',
		gap: '6px',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineElement: {
		margin: 0,
		width: '10px',
		height: '10px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		color: '#000000',
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: '#78C091',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: 0,
	},
	timelineDot: {
		margin: 0,
		backgroundColor: '#78C091',
	},
}))

const getTrimmedNodeName = node => {
	if (node?.name) {
		return node?.name?.length > 26 ? `${node.name.substring(0, 26)} ...` : node?.name
	}
	return ''
}

const ChapterBeatsContainer = memo(
	({ type, beatsList, referenceElement, droppable, beatCount, response, id }) => {
		const isNarrow = useMediaQuery('(max-width: 1024px)')
		const classes = useStyles()
		
		const {
			state: { editorData, activeConnections },
			dispatch: sharedDispatch,
		} = useContext(sharedContext)
		const client = useApolloClient()

		const {isMobile, createLog, toastHandler} = useContext(systemStateContext)

		const graphData = useContext(graphDataStateContext)
		const [createBeat] = useCreateBeatMutation({ ignoreResults: true })
		const [beatContentChanged, setBeatContentChanged] = useState(false)
		const [newBeat, setNewBeat] = useState(null)
		const [tempBeatContent, setTempBeatContent] = useState('')

		const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 768px)')

		const [updateElement] = useUpdateNodeMutation("Chapter", { ignoreResults: true })

		const handleBeatDelete = ({name,id,type}) => {
			sharedDispatch(
				setElementToDelete({
					isActive: true,
					type,
					id,
					nodeName: name,
				}),
			)
		}

		useEffect(async () => {
			if (newBeat && tempBeatContent) {
				client.cache.modify({
					id: client.cache.identify({
						id: newBeat.id,
						__typename: newBeat.__typename,
					}),
					fields: {
						description() {
							return tempBeatContent.description
						},
					},
				})
				try {
					await updateBeat({
						variables: {
							id: newBeat.id,
							input: {
								description: tempBeatContent.description,
								updatedAt: new Date().toISOString(),
							},
						},
					})
				} catch (error) {
					console.error(error)
					client.cache.modify({
						id: client.cache.identify({
							id: newBeat.id,
							__typename: newBeat.__typename,
						}),
						fields: {
							description() {
								return ''
							},
						},
					})
				}
				setNewBeat(null)
				setTempBeatContent('')
			}
		}, [tempBeatContent, newBeat])

		

		const checkBeatContentChanged = (updateId, content, text) => {

			if (text.length > 1) {
				
				setBeatContentChanged(true)
			} else {
				
				setBeatContentChanged(false)
			}
			updateElement({
				variables: {
					id: updateId,
					input: {
						firstBeat:content,
						updatedAt: new Date().toISOString(),
					},
				},
			})
			
		}
		
		const { quill, quillRef } = useQuillAutoSave(
			{
				modules: {
					toolbar: false,
				},
				formats: [],
				placeholder: 'Type new beat here',
			},
			checkBeatContentChanged,
			id,
			'firstBeat',
			referenceElement.firstBeat,
		)

		const createBeatHandler = async (beatContent = JSON.stringify({ ops: [{ insert: '\n' }] })) => {
			const beatId = createNodeId('Beat')
			const relId = createNodeId('Relationship')
			const tempData = {
				createBeat: {
					beat: {
						id: beatId,
						beatConnections: [],
						driverConnections: [],
						description: beatContent,
						driverConnections: [],
						order: null,
						parentId: id,
						staticConnections: [],
						noteConnections: [],
						premiseConnections: [],
						templateText: '',
						createdAt: new Date().toISOString(),
						updatedAt: new Date().toISOString(),
						xCoordinate: null,
						yCoordinate: null,
						vizLocSaved: false,
						firstBeat: '',
						__typename: 'Beat',
						milestone: false,
						aiRecommendations:'',
					},
					relationship: {
						arc: {
							id,
							__typename: type,
						},
						connectionType: 'Beat',
						sourceNode: {
							id: beatId,
							__typename: 'Beat',
						},
						id: relId,
						order: String(beatsList.length),
						destNode: {
							id,
							__typename: type,
						},
						name: null,
						description: null,
						arcStage: null,
						relName: null,
						__typename: 'Relationship',
						structureTag: null,
						beatsDriven: null,
						sharedBeats: [],
						firstBeat: '',
						createdAt: new Date().toISOString(),
						updatedAt: new Date().toISOString(),
					},
				},
			}
			await createCacheElement(client, tempData, false, false)
			await createCacheConnection(client, tempData.createBeat.relationship)

			quill.setText('',Quill.sources.USER)
			updateCacheField(client, referenceElement, {
				firstBeat: '',
			})
			updateElement({
				variables: {
					id: referenceElement.id,
					input: {
						firstBeat:'',
						updatedAt: new Date().toISOString(),
					},
				},
			})
			
			setBeatContentChanged(false)
			
			setNewBeat(tempData.createBeat.beat)
			createBeat({
				variables: {
					beat: {
						id: tempData.createBeat.beat.id,
						description: tempData.createBeat.beat.description,
					},
					relationship: {
						id: tempData.createBeat.relationship.id,
						destNodeId: tempData.createBeat.relationship.destNode.id,
						sourceNodeId: tempData.createBeat.relationship.sourceNode.id,
						description: tempData.createBeat.relationship.description,
						connectionType: tempData.createBeat.relationship.connectionType,
						structureTag: tempData.createBeat.relationship.structureTag,
						relName: 'RELATED',
						beatsDriven: tempData.createBeat.relationship.beatsDriven,
						sharedBeats:
							tempData.createBeat.relationship.sharedBeats?.map(beat => {
								return { id: beat.id }
							}) || [],
						firstBeat: '',
					},
					parentId: id,
				},
				ignoreResults: true,
			}).catch(async error => {
				console.error(error)
				await deleteCacheElement(client, tempData.createBeat.beat)
				await deleteCacheConnections(client, tempData.createBeat.relationship)
				toastHandler(
					'error',
					`There was an error creating the Beat, try again.`,
					null,
					'ListElement',
					'Beat Creation',
				)
			})
			
			
		}

		return (
			<>
				<BeatsTitle className="beats-pill-wrapper">
					{!isNarrow && <p className="beats-title">{type} Development</p>}
					{!isNarrow && (
						<SuggestionTooltip
							title="There should be at least one Milestone Beat in every Chapter."
							placement="right"
							arrow
						>
							<img src={helpIcon} alt="help Icon" style={{ width: '8px', height: '8px' }} />
						</SuggestionTooltip>
					)}
					<PillWrapper>
						<PillComponent
							type="beat"
							className="beats-heading"
							beatCount={beatCount}
							shouldHover={true}
						/>
						<PillComponent
							type="wordCount"
							className="beats-heading"
							count={
								editorData?.id === referenceElement?.id
									? editorData?.count
									: referenceElement?.wordCount || 0
							}
							shouldHover={true}
						/>
					</PillWrapper>
				</BeatsTitle>

				<SortableContext
					items={beatsList?.map((beat) => {
						
						return beat?.id + '-' + id
					})}
					strategy={verticalListSortingStrategy}
				>
					<BeatsContainer
						id={`${id}-beatContainer`}
						isVisible={true}
						isEmpty={!beatsList?.length}
						className="beatsContainer"
						ref={droppable}
					>
						<div className="beats-wrapper">

							<Timeline className={classes.timeline}>
								{beatsList?.map((beat, index) => {
									const node = graphData?.nodes[id]
									return (
										<TimelineItem key={`${index}-${beat.id}`} className={classes.timelineItem}>
											<DraggableBeats
												node={node}
												index={index}
												key={beat.id}
												chapterId={id}
												beatId={beat.id}
												setCurrentElement={handleBeatDelete}
												referenceElementType={type}
												referenceElement={referenceElement}
												beatConnection={graphData.nodes[beat.id]?.beatConnections?.[0]}
												connectionId={beat?.connectionId}
												beat={graphData.nodes[beat.id]}
												location={'Chapter Card'}
												locationId={id}
												shouldFocus={false}
												isNewBeat={false}
												setTempBeatContent={setTempBeatContent}
												tempBeatContent={tempBeatContent}
												setNewBeat={setNewBeat}
											/>
										</TimelineItem>
									)
								})}
							</Timeline>
		
								
							<TimelineItem
								className={classes.timelineItem}
								style={{ display: false ? 'none' : 'flex' }}
                    		>
								<TimelineSeparator>
									<TimelineConnector className={classes.hiddenTimelineConnector} />
									<TimelineDot className={classes.timelineDot} />
									<TimelineConnector className={classes.hiddenTimelineConnector} />
									{(beatContentChanged || quill?.getText().length > 1 ) ? <div style={{ height: '20px' }} /> : <></>}
								</TimelineSeparator>
								<TimelineContent className={classes.timelineContent}>
									<CreateBeatQuill
										ref={quillRef}
										key={id}
										id={id}
										data-fieldname={'firstBeat'}
										translate="no"
									/>
									{(beatContentChanged || quill?.getText().length > 1 ) && (
										<AddBeat
											onClick={() => createBeatHandler(JSON.stringify(quill.getContents()))}
										>
											+ Create Beat
										</AddBeat>
									)}
								</TimelineContent>
							</TimelineItem>
                    <div
                        style={{
                            marginLeft: '16px',
                            marginTop: '5px',
                        }}
                    >
                        {(graphData?.nodesByType?.["Beat"].length > 0) && !["Search Connection Card","Viz Connection Card","Driver Card"].includes(location) && (<div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px',
                            }}
                        >
                            <div>
                                <img src={Arrow} />
                            </div>
                            <div
                                style={{
                                    width: `calc(100% - 30px)`,
                                }}
                            >
                                {false && (<ExistingBeatLabel>What will happen next?</ExistingBeatLabel>)}
                                {
                                (<ExistingBeat
                                    onClick={() => {
										createLog(
											`Drag Existing Beat Button Clicked`,
											{location, parentNodeType:type},
											'Beats Chapter Container',
											'Add Beat to List',
										)
                                        sharedDispatch(setIsCatelogOpen('Beats'))
										if(isTablet || isMobile){
											sharedDispatch(
												setActiveConnections({
													isActive: null,
													elementId: null,
													elementType: null,
													docType: null,
													appView: activeConnections.appView,
													isMobileTempClose: true,
												}),
											)
										}
                                    }}
                                >
                                    Or drag existing beat from Catalog
                                </ExistingBeat>)}
                            </div>
                        </div>)}
                       
                    </div>
						</div>
					</BeatsContainer>
				</SortableContext>
			</>
		)
	},
)

const makeDroppable = Component => {
	return props => {
		const [referenceElement, setReferenceElement] = useState({})

		

		const {active, over} = useDndContext()
		const graphData = useContext(graphDataStateContext)

		// const newBeatList = graphData.nodes[props?.id]?.[props?.connectionTypeField]?.map(connection => {
		// 	const beatId = connection?.sourceNode?.id?.startsWith('bea')
		// 						? connection?.sourceNode?.id
		// 						: connection?.destNode?.id
		// 	return { id:beatId,connectionId:connection.id, order: +connection.order }
		// })
		
		// const orderedBeatList= orderBy(newBeatList, ['order'], ['asc'])

		const beatsList = useDraggable(props?.id, active, over, [], props?.connectionTypeField)

		const beatCount = {
			'In Chapter': 0,
			Unused: 0,
			Total: beatsList?.length,
		}

		useEffect(() => {
			if (graphData?.nodes?.[props?.id]) {
				setReferenceElement(graphData?.nodes?.[props?.id])
			}
		}, [graphData?.nodes?.[props?.id]])

		const emptyDroppable = useDroppable({
			id: `rel-empty-beat-connections-${props?.id}`,
			disabled: !!referenceElement?.beatConnections?.length,
			data: {
				type: 'beat',
				referenceElement: { id: props?.id },
				id: `rel-empty-beat-connections-${props?.id}`,
				connectionTypeField: props?.connectionTypeField,
				location:  props.location,
				locationId: props.locationId,
			},
		})

		const beatsContainer = useDroppable({
			id: `container-${props?.id}`,
			//disabled: !!referenceElement?.beatConnections?.length,
			data: {
				type: 'beat',
				referenceElement: { id: props?.id },
				id: `container-${props?.id}`,
				connectionTypeField: props?.connectionTypeField,
				location:  props.location,
				locationId: props.locationId,
			},
		})

		// useEffect(() => {
		// 	const childCount = {
		// 		'In Chapter': 0,
		// 		Unused: 0,
		// 		Total: 0,
		// 	}

		// 	if (referenceElement?.beatConnections?.length) {
		// 		referenceElement?.beatConnections?.forEach(beat1 => {
		// 			const beatId = beat1?.sourceNode?.id?.startsWith('bea')
		// 				? beat1?.sourceNode?.id
		// 				: beat1?.destNode?.id
		// 			const beat = graphData.nodes[beatId]
		// 			const nodeId =
		// 				props.type === 'Chapter'
		// 					? beat?.parentId
		// 					: beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
		// 					? beat?.beatConnections?.[0]?.sourceNode?.id
		// 					: beat?.beatConnections?.[0]?.destNode?.id
		// 			const node = graphData.nodes[nodeId]
		// 			if (node) {
		// 				childCount['In Chapter'] += 1
		// 			} else {
		// 				childCount['Unused'] += 1
		// 			}
		// 			childCount['Total'] += 1
		// 		})
		// 	}
		// 	if (!isEqual(childCount, beatCount)) {
		// 		setBeatCount(childCount)
		// 	}
		// }, [referenceElement])

		// const beatConnections =
		// 	props.beatConnections.length > 0 &&
		// 	props.beatConnections.map(beatConnection => {
		// 		// const beat = beatConnection?.destNode?.id.startsWith('bea')
		// 		// 	? graphData.nodes[beatConnection?.destNode?.id]
		// 		// 	: graphData.nodes[beatConnection?.sourceNode?.id]

		// 		// const isParent = beat?.parentId === deepModeElement?.id
		// 		// const isDriver = beat?.driverConnections?.some(
		// 		// 	conn =>
		// 		// 		conn.destNode?.id === deepModeElement?.id ||
		// 		// 		conn.sourceNode?.id === deepModeElement?.id,
		// 		// )
		// 		const isActive = deepModeElement?.nodeType !== 'Chapter' ? true : true

		// 		//const nodeId =null
		// 		// props.type === 'Chapter'
		// 		// 	? beat?.parentId
		// 		// 	: beat?.beatConnections?.[0]?.destNode?.id?.startsWith('bea')
		// 		// 	? beat?.beatConnections?.[0]?.sourceNode?.id
		// 		// 	: beat?.beatConnections?.[0]?.destNode?.id
		// 		//const node = graphData.nodes[nodeId]
		// 		return { isActive, beatConnection }
		// 	})

		// const beatsList = 
		// 	props.beatsList.length > 0 &&
		// 	props.beatsList.map(beat => {
				
				
		// 		return { isActive, beatConnection }
		// 	})

		return (
			<Component
				{...props}
				beatsList={beatsList}
				droppable={
					beatsList?.length ? beatsContainer.setNodeRef : emptyDroppable.setNodeRef
				}
				
				referenceElement={referenceElement}
				beatCount={beatCount}
			/>
		)
	}
}

export default memo(makeDroppable(ChapterBeatsContainer))
