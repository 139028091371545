import styled from 'styled-components'

const Container = styled.div`
	box-sizing: border-box;
	min-height: 43px;
	border: 0.5px solid #e4e4e4;
	background-color: ${({ isEditor }) => (isEditor ? '#FBFBFB' : '#F3F4F5')};
	display: ${({ shouldDisplay }) => (shouldDisplay ? 'flex' : 'none')};
	gap: 5px;
	padding: 5px;
	border-radius: 2px;
	margin-bottom: 5px;

	@media (min-width: 769px) {
		.hidden {
			visibility: hidden;
		}

		:hover {
			.hidden {
				visibility: visible;
			}
		}
	}
`

const StatusImg = styled.img`
	cursor: pointer;
	vertical-align: top;
	width: 14px;
	border-radius: 50%;
	@media (max-width: 600px) {
		width: 11px;
	}

	${({ isCompleted }) =>
		!isCompleted
			? `
    :hover {
        box-shadow: 0 0 10px 0px #6EEA89;
    }
  `
			: ''}
`

const Quill = styled.div`
	flex: 1;
	overflow-x: hidden;
	border: none !important;

	.ql-editor {
		padding: 0;
		font-size: 12px;
		overflow-x: hidden;
		@media (max-width: 600px) {
			font-size: 10px;
		}
	}

	.ql-editor.ql-blank::before {
		content: none;
	}

	.ql-editor.ql-blank::after {
		color: #a1a1a1;
		content: attr(data-placeholder);
		display: block;
		margin-top: -1.42em;
		min-height: 1em;
		pointer-events: none;
	}
`

const TaskDescription = styled.div`
	font-size: 12px;
	@media (max-width: 600px) {
		font-size: 10px;
	}
	cursor: pointer;
	flex: 1;
	overflow-x: hidden;
`

const DragHandler = styled.img`
	cursor: grab;
	width: 6px;
	height: 12px;
	vertical-align: top;
	margin: 1px 2px 0 0;
`

const DeleteTaskImg = styled.img`
	cursor: pointer;
	width: 8px;
	height: 12px;
	opacity: 0.8;
`
export { Container, StatusImg, Quill, TaskDescription, DragHandler, DeleteTaskImg }
