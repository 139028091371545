import { useMutation } from '@apollo/client'
import { CREATE_SHARED_BEAT } from '../data'
import { createCacheConnection, createCacheElement, deleteCacheConnections, deleteCacheElement, updateCacheField } from '../utils/apollo'
import { toastHandler } from '../utils/backendHandler'


export const useCreateSharedBeatMutation = (options) => {
	const [createSharedBeat, { data, loading, error }] = useMutation(CREATE_SHARED_BEAT, options)
	// let context = options.context
	// context ={...context, serializationKey: 'MUTATION'}
	// options = {...options,context}

	// await createCacheElement(client, newBeat, false, false)
	// newDriverConnections.map(conn => {
	//     createCacheConnection(client, conn,false,true)
	// })
	const performCreateSharedBeat = async ({
		tempData,
		destNodeDriverConnection,
		staticConnectionUpdateFields,
		id,
		sharedBeats,
		beatsDriven,
		client
	}) => {

		if (!tempData) {
			throw new Error('Missing tempData for Create Shared Beat')
		}

		if (!destNodeDriverConnection) {
			throw new Error('Missing destNodeDriverConnection for Create Shared Beat')
		}

		if (!staticConnectionUpdateFields) {
			throw new Error('Missing staticConnectionUpdateFields for Create Shared Beat')
		}

		if (!id) {
			throw new Error('Missing Id for Create Shared Beat')
		}


		if (!sharedBeats) {
			throw new Error('Missing sharedBeats for Create Shared Beat')
		}

		if (!client) {
			throw new Error('Missing client for Create Shared Beat')
		}

		await createCacheElement(client, tempData, false, false)
		await createCacheConnection(client, [destNodeDriverConnection, tempData.createBeat.relationship], true)
		await updateCacheField(client, { id, __typename: 'Relationship' }, staticConnectionUpdateFields)

		await createSharedBeat({
			variables: {
				beat: {
					id: tempData.createBeat.beat.id,
					description: tempData.createBeat.beat.description
				},
				addedDriverConnections: [
					{
						id: destNodeDriverConnection.id,
						destNodeId: destNodeDriverConnection.destNode.id,
						sourceNodeId: destNodeDriverConnection.sourceNode.id,
						description: "",
						connectionType: 'Driver',
						structureTag: null,
						relName: 'RELATED',
						beatsDriven: null,
						sharedBeats: null,
						firstBeat: ''
					},
					{
						id: tempData.createBeat.relationship.id,
						destNodeId: tempData.createBeat.relationship.destNode.id,
						sourceNodeId: tempData.createBeat.relationship.sourceNode.id,
						description: "",
						connectionType: 'Driver',
						structureTag: null,
						relName: 'RELATED',
						beatsDriven: null,
						sharedBeats: null,
						firstBeat: ''
					}],
				connectionId: id
			}
		}).catch(async error => {
			
			toastHandler('error', `There was an error creating the Beat, try again.`, null, "ListElement", "Beat Creation")
			await deleteCacheElement(client, tempData.createBeat.beat)
			await deleteCacheConnections(client, tempData.createBeat.relationship)
			await deleteCacheConnections(client, destNodeDriverConnection)
			await updateCacheField(client, { id, __typename: 'Relationship' }, { beatsDriven, sharedBeats })

			console.error(error)

		})
	}
	return [performCreateSharedBeat, { data, loading, error }]
}