/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import { useApolloClient } from '@apollo/client'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'

import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import {
	useDraggable,
	useReorderBeatsMutation,
	useReorderNodesMutation,
} from '@lynit/shared/src/hooks'
import {
	setActiveConnections,
	setCurrentElement,
	setDeepModeElement,
	setDivingDeep,
	setElementToDelete,
	setLeftToggleTabs,
	setNoElementError,
	setOpenToolTip,
	setRightToggleTabs,
	setServerError,
} from '@lynit/shared/src/state/actions'

import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'
import DescriptionField from '@lynit/shared/src/ui/DescriptionField'
import ChapterBeatsContainer from '@lynit/shared/src/ui/ChapterBeatsContainer'
import TextField from '@lynit/shared/src/ui/Textfield'
import dragger from '@lynit/shared/src/images/dragger.svg'
import trashIcon from '@lynit/shared/src/images/IconDelete.svg'
import documentIconDark from '@lynit/shared/src/images/documentIconDark.svg'

import {
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheBeatsDriven,
	updateCacheOrderBeat,
} from '@lynit/shared/src/utils/apollo'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'

import { TextFieldsContainer, Element, Card, ChapterDescription } from './styles'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'
import HtmlTooltip from '@lynit/shared/src/ui/ToolTip/HtmlTooltip'
import { TooltipDescription, TooltipWrapper } from '@lynit/layout/src/ui/DriverDoc/styles'




const ChapterCard = React.memo(
	({
		id,
		name,
		description,
		type,
		number,
		isRightPanel,
		attributes,
		listeners,
		beatsList,
		isOverlay,
		cursor,
	}) => {
		const client = useApolloClient()
		const history = useHistory()
		const {
			dispatch: sharedDispatch,
		} = useContext(sharedContext)
		
		const modalRef = useRef()
		const [elementName, setElementName] = useState(name)
		const [elementNumber, setElementNumber] = useState(number)
		//const [elementDescription, setElementDescription] = useState(description)
		const [isEdit, setEdit] = useState(false)
		const [currentNode, setCurrentNode] = useState(null)

		const [reorderNodes] = useReorderNodesMutation()
		const [reorderBeats] = useReorderBeatsMutation()

		const {
			state: {
				deleteNode,
			},
			dispatch,
		} = useContext(sharedContext)

		const beatsRelatedData = useContext(beatsDataStateContext)
		const beatsDataDispatch = useContext(beatsDataDispatchContext)
		const systemRelatedData = useContext(systemStateContext)
		const user = useContext(userStateContext)
		const graphData = useContext(graphDataStateContext)


		
		const useStyles = makeStyles(() => ({
			tooltip: {
				margin: 0,
				background: '#55534F',
				display: props => props.isTooltip && 'none',
			},
			tooltip1: {
				display: props => !props.isCollapsed && 'none',
				background: '#55534F',
			},
			initialsTooltip: {
				background: '#55534F',
			},
			tooltipSm: {
				margin: '0 auto',
				background: 'transparent',
				width: '100%',
				padding: 0,
				marginTop: '6px !important',
				// margin: '6px 0',
			},
			arrow: {
				color: '#55534F',
			},
			timeline: {
				margin: '0',
				padding: '7px 5px',
			},
			timelineItem: {
				'&:before': {
					display: 'none',
				},
				minHeight: 'unset',
				boxSizing: 'border-box',
				justifyContent:"center",
				alignItems:"center"
			},
			timelineContent: {
				padding: '0px 3px',
				width: 'calc(100% - 20px)',
			},
			timelineDot: {
				margin: 0,
				backgroundColor: '#78C091',
			},
			
			// timelineChapter: {
			// 	margin: 0,
			// 	backgroundColor: nodeColors.Chapter,
			// 	width: '8px',
			// 	height: '8px',
			// 	padding: '0',
			// 	display: 'flex',
			// 	justifyContent: 'center',
			// 	alignItems: 'center',
			// 	fontSize: '10px',
			// 	color: '#000000',
			// },
			hiddenTimelineConnector: {
				visibility: 'hidden',
			},
			timelineConnector: {
				background: '#78C091',
			},
			dashedTimelineConnector: {
				background: 'transparent',
				border: '1px dashed #78C091',
				width: 0,
			},
			tab: {
				color: '#888585',
				fontFamily: 'Source Sans Pro',
				fontSize: '14px',
				fontStyle: 'normal',
				fontWeight: 700,
				lineHeight: 'normal',
				textTransform: 'none',
				minWidth: '70px',
				padding: '2px 5px',
			},
			tabSelected: {
				color: '#000000 !important',
			},
			indicator: {
				height: '6px',
				borderRadius: '10px',
				backgroundColor: '#FFC300',
			},
			tabs: {
				borderBottom: '6px solid #fff5d4',
				borderRadius: '4px',
			},
		}))
		
		const classes = useStyles({
			isTooltip: false,
			isCollapsed: false,
		})
		

		

		return (
			<>
				{(
					<Element
						id={`${id}-chapter-header`}
						isRightPanel={isRightPanel}
						style={{ cursor: isOverlay ? cursor : 'default' }}
					>
						{!isRightPanel && (
							<img
								src={dragger}
								style={{ marginRight: '5px', cursor: isOverlay ? 'grabbing' : 'grab' }}
								alt="Dragger Icon"
								width={7}
								height={12}
								{...attributes}
								{...listeners}
							/>
						)}
							<div id={`${id}-chapter-name`} className="chapter-name">
								<HtmlTooltip
									title={
										<TooltipWrapper>
											<TooltipDescription>Open {elementName}</TooltipDescription>
										</TooltipWrapper>
									}
									placement={'bottom'}
									classes={{
										tooltip: classes.tooltipSm,
									}}
									ref={modalRef}
								>
									<TextFieldsContainer 
										hoverStyle={true}
										onClick={()=>{
											dispatch(setActiveConnections({isActive:true, elementId:id, elementType:"Chapter", docType:"Chapter", appView:"Writing"}))
										}}
									>
										<div>Ch:</div>
										<div 
											id={`${id}-number`}
											className="chapter-number-container"
											>
											{elementNumber}
										</div>
										<div className="chapter-name-container" >
										{elementName}
										</div>
									</TextFieldsContainer>
								</HtmlTooltip>
							</div>
						
						{/* <div
							disabled={beatsRelatedData?.serverError || beatsRelatedData?.noElementError}
							onClick={() => {
								// Put 
							}}
						>
							<Tooltip title="Open Chapter" classes={{ tooltip: classes.tooltip }} placement="left">
								<img
									src={documentIconDark}
									alt="document icon"
									className="document-img"
									width={16}
									height={16}
								/>
							</Tooltip>
						</div> */}
					</Element>
				)}

				<ChapterDescription>
					<DescriptionField
						id={id}
						fieldName={"description"}
						type={type ?? 'Chapter'}
						isEdit={isEdit}
						setEdit={setEdit}
						createLog={systemRelatedData?.createLog}
						description={description ?? ''}
						elementDescription={description ?? ''}
						isArcConnection={false}
						isElement={true}
						placeholder={`Describe your ${type} here`}
					/>
					<div className="delete-icon">
						<img
							src={trashIcon}
							alt="Trash Icon"
							disabled={beatsRelatedData?.serverError || beatsRelatedData?.noElementError}
							onClick={() => {								
								sharedDispatch(
									setElementToDelete({
										isActive: true,
										type,
										id,
										nodeName: name,
									}),
								)
							}}
						/>
					</div>
				</ChapterDescription>
				<ChapterBeatsContainer
					id={id}
					type={type}
					beatsList={beatsList}
					setCurrentElement={setCurrentNode}
				/>
			</>
		)
	},
)

const Draggable = props => {
	const { attributes, isDragging, listeners, setNodeRef, transition, transform, active, over } =
		useSortable({
			id: props?.id,
			data: {
				type: 'chapter',
				id: props?.id,
			},
		})

	const graphData = useContext(graphDataStateContext)

	const newBeatList = graphData.nodes[props?.id]?.beatConnections.map(connection => {
		const beatId = connection?.sourceNode?.id?.startsWith('bea')
							? connection?.sourceNode?.id
							: connection?.destNode?.id
		return { id:beatId,connectionId:connection.id, order: +connection.order }
	})

	const orderedBeatList= orderBy(newBeatList, ['order'], ['asc'])
	const beatsList = useDraggable(props?.id, active, over, orderedBeatList,"beatConnections")

	return (
		<Card
			isRightPanel={props.isRightPanel}
			id={`${props?.id}-chapter-card`}
			ref={setNodeRef}
			style={{
				transition,
				transform: CSS.Translate.toString(transform),
				opacity: isDragging ? '0.5' : 'unset',
				//width:'100%'
			}}
		>
			<ChapterCard
				{...props}
				attributes={attributes}
				listeners={listeners}
				beatsList={beatsList}			/>
		</Card>
	)
}

export default React.memo(Draggable)
