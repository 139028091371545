import React, { useContext, useEffect, useRef } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import HomeIcon from '@mui/icons-material/Home'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { useApolloClient } from '@apollo/client'

import { StyledBreadcrumb } from './styles'
import { sharedContext } from '../../state/sharedProvider'
import { setDeepModeElement, setSummaryCardVisible, setSummaryView } from '../../state/actions'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from '../../state/beatsProvider'
import { systemDispatchContext, systemStateContext } from '../../state/systemProvider'
import { InputContainer } from '../Textfield/styles'
import { updateCacheField } from '../../utils/apollo'
import { autoSaveDispatchContext, setSavingData } from '@lynit/shared/src/state/autoSaveProvider'
import { useEditStoryMutation } from '../../hooks'
import { toastHandler } from '../../utils/backendHandler'
import { userStateContext } from '../../state/userProvider'
import { debounce } from 'lodash'

function BreadCrumb() {
	const {
		state: { currentStory, flow: currentFlow },
		sharedDispatch,
	} = useContext(sharedContext)
	const client = useApolloClient()

	const beatsRelatedData = useContext(beatsDataStateContext)

	const { isSummaryCardVisible, createLog } = useContext(systemStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const graphData = useContext(graphDataStateContext)
	const autoSaveDispatch = useContext(autoSaveDispatchContext)
	const [editStory] = useEditStoryMutation()

	const isSmallDevice = useMediaQuery('(max-width: 1024px)')
	const history = useHistory()
	const { user } = useContext(userStateContext)

	const ellipsisText = text => {
		if (isSmallDevice) {
			return `${text.slice(0, 10)}...`
		} else if (text.length > 25) {
			return `${text.slice(0, 25)}...`
		} else {
			return text
		}
	}

	useEffect(()=> {
		if(currentStory?.name === "My New Story"){
			document.getElementById('editableStoryName')?.focus()

		}
		
	},[])
	
	const updateStory = async input => {
		await editStory({
			variables: {
				id: currentStory?.id,
				input,
			},
		})
			.then(() => {
				autoSaveDispatch(setSavingData(false))
			})
			.catch(e => {
				autoSaveDispatch(setSavingData(false))
				if (e.message.includes('Upgrade')) {
					toastHandler('error', e.message)
					if (
						user?.subscriptionStatus?.status === 'trialing' ||
						user?.subscriptionStatus?.status === 'active'
					) {
						window.location.reload()
					}
				} else {
					toastHandler('error', e.message)
					createLog(
						`ToastHandler Error Message`,
						`{"errorMessage":"${e.message}"}`,
						'Summary',
						'Story Name Update',
					)
				}
			})
	}

	const handleStoryNameChange = debounce(e => {
		if (e.target.value !== currentStory?.name) {
			autoSaveDispatch(setSavingData(true))
			updateStory({ name: e.target.value })
		}
	}, 1000)

	return (
		<Breadcrumbs aria-label="breadcrumb">
			{/* <StyledBreadcrumb
				onClick={() => {
					if (currentFlow === 'My Stories') {
						history.push('/dashboard')
					}
				}}
			>
				<HomeIcon
					sx={{ mr: 0.5, mb: 0.3, fill: '#FFC300', width: '20px', height: '22px' }}
					fontSize="inherit"
				/>
				{!isSmallDevice && currentFlow}
			</StyledBreadcrumb> */}
			{currentStory && (
				<StyledBreadcrumb
					//disabled={!isSummaryCardVisible}
					onClick={() => {
						//beatsDataDispatch(setDeepModeElement(null))

						
					}}
				>
					<input
						id={'editableStoryName'}
						className="summaryCardStoryName"
						defaultValue={currentStory?.name}
						placeholder='Enter a Story Name'
						onChange={handleStoryNameChange}
						onBlur={async e => {
							if (e.target.value !== currentStory?.name) {
								updateCacheField(
									client,
									{ id: currentStory.id, __typename: 'Story' },
									{ name: e.target.value },
								)
							}
						}}
					/>
				</StyledBreadcrumb>
			)}
		</Breadcrumbs>
	)
}

export default BreadCrumb
