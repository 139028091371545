import React, { useContext, useMemo } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import MarkDown from '../../../shared-ui/Markdown'

import {
	BreadCrumbItem,
	Content,
	DifficultyLevelIcon,
	Header,
	Info,
	TutorialContainer,
	TutorialDescription,
	TutorialImg,
	TutorialVideo,
} from './styles'

import backArrowIcon from '../../../images/back-arrow-icon.svg'
import { difficultyLevelIcon } from '../../Tutorials/TutorialCard'
import { imgRegEx } from '../../../utils/utils'
import { openTutorial } from '../../../state/actions'
import { sharedContext } from '../../../state/sharedProvider'

function Tutorial({ tutorial, backToTutorialSet, tutorialSet }) {
	const isImage = useMemo(() => (tutorial.image ? imgRegEx.test(tutorial.image) : ''), [tutorial])
	const { dispatch } = useContext(sharedContext)

	return (
		<TutorialContainer>
			<Header>
				<img
					className="back-btn"
					src={backArrowIcon}
					alt="back button icon"
					onClick={backToTutorialSet}
				/>
				<Breadcrumbs aria-label="breadcrumb">
					<BreadCrumbItem onClick={backToTutorialSet}>{tutorialSet.name}</BreadCrumbItem>
					<BreadCrumbItem isActive>{tutorial.title}</BreadCrumbItem>
				</Breadcrumbs>
			</Header>
			<Content>
				<Info>
					<DifficultyLevelIcon
						src={difficultyLevelIcon[tutorial.difficulty]}
						alt="difficulty level icon"
					/>
					<span className="difficulty-text">{tutorial.difficulty}</span>
					<AccessTimeIcon className="time-icon" />
					<span className="time-text">{tutorial.timeToComplete} min to Complete</span>
				</Info>
				<TutorialDescription>
					<MarkDown
						markdownText={tutorial.message}
						handleOpenTutorial={tutorialId => {
							dispatch(openTutorial(tutorialId))
						}}
					/>
				</TutorialDescription>
				{tutorial.image &&
					(isImage ? (
						<TutorialImg src={tutorial.image} alt="tutorial img" />
					) : (
						<TutorialVideo controls>
							<source src={tutorial.image} type="video/mp4" data-testid="tutorial-video"/>
						</TutorialVideo>
					))}
			</Content>
		</TutorialContainer>
	)
}

export default Tutorial
