import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React from 'react'

const makeDraggable = WrappedComponent => {
	return props => {
		const { setNodeRef, attributes, listeners, transition, transform, isDragging } = useSortable({
			id: props.task.id,
			data: {
				type: 'task',
			},
		})

		return (
			<div
				ref={setNodeRef}
				style={{
					transition,
					transform: CSS.Translate.toString(transform),
					position: isDragging ? 'relative' : 'static',
					zIndex: isDragging ? 1 : 0,
					cursor: isDragging ? 'grabbing' : 'default',
				}}
			>
				<WrappedComponent {...props} attributes={attributes} listeners={listeners} />
			</div>
		)
	}
}

export default makeDraggable
