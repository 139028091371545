import { SET_STATIC_NOTE_MUTATION } from './actionTypes'

export const layoutReducer = (state, action) => {
	switch (action.type) {
		case SET_STATIC_NOTE_MUTATION:
			return { ...state, isStaticNoteMutation: action.payload }
		default:
			return state
	}
}
