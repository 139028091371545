import React, { useEffect, useState, useContext } from 'react'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import Toast from '@lynit/shared/src/ui/CustomToast'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'

import {
	Overlay,
	ModalContainer,
	ButtonContainer,
	NoNetworkImg,
	TopContainer,
	TopCenterContainer,
	TopRightContainer,
	TopInnerContainer,
	StatusNotification,
} from './styles'

import NoNetworkIcon from '../../images/no-internet-modal.svg'
import AlertVersionIcon from '../../images/alert-version.svg'
import TopNoNetworkIcon from '../../images/top-no-internet.svg'
import UpdatingIcon from '../../images/updating.svg'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'

const NoNetworkOverlay = ({ isShowed, setIsShowed, isOnline }) => {
	const beatsRelatedData = useContext(beatsDataStateContext)
	const systemRelatedData = useContext(systemStateContext)
	const [lastUpdateTime, setLastUpdateTime] = useState(null)
	const [wasServerError, setWasServerError] = useState(false)
	const [wasNoElementError, setWasNoElementError] = useState(false)

	useEffect(() => {
		const date = new Date()
		setLastUpdateTime(
			`${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`,
		)
		//handles showing overlay when serverError or noELementError happen
		if ((beatsRelatedData?.serverError || beatsRelatedData?.noElementError) && isOnline) {
			if (beatsRelatedData?.serverError) {
				setWasServerError(true)
			}
			if (beatsRelatedData?.noElementError) {
				setWasNoElementError(true)
			}
			setIsShowed(true)
		}
		//handles UX after recovery from serverError
		if (isOnline && !beatsRelatedData?.serverError && wasServerError) {
			toastHandler('dark1', '', <Toast isRetry={false} content="Content updated" />)
			setIsShowed(false)
			setWasServerError(false)
		}
		//handles UX after recovery from noElementError
		if (isOnline && !beatsRelatedData?.noElementError && wasNoElementError) {
			toastHandler('dark1', '', <Toast isRetry={false} content="Content updated" />)
			setIsShowed(false)
			setWasNoElementError(false)
		}

		//user is having a network error
		if (!isOnline && !beatsRelatedData?.serverError && !beatsRelatedData?.noElementError) {
			setIsShowed(true)
		}
		//handles UX after recovery from network error
		if (window.navigator.onLine && systemRelatedData?.wasOffline) {
			setIsShowed(false)
		}
		//everything is working fine
		if (isOnline && !beatsRelatedData?.serverError && !beatsRelatedData?.noElementError) {
			setIsShowed(false)
		}
	}, [isOnline, systemRelatedData?.wasOffline, beatsRelatedData?.serverError, beatsRelatedData?.noElementError, wasNoElementError, wasServerError])

	useEffect(() => {
		if (systemRelatedData?.isDifferentVersion) {
			setIsShowed(true)
		}
		if (!systemRelatedData?.isDifferentVersion) {
			setIsShowed(false)
		}
	}, [systemRelatedData?.isDifferentVersion])

	return (
		<>
			<Overlay isShow={isShowed} data-testid="no-network-overlay">
				<TopContainer>
					<TopInnerContainer>
						<TopCenterContainer>
							{!isOnline && (
								<>
									<NoNetworkImg isTop src={TopNoNetworkIcon} />
									<p>
										You’re offline {window.innerWidth > 768 ? '. Saving your last edits ...' : ''}
									</p>
								</>
							)}
							{beatsRelatedData?.serverError && isOnline && (
								<>
									<NoNetworkImg isTop src={UpdatingIcon} />
									<p>Trying to fetch ...</p>
								</>
							)}
							{beatsRelatedData?.noElementError && isOnline && (
								<>
									<NoNetworkImg isTop src={UpdatingIcon} />
									<p>Updating ...</p>
								</>
							)}
							{systemRelatedData?.isDifferentVersion && (
								<>
									<NoNetworkImg isTop src={AlertVersionIcon} />
									<p>Lynit has been updated. Reloading your story ...</p>
								</>
							)}
						</TopCenterContainer>
						<TopRightContainer>
							{systemRelatedData?.isDifferentVersion ? (
								<p>{`Lynit v.${localStorage?.version}`}</p>
							) : (
								<p>Last update at {lastUpdateTime}</p>
							)}
						</TopRightContainer>
					</TopInnerContainer>
				</TopContainer>
				{!isOnline && (
					<ModalContainer>
						<div className="title">
							<NoNetworkImg src={NoNetworkIcon} />
							Ooops, no internet connection
						</div>
						<div className="content">
							<b>Please check your network connection.</b> <br /> Don’t worry, we’ll sync your last
							edits once your internet connection is back. <br />
						</div>
						<ButtonContainer></ButtonContainer>
					</ModalContainer>
				)}
				{beatsRelatedData?.serverError ? (
					<StatusNotification>
						There's a problem with the server, trying to reconnect.
					</StatusNotification>
				) : (
					''
				)}
			</Overlay>
		</>
	)
}

export default NoNetworkOverlay
