import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import { recommendationOpened } from '@lynit/shared/src/state/actions'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import useRecommendations from '@lynit/shared/src/hooks/useRecommendations'
import LoadingSpinner from '@lynit/shared/src/shared-ui/LoadingSpinner'
import Tutorials from '@lynit/shared/src/ui/Tutorials'
import { useStoryOutlineWidth } from '@lynit/shared/src/utils/utils'

import craftImage from '@lynit/shared/src/images/craft.svg'
import outlineImage from '@lynit/shared/src/images/outline.svg'
import habitImage from '@lynit/shared/src/images/habit.svg'

import { Main, Header, Tab, RecommendationsContainer } from './styles'
import emptySpeedUpRecommendationsImg from '../../images/empty-speed-up-recommendations-icon.svg'
import emptyCraftRecommendationsImg from '../../images/empty-craft-recommendations-icon.svg'
import emptyHabitRecommendationsImg from '../../images/empty-habit-recommendations-icon.svg'

import { setRecommendationOpen } from '../../state/actions'
import { storyOutlineContext, StoryOutlineContextProvider } from '../../state/storyOutlineProvider'
import { NotificationIcon } from '../NotificationIcon'
import ValuePropRecommendations from './ValuePropRecommendations'
import RecommendationDetails from './RecommendationDetails'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'

const rightPanelTabs = ['RECOMMENDATIONS']
const normalTabs = ['RECOMMENDATIONS', 'LEARN WITH LYNIT']

function Recommendations({ isRightPanel }) {
	const tabs = isRightPanel ? rightPanelTabs : normalTabs

	const [activeTab, setActiveTab] = useState(isRightPanel ? tabs[0] : tabs[0])
	const [currentRecommendation, setCurrentRecommendation] = useState()

	const {
		craftRecommendations,
		speedRecommendations,
		habitRecommendations,
		fetchingRecommendations,
		activeSeenOpenedRecommendations,
	} = useRecommendations()

	const { isNarrow } = useStoryOutlineWidth()

	const {
		state: { isRecommendationOpen },
		dispatch,
	} = useContext(storyOutlineContext)

	const {
		state: {
			recommendationId,
			unseenRecommendationCount: { total: unseenRecommendationCount },
			tutorialId,
			clickedValueProp,
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	const {createLog} = useContext(systemStateContext)

	useEffect(() => {
		return () => {
			dispatch(setRecommendationOpen(false))
		}
	}, [])

	useEffect(() => {
		if (clickedValueProp && activeTab !== tabs[1]) {
			setActiveTab(tabs[1])
		}
	}, [clickedValueProp])

	// open "learn with lynit" tab if user clicks on tutorial link
	useEffect(() => {
		if (!isRightPanel && tutorialId && activeTab !== tabs[1]) {
			setActiveTab(tabs[1])
		}
	}, [tutorialId])

	useEffect(() => {
		if (!isRightPanel && recommendationId && activeSeenOpenedRecommendations?.length) {
			if (activeTab !== tabs[0]) {
				setActiveTab(tabs[0])
			}
			const recommendation = activeSeenOpenedRecommendations?.find(
				recommendation => recommendation.id === recommendationId,
			)
			const propName = recommendation?.valueProps[0] || ''
			const valueProp = valueProps?.find(prop => prop.name === propName)
			const valuePropImage = valueProp?.valuePropImage
			setCurrentRecommendation({
				data: recommendation,
				propName,
				valuePropImage,
			})
			dispatch(setRecommendationOpen(true))
			sharedDispatch(recommendationOpened())
		}
	}, [recommendationId, activeSeenOpenedRecommendations])

	const valueProps = useMemo(() => [
		{
			name: 'craft',
			valuePropImage: craftImage,
			valuePropTitle: 'Improve your Craft',
			recommendations: craftRecommendations,
			emptyStateImg: emptyCraftRecommendationsImg,
		},
		{
			name: 'speed',
			valuePropImage: outlineImage,
			valuePropTitle: 'Speed up your Outlining',
			recommendations: speedRecommendations,
			emptyStateImg: emptySpeedUpRecommendationsImg,
		},
		{
			name: 'habit',
			valuePropImage: habitImage,
			valuePropTitle: 'Build the Habit',
			recommendations: habitRecommendations,
			emptyStateImg: emptyHabitRecommendationsImg,
		},
	])

	return (
		<Main
			data-public
			data-dd-privacy="allow"
			isRightPanel={isRightPanel}
			isOpen={isRecommendationOpen}
			isNarrow={isNarrow}
			isTutorialOpened={!isRightPanel && activeTab === tabs[1]}
		>
			<Header>
				{tabs.map(tab => (
					<Tab key={tab} onClick={() => setActiveTab(tab)} isActive={tab === activeTab}>
						{!isRightPanel &&
						unseenRecommendationCount > 0 &&
						tab === tabs[0] &&
						tab !== activeTab ? (
							<NotificationIcon right={-12} top={-7} width={15} />
						) : (
							<></>
						)}
						<span>{tab}</span>
					</Tab>
				))}
			</Header>
			{activeTab === tabs[0] &&
				(currentRecommendation ? (
					<RecommendationDetails
						currentRecommendation={currentRecommendation}
						setCurrentRecommendation={setCurrentRecommendation}
						isRightPanel={isRightPanel}
					/>
				) : fetchingRecommendations ? (
					<LoadingSpinner />
				) : (
					<RecommendationsContainer isRightPanel={isRightPanel}>
						{valueProps.map(
							({ name, valuePropImage, valuePropTitle, recommendations, emptyStateImg }) => (
								<ValuePropRecommendations
									key={valuePropTitle}
									propName={name}
									valuePropImage={valuePropImage}
									valuePropTitle={valuePropTitle}
									recommendations={recommendations}
									emptyStateImg={emptyStateImg}
									fetchingRecommendations={fetchingRecommendations}
									setCurrentRecommendation={setCurrentRecommendation}
									isRightPanel={isRightPanel}
								/>
							),
						)}
					</RecommendationsContainer>
				))}
			{activeTab === tabs[1] && (
				<Tutorials currentRecommendation={currentRecommendation} createLog={createLog} />
			)}
		</Main>
	)
}

export default memo(Recommendations)
