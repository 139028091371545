import React, { useState, useContext } from 'react'
import { Tooltip, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { sharedContext } from '../../state'
import { color, Menu, PillWrapper } from './styles'
import { systemStateContext } from '../../state/systemProvider'

const useStyles = makeStyles(() => ({
	tooltip: {
		marginLeft: 0,
		background: 'transparent',
	},
	tooltipSm: {
		margin: '0 auto',
		background: 'transparent',
	},
}))

const PillComponent = ({
	type = 'connection',
	count,
	shouldHover,
	isLeftPanel,
	connectionCount,
	beatCount,
	...props
}) => {
	const classes = useStyles()
	const sm = useMediaQuery('(max-width:600px)')

	const [isHover, setHover] = useState(false)
	const systemRelatedData = useContext(systemStateContext)

	const handleMouseHover = bool => {
		if (type !== 'wordCount' && !shouldHover) {
			setHover(bool)
		}
	}

	return (
		<Tooltip
			title={
				<Menu sm={sm}>
					{Object.entries(type === 'connection' ? connectionCount : beatCount ?? {}).map(
						([key, value]) => {
							if (key === 'Total') {
								return
							}
							return (
								<div className="wrapper" key={key}>
									{key} <span>{value ?? 0}</span>{' '}
								</div>
							)
						},
					)}
				</Menu>
			}
			placement={sm ? 'bottom' : 'right-start'}
			open={type !== 'wordCount' && !shouldHover && isHover}
			classes={{
				tooltip: sm ? classes.tooltipSm : classes.tooltip,
			}}
		>
			<PillWrapper
				{...props}
				color={color?.[type]}
				onMouseEnter={() => {
					if (beatCount && type === 'beat') {
						systemRelatedData?.createLog(
							'Beats Count Hovered',
							`{"location":"${isLeftPanel ? 'ListElement' : 'ChapterCard'}"}`,
							'PillComponent',
							null,
						)
					}
					handleMouseHover(true)
				}}
				onMouseLeave={() => handleMouseHover(false)}
			>
				<span>
					{count ?? (type === 'connection' ? connectionCount?.['Total'] : beatCount?.['Total'])}
				</span>
				{color?.[type]?.name}
			</PillWrapper>
		</Tooltip>
	)
}

export default PillComponent
