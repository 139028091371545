import nlp from "compromise"



export const getAiRecommendationFromText = (text) => {

    const aiRecommendations = []
    let doc = nlp(text);
    doc.people().json().map(term => {
        aiRecommendations.push({driverType: 'Character', name: term.text})

    })
    return aiRecommendations
}
