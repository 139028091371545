import styled from 'styled-components'
import { colorStyles, nodeColors } from '../../utils/commonStyles'

const Container = styled.div`
	height: 3.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: 0.5px solid #c4c4c4;
	justify-content: space-between;
	background: white;
	width: 100vw;
	background-color: white;
`

const MenuContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${props => (props.isSmallScreen ? 0 : '20px')};
	flex-direction: ${props => (props.isSmallScreen ? 'row-reverse' : 'row')};
`

const FrameOption = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	//gap: 24px;
	@media (max-width: 869px) {
		/* gap: 15px; */
		right: 30px;
		position: relative;
	}
`

const NameButton = styled.div`
	width: 2.3rem;
	height: 2.3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #c4c4c4;
	color: white;
	font-weight: bold;
	border-radius: 0.5rem;
	border: none;
	object-fit: contain;
	cursor: pointer;
	@media (max-width: 880px) {
		width: 2rem;
		height: 2rem;
	}

	@media (max-width: 600px) {
		width: 23px;
		height: 23px;
		font-size: 14px;
	}
`

const LogoutButton = styled.div`
	width: 12rem;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	object-fit: contain;
	z-index: 999;
	cursor: pointer;
	text-decoration: none;
	&.userNameLabel {
		padding: 0px 2px 10px;
		text-align: center;
		justify-content: center;
		align-items: center;
		border-bottom: 0.5px solid #ada9a97a;
		width: 100%;
	}
	p {
		text-align: left;
		word-break: normal;
		width: 100%;
		padding-left: 4%;
		margin: 0;
	}
	img {
		width: 15px;
		height: 15px;
		overflow: auto;
	}
	@media (max-width: 880px) {
		width: auto;
		font-size: 0.7rem;
	}
`

const HeaderLeftContainer = styled.div`
	width: auto;
	height: auto;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	margin-right: 0rem;
	padding-left: 0.5rem;
	.logo-container {
		position: relative;
		/* ::after {
			position: absolute;
			content: '';
			height: 13px;
			border: 1px solid #666666;
			right: -5px;
			top: 50%;
			transform: translateY(-50%);
		} */
		margin-right: 15px;
	}
	@media (min-width: 601px) {
		.logo-container {
			position: relative;
			::after {
				position: absolute;
				content: '';
				height: 13px;
				border: 1px solid #666666;
				right: -5px;
				top: 50%;
				transform: translateY(-50%);
			}
			margin-right: 15px;
		}
	}
`

const MenuContent = styled.div`
	width: auto;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

const ProfileOptions = styled.div`
	width: 200px;
	height: auto;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 2.2rem;
	//right: 0.5rem;
	z-index: 99999999999;
	padding: 12px 8%;
	gap: 12px;
	box-shadow: -2px 4px 10px 4px #c4c4c470;
	background: #fefefe;
	border: 1px solid #c4c4c4;
	border-radius: 5px;
	@media (max-width: 880px) {
	}
	@media (max-width: 600px) {
		top: 2rem;
		left: -2rem;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 2rem;
		top: -6px;
		width: 10px;
		height: 10px;
		background: #ffffff;
		border-right: 1px solid #cad5e0;
		border-bottom: 1px solid #cad5e0;
		transform: rotate(-135deg);
		z-index: 999;
		@media (max-width: 880px) {
			right: 1.5rem;
		}
		@media (max-width: 600px) {
			right: 1.3rem;
		}
		@media (max-width: 500px) {
			right: 0.2rem;
		}
	}
`
const MenuOption = styled.div`
	width: 150px;
	height: auto;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 2.8rem;
	right: 20%;
	z-index: 99999999999;
	padding: 10px;
	gap: 12px;
	box-shadow: -2px 4px 10px 4px #c4c4c470;
	background: #fefefe;
	border: 1px solid #c4c4c4;
	border-radius: 5px;
	&:after {
		content: '';
		display: block;
		position: absolute;
		right: 6%;
		top: -6px;
		width: 10px;
		height: 10px;
		background: #ffffff;
		border-right: 1px solid #cad5e0;
		border-bottom: 1px solid #cad5e0;
		transform: rotate(-135deg);
		z-index: 999;
	}
`

const Box = styled.div`
	width: auto;
	height: auto;
	display: flex;
	flex-direction: column;
`

const LynitLogo = styled.img`
	height: 22px;
	cursor: pointer;
`

const ViewLabel = styled.p`
	margin: 0;
	font-size: 13px;
	text-align: center;
	font-weight: 500;
	text-decoration: ${props => (props.isActive ? `underline` : `unset`)};
`

const ViewContainer = styled.div`
	width: 80px;
	height: 50px;
	display: flex;
	flex-direction: column;
	background: ${props => (props.isActive ? '#FFC300' : 'none')};
	cursor: pointer;
	filter: ${props => (props.isActive ? 'drop-shadow(0px 0px 2px rgba(255, 195, 0, 1))' : 'none')};
	align-items: center;
	gap: 8px;
	padding-top: 6px;
	//justify-content: space-between;
	@media (max-width: 425px) {
		//height: 35px;
		p {
			font-size: 10px;
		}
	}
	:hover{
		background: #FFC3001c
	}
`

const OutlliningLogo = styled.img`
	height: 18px;
	cursor: pointer;
`
const WritingLogo = styled.img`
	height: 18px;
	cursor: pointer;
`

const ProfileContainer = styled.div`
	width: 91px;
	margin-right: 5px;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	user-select: none;
	@media (max-width: 880px) {
		width: auto;
		min-width: auto;
	}
	img {
		width: 0.8rem;
		@media (max-width: 1024px) {
			padding: none;
		}
		@media (max-width: 500px) {
			//display: none;
		}
	}
	p {
		font-size: 14px;
		margin: 0;
		color: #555555;
		@media (max-width: 1024px) {
			padding: none;
		}
		@media (max-width: 880px) {
			//display: none;
		}
	}
	cursor: pointer;
`

const HelperDiv = styled.div`
	width: auto;
	height: auto;
	display: flex;
	max-width: 40rem;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	/* margin-left: 1rem; */
	@media (max-width: 600px) {
		display: none;
		min-width: auto;
		max-width: 20rem;
	}
	@media (max-width: 950px) {
		display: none;
	}
`

const CreateElementBar = styled.nav`
	//transform: rotate(90deg);
	background-color: #fbf6e8;
	flex-direction: column;
	display: flex;
	//max-width: 60px;
	align-items: center;
	//gap: 40px 42px;
	overflow: hidden;
	color: #c7c7c7;
	white-space: nowrap;
	text-align: center;
	justify-content: center;
	padding: 9px 12px 9px 9px;
	font: 600 10px Source Sans Pro, sans-serif;
	z-index: 99999999;
	position: relative;
	//top: 3.2rem;
	//right: 0.5rem;
`

const ItemWrapper = styled.div`
	//transform: rotate(-90deg);
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	//margin: auto 0;
	position: relative;
	margin: 15px 0 0 0 !important;
	border-radius: 100px;
	width: 2rem;
	height: 3rem;
	cursor: pointer;
`

const ItemIcon = styled.img`
	aspect-ratio: 1;
	object-fit: contain;
	object-position: center;
	width: 1.3rem;
	height: 1.3rem;
	//border-radius: ${props => (props.label === 'Beat' ? '0' : '50px')};
`

const ItemLabel = styled.span`
	margin-top: 4px;
	color: ${props => (props.isSelected ? '#000000' : '#c7c7c7')};
`

const CreateStoryElementButton = styled.button`
	width: auto;
	height: auto;
	background-color: #f7ecce;
	border: none;
	border-radius: 5px;
	color: black;
	font-size: 0.7rem;
	font-weight: bold;
	font-family: 'Source Sans Pro', sans-serif;
	align-self: center;
	box-shadow: 0 4px 4px -2px #c4c4c4;
	cursor: pointer;
	padding: 0.5rem;
	white-space: nowrap;
	position: relative;
	/* right: 30px; */
	&:hover {
		text-decoration: none;
		transform: translateY(-2px);
	}
	&:active {
		transform: translateY(0);
		transition: background 400ms;
	}
	@media (min-width: 601px) and (max-width: 868px) {
		font-size: 0.6rem;
		padding: 0.5rem 0.5rem;
	}
	@media (max-width: 600px) {
		font-size: 0.6rem;
	}
`

const IconContainer = styled.div`
	position: relative;
	width: 1.7rem;
	height: 1.7rem;
	border-radius: 100px;
	text-align: center;
	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${props => (props.isSelected ? nodeColors[props.elementType] : 'transparent')};
`

export {
	Box,
	Container,
	LynitLogo,
	NameButton,
	HelperDiv,
	MenuContent,
	LogoutButton,
	MenuContainer,
	ProfileOptions,
	ProfileContainer,
	HeaderLeftContainer,
	FrameOption,
	OutlliningLogo,
	WritingLogo,
	ViewLabel,
	ViewContainer,
	CreateElementBar,
	ItemWrapper,
	ItemIcon,
	ItemLabel,
	CreateStoryElementButton,
	IconContainer,
	MenuOption,
}
