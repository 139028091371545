import { LOGIN, LOGOUT } from '../actionTypes'

const initialState = {
	isLoggedIn: !!localStorage.getItem('token'),
}

// Reducer
export default (state = initialState, action) => {
	switch (action.type) {
		// TODO real user authentication. Save a token?
		case LOGIN: {
			return {
				isLoggedIn: true,
			}
		}

		case LOGOUT: {
			return {
				isLoggedIn: false
			}
		}

		default:
			return state
	}
}
