import React, { useState, useEffect, useContext, memo, useRef } from 'react'
import { useMediaQuery } from '@mui/material'
import orderBy from 'lodash/orderBy'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import {
	DndContext,
	MouseSensor,
	TouchSensor,
	useSensors,
	useSensor,
	MeasuringStrategy,
} from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers'

import BorderLinearProgress from '../../shared-ui/BorderLinearProgress'
import {
	CollapsibleContainer,
	Container,
	EmptyState,
	HorizontalLine,
	MobileLowerContainer,
	NewTaskBtn,
	RecommendationsContainer,
	Title,
} from './styles'

import expandIcon from '../../images/downCheveron.svg'
import addIcon from '../../images/add-driver-icon.svg'
import tasksEmptyState from '../../images/tasks-empty-state.svg'
import useRecommendations from '../../hooks/useRecommendations'
import { toastHandler } from '../../utils/backendHandler'
import UserTask from './UserTask'
import LynitTask from './LynitTask'
import { useCreateTaskMutation, useGetTasksQuery, useReorderTasksMutation } from '../../hooks'
import { systemStateContext } from '../../state/systemProvider'
import { throttle } from 'lodash'

const TaskBar = () => {
	const { createLog }= useContext(systemStateContext)

	const [totalProgress, setTotalProgress] = useState(0)
	const [numberOfIncompleteTasks, setNumberOfIncompleteTasks] = useState(0)
	const [orderedTasks, setOrderedTasks] = useState([])
	const [newTaskId, setNewTaskId] = useState('')
	const [isExpanded, setIsExpanded] = useState(false)

	const [createTask] = useCreateTaskMutation()
	const { data: tasksData, refetch: refetchTasks } = useGetTasksQuery()
	const [reorderTasks] = useReorderTasksMutation()
	const { activeSeenOpenedRecommendations, completedRecommendations } = useRecommendations()



	const handleCreateUserTask = async () => {
		try {
			const newTask = await createTask({
				variables: {
					input: {
						description: '',
						completed: false,
					},
				},
			})
			await refetchTasks()
			setNewTaskId(newTask.data.createTask.id)
		} catch (error) {
			console.log(error)
			toastHandler('error', 'error creating a task, try again')
		}
	}
	
	const throttleHandleCreateUserTask = useRef(
		throttle(() => handleCreateUserTask(),9999, { 'trailing': false })
	 ).current

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 10,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				distance: 10,
			},
		}),
	)

	useEffect(() => {
		const recommendations = [...activeSeenOpenedRecommendations||[], ...completedRecommendations||[]]
		if (tasksData) {
			const allTasks = tasksData.tasks || []
			let tasks =
				allTasks.map(task => {
					// if lynit task then add recommendation field
					if (task.recommendationId) {
						const recommendation = recommendations.find(rec => rec.id === task.recommendationId)
						return { ...task, recommendation, order: +task.order }
					}
					return { ...task, order: +task.order }
				})

			// order tasks by order field
			tasks = orderBy(tasks, ['order'], ['asc'])

			const hasLynitTask = tasks.some(task => task.recommendationId)
			if ((hasLynitTask && recommendations.length) || !hasLynitTask) {
				setOrderedTasks(tasks)
			}
		}
	}, [tasksData, completedRecommendations, activeSeenOpenedRecommendations])

	// set total progress and number of incomplete tasks
	useEffect(() => {
		if (orderedTasks?.length) {
			let incompleteTasks = 0
			orderedTasks.forEach(task => {
				// lynit task
				if (task.recommendationId) {
					if (task.recommendation && task.recommendation?.progress !== 100) {
						incompleteTasks++
					}
				}
				// user task
				else if (!task.completed) {
					incompleteTasks++
				}
			})

			setNumberOfIncompleteTasks(incompleteTasks)

			const finalProgress =
				orderedTasks.reduce((acc, current) => {
					if (current?.recommendationId) {
						return acc + current?.recommendation?.progress
					}
					return current.completed ? acc + 100 : acc
				}, 0) / orderedTasks.length

			setTotalProgress(Math.round(finalProgress))
		}
		if (orderedTasks?.length === 0) {
			setTotalProgress(0)
		}
	}, [orderedTasks])

	// close onboarding if use clicks outside of onboarding
	useEffect(() => {
		const onboardingContainer = document.getElementById('onboarding-container')
		const outsideClickHandler = e => {
			if (!onboardingContainer.contains(e.target)) {
				setIsExpanded(false)
			}
		}
		document.addEventListener('click', outsideClickHandler)

		return () => document.removeEventListener('click', outsideClickHandler)
	}, [])

	

	const handleDragEnd = ({ active, over }) => {
		if (active.id !== over.id) {
			const tasks = orderedTasks
			const mutationData = []
			const orderOfDestinationElement = tasks.find(task => task.id === over.id)?.order
			const orderOfSourceElement = tasks.find(task => task.id === active.id)?.order

			// up direction drag
			if (orderOfSourceElement > orderOfDestinationElement) {
				tasks.forEach(task => {
					if (task.order >= orderOfDestinationElement && task.order < orderOfSourceElement) {
						mutationData.push({
							id: task.id,
							order: `${task.order + 1}`,
						})
					} else if (task.order === orderOfSourceElement) {
						mutationData.push({
							id: task.id,
							order: `${orderOfDestinationElement}`,
						})
					}
				})
			}

			//down direction drag
			if (orderOfSourceElement < orderOfDestinationElement) {
				tasks.forEach(task => {
					if (task.order === orderOfSourceElement) {
						mutationData.push({
							id: task.id,
							order: `${orderOfDestinationElement}`,
						})
					} else if (task.order > orderOfSourceElement && task.order <= orderOfDestinationElement) {
						mutationData.push({
							id: task.id,
							order: `${task.order - 1}`,
						})
					}
				})
			}

			if (mutationData.length) {
				const updatedTasks = orderedTasks.map(task => {
					const mutationTask = mutationData.find(data => data.id === task.id)
					if (mutationTask) {
						return { ...task, order: +mutationTask.order }
					}
					return task
				})
				setOrderedTasks(orderBy(updatedTasks, ['order'], ['asc']))
				reorderTasks({
					variables: {
						newNodeOrder: mutationData,
					},
				})
			}
		}
	}

	return (
		<DndContext
			sensors={sensors}
			measuring={{
				droppable: {
					strategy: MeasuringStrategy.Always,
				},
			}}
			onDragEnd={handleDragEnd}
			modifiers={[restrictToParentElement, restrictToVerticalAxis]}
		>
			<CollapsibleContainer data-public data-dd-privacy="allow" id="onboarding-container">
				<Container isExpanded={isExpanded} data-testid="taskbar-container">
					<div
						onClick={() => {
							setIsExpanded(!isExpanded)
							if (!isExpanded) {
								createLog('Task Bar Opened', '{}', 'TaskBar', null)
							}
						}}
						className="title-bar"
						data-testid="taskbar"
					>
						<Title>
							<div className="left">
								<img className="expand-icon" src={expandIcon} alt="expand icon" />
								<span className="title">My Tasks:</span>
							</div>
							{orderedTasks.length ? (
								<span className="status">
									<b>
										{numberOfIncompleteTasks}/{orderedTasks.length}
									</b>{' '}
									&nbsp;Left
								</span>
							) : (
								<span className="status">
									<b>0</b>
								</span>
							)}
						</Title>
						{/* <BorderLinearProgress
							variant="determinate"
							value={totalProgress}
							barcolor="#FFC300"
							primarycolor="#F1F1F1"
							height={5}
						/>
						<HorizontalLine /> */}
					</div>
					<RecommendationsContainer data-public data-dd-privacy="allow">
						<SortableContext
							items={orderedTasks?.map(task => task.id)}
							strategy={verticalListSortingStrategy}
						>
							{orderedTasks?.map(task => {
								if (task.recommendationId) {
									return <LynitTask key={task.id} task={task} />
								}
								return (
									<UserTask
										key={task.id}
										task={task}
										tasks={orderedTasks}
										newTaskId={newTaskId}
										setNewTaskId={setNewTaskId}
										isExpanded={isExpanded}
									/>
								)
							})}
						</SortableContext>
					</RecommendationsContainer>
					{orderedTasks?.length ? (
						<HorizontalLine marginTop={5} />
					) : (
						<EmptyState>
							<img src={tasksEmptyState} alt="tasks empty state image" />
							<p>No remaining tasks.</p>
						</EmptyState>
					)}
					<NewTaskBtn onClick={throttleHandleCreateUserTask}>
						<img src={addIcon} alt="add driver icon" />
						New Task
					</NewTaskBtn>
				</Container>
			</CollapsibleContainer>
		</DndContext>
	)
}

const taskBarWrapper = TaskBar => props => {
	const isMobile = useMediaQuery('(max-width: 600px)')

	if (isMobile) {
		return (
			<MobileLowerContainer>
				<TaskBar {...props} />
			</MobileLowerContainer>
		)
	}
	return <TaskBar {...props} />
}

export default taskBarWrapper(memo(TaskBar))
