import styled from 'styled-components'

const DragHandler = styled.img`
	cursor: grab;
	width: 6px;
	height: 12px;
	margin: 0 2px 0 5px;
`

const LynitTask = styled.div`
	transition: background-color 0.2s;
	background-color: #fef6f0;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px;
	padding-right: 5px;
	height: 24px;

	.left {
		flex: 1;
		overflow: hidden;
		display: flex;
		align-items: center;
	}

	.right {
		display: flex;
		gap: 5px;
		.progress {
			margin: 0;
			font-size: 12px;
			font-weight: 700;
			color: #535353;
		}
		.dismiss-icon {
			width: 8px;
			cursor: pointer;
		}
	}

	.lynit-task-icon {
		margin-right: 5px;
	}

	.description {
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 12px;
		margin: 0;
		${({ isDisabled }) =>
			!isDisabled
				? `
				color: #535353;
				:hover {
						text-decoration: underline;
						cursor: pointer;
				}
		`
				: `
				text-decoration: line-through;
				color: #888888;
		`}
	}

	@media (min-width: 769px) {
		.hidden {
			visibility: hidden;
		}

		:hover {
			background-color: #ffebdc;
			box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.08);
			.hidden {
				visibility: visible;
			}
		}
	}
`

export { DragHandler, LynitTask }
