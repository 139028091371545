/* eslint-disable import/prefer-default-export */

// Middleware to detect interaction store changes and notify the d3 visualization via javascript Events
// Must communicate to the Viz component via events instead of react state/props when we want to
// communicate state changes (e.g. hoveredElem) but don't want to trigger a rerender of the whole
// component (which would interrupt the force simulation animation).
export const interactionEvents = () => next => action => {
	const result = next(action)
	return result
}
