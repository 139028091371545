import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { StyledBackButton } from './styles'

function BackButton({ onClick }) {
	return (
		<StyledBackButton onClick={onClick}>
			<KeyboardBackspaceIcon />
		</StyledBackButton>
	)
}

export default BackButton
