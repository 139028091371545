import styled from 'styled-components'

const StyledBackButton = styled.button`
	cursor: pointer;
	background-color: #fff3cc;
	width: 25px;
	height: 25px;
	border: none;
	padding: 0;
	border-radius: 50%;
	svg {
		width: 15px;
	}
`

export { StyledBackButton }
