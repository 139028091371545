import { SET_RECOMMENDATION_OPENED, VIZ_NODE_HOVER } from './actionTypes'

export const setRecommendationOpen = payload => {
	return {
		type: SET_RECOMMENDATION_OPENED,
		payload,
	}
}
export const vizNodeHover = id => ({
	type: VIZ_NODE_HOVER,
	payload: {
		id,
	},
})
