import styled from 'styled-components'

const BeatsContainer = styled.div`
	width: auto;
	display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
	flex-direction: column;
	background: #d7edde;
	border: ${({ isEmpty }) => (isEmpty ? '2px dashed #78C091' : 'unset')};
	border-radius: 10px;
	box-shadow: ${({ isEmpty }) =>
		isEmpty ? 'unset' : '0px 0.888587px 0.888587px rgba(9, 30, 66, 0.1)'};
	user-select: none;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
	padding: 7px 5px;
`

const EmptyBeatContainer = styled.div`
	margin-top: 5px;
	height: 100%;
	overflow: hidden;
	.drop-placeholder {
		box-sizing: border-box;
		height: 100%;
		background-color: rgba(225, 244, 231, 0.4);
		border: 2px dashed #609d68;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			font-family: 'Source Sans Pro';
			font-size: 24px;
			line-height: 30px;
			color: #0d5d56;
		}
	}
	.empty-chapter-image {
		width: 120px;
		margin-top: 20px;
	}
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

const PillWrapper = styled.div`
	display: flex;
	gap: 5px;
`

const BeatsTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	.beats-title {
		font-size: 10px;
		font-weight: bold;
		margin: 0;
		white-space: nowrap;
	}
`

export { EmptyBeatContainer, BeatsContainer, PillWrapper, BeatsTitle }
