/* eslint-disable no-nested-ternary */
import styled from 'styled-components'

const QuillContainer = styled.div`
	width: 100%;
	opacity: ${props => (props.isEnabled ? 1 : 0.3)};
	margin-left: 0;
	height: auto !important;
	min-height: ${props => (props.type === 'Note' ? '80px' : props.type === 'Chapter' ? '40px' : 'unset')};
	max-height: ${props => (!props.isRightPanel && props.type === 'Chapter' ? 'unset' : 'unset')};
	cursor: ${props => (props.isEnabled ? 'default' : 'not-allowed')};
	//background: #f5f5f5;
	border-radius: 5px;
	border: none !important;
	.ql-editor.ql-blank::before {
		color: #6e6d6d;
	}

	${props =>
		props.type === 'Chapter' && !props.isRightPanel
			? `.ql-editor {
			padding: 5px 5px 5px 16px;
			font-size: 12px;
			overflow-x: hidden;
			//max-height: 80px;
			@media (max-width: 600px) {
				font-size: 10px;
			}
		}`
			: `.ql-editor {
		min-height: 5rem;

	}`}

	@media (max-width: 600px) {
		min-height: ${props => (props.type === 'Chapter' ? '80px' : '80px')};
	}
`

export { QuillContainer }
