import React from 'react'
import { BellIcon, BellIconContainer } from './styles'
import notificationIcon from '../../images/notification-icon.svg'

const NotificationIcon = ({ top, right, bottom, left, width }) => (
	<BellIconContainer>
		<BellIcon
			src={notificationIcon}
			alt="bell icon"
			top={top}
			right={right}
			bottom={bottom}
			left={left}
			width={width}
		/>
	</BellIconContainer>
)

export default NotificationIcon
