import { saveAs } from 'file-saver'
import * as quillToWord from 'quill-to-word'

const exportData = async (data, storyName) => {
	const quillToWordConfig = {
		exportAs: 'blob',
	}
	try {
		const docAsBlob = await quillToWord.generateWord(data, quillToWordConfig)
		saveAs(
			docAsBlob,
			storyName?.length > 0 ? `${storyName.replace(/\s/g, '_')}.docx` : 'My_story.docx',
		)
	} catch (e) {
		throw new Error('Error')
	}
}

export default exportData
